import { ui } from 'angular';

export class MeurhAcompanhamentoModalService {

    static $inject = [
        '$uibModal'
    ];

    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService,
    ) { }

    public open(
        textos: { titulo: string, descricao: string }
    ): ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./acompanhamentomodal.html'),
            controller: 'MeurhAcompanhamentoModalController',
            controllerAs: 'acompanhamentoController',
            windowClass: '',
            backdrop: 'static',
            resolve: {
                textos: function () {
                    return textos;
                }
            }
        });
    }

    public openModalEstabilidade(tipoEstabilidade: string): ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./modal-estabilidade.html'),
            controller: 'MeurhModalEstabilidadeController',
            controllerAs: 'modalEstabilidadeController',
            windowClass: '',
            backdrop: 'static',
            resolve: {
                tipoEstabilidade: function () {
                    return tipoEstabilidade;
                }
            }
        });
    }

}
