declare var nsjGlobals: any;
import angular from 'angular';
import moment from 'moment';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';
import { TrabalhadoresService } from '../../../Trabalhadores/trabalhadores.service';
import { MeurhSolicitacoespromocoesService } from '../promocoes.service';
import { SituacaoEnum } from '../../Relatoriossolicitacoes/enums/situacaoEnum';
import { TipoPromocaoEnum } from '../enums/tipoPromocaoEnum';

export class MeurhPromocoesPassoumController implements angular.IController {

    static $inject = ['$rootScope', '$scope', '$state', '$http', '$stateParams', '$location', '$transitions', 'nsjRouting', 'NewToaster', 'TrabalhadoresService', '$sce', 'MeurhSolicitacoespromocoesService', 'ModalConfirmService', '$q'];

    public busy: boolean = false;
    public dataAtual: string = moment().format('YYYY-MM-DD');
    public dataMinimaEfetivacao: string;
    public dataMaximaEfetivacao: string;
    public entity: ITrabalhador;
    public entitySolicitacao: any = {};
    public passo: number = 1;
    public mensagensPassos: any = [];
    public passoZeroAtivo: string;
    public trabalhador: string = this.$stateParams['trabalhador'];
    public colaboradorSelecionado: ITrabalhador;
    public allowRefresh: boolean = false;
    public cardContents: any;

    public carregandoPromocoesAnteriores: boolean = false;
    public promocoesAnterioresTrabalhador: any = [];
    public exibeAvisoPromocoesAnteriores: boolean = false;
    public tipoPromocaoEnum = TipoPromocaoEnum;
    public tituloAvisoPromocoesAnteriores: string;
    public textoAvisoPromocoesAnteriores: string;

    constructor(
        public $rootScope: angular.IRootScopeService & { configuracoes: any, temPermissao: (arg: string) => boolean  },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $http: angular.IHttpService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public $transitions: any,
        public nsjRouting: any,
        public NewToaster: any,
        public entityService: TrabalhadoresService,
        public $sce: angular.ISCEService,
        public MeurhSolicitacoespromocoesService: MeurhSolicitacoespromocoesService,
        public ModalConfirmService: any,
        public $q: any
    ) {
        $scope.$on('persona_trabalhadores_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.entity = args;

            this.montaCards(args.cargo.nome, args.nivelcargo.codigo);

            if (this.$stateParams.solicitacao !== undefined) {
                this.MeurhSolicitacoespromocoesService.get(this.$stateParams.solicitacao);
            } else {
                this.alteraEntity();
                this.busy = false;
            }
        });

        $scope.$on('meurh_solicitacoespromocoes_getloaded', (event: angular.IAngularEvent, args: any): void => {
            this.entitySolicitacao = args;
            this.alteraEntity();
            this.busy = false;
        });

        $scope.$on('meurh_solicitacoespromocoes_loaded', (event: angular.IAngularEvent, args: any): void => {

            if (args.length > 0) {
                // obtendo apenas as promoções nos status que devem ser considerados
                this.promocoesAnterioresTrabalhador = args.filter((promocao) => {
                    if (promocao.situacao === SituacaoEnum.ABERTA || promocao.situacao === SituacaoEnum.ENVIADA || promocao.situacao === SituacaoEnum.EFETUADA) {
                        return promocao;
                    }
                });
            }

            this.carregandoPromocoesAnteriores = false;
        });

        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    async $onInit(): Promise<void> {
        this.busy = true;
        this.carregandoPromocoesAnteriores = true;

        if (this.passoZeroAtivo === '0' && this.trabalhador) {
            this.buscaPromocoesColaborador(this.trabalhador);
        } else {
            this.buscaPromocoesColaborador(this.colaboradorSelecionado.trabalhador);
        }

        if (this.passoZeroAtivo === '0' && !this.valorExiste(this.entity)) {
            this.entityService.get(this.trabalhador);
            return;
        }

        if (!this.valorExiste(this.entity)) {
            this.entity = await this.entityService.get(this.colaboradorSelecionado.trabalhador);
            return;
        } else {
            this.montaCards(this.entity.cargoantigo.nome, this.entity.nivelcargoantigo.codigo);
        }

        this.setLimiteDataEfetivacao();

        this.busy = false;
    }

    alteraEntity(): void {
        this.entity.dataadmissaoFormatted = moment(this.entity.dataadmissao).format('DD/MM/YYYY');

        this.setLimiteDataEfetivacao();

        if (this.entitySolicitacao.solicitacao !== undefined) {
            this.entity.solicitacao = this.entitySolicitacao.solicitacao;
            this.entity.cargoantigo = this.entity.cargo;
            this.entity.nivelcargoantigo = this.entity.nivelcargo;
            this.entity.cargo = this.entitySolicitacao.cargo;
            this.entity.nivelcargo = this.entitySolicitacao.nivelcargo;
            this.entity.salarionovo = this.entitySolicitacao.salario ? this.entitySolicitacao.salario : 0;
            this.entity.tipopromocao = this.entitySolicitacao.tipopromocao;
            this.entity.bateponto = this.entitySolicitacao.bateponto;
            this.entity.descricaopromocao = this.entitySolicitacao.descricaopromocao;
            this.entity.data = this.entitySolicitacao.data;
            this.entity.unidadesalarionovo = this.entitySolicitacao.unidadesalarionovo;
            this.entity.unidadesalarioantigo = this.entitySolicitacao.unidadesalarioantigo;
            this.entity.descricaounidadesalarionovo = this.entitySolicitacao.descricaounidadesalarionovo ? this.entitySolicitacao.descricaounidadesalarionovo : null;
            this.entity.descricaounidadesalarioantigo = this.entitySolicitacao.descricaounidadesalarioantigo ? this.entitySolicitacao.descricaounidadesalarioantigo : null;
        }
    }

    setLimiteDataEfetivacao(): void {

        if (!this.valorExiste(this.dataAtual)) {
            this.dataAtual = moment().format('YYYY-MM-DD');
        }

        if (!this.valorExiste(this.dataMinimaEfetivacao)) {

            if (!this.valorExiste(this.entity.dataadmissao)) {
                this.dataMinimaEfetivacao = this.dataAtual;
            } else {
                this.dataMinimaEfetivacao = moment(this.entity.dataadmissao).add(1, 'day').format('YYYY-MM-DD');
            }
        }

        if (!this.valorExiste(this.dataMaximaEfetivacao)) {

            this.dataMaximaEfetivacao = this.dataAtual;

            if (this.$rootScope.configuracoes.SOLICITACAO_PROMOCAO_DATA_FUTURA.ativado === true) {
                this.dataMaximaEfetivacao = moment(this.dataAtual).add(this.$rootScope.configuracoes.SOLICITACAO_PROMOCAO_DATA_FUTURA.dias, 'day').format('YYYY-MM-DD');
            }
        }
    }

    isBusy(): boolean {
        return this.busy || this.carregandoPromocoesAnteriores;
    }

    verificaTipoPromocao(valor: string | number) {
        return this.entity.tipopromocao === valor;
    }

    togglePromocao(tipo: number): void {
        this.entity.tipopromocao = tipo;

        switch (tipo) {
            case 0:
                this.entity.mensagemTipoPromocao = 'mudará de cargo';
                break;
            case 1:
                this.entity.mensagemTipoPromocao = 'mudará de nível de cargo';
                break;
            case 2:
                this.entity.mensagemTipoPromocao = 'receberá um aumento salarial';
                break;
        }

        if (this.valorExiste(this.entity.dataFormatted)) {
            this.montaMensagemPasso();
        }
    }

    formatData(): void {
        this.entity.dataFormatted = moment(this.entity.data).format('DD/MM/YYYY');

        if (this.valorExiste(this.entity.tipopromocao)) {
            this.montaMensagemPasso();
        }
    }

    montaMensagemPasso(): void {
        if (!this.entity.mensagemTipoPromocao && this.entity.tipopromocao !== null && this.entity.tipopromocao !== undefined) {
            this.togglePromocao(this.entity.tipopromocao);
        }

        this.mensagensPassos['mensagempasso1'] = this.entity.nome + ' ' + this.entity.mensagemTipoPromocao + ' e a data de efetivação da promoção será ' + this.entity.dataFormatted;
    }

    limpaCampos(): void {
        this.allowRefresh = false;
        this.entity = null;
        this.calculaPasso(false);
    }

    formInvalido(): boolean {
        return (!this.valorExiste(this.entity.tipopromocao) || !this.valorExiste(this.entity.data));
    }

    calculaPasso(continua: boolean): void {
        this.allowRefresh = false;
        this.passo = continua ? this.passo + 1 : this.passo - 1;
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, !this.entity.solicitacao ? ' criação' : ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'promocoes' && this.allowRefresh) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, !this.entity.solicitacao ? ' criação' : ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }

    montaCards(nomeCargo: string, codigoCargo: string) {
        this.cardContents = [
            {
                'url_img': 'https://s3.sa-east-1.amazonaws.com/imagens.nasajon/geral/sistemas/departamento-pessoal-e-recursos-humanos/ilustracoes/promocoes/mudanca-de-cargo.svg',
                'title': 'Mudança de cargo',
                'description': `O colaborador passará de ${nomeCargo} ${codigoCargo} para um novo cargo.`,
            },
            {
                'url_img': 'https://s3.sa-east-1.amazonaws.com/imagens.nasajon/geral/sistemas/departamento-pessoal-e-recursos-humanos/ilustracoes/promocoes/aumento-de-nivel-do-cargo.svg',
                'title': 'Aumento de nível de cargo',
                'description': `O colaborador passará do nível ${codigoCargo} para um novo nível.`,
            },
            {
                'url_img': 'https://s3.sa-east-1.amazonaws.com/imagens.nasajon/geral/sistemas/departamento-pessoal-e-recursos-humanos/ilustracoes/promocoes/aumento-salarial.svg',
                'title': 'Aumento de salário',
                'description': 'O colaborador receberá um aumento em seu salário atual.',
            }
        ];
    }

    buscaPromocoesColaborador(idTrabalhador: string) {
        this.MeurhSolicitacoespromocoesService.filters = {'trabalhador.trabalhador': idTrabalhador};

        this.MeurhSolicitacoespromocoesService.constructors = {forcagestor: false, status: '00'};
        this.MeurhSolicitacoespromocoesService.after = {};
        this.MeurhSolicitacoespromocoesService.filter = '';

        this.MeurhSolicitacoespromocoesService.reload();
    }

    verificaExibicaoAvisoPromocaoAnterior() {

        if (this.entity.data && this.promocoesAnterioresTrabalhador.length > 0) {
            let temPromocaoAnteriorMudancaCargo: boolean = false;

            this.exibeAvisoPromocoesAnteriores = this.promocoesAnterioresTrabalhador.some(promocao => {
                if (promocao.solicitacao !== this.entity.solicitacao) {
                    return promocao.data === this.entity.data;
                }
            });

            if (this.exibeAvisoPromocoesAnteriores) {
                this.tituloAvisoPromocoesAnteriores = 'Promoção já enviada na data selecionada';
                this.textoAvisoPromocoesAnteriores = 'Uma promoção já foi enviada na data selecionada para esse colaborador.';

                // verificando a mensagem a ser exibida
                if (this.entity.tipopromocao === TipoPromocaoEnum.AUMENTO_SALARIAL) {

                    temPromocaoAnteriorMudancaCargo = this.promocoesAnterioresTrabalhador.some(promocao => {
                        return promocao.tipopromocao === TipoPromocaoEnum.MUDANCA_CARGO && promocao.data === this.entity.data;
                    });

                    if (temPromocaoAnteriorMudancaCargo) {
                        this.textoAvisoPromocoesAnteriores = 'Uma promoção de mudança de cargo foi enviada para este colaborador na data selecionada, resultando em um aumento salarial automático, sem a necessidade de outra solicitação.';
                    }

                }
            } else {
                this.tituloAvisoPromocoesAnteriores = this.textoAvisoPromocoesAnteriores = '';
            }

        } else {
            this.exibeAvisoPromocoesAnteriores = false;
            this.tituloAvisoPromocoesAnteriores = this.textoAvisoPromocoesAnteriores = '';
        }
    }

}
