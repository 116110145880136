import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { MeurhSolicitacoesrescisoesService } from '../solicitacoesrescisoes.service';
import { IRescisao } from '../models/rescisao.model';
import { SituacaoRescisoesEnum } from '../enums/situacaorescisoes';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { TipoCumprimentoAvisoEnum } from '../enums/tipocumprimento';
import { IConfiguracaoRescisao } from '../models/configuracaorescisao';
import { MeurhRescisoesModalConfirmService } from '../modal/modalrescisao.confirm.service';
import { IModalConfirmacao } from '../models/modalconfirm';
import { MotivoRescisaoEnum } from '../enums/motivorescisao';
import { TrabalhadoresService } from '../../../Trabalhadores/trabalhadores.service';
import { RescisaoPontoService } from '../../Ponto/Rescisao/rescisao.ponto.service';
import { MeurhAcompanhamentoService } from '../../Acompanhamento/acompanhamento.service';
import { IMovimentoExistenteRescisao } from '../models/movimentorescisao';
import { TipoMovimentoTrabalhadorEnum } from '../enums/tipomovimentotrabalhador';
export class MeurhSolicitacoesrecisoesEditController implements ng.IController {

    static $inject = [
        'entity',
        '$scope',
        '$stateParams',
        '$state',
        'MeurhSolicitacoesrescisoesService',
        'NewToaster',
        'TiposDocumentosRequeridosService',
        '$rootScope',
        'MeurhRescisoesModalConfirmService',
        'TrabalhadoresService',
        'RescisaoPontoService',
        'MeurhAcompanhamentoService',
        'HabilitadoService',
    ];

    public action: string = this.entity.solicitacao ? 'update' : 'insert';
    public dadosGeraisTitle: string;
    public form: angular.IFormController;
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;
    public busy: boolean = false;
    public tiposubmit: string;
    public configuracaoRescisao: IConfiguracaoRescisao;
    public nomeEstabilidade: string = '';

    // documentos / anexos
    public mostrarAlerta: boolean = false;
    public tiposdocumentosrequeridos: Array<any>;
    public busyTipoDocumentos: boolean = true;
    public busyAnexo: boolean = false;
    public editanexo: boolean = true;
    public adicionarAnexo: boolean = false;
    public alterouanexo: boolean = false;
    public documentosValidados: boolean = false;
    public entityAnexo: IAnexo = {};
    public carregandoMovimentos: boolean = false;
    public movimentosAtivados: boolean;

    private toastTimeout: number = 8000;
    private entitySave: IRescisao;

    private tipoCumprimentoEnum = TipoCumprimentoAvisoEnum;
    private motivorescisao = MotivoRescisaoEnum;
    private dataAtualString: string;
    private codigosErroValidacaoMovimentos: Array<number> = [2, 3, 5, 6];
    private diferencaNosMovsExistentes: boolean = false;

    constructor(
        public entity: IRescisao,
        private $scope: angular.IScope,
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IState & { go: (route: string, params: any) => void },
        private entityService: MeurhSolicitacoesrescisoesService,
        private NewToaster: any,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        private $rootScope: angular.IRootScopeService & { configuracaoRescisao: IConfiguracaoRescisao },
        private MeurhRescisoesModalConfirmService: MeurhRescisoesModalConfirmService,
        private TrabalhadoresService: TrabalhadoresService,
        private RescisaoPontoService: RescisaoPontoService,
        private MeurhAcompanhamentoService: MeurhAcompanhamentoService,
        private HabilitadoService: any,
    ) {
        this.entity.criadoautomaticamente
            ? this.dadosGeraisTitle = 'Dados do término de contrato'
            : this.dadosGeraisTitle = 'Dados da rescisão';

        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.reload();

        this.entity.tipocumprimentoaviso_select = this.entity.tipocumprimentoaviso;

        $scope.$watch('mrh_slctcsrscss_frm_cntrllr.entity', (newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
            }
        }, true);

        $scope.$watch('mrh_slctcsrscss_frm_edit_cntrllr.entity.motivorescisao', (newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
                this.formchanged = true;
            }
        }, true);

        $scope.$on('rescisao_ponto_etapa_updated', () => {
            this.MeurhAcompanhamentoService.reload();
        });

        document.querySelector('form')?.addEventListener('change', (e) => {
            this.formchanged = true;
        });

        // inicio - configurações de documentos / anexos
        this.entityAnexo['solicitacao'] = this.entity.solicitacao;
        this.entityAnexo['casodeuso'] = 'RESCISOES';
        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();

        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                return tipodocumentorequerido;
            });
        });
        // fim - configurações de documentos / anexos

        this.onSubmitSuccess();
        this.onSubmitError();
        this.onDeleteSuccess();
        this.onDeleteError();

        // todo: resolver modais de descarte de saída de tela
        // this.exitScreenDescartarModal();
        // document.onkeydown = this.refreshDescartarModal;

        this.allowRefresh = true;

        // verificando se veio da modal informando diferença entre movimentos existentes na visualização
        this.diferencaNosMovsExistentes = this.$stateParams['diferenca_movs_existentes'];

        this.movimentosAtivados = this.HabilitadoService.estaHabilitado('LIBERAR_PAGAMENTO_MOVIMENTOS_RESCISAO');

    }

    async $onInit(): Promise<void> {
        !!this.$rootScope.configuracaoRescisao
            ? this.configuracaoRescisao = this.$rootScope.configuracaoRescisao
            : await this.getConfiguracaoRescisao();

        this.dataAtualString = moment().format('YYYY-MM-DD');
        let afastamentosTrabalhador = [];
        let dadosCipaTrabalhador;

        await this.TrabalhadoresService.getEstabilidadeTrabalhador(this.entity.trabalhador!.trabalhador)
        .then((dadosRetornados: any) => {
            afastamentosTrabalhador = dadosRetornados.afastamentos;
            dadosCipaTrabalhador = dadosRetornados.membrocipa;
            this.entity.colaboradorTemEstabilidade = (
                (dadosRetornados.afastamentos && dadosRetornados.afastamentos.length > 0 && this.algumAfastamentoTemPeriodoEstavel(afastamentosTrabalhador)) ||
                dadosRetornados.membrocipa
            ) ? true : false;
        });

        if (this.entity.colaboradorTemEstabilidade) {
            let infosEstabilidade = this.getFimPeriodoEstavel(afastamentosTrabalhador, dadosCipaTrabalhador);
            this.entity.fimPeriodoEstavel = infosEstabilidade.fimperiodoestavel;
            this.nomeEstabilidade = infosEstabilidade.descricaoestabilidade.toLowerCase();
        }

        this.exibeAvisosEstabilidade(this.entity.fimPeriodoEstavel, this.entity.datarescisao);

        // se abriu a edição pela modal informando que há diferenças nos movimentos existentes, buscar movimentos existentes e atualizar na tabela de movimentos
        if (this.diferencaNosMovsExistentes) {

            this.carregandoMovimentos = true;

            // buscar os movimentos existentes atualizados e atribuir na rescisão
            await this.entityService.getMovimentosRescisaoTrabalhador(this.entity.trabalhador!.trabalhador)
            .then((dadosRetornados: Array<IMovimentoExistenteRescisao>) => {
                this.entity.movimentosexistentescolaborador = dadosRetornados;
                this.ordenaMovimentosExistentes();
                this.carregandoMovimentos = false;
            })
            .catch((error: any) => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não foi possível carregar os movimentos existentes do colaborador',
                    body: error.data?.message
                });
                this.carregandoMovimentos = false;
            });
        }
    }

    /**
     * Monta as mensagens dos avisos sobre estabilidade que serão exibidos ou limpa as mensagens, caso não seja para exibir avisos
     * @param fimPeriodoEstavel A data que o período estável acaba
     * @param dataRescisao A data de rescisão
     */
    public exibeAvisosEstabilidade(fimPeriodoEstavel: string, dataRescisao: string) {
        if (this.entity.colaboradorTemEstabilidade) {
            this.entity.exibeInfosEstabilidade = !dataRescisao || (moment(dataRescisao).isSameOrBefore(fimPeriodoEstavel));

            if (this.entity.exibeInfosEstabilidade && moment(fimPeriodoEstavel).isSameOrAfter(this.dataAtualString)) {
                this.entity.msgEstabilidade = `O colaborador encontra-se em período de estabilidade até ${moment(this.entity.fimPeriodoEstavel).format('DD/MM/YYYY')}.`;
                this.entity.msgEstabilidadeModal = `${this.entity.trabalhador?.nome} está sob estabilidade de ${this.nomeEstabilidade} até ${moment(this.entity.fimPeriodoEstavel).format('DD/MM/YYYY')}.`;
            } else {
                this.entity.msgEstabilidade = this.entity.msgEstabilidadeModal = '';
            }

        } else {
            this.entity.exibeInfosEstabilidade = false;
            this.entity.msgEstabilidade = this.entity.msgEstabilidadeModal = '';
        }
        this.$scope.$applyAsync();
    }

    // inicio - ações do formulário
    public submit(rascunho: boolean = false): void {
        this.alteraEntityEnviar(rascunho);
        this.form.$submitted = true;

        if (rascunho) {
            this.busy = true;
            this.tiposubmit = 'salvar';
            this.allowRefresh = false;
            if (this.podeSalvarRascunho()) {
                this.entityService.save(this.entitySave);
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não é possível cadastrar um apontamento com a rubrica vazia!',
                    timeout: this.toastTimeout
                });
                this.busy = false;
            }
            return;
        }

        let customMessage: IModalConfirmacao = this.configuracaoRescisao.workflowativado
            ? this.getMensagensModal('envioWkf')
            : this.getMensagensModal('envio');

        if (this.entity.exibeInfosEstabilidade) {
            customMessage = this.getMensagensModal('envioEstabilidade');
        }

        this.tiposubmit = 'enviar';
        const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(this.entity, customMessage);

        confirm.result
            .then((): void => {
                this.busy = true;

                if (this.podeEnviar()) {
                    this.allowRefresh = false;
                    this.entityService.save(this.entitySave);
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Alguns campos obrigatórios não estão preenchidos',
                        body: 'Preencha os campos obrigatórios assinalados com "*" e tente novamente.',
                        timeout: this.toastTimeout
                    });

                    this.busy = false;
                }
            })
            .catch(function () {/**/ });
    }

    iniciarRescisao() {
        this.alteraEntityEnviar(true);
        this.form.$submitted = true;
        this.tiposubmit = 'salvar';
        this.busy = true;
        this.entityService.save(this.entitySave, false, () => {
            this.RescisaoPontoService.setEtapa(this.entitySave.solicitacao, {
                etapa: 1,
                etapadetalhe: 1
            })
            .then(() => {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A rescisão foi iniciada com sucesso.',
                    timeout: this.toastTimeout
                });
            })
            .catch(() => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao iniciar a rescisão.',
                    timeout: this.toastTimeout
                });
            })
            .finally(() => {
                this.busy = false;
                this.$state.go('meurh_solicitacoesrescisoes_show', this.entityService.constructors);
            });
        });
    }

    public delete(force: boolean): void {
        this.entityService.delete(this.$stateParams['solicitacao'], force);
    }

    public buttonDescartarModal(): void {
        if (this.formchanged) {
            const customMessage = this.getMensagensModal('descartar');
            const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(this.entity, customMessage);

            confirm.result.then((): void => {
                this.allowRefresh = false;
                this.$state.go('meurh_solicitacoesrescisoes_show',
                    angular.extend({}, {
                        'solicitacao': this.$stateParams['solicitacao'],
                        'tipo': this.$stateParams.tipo
                    })
                );
            })
                .catch((error: any): void => {/* */ });
        } else {
            this.$state.go('meurh_solicitacoesrescisoes_show',
                angular.extend({}, {
                    'solicitacao': this.$stateParams['solicitacao'],
                    'tipo': this.$stateParams.tipo
                })
            );
        }
    }
    // fim - ações do formulário

    // todo: resolver modais de descarte de saída de tela
    // public refreshDescartarModal = (e: any) => {
    //     e = e || window.event;
    //     if (this.formchanged && this.allowRefresh) {
    //         if (e.keyCode === 116) {
    //             e.preventDefault();
    //             const customMessage = this.getMensagensModal('descartar');
    //             const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(this.entity, customMessage);

    //             confirm.result.then((): void => {
    //                 this.allowRefresh = false;
    //                 window.location.reload();
    //             })
    //                 .catch((error: any): void => {/* */ });
    //         }
    //     }
    // }

    // public exitScreenDescartarModal(): void {
    //     this.$transitions.onBefore({}, (trans: any) => {
    //         const defer = this.$q.defer();

    //         if (
    //             trans._targetState._identifier !== 'meurh_solicitacoesrescisoes_edit' &&
    //             this.allowRefresh &&
    //             this.formchanged
    //         ) {
    //             const customMessage = this.getMensagensModal('descartar');
    //             const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(this.entity, customMessage);

    //             confirm.result.then((): void => {
    //                 const modalConfirmed = true;
    //                 this.allowRefresh = false;

    //                 if (modalConfirmed) {
    //                     defer.resolve();
    //                 } else {
    //                     defer.reject();
    //                 }
    //             })
    //                 .catch(function () { /**/ });
    //         } else {
    //             defer.resolve();
    //         }

    //         return defer.promise;
    //     });
    // }


    // início - controle de botões
    public podeSalvarRascunho(): boolean {
        return (
            this.validaColaboradorTemporario() &&
            !!this.entity.trabalhador &&
            !!this.entity.datarescisao &&
            !!this.entity.motivorescisao &&
            !this.form.$pristine
        );
    }

    public podeIniciarRescisao() {
        if (
            this.entity.etapaponto === 0 &&
            this.entity.situacao === -1 &&
            this.podeSalvarRascunho() &&
            this.entity.trabalhador?.marcaponto
        ) {
            const dataAtual = moment().format('YYYY-MM-DD');
            return moment(dataAtual).isSameOrAfter(moment(this.entity.datarescisao));
        }
        return false;
    }

    public podeEnviar(): boolean {
        return (
            this.validaColaboradorTemporario() &&
            this.validaCamposObrigatoriosEnviar() &&
            this.validaDocumentosAnexos() &&
            this.validaMovimentos()
        );
    }
    // fim - controle de botões


    // inicio - validações
    private validaColaboradorTemporario(): boolean {
        return (
            !this.entity.criadoautomaticamente ||
            ((
                this.entity.motivorescisao?.motivorescisao === this.motivorescisao.RESCISAO_ANTECIPADA_INICIATIVA_EMPREGADOR ||
                this.entity.motivorescisao?.motivorescisao === this.motivorescisao.RESCISAO_ANTECIPADA_INICIATIVA_EMPREGADO
            ) && moment(this.entity.datarescisao).isBefore(moment(this.entity.trabalhador?.datafimcontrato!)) ||
                this.entity.motivorescisao?.motivorescisao === this.motivorescisao.RESCISAO_TERMINO_CONTRATO_TERMO &&
                moment(this.entity.datarescisao).isBefore(moment(this.entity.trabalhador?.datafimcontrato!).add(1, 'day'))
            )
        );
    }

    private validaCamposObrigatoriosEnviar(): boolean {
        return (
            this.validaCamposObrigatoriosSalvar() &&
            !!this.entity.justificativa &&
            this.validaAvisoPrevio()
        );
    }

    private validaCamposObrigatoriosSalvar(): boolean {
        return (
            !!this.entity.trabalhador &&
            !!this.entity.datarescisao &&
            !!this.entity.motivorescisao
        );
    }

    private validaAvisoPrevio(): boolean {
        return this.entity.tipocumprimentoaviso_select === this.tipoCumprimentoEnum.DISPENSADO
            ? true
            : this.validaCamposAvisoPrevio();
    }

    private validaCamposAvisoPrevio(): boolean {
        return (
            !!this.entity.tipoconcessaoaviso &&
            !!this.entity.dataconcessaoaviso &&
            !!this.entity.dataprojetadaaviso &&
            this.entity.dataconcessaoaviso < this.entity.dataprojetadaaviso
        );
    }

    private validaMovimentos(): boolean {
        let movimentosExistentesValidos: boolean = true;

        if (this.entity.movimentosexistentescolaborador && this.entity.movimentosexistentescolaborador.length > 0) {

            movimentosExistentesValidos = this.entity.movimentosexistentescolaborador.every(movimentoExistente => {

                // movimento exige uma ação
                if ((movimentoExistente.tipoorigem === 2 || movimentoExistente.tipoorigem === 3 || movimentoExistente.tipoorigem === 4) ||
                    (movimentoExistente.tipoorigem === 1 && movimentoExistente.tipo === 4)) {

                    return movimentoExistente.tipoorigemstatus === 1 || movimentoExistente.tipoorigemstatus === 2;

                } else if (movimentoExistente.tipoorigem === 1 && movimentoExistente.tipo !== 4) {
                    // movimento fixo sempre é válido, não vai na requisição
                    return true;
                }

            });

        }

        return movimentosExistentesValidos;
    }

    private validaDocumentosAnexos(): boolean {
        return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return (
                tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio ||
                tipodocumentorequerido.fileSelecionado
            );
        });
    }
    // fim - validações


    // início - Requisições
    private async getConfiguracaoRescisao(): Promise<void> {
        await this.entityService.getConfiguracaoRescisao()
            .then((configuracao: ng.IHttpResponse<IConfiguracaoRescisao>) => {
                this.configuracaoRescisao = configuracao.data;
            })
            .catch((error) => {
                this.NewToaster.pop({
                    type: 'error',
                    tittle: 'Ocorreu um erro ao obter as configurações da rescisão',
                    timeout: this.toastTimeout
                });

                this.configuracaoRescisao = {
                    workflowativado: false
                };
            });
    }
    // fim - Requisições


    // inicio - pós requisições
    private onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesrescisoes_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.entityService.constructors.solicitacao = args.entity.solicitacao;
            this.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;
            this.entityAnexo['solicitacao'] = args.entity.solicitacao;

            this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

            this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                this.$scope.$apply();

                if (
                    this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult) &&
                    !this.documentosValidados
                ) {
                    this.documentosValidados = true;
                }
            });

            if (args.callback) {
                args.callback();
                return;
            }

            const tituloToast = args.entity.situacao === SituacaoRescisoesEnum.ABERTA
                ? 'A solicitação de rescisão foi editada com sucesso!'
                : 'A solicitação de rescisão foi enviada com sucesso!';
            this.NewToaster.pop({
                type: 'success',
                title: tituloToast,
                timeout: this.toastTimeout
            });

            this.$state.go('meurh_solicitacoesrescisoes_show', this.entityService.constructors);
            this.busy = false;
        });
    }

    private onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesrescisoes_submit_error', async (event: angular.IAngularEvent, args: any): Promise<void> => {
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity);
                }
            } else {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    if (args.response.data.message === 'Validation Failed') {
                        if (args.response?.data?.errors?.children) {

                            let msgErro = '';
                            let tituloToaster = this.tiposubmit === 'enviar' ? 'Não foi possível enviar rescisão' : 'Não foi possível salvar rescisão';

                            for (const campo in args.response.data.errors.children) {
                                if (args.response.data.errors.children[campo]['errors']) {
                                    args.response.data.errors.children[campo]['errors'].forEach((element: string) => {
                                        msgErro = msgErro + '&bull; ' + element + '<br>';
                                    });
                                }
                            }
                            this.NewToaster.pop({
                                type: 'error',
                                title: tituloToaster,
                                body: msgErro,
                                bodyOutputType: 'trustedHtml',
                                timeout: this.toastTimeout
                            });
                        } else {
                            this.NewToaster.pop(
                                {
                                    type: 'error',
                                    title: 'Alguns campos obrigatórios não estão preenchidos',
                                    body: 'Preencha os campos obrigatórios assinalados com "*" e tente novamente.',
                                    timeout: this.toastTimeout
                                }
                            );
                        }
                    } else {

                        // verificando se o erro é porque tem diferença dos movimentos existentes enviados na rescisão e os movimentos existentes
                        if (this.codigosErroValidacaoMovimentos.includes(args.response.data.code)) {

                            // buscar os movimentos existentes atualizados e atribuir na rescisão
                            await this.entityService.getMovimentosRescisaoTrabalhador(this.entity.trabalhador!.trabalhador)
                            .then((dadosRetornados: Array<IMovimentoExistenteRescisao>) => {
                                this.entity.movimentosexistentescolaborador = dadosRetornados;
                                this.NewToaster.pop({
                                    type: 'warning',
                                    title: 'Movimentos atualizados',
                                    body: 'Alguns movimentos foram atualizados durante o processo de rescisão. Revise as alterações e tome as ações necessárias, se for o caso. Se não houver ações a serem feitas, apenas confira as mudanças antes de enviar.',
                                    timeout: 10000
                                });
                            })
                            .catch((error: any) => {
                                this.NewToaster.pop({
                                    type: 'error',
                                    title: 'Não foi possível carregar os movimentos existentes do colaborador',
                                    body: error.data?.message
                                });
                            });

                        } else {
                            this.NewToaster.pop(
                                {
                                    type: 'error',
                                    title: args.response.data.message,
                                    timeout: this.toastTimeout
                                }
                            );
                        }
                    }
                } else {
                    if (args.entity.situacao === SituacaoRescisoesEnum.ENVIADA) {
                        this.NewToaster.pop(
                            {
                                type: 'error',
                                title: 'Ocorreu um erro ao enviar a solicitação!',
                                timeout: this.toastTimeout
                            }
                        );
                    } else {
                        this.NewToaster.pop(
                            {
                                type: 'error',
                                title: 'Ocorreu um erro ao atualizar a solicitação!',
                                timeout: this.toastTimeout
                            }
                        );
                    }
                }
            }

            this.busy = false;
        });
    }

    private onDeleteSuccess(): void {
        this.$scope.$on('meurh_solicitacoesrescisoes_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A solicitação de rescisão foi excluída com sucesso!'
            });
            this.$state.go('meurh_solicitacoesrescisoes', angular.extend(this.entityService.constructors));
        });
    }

    private onDeleteError(): void {
        this.$scope.$on('meurh_solicitacoesrescisoes_delete_error', (event: angular.IAngularEvent, args: any): void => {
            if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: args.response.data.message
                    }
                );
            } else {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: 'Ocorreu um erro ao excluir Solicitação.'
                    }
                );
            }
        });
    }
    // fim - pós requisições


    // inicio - tratamentos de dados
    private getMensagensModal(tipo: 'envio' | 'descartar' | 'envioWkf' | 'envioEstabilidade'): IModalConfirmacao {
        const customText = {
            'envio': {
                titulo: 'Enviar rescisão',
                descricao: (
                    'Após enviar a rescisão, os documentos serão gerados e a ' +
                    'rescisão efetuada. Se você deseja ver uma prévia dos ' +
                    'cálculos da rescisão, utilize a opção "Salvar" ao invés da "Enviar".'
                ),
                textoConfirmacao: (
                    'Você tem certeza que deseja enviar a rescisão ' +
                    'de ' + this.entity.trabalhador?.nome + '?'
                ),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Sim, enviar'
                }
            },
            'descartar': {
                titulo: 'Descartar alterações',
                descricao: (
                    'Após descartar, todas as informações alteradas serão perdidas.'
                ),
                textoConfirmacao: (
                    'Você tem certeza que deseja descartar as alterações da rescisão?'
                ),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Sim, descartar'
                }
            },
            'envioWkf': {
                titulo: 'Enviar rescisão',
                descricao: (
                    'Após enviar a rescisão, as prévias serão geradas para confirmação. Pode levar ' +
                    'um tempo até elas ficarem disponíveis, mas não se preocupe, vamos lhe avisar por ' +
                    'e-mail assim que as prévias estiverem disponíveis.'
                ),
                textoConfirmacao: (
                    'Você tem certeza que deseja enviar a rescisão ' +
                    'de ' + this.entity.trabalhador?.nome + '?'
                ),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Sim, enviar'
                }
            },
            'envioEstabilidade': {
                titulo: 'Colaborador em estabilidade',
                descricao: (this.entity.msgEstabilidadeModal),
                textoConfirmacao: (`Você tem certeza que deseja enviar a rescisão de ${this.entity.trabalhador?.nome}?`),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Enviar rescisão'
                }
            },
        };

        return customText[tipo];
    }

    /**
     * Recebe o array de afastamentos e retorna se algum deles possui período estável
     * @param afastamentos array de afastamentos
     */
    private algumAfastamentoTemPeriodoEstavel (afastamentos: Array<any>) {
        return afastamentos.some((afastamento) => {
            return afastamento.hasOwnProperty('fimperiodoestavel') && afastamento.fimperiodoestavel != null;
        });
    }

    /**
     * Dado o array de afastamentos e os dados de membro CIPA, método retornará a data final do período estável a ser considerada
     * @param afastamentos array de afastamentos
     * @param membrocipa dados de membro CIPA
     * @returns objeto com a data final do período estável e a descrição da estabilidade
     */
    private getFimPeriodoEstavel(afastamentos: Array<any>, membrocipa: any): {fimperiodoestavel: string, descricaoestabilidade: string} {

        if (!afastamentos || afastamentos.length < 1) { // não tem afastamentos, apenas cipa
            return {
                fimperiodoestavel: membrocipa.fimperiodoestavel,
                descricaoestabilidade: 'membro CIPA'
            };

        } else if (!membrocipa) { // não tem cipa, apenas afastamentos

            let afastamentosComPeriodoEstavel = afastamentos.filter((afastamento) => {
                if (afastamento.fimperiodoestavel) {
                    return afastamento;
                }
            });

            let afastamentoMaiorFimPeriodoEstavel = this.getAfastamentoMaiorFimPeriodoEstavel(afastamentosComPeriodoEstavel);
            return {
                fimperiodoestavel: afastamentoMaiorFimPeriodoEstavel.fimperiodoestavel,
                descricaoestabilidade: afastamentoMaiorFimPeriodoEstavel.descricao
            };

        } else { // tem afastamentos e cipa

            let afastamentosComPeriodoEstavel = afastamentos.filter((afastamento) => {
                if (afastamento.fimperiodoestavel) {
                    return afastamento;
                }
            });

            let afastamentoMaiorFimPeriodoEstavel = this.getAfastamentoMaiorFimPeriodoEstavel(afastamentosComPeriodoEstavel);
            let fimPeriodoEstavelAfastamentos = afastamentoMaiorFimPeriodoEstavel.fimperiodoestavel;
            let fimPeriodoEstavelCipa = membrocipa.fimperiodoestavel;

            let afastamentoMaiorQueCipa = moment(fimPeriodoEstavelAfastamentos).isSameOrAfter(fimPeriodoEstavelCipa);

            if (afastamentoMaiorQueCipa) {
                return {
                    fimperiodoestavel: afastamentoMaiorFimPeriodoEstavel.fimperiodoestavel,
                    descricaoestabilidade: afastamentoMaiorFimPeriodoEstavel.descricao
                };
            } else {
                return {
                    fimperiodoestavel: membrocipa.fimperiodoestavel,
                    descricaoestabilidade: 'membro CIPA'
                };
            }
        }

    }

    /**
     * Dada uma lista de afastamentos de um colaborador, retorna o afastamento que possui o maior fim período estável
     * @param afastamentos lista de afastamentos
     */
    private getAfastamentoMaiorFimPeriodoEstavel (afastamentos: Array<any>) {

        return afastamentos.reduce((maiorAfastamento: any, afastamentoAtual: any) => {
            const fimPeriodoEstavelMaior = moment(maiorAfastamento.fimperiodoestavel, 'YYYY-MM-DD');
            const fimPeriodoEstavelAtual = moment(afastamentoAtual.fimperiodoestavel, 'YYYY-MM-DD');

            return fimPeriodoEstavelMaior.isAfter(fimPeriodoEstavelAtual) ? maiorAfastamento : afastamentoAtual;
        });

    }

    private alteraEntityEnviar(rascunho: boolean): void {
        this.entitySave = angular.copy(this.entity);
        this.entitySave.situacao = rascunho
            ? SituacaoRescisoesEnum.ABERTA
            : SituacaoRescisoesEnum.ENVIADA;

        this.entitySave.tipocumprimentoaviso = this.entitySave.tipocumprimentoaviso_select;
        delete this.entitySave.tipocumprimentoaviso_select;

        let beneficiosadescontar: any[] = [];
        this.entitySave.beneficiostrabalhadores.map((obj: any) => {
            if (obj.descontar) {
                beneficiosadescontar.push(obj.beneficio);
            }
        });
        this.entitySave.beneficiosadescontar = JSON.stringify(beneficiosadescontar);
        if (this.entitySave.trabalhador?.tipo !== 0) {
            this.entitySave.beneficiosadescontar = null;
            this.entitySave.orientacoescalculo = null;
            this.entitySave.tipocumprimentoaviso = null;
            this.entitySave.tipoconcessaoaviso = null;
            this.entitySave.dataconcessaoaviso = null;
            this.entitySave.dataprojetadaaviso = null;
            this.entitySave.observacaoconcessaoaviso = null;
        }

        // ajustando os movimentos adicionados para enviar
        if (this.entitySave.movimentos && this.entitySave.movimentos.length > 0) {

            let movimentosAjustados: any = [];

            this.entitySave.movimentos.forEach(movimentoAdicionado => {
                movimentosAjustados.push({
                    movimento: movimentoAdicionado.movimento,
                    tipoorigem: movimentoAdicionado.tipoorigem,
                    tipoorigemregistro: movimentoAdicionado.tipoorigemregistro,
                    tipoorigemstatus: movimentoAdicionado.tipoorigemstatus,
                    evento: movimentoAdicionado.evento.evento,
                    conteudo: movimentoAdicionado.conteudo,
                    tipo: movimentoAdicionado.tipo
                });
            });

            this.entitySave.movimentos = movimentosAjustados;
        }

        // ajustando os movimentos existentes do colaborador para enviar
        if (this.entitySave.movimentosexistentescolaborador && this.entitySave.movimentosexistentescolaborador.length > 0) {

            if (!this.entitySave.movimentos) {
                this.entitySave.movimentos = [];
            }

            this.entitySave.movimentosexistentescolaborador.forEach(movimentoExistente => {

                // só adiciona o movimento no objeto a ser enviado na requisição se não for um movimento fixo
                if (!(movimentoExistente.tipoorigem === 1 && movimentoExistente.tipo !== 4)) {
                    this.entitySave.movimentos?.push({
                        movimento: movimentoExistente.movimento,
                        tipoorigem: movimentoExistente.tipoorigem,
                        tipoorigemregistro: movimentoExistente.tipoorigemregistro,
                        tipoorigemstatus: movimentoExistente.tipoorigemstatus,
                        evento: movimentoExistente.evento.evento,
                        conteudo: movimentoExistente.conteudo,
                        tipo: movimentoExistente.tipo
                    });
                }

            });

        }

    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': TipoSolicitacaoEnum.RECISAO };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }

    /**
     * Ordena os movimentos existentes para manter os fixos sempre nas primeiras linhas da tabela e também ordena os movimentos fixos baseados nos seus tipos
     */
    private ordenaMovimentosExistentes() {

        // ordenação dos tipos nos movimentos fixos
        const ordemTipo = [
            TipoMovimentoTrabalhadorEnum.EMPRESA, TipoMovimentoTrabalhadorEnum.ESTABELECIMENTO,
            TipoMovimentoTrabalhadorEnum.SINDICATO, TipoMovimentoTrabalhadorEnum.DEPARTAMENTO,
            TipoMovimentoTrabalhadorEnum.LOTACAO, TipoMovimentoTrabalhadorEnum.CARGO
        ];

        this.entity.movimentosexistentescolaborador!.sort((movA, movB) => {
            const movAFixo = movA.tipoorigem === 1 && movA.tipo !== 4;
            const movBFixo = movB.tipoorigem === 1 && movB.tipo !== 4;

            if (movAFixo && !movBFixo) {
                return -1; // coloca movA antes de movB
            } else if (!movAFixo && movBFixo) {
                return 1; // coloca movB antes de movA
            } else if (movAFixo && movBFixo) {
                return ordemTipo.indexOf(movA.tipo) - ordemTipo.indexOf(movB.tipo); // os dois são movimentos fixos, ordenar baseado no tipo
            } else {
                return 0; // mantém a ordem original
            }
        });

    }

    // fim - tratamentos de dados
}
