import angular from 'angular';
import moment from 'moment';
import { IControleFerias } from '../../ControleFerias/models/controleferias.model';
import { Empresa, Estabelecimento } from '../../../../../core/usuario/models/usuario-model';
import { FeriasEmGradeEnum } from '../FeriasEmGradeEnum';
import { FilterFeriasEnum } from '../FilterFeriasEnum';
import { MeurhCalendarioFeriasModalService } from './../calendarioferiasmodal/calendarioferias.modal.service';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { TipoColaboradorEnum } from '../../../../../shared/enums/TipoColaboradorEnum';
import { conversaoFormatoData } from '../../../../../utils/utils';

export class MeurhFeriasmarcaremgradeIndexController implements angular.IController {
	static $inject = [
		'$rootScope',
		'$scope',
		'$state',
		'$http',
		'$stateParams',
		'$location',
		'$transitions',
		'nsjRouting',
		'NewToaster',
		'ControleFeriasService',
		'$sce',
		'MeurhCalendarioFeriasModalService',
		'ListagemService'
	];

	public busy: boolean = false;
	public carregouTudo: boolean = false;
	public carregouParte: boolean = false;
	public filterFeriasEnum = FilterFeriasEnum;
	public filterFerias: string;
	public filteredEntities: any;
	public dataAtual: Date = new Date();
	public mesAtual: number;
	public anoAtual: number;
	public anosSelect: Array<object> = [];
	public mesesSelect: Array<object> = [];
	public filters: any;
	public filter: any;
	public constructors: any;
	public entities: Array<IControleFerias>;
	public passo: number = 1;
	public filterModo: string = '';
	public filterMes: string = '';
	public filterAno: string = '';
	public checkboxPreenchidos: any = [];
	public checkToggle: any = {};
	public entitiesSolicitacoes: any = [];
	public solicitacoesRascunhoDeletadas: any = [];
	public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_ferias'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
	public fields = [
		{
			value: 'nome',
			label: 'Colaborador',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'codigo',
			label: 'Matrícula',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'saldo',
			label: 'Saldo disponível',
			type: 'integer',
			style: 'default',
			copy: '',
		},
		{
			value: 'saldoTotal',
			label: 'Saldo total',
			type: 'integer',
			style: 'default',
			copy: ''
		},
		{
			value: 'direitoAPartir',
			label: 'Direito a partir de',
			type: 'date',
			style: 'title',
			copy: '',
		},
		{
			value: 'datalimitegozo',
			label: 'Limite para a dobra',
			type: 'date',
			style: 'title',
			copy: '',
			customCollumnScope: {
				getDataLimiteGozoFormatada: (dataLimiteGozo: string): string => {
					return conversaoFormatoData(dataLimiteGozo);
				}
			},
			customCollumnHtml: (): string => {
				let someHtmlVar = `<span ng-if="tableRowEntity.datalimitegozo" ng-class="!tableRowEntity.dataproximodolimite && !tableRowEntity.datapassoudolimite ? '' : 'limite-alert'" ng-bind-template="{{ customCollumnScope.getDataLimiteGozoFormatada(tableRowEntity.datalimitegozo) }}"></span>
				<span ng-if="!tableRowEntity.datalimitegozo">-</span>
                <span ng-if="tableRowEntity.dataproximodolimite">
                    <i style="color: #C82333;" class="fas fa-info-circle"></i>
                    <nsj-tooltip tooltip-position="top">
                        <p>O limite está próximo do vencimento.</p>
                    </nsj-tooltip>
                </span>
                <span ng-if="tableRowEntity.datapassoudolimite">
                    <i style="color: #C82333;" class="fas fa-info-circle"></i>
                    <nsj-tooltip tooltip-position="top">
                        <p>O limite foi ultrapassado.</p>
                    </nsj-tooltip>
                </span>`;

				return someHtmlVar;
			},
		},
		{
			value: 'observacoes',
			label: 'Situação',
			type: 'string',
			style: 'title',
			copy: '',
		},
	];

	constructor(
		public $rootScope: angular.IRootScopeService & {
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            session: any,
            nsjGlobals: any,
			showModalSaldoZero: any
        },
		public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
		public $http: angular.IHttpService,
		public $stateParams: angular.ui.IStateParamsService,
		public $location: any,
		public $transitions: any,
		public nsjRouting: any,
		public NewToaster: any,
		public entityService: any,
		public $sce: any,
		public MeurhCalendarioFeriasModalService: MeurhCalendarioFeriasModalService,
		public ListagemService: ListagemService
	) {
		$rootScope['showModalSaldoZero'] = function showModalSaldoZero(): void {
			let colaboradoresComSaldoZero = this.entities.filter((entity: any) => !entity.disableCheckbox);
			this.MeurhAvisoSaldoZeroModalService.open(colaboradoresComSaldoZero);
		};

		entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
		this.filters = entityService.filters = {};
		this.montaListagemVisibilidade();
		this.constructors = entityService.constructors = [];
		this.constructors.order = {
			'nome': 'ASC'
		};

		this.constructors['exibirsolicitacoes'] = true;
		this.constructors['exibiravisos'] = true;
		this.constructors['status'] = '00';

		this.filterEntities(entityService.reload());

		this.mesAtual = this.dataAtual.getMonth() + 1;
		this.anoAtual = this.dataAtual.getFullYear();
		this.montaSelectsData();

		$rootScope.$on('controleferias_list_finished_part', (event: any, args: any) => {
			this.alteraEntities();
			this.carregouParte = true;
			this.busy = false;
			this.filterEntities(entityService.entities);
		});

		$scope.$on('controleferias_list_finished', (event: any, args: any) => {
			this.alteraEntities();
			this.carregouTudo = true;
			this.busy = false;
			this.filterEntities(entityService.entities);
		});
	}

	$onInit(): void {
		this.checkToggle = document.getElementById('check_all') as HTMLInputElement;
	}

	montaListagemVisibilidade(): void {
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.filterReload();
		this.checkboxPreenchidos = [];
    }

	reloadScope(): void {
		this.$scope.$applyAsync();
	}

	filterEntities(entities: Array<IControleFerias>): void {
		if (parseInt(this.filterFerias, 10) === this.filterFeriasEnum.COM_FERIAS_MARCADAS) {
			this.entities = entities.filter((entity: IControleFerias): boolean => {
				return entity.ferias.length > 0;
			});
		} else if (parseInt(this.filterFerias, 10) === this.filterFeriasEnum.SEM_FERIAS_MARCADAS) {
			this.entities = entities.filter((entity: IControleFerias): boolean => {
				return entity.ferias.length === 0;
			});
		} else {
			this.entities = entities;
		}

		this.updateChecked(this.entities);
	}

	updateChecked(filteredEntities: Array<IControleFerias>): void {
		// atualiza o checkbox com a referencia da lista atual
		for (let i = 0; i < this.checkboxPreenchidos.length; i++) {
			let aux = filteredEntities.find((item) => item.trabalhador === this.checkboxPreenchidos[i].trabalhador);
			if (aux) {
				this.checkboxPreenchidos[i] = angular.copy(aux);
			}
		}
	}

	search(): void {
		if (this.entityService.filter !== '') {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
		} else {
			this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
		}

		var filter = {
			search: this.entityService.filter,
			filters: angular.copy(this.entityService.filters)
		};

		return this.entityService.search(filter);
	}

	loadMore(): void {
		this.entityService.loadMore();
	}

	finished(): boolean {
		return this.entityService.loadParams.finished;
	}

	filterReload(): void {
		this.search();
	}

	filterReloadData(): void {
		let inicioproximoperiodoaquisitivoferias: any = [];

		if (this.filterAno === '' && this.filterMes === '') {
			this.constructors.inicioproximoperiodoaquisitivoferias = null;
		}

		if (this.filterAno !== '' || this.filterMes !== '') {
			let ano = this.filterAno !== '' ? parseInt(this.filterAno, 10) : this.anoAtual;
			let mes = this.filterMes !== '' ? parseInt(this.filterMes, 10) : this.mesAtual;
			let ultimoDiaMes = new Date(ano, mes, 0);
			let diaUltimoDiaMes = ultimoDiaMes.getDate();

			let mesUltimoDiaMes = (ultimoDiaMes.getMonth() + 1) < 10 ? '0' + (ultimoDiaMes.getMonth() + 1) : (ultimoDiaMes.getMonth() + 1);
			let anoUltimoDiaMes = ultimoDiaMes.getFullYear();
			let ultimoDiaMesString = anoUltimoDiaMes + '-' + mesUltimoDiaMes + '-' + diaUltimoDiaMes + ' 23:59:59';

			inicioproximoperiodoaquisitivoferias.push({
				condition: 'lte',
				value: ultimoDiaMesString
			});

			delete this.constructors.inicioproximoperiodoaquisitivoferias;
		}

		this.constructors.inicioproximoperiodoaquisitivoferias = inicioproximoperiodoaquisitivoferias;
		this.entityService.constructors = this.constructors;
		this.filterReload();
	}

	divisivelPor20(numero: number): boolean {
		let ultimosDoisDigitos = parseInt(numero.toString().substring(numero.toString().length - 2), 10);
		return ((ultimosDoisDigitos % 5 === 0) && (ultimosDoisDigitos % 4 === 0));
	}

	selecionaModo(): void {
		if (this.filterModo === '') {
			this.filterAno = '';
			this.filterMes = '';
			this.filterReload();
		}
	}

	retornaDataDireitoFerias(entity: IControleFerias) {
		if (entity.tipo === TipoColaboradorEnum.ESTAGIARIO) {
			return entity.inicioperiodoaquisitivoferias;
		}

		if (entity.hasOwnProperty('inicioproximoperiodoaquisitivoferias') && entity.inicioproximoperiodoaquisitivoferias != null) {
			return entity.inicioproximoperiodoaquisitivoferias;
		}
	}

	retornaLimiteParaDobra(entity: IControleFerias): any {
		if (entity.tipo === TipoColaboradorEnum.ESTAGIARIO) {
			return '';
		}

		return entity.datalimitegozo === null ? '' : entity.datalimitegozo;
	}

	alteraEntities(): void {
		this.entityService.entities.forEach((item: IControleFerias) => {
			item.datalimitegozo = this.retornaLimiteParaDobra(item);
			item.direitoAPartir = this.retornaDataDireitoFerias(item);

			item['observacoes'] = '-';
			item['dataproximodolimite'] = false;
			item['datapassoudolimite'] = false;
			item['rowcustomclass'] = '';

			if (item.hasOwnProperty('ferias') && (item.ferias.length > 0)) {
				item.ferias.forEach((element: any) => {
					if (element.situacao === FeriasEmGradeEnum.SITUACAO_RASCUNHO) {
						item['observacoes'] = 'Rascunho iniciado';
					} else if (element.situacao === FeriasEmGradeEnum.SITUACAO_PENDENTE) {
						item['observacoes'] = 'Enviada';
					}

					if (element.situacao !== FeriasEmGradeEnum.SITUACAO_RASCUNHO) {
						if (item.saldo === 0) {
							item['disableCheckbox'] = true;
							item['enableTooltip'] = true;
							item['checkboxTooltip'] = 'Colaborador não possui saldo disponível nesse período aquisitivo.';
						}
					}
				});
			}

			if ((item.datalimitegozo !== null) && (moment(this.dataAtual).isAfter(moment(item.datalimitegozo)))) {
				item['datapassoudolimite'] = true;
				item['rowcustomclass'] = 'linha-vermelha';
			}

			if ((item.datalimitegozo !== null) && ((moment((this.dataAtual.getDate() + 60)).isAfter(moment(item.datalimitegozo)) || (moment((this.dataAtual.getDate() + 60)).isSame(moment(item.datalimitegozo)))))) {
				if (item['datapassoudolimite'] === false) {
					item['dataproximodolimite'] = true;
				}
			}

		});
	}

	/* INÍCIO - COMBO DE FUNÇÕES PARA HABILITAR AS FUNÇÕES SELECIONAR TODOS E DOWNLOAD EM LOTE */
	checar_todos(): void {
		this.checkToggle = document.getElementById('check_all') as HTMLInputElement;

		if (this.checkToggle.checked) {
			let checked = this.entities.filter((entity: IControleFerias): boolean => !entity.disableCheckbox);
			this.checkboxPreenchidos = angular.copy(checked);

			if (checked.length !== this.entities.length) {
				this.NewToaster.pop({
					type: 'info',
					title: 'Atenção!',
					body: 'toaster-html-directive',
					directiveData: { entities: this.entities },
					bodyOutputType: 'directive',
					timeout: 5000
				});
			}
		} else {
			this.checkboxPreenchidos = [];
		}
	}

	temTodosMarcados(): boolean {
		return this.entities.length && (this.checkboxPreenchidos.length === this.entities.length);
	}

	naoTemTodosMarcados(): boolean {
		return !!this.checkboxPreenchidos.length && !this.temTodosMarcados();
	}
	/* FIM - COMBO DE FUNÇÕES PARA HABILITAR AS FUNÇÕES SELECIONAR TODOS E DOWNLOAD EM LOTE */

	montaSelectsData(): void {
		this.anosSelect = [
			{
				'ano': this.anoAtual
			},
			{
				'ano': (this.anoAtual + 1)
			},
			{
				'ano': (this.anoAtual + 2)
			},
		];

		this.mesesSelect = [
			{
				'value': 1,
				'mes': 'Janeiro'
			},
			{
				'value': 2,
				'mes': 'Fevereiro'
			},
			{
				'value': 3,
				'mes': 'Março'
			},
			{
				'value': 4,
				'mes': 'Abril'
			},
			{
				'value': 5,
				'mes': 'Maio'
			},
			{
				'value': 6,
				'mes': 'Junho'
			},
			{
				'value': 7,
				'mes': 'Julho'
			},
			{
				'value': 8,
				'mes': 'Agosto'
			},
			{
				'value': 9,
				'mes': 'Setembro'
			},
			{
				'value': 10,
				'mes': 'Outubro'
			},
			{
				'value': 11,
				'mes': 'Novembro'
			},
			{
				'value': 12,
				'mes': 'Dezembro'
			},
		];
	}

	confereData(): void {
		if ((parseInt(this.filterMes, 10) < this.mesAtual) && ((this.filterAno === '') || (parseInt(this.filterAno, 10) === this.anoAtual))) {
			this.NewToaster.pop({
				type: 'error',
				title: 'Não é possível filtrar com a data selecionada.'
			});
		} else {
			if ((this.filterMes !== '' && this.filterMes !== null) && (this.filterAno !== '' && this.filterMes !== null)) {
				this.busy = true;
				this.filterReloadData();
			}
		}
	}

	getRowCustomClass(entity: IControleFerias): string {
		return entity.rowcustomclass;
	}

	verificaSelecao(): void {
		if (this.checkboxPreenchidos.length === 0) {
			this.NewToaster.pop({
				type: 'error',
				title: 'É necessário selecionar ao menos um Colaborador para prosseguir.'
			});
		} else {
			this.calculaPasso(true);
		}
	}

	valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}

	calculaPasso(continua: boolean): void {
		this.reloadScope();
		if (continua) {
			this.passo = this.passo + 1;
			this.reloadScope();
		} else {
			this.passo = this.passo - 1;
			this.reloadScope();
		}
	}

	showModal(): void {
		let draftEntities = this.entitiesSolicitacoes.filter((solicitacao: any) => solicitacao.situacao === '' || solicitacao.situacao === FeriasEmGradeEnum.SITUACAO_RASCUNHO);
		this.MeurhCalendarioFeriasModalService.open(this.entityService.entities, draftEntities);
	}
}
