import ng from 'angular';
import angular from 'angular';

export class MeurhSolicitacoesgenericasOutrosDadosFormController implements ng.IController {
    static $inject = [];

    public entity: any;
    public action: string;
    public ckEditorConfig = {
        removePlugins: 'about, youtube'
    };

    constructor(private $scope: angular.IScope) {}

}
