import angular from 'angular';
import { MeurhApontamentosService } from '../apontamentos.service';
import moment from 'moment';
import { IApontamento } from '../models/apontamentos.model';

export class MeurhApontamentosFormController {
    static $inject = ['ApontamentosTrabalhadoresModalService', '$scope', '$element', '$attrs', 'NewToaster',
        '$rootScope', 'moment', 'ApontamentosTrabalhadoresService', '$stateParams', 'MeurhApontamentosService', '$location'];

    public meses = [{
        valor: '1',
        mes: 'Janeiro'
    }, {
        valor: '2',
        mes: 'Fevereiro'
    }, {
        valor: '3',
        mes: 'Março'
    }, {
        valor: '4',
        mes: 'Abril'
    }, {
        valor: '5',
        mes: 'Maio'
    }, {
        valor: '6',
        mes: 'Junho'
    }, {
        valor: '7',
        mes: 'Julho'
    }, {
        valor: '8',
        mes: 'Agosto'
    }, {
        valor: '9',
        mes: 'Setembro'
    }, {
        valor: '10',
        mes: 'Outubro'
    }, {
        valor: '11',
        mes: 'Novembro'
    }, {
        valor: '12',
        mes: 'Dezembro'
    }];


    public SITUACAO_RASCUNHO = -1;
    public SITUACAO_PENDENTE = 0;
    public SITUACAO_APROVADO = 1;

    public entity: IApontamento;
    public form: angular.IFormController;
    public busy: any;
    public tipo: any;
    public anoAtual: any;
    public mesAtual: any;
    public path: any;
    public ultimoMesDisponivel: any;
    public idCount: any;
    public naoAdicionarAponamentos: any;
    public mesesFiltrados: any;
    public semMovimentos: any;

    constructor(
        public ApontamentosTrabalhadoresModalService: any,
        public $scope: any,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $rootScope: any,
        public moment: any,
        public ApontamentosTrabalhadoresService: any,
        public $stateParams: any,
        public entityService: MeurhApontamentosService,
        public $location: any,
    ) {
        this.anoAtual = (new Date()).getFullYear();
        this.mesAtual = (new Date()).getMonth() + 1;
        this.ultimoMesDisponivel = this.mesAtual;
        this.path = $location.path();
        this.idCount = 0;
        this.tipo = $stateParams['tipo'];

        if (this.path.contains('/new')) {
            this.naoAdicionarAponamentos = true;
        }

        $scope.$watch('$ctrl.entity', (newValue: any, oldValue: any): void => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
            }
        }, true);

        $scope.$on('apontamentos_apontamentostrabalhadores_loaded', (): void => {
            this.busy = false;
        });
    }

    filterMonth() {
        if (this.entity.anoreferencia > this.anoAtual) {
            if (!(this.entity.anoreferencia === this.anoAtual + 1 && this.mesAtual === 12)) {
                this.entity.anoreferencia = this.anoAtual;
            } else {
                this.mesesFiltrados = [{
                    valor: '1',
                    mes: 'Janeiro'
                }];
            }
        } else if (this.entity.anoreferencia === this.anoAtual) {
            this.mesesFiltrados = [];
            let i = 0;
            this.ultimoMesDisponivel = this.mesAtual;
            for (i; i < this.mesAtual; i++) {
                this.mesesFiltrados.push(this.meses[i]);
            }
            if (this.showNextMonth()) {
                this.mesesFiltrados.push(this.meses[i]);
                this.ultimoMesDisponivel++;
            }
            // mudando o mês selecionado caso a seleção atual não exista na nova lista gerada
            if (parseInt(this.entity.valor, 10) > this.ultimoMesDisponivel) {
                this.entity.valor = this.ultimoMesDisponivel.toString();
            }
        } else {
            this.mesesFiltrados = this.meses;
        }
    }

    filterType(): void {
        if (this.tipo !== '-1' && this.entity.apontamentostrabalhadores) {
            return this.entity.apontamentostrabalhadores.filter((apontamento) => {
                return apontamento.trabalhador.tipo = this.tipo;
            });
        } else {
            return this.entity.apontamentostrabalhadores;
        }
    }

    UpdateMonth() {
        this.entity.mesreferencia = parseInt(this.entity.valor, 10);
    }

    submit() {
        this.entity.mesreferencia = parseInt(this.entity.valor, 10);
        if (this.form.$valid && !this.entity.$$__submitting) {
            this.entityService.save(this.entity);
        } else {
            this.entity.situacao = null;
            this.NewToaster.pop({
                type: 'error',
                title: 'Alguns campos do formulário apresentam erros'
            });
        }
    }

    draftUpdate(entity: any) {
        this.semMovimentos = false;
        if (this.entity.apontamentostrabalhadores !== undefined && this.entity.apontamentostrabalhadores.length > 0) {
            this.entity.apontamentostrabalhadores.forEach((aptTrab: any, id: any) => {
                if (aptTrab.movimentos === undefined || aptTrab.movimentos === null || aptTrab.movimentos.length === 0) {
                    this.semMovimentos = true;
                }
            });
            if (this.semMovimentos) {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não é possível salvar um apontamento sem rubricas'
                });
            } else {
                this.entity.situacao = this.SITUACAO_RASCUNHO;
                this.submit();
            }
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não é possível salvar um apontamento vazio'
            });
        }

    }

    adicionarApontamentoTrabalhador() {
        var modal = this.ApontamentosTrabalhadoresModalService.open({}, {}, this.entity.mesreferencia, this.entity.anoreferencia, this.entity.apontamentostrabalhadores, this.tipo);
        modal.result.then((subentity: any) => {
            subentity.$id = this.idCount++;
            if (this.entity.apontamentostrabalhadores === undefined) {
                this.entity.apontamentostrabalhadores = [subentity];
            } else {
                this.entity.apontamentostrabalhadores.push(subentity);
            }
            this.draftUpdate(this.$rootScope.entityApt);
            var updateRascunho = 'Sucesso ao salvar Rascunho!';

            if (this.entity.situacao === this.SITUACAO_RASCUNHO) {
                this.NewToaster.pop({
                    type: 'success',
                    title: updateRascunho,
                });
            }
        })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    apontamentosApontamentostrabalhadoresFormEditShow(subentity: any, visualizacao: boolean) {
        var parameter = { 'identifier': subentity.apontamentotrabalhador };
        var modal = this.ApontamentosTrabalhadoresModalService.open(parameter, subentity, this.entity.mesreferencia, this.entity.anoreferencia, this.entity.apontamentostrabalhadores, this.tipo, visualizacao);
        modal.result.then((subentity: any) => {
            let key;
            for (key in this.entity.apontamentostrabalhadores) {
                if ((this.entity.apontamentostrabalhadores[key].apontamentotrabalhador !== undefined && this.entity.apontamentostrabalhadores[key].apontamentotrabalhador === subentity.apontamentotrabalhador)
                    || (this.entity.apontamentostrabalhadores[key].$id !== undefined && this.entity.apontamentostrabalhadores[key].$id === subentity.$id)) {
                    this.entity.apontamentostrabalhadores[key] = subentity;
                }
            }
            this.draftUpdate(this.$rootScope.entityApt);
        })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
                    this.busy = false;
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    possuiApontamentosEnviados(subentity: any) {
        let movimentosPendentes = false;
        if (subentity.movimentos) {
            subentity.movimentos.forEach((movimento: any, key: any) => {
                if (movimento.situacao !== -1) {
                    movimentosPendentes = true;
                }
            });
        }
        return movimentosPendentes;
    }

    possuiMovimentoEnviado(entity: any) {
        for (let i = 0; i < entity.movimentos.length; i++) {
            if (entity.movimentos[i].situacao > this.SITUACAO_RASCUNHO) {
                return true;
            }
        }
        return false;
    }

    async podeRemoverApontamentoTrabalhador(entity: any) {
        if (!entity.apontamentotrabalhador) {
            return true;
        }
        if (entity.apontamentotrabalhador && entity.$__loaded) {
            if (!this.possuiMovimentoEnviado(entity)) {
                return true;
            }
        }
        if (entity.apontamentotrabalhador && !entity.$__loaded) {
            this.busy = true;
            let aptTrab = await this.ApontamentosTrabalhadoresService.get(entity.apontamentotrabalhador);
            if (!this.possuiMovimentoEnviado(aptTrab)) {
                return true;
            }
        }
        return false;
    }

    ultimoDiaMes(mes: any, ano: any) {
        let result = 0;
        switch (mes) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                result = 31;
                break;
            case 4:
            case 6:
            case 9:
            case 11:
                result = 30;
                break;
            case 2:
                if ((ano % 4) === 0) {
                    result = 29;
                } else {
                    result = 28;
                }
                break;
        }
        return result;
    }

    getDataFechamentoFolha(mes: any, ano: any) {
        // se fechamento é para o próximo mês, incremento o mês e ano se for o caso
        if (this.$rootScope.configuracoes['FOLHA_FECHA_PROXIMO_MES'] === '1') {
            mes = mes + 1;
            if (mes === 13) {
                mes = 1;
                ano = ano + 1;
            }
        }
        let diaFechamento: any = this.$rootScope.configuracoes['FECHAMENTO_FOLHA'];
        let ultimoDiaMes: any = this.ultimoDiaMes(mes, ano);
        if (parseInt(diaFechamento, 10) > parseInt(ultimoDiaMes, 10)) {
            diaFechamento = ultimoDiaMes.toString();
        }
        let data = diaFechamento + '-' + mes + '-' + ano;
        let datafinal = moment(data, 'DD-MM-YYYY').endOf('day');

        return datafinal;
    }

    habilitaApontamentoDias() {
        let datafinal = this.getDataFechamentoFolha(this.entity.mesreferencia, this.entity.anoreferencia);
        return moment().startOf('day').isBefore(datafinal);
    }

    showNextMonth() {
        let datafinal = this.getDataFechamentoFolha(moment().month() + 1, moment().year());
        return moment().startOf('day').isAfter(datafinal);
    }

    async removerApontamentoTrabalhador(subentity: any, removeFunction: any) {
        let podeRemover = await this.podeRemoverApontamentoTrabalhador(subentity);
        if (podeRemover) {
            this.$scope.$apply(() => { removeFunction(subentity); });
            this.draftUpdate(this.$rootScope.entityApt);
            return;
        }
        this.$scope.$apply(() => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não é possível remover este trabalhador pois ele possui um movimento pendente, aprovado ou rejeitado'
            });
        });
    }
}
