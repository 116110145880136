import angular from 'angular';
import moment from 'moment';
import { BancodeHorasService } from '../bancodehoras.service';
import { ApuracoesPontoService } from '../../ApuracoesPonto/apuracoesponto.service';
import { TrabalhadoresService } from '../../../../Trabalhadores/trabalhadores.service';
import { IBancoHoras } from '../../models/bancohoras.model';
import { IBancoHorasColaboradores } from '../../models/bancohorascolaboradores.model';
import { IApuracaoPonto } from '../../models/apuracaoponto.model';
import { ITrabalhador } from '../../../../Trabalhadores/models/trabalhador.model';

type BancoHorasFilters = {
    apuracaoinicial?: string;
    apuracaofinal?: string;
    lotacao?: { lotacao: string };
    departamento?: { departamento: string };
    grupo?: { grupo: string };
    trabalhador?: { trabalhador: string };
    situacaoColaborador?: string;
};

export class BancodeHorasIndexController {

    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$location',
        'BancodeHorasService',
        'ApuracoesPontoService',
        'TrabalhadoresService',
        'BuscaAvancadaService',
    ];

    public busy: boolean = false;
    public busyFilter: boolean = false;
    public entitiesLoaded: boolean = false;
    public filterTouched: boolean = false;
    public entities: Array<IBancoHorasColaboradores> = [];
    public apuracoesSelect: Array<Partial<IApuracaoPonto & { periodo: string }>> = [];
    public colaboradoresList: Array<ITrabalhador> = [];
    public filters: BancoHorasFilters = {
        situacaoColaborador: '00',
    };
    public apuracoes: Array<IApuracaoPonto> = [];
    public pontoPorLotacao: boolean = false;

    constructor (
        public $rootScope: angular.IRootScopeService & {
            liberadoTela: (arg: string) => boolean,
            temPermissao: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            session: any,
            configuracoes: any,
            nsjGlobals: any
        },
		public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
		public BancodeHorasService: BancodeHorasService,
        public ApuracoesPontoService: ApuracoesPontoService,
        public TrabalhadoresService: TrabalhadoresService,
        public BuscaAvancadaService: any,
	) {
        if (
            !this.$rootScope.temPermissao('visualizar_banco_horas') ||
			!this.$rootScope.liberadoTela('LIBERAR_BANCO_HORAS')
        ) {
            $state.go('dp');
        }

        this.busy = true;
        this.pontoPorLotacao = this.$rootScope.configuracoes.PONTO_APURAR_POR_LOTACAO;
        this.carregaApuracoes();
        this.carregaTrabalhadores();

        this.$scope.$on('historicosbancoapuracoes_index_loaded', (event, args: any) => {
            this.entities = this.formataPeriodo(args);
            this.busy = false;
            this.busyFilter = false;
            this.entitiesLoaded = true;

            // verifica se é ponto por lotação e agrupa o colaborador por lotação
            if (this.pontoPorLotacao) {
                this.entities = this.agruparLotacoes();
            }
        });
        this.$scope.$on('apuracoesponto_index_loaded', () => {
            if (this.ApuracoesPontoService.loadParams.finished ||
                this.ApuracoesPontoService.loadParams.to_load === 0
            ) {
                this.montaApuracaoSelect();
                this.busy = false;
            }
        });
        this.$scope.$on('historicosbancoapuracoes_relatoriobancoshoras_sucesso', (event, args: any) => {
			this.download(args.data);
            this.busy = false;
        });
        this.$scope.$on('historicosbancoapuracoes_relatoriobancoshoras_erro', () => {
            this.busy = false;
        });
        this.$scope.$on('historicosbancoapuracoes_pdf_sucesso', (event, args: any) => {
			this.download(args.data, true);
            this.busy = false;
        });
        this.$scope.$on('historicosbancoapuracoes_pdf_erro', () => {
            this.busy = false;
        });

        // busca avançada - colaboradores
        this.$rootScope.$on('persona_trabalhadores_list_loaded', (event, args: any): void => {
            this.colaboradoresList = [...args];
        });
    }

	carregaApuracoes() {
        this.busy = true;
		this.ApuracoesPontoService.constructors['vigente'] = false;
		this.ApuracoesPontoService.constructors['empresa'] = this.$rootScope.session.entities.empresa.empresa;
		this.ApuracoesPontoService.constructors['estabelecimento'] = '';
		this.apuracoes = this.ApuracoesPontoService.reload();
        this.limpaNsjSearch('colaboradorSearchFilter');
	}

    carregaTrabalhadores() {
        let constructor = {
          'empresa': this.$rootScope.session.entities.empresa.empresa,
          'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento,
          'tipo': '-1',
        };
        this.TrabalhadoresService.constructors = constructor;
        this.TrabalhadoresService.filter = '';
        this.TrabalhadoresService.reload();
    }

    montaApuracaoSelect(): void {
        this.apuracoesSelect = [];
		this.apuracoes.forEach((element: IApuracaoPonto) => {
			let apuracao = {
				periodo: moment(element.inicioapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' a ' + moment(element.terminoapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY') + this.getReferenciaApuracao(element),
				inicioapuracao: element.inicioapuracao,
				terminoapuracao: element.terminoapuracao,
				apuracaoponto: element.apuracaoponto
			};
			this.apuracoesSelect.push(apuracao);
		});
		if (!this.valorExiste(this.filters.apuracaoinicial)) {
			const dataAtual = moment().format('YYYY-MM');
            const dataAtualMenosUmMes = moment().subtract(1, 'month').format('YYYY-MM');
            const dataAtualMenosDoisMeses = moment().subtract(2, 'months').format('YYYY-MM');

            // verifica se existe algum periodo com dois meses anteriores ao mes da data atual, um mes ou no mesmo mes
            if (
                this.apuracoesSelect.some((apuracao) =>
                    dataAtualMenosDoisMeses === moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('YYYY-MM') ||
                    dataAtualMenosDoisMeses === moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('YYYY-MM')
                )
            ) {
                this.filters.apuracaoinicial = this.apuracoesSelect.find((apuracao) =>
                    dataAtualMenosDoisMeses === moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('YYYY-MM') ||
                    dataAtualMenosDoisMeses === moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('YYYY-MM')
                )?.apuracaoponto;
            } else if (
                this.apuracoesSelect.some((apuracao) =>
                    dataAtualMenosUmMes === moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('YYYY-MM') ||
                    dataAtualMenosUmMes === moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('YYYY-MM')
                )
            ) {
                this.filters.apuracaoinicial = this.apuracoesSelect.find((apuracao) =>
                    dataAtualMenosUmMes === moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('YYYY-MM') ||
                    dataAtualMenosUmMes === moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('YYYY-MM')
                )?.apuracaoponto;
            } else if (
                this.apuracoesSelect.some((apuracao) =>
                    dataAtual === moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('YYYY-MM') ||
                    dataAtual === moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('YYYY-MM')
                )
            ) {
                this.filters.apuracaoinicial = this.apuracoesSelect.find((apuracao) =>
                    dataAtual === moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('YYYY-MM') ||
                    dataAtual === moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('YYYY-MM')
                )?.apuracaoponto;
            } else {
                this.filters.apuracaoinicial = this.apuracoesSelect[0]?.apuracaoponto;
            }

            // verifica se existe algum periodo com o mes da data atual
            if (
                this.apuracoesSelect.some((apuracao) =>
                    dataAtual === moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('YYYY-MM') ||
                    dataAtual === moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('YYYY-MM')
                )
            ) {
                this.filters.apuracaofinal = this.apuracoesSelect.find((apuracao) =>
                    dataAtual === moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('YYYY-MM') ||
                    dataAtual === moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('YYYY-MM')
                )?.apuracaoponto;
            } else {
                this.filters.apuracaofinal = this.apuracoesSelect[0]?.apuracaoponto;
            }
		}
	}

    getReferenciaApuracao(apuracao: IApuracaoPonto) {
        if (apuracao.trabalhadorobj?.trabalhador) {
            return ` - Trabalhador: ${apuracao.trabalhadorobj.nome}`;
        }
        if (apuracao.lotacaoobj?.lotacao) {
            return ` - Lotação: ${apuracao.lotacaoobj.nome} `;
        }
        if (apuracao.estabelecimentoobj?.estabelecimento) {
            return ` - Estabelecimento: ${apuracao.estabelecimentoobj.nomefantasia}`;
        }
        if (apuracao.empresaobj?.empresa) {
            return ` - Empresa: ${apuracao.empresaobj.razaosocial}`;
        }
        return '';
    }

    formataPeriodo(entities: Array<IBancoHorasColaboradores>): Array<IBancoHorasColaboradores> {
        entities.forEach((bancohorascolaborador: IBancoHorasColaboradores) => {
            bancohorascolaborador.bancohoras.forEach((banco: IBancoHoras) => {
                banco.apuracaoponto.periodo = moment(banco.apuracaoponto.inicioapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' - ' + moment(banco.apuracaoponto.terminoapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY');
            });
        });
        return entities;
    }

    filterReload(): void {
		this.busyFilter = true;
        this.filterTouched = false;
        let constructors = {
            empresa: this.$rootScope.session.entities.empresa.empresa,
            estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
        };

        if (this.valorExiste(this.filters.trabalhador)) {
            constructors['trabalhador_trabalhador'] = {
                condition: 'eq',
                value: this.filters.trabalhador?.trabalhador
            };
		}

        if (this.valorExiste(this.filters.departamento)) {
            constructors['trabalhador_departamento'] = {
                condition: 'eq',
                value: this.filters.departamento?.departamento
            };
		}

        if (this.valorExiste(this.filters.grupo)) {
            constructors['grupo'] = {
                condition: 'eq',
                value: this.filters.grupo?.grupo
            };
		}

        if (this.valorExiste(this.filters.lotacao)) {
            constructors['lotacao_lotacao'] = {
                condition: 'eq',
                value: this.filters.lotacao?.lotacao
            };
		}

        if (this.valorExiste(this.filters.situacaoColaborador)) {
            constructors['trabalhador_status'] = {
                condition: 'eq',
                value: this.filters.situacaoColaborador
            };
		}

		if (!this.valorExiste(this.filters.apuracaoinicial)) {
			this.filters.apuracaoinicial = this.apuracoesSelect[0]?.apuracaoponto;
            if (!this.filters.apuracaoinicial) {
				return;
			}
		}
        let apuracaoInicial = this.encontraApuracao(this.filters.apuracaoinicial);
        constructors['apuracaoponto_inicioapuracao'] = {
            condition: 'gte',
            value: apuracaoInicial?.inicioapuracao
        };

        if (this.valorExiste(this.filters.apuracaofinal)) {
            let apuracaoFinal = this.encontraApuracao(this.filters.apuracaofinal);
            constructors['apuracaoponto_terminoapuracao'] = {
                condition: 'lte',
                value: apuracaoFinal?.terminoapuracao
            };
        }

		this.BancodeHorasService.constructors = constructors;
        this.entities = this.BancodeHorasService.reload();
        this.entitiesLoaded = false;
    }

    apuracoesValidas(): boolean {
        // a data inicial tem que ser menor que a final
        if ((this.filters.apuracaoinicial && this.filters.apuracaofinal) && (this.filters.apuracaoinicial !== this.filters.apuracaofinal)) {
            let inicial = this.encontraApuracao(this.filters.apuracaoinicial);
            let final = this.encontraApuracao(this.filters.apuracaofinal);
            return moment(inicial?.terminoapuracao, 'YYYY-MM-DD').isBefore(moment(final?.inicioapuracao, 'YYYY-MM-DD'));
        } else if (!this.filters.apuracaoinicial) {
            return false;
        }
        return true;
    }

    exportar(isPdf: boolean = false): void {
		this.busy = true;
        if (isPdf) {
            this.BancodeHorasService.exportarPDF(this.BancodeHorasService.constructors);
        } else {
            this.BancodeHorasService.exportar(this.BancodeHorasService.constructors);
        }
	}

    download(data: any, isPdf: boolean = false): void {
        let blob = new Blob([data]);
        let fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
        let a = document.createElement('a');

        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = fileURL;
        let apuracao = '';
		this.apuracoesSelect.forEach((element: any) => {
			if (element.apuracaoponto === this.filters.apuracaofinal) {
				apuracao = element.terminoapuracao;
			}
			if (element.apuracaoponto === this.filters.apuracaoinicial) {
				apuracao = element.inicioapuracao + '-a-' + apuracao;
			}
		});
        a.download = 'banco-de-horas-apuracao-' + apuracao + (isPdf ? '.pdf' : '.xlsx');
        a.click();
        window.URL.revokeObjectURL(fileURL);
	}

    encontraApuracao(guidApuracao: string | undefined): Partial<IApuracaoPonto & { periodo: string }> | undefined {
        return this.apuracoesSelect.find((apuracao) => apuracao.apuracaoponto === guidApuracao);
    }

	valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}

    // inicio - Busca avançada - colaboradores
    buscaAvancada(): void {
        this.TrabalhadoresService.constructors.tipo = '-1';
        delete this.TrabalhadoresService.constructors.trabalhador;

        let columns = ['Nome', 'Tipo'];
        let values = ['nome', 'tipo'];
        let enumValues = [];
        enumValues['nome'] = '';
        enumValues['tipo'] = 'lookupTipoTrabalhador';
        var modal = this.BuscaAvancadaService.open(self, this.filters, this.TrabalhadoresService, columns, values, 'Colaboradores', enumValues, 'trabalhador', true, true, false, null);
        modal.result.then(function () {/**/}).catch(function () {
            this.filterTouched = true;
            this.TrabalhadoresService.filter = '';
            this.TrabalhadoresService.reload();
        }.bind(this));
    }

    eventoBuscaAvancada(event: angular.IAngularEvent & {detail: any}): void {
        let constructorSearchList = {
            'empresa': this.$rootScope.session.entities.empresa.empresa,
            'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento,
            'tipo': '-1',
            'forcagestor': this.$rootScope.modoGestorPermissao('registrar_apontamento')
        };
        let filter: { search?: string } = {};
        filter.search = event.detail.value;
        this.TrabalhadoresService.constructors = constructorSearchList;
        this.TrabalhadoresService.filter = event.detail;
        this.TrabalhadoresService.search(filter);
    }

    listagemColaboradores() {
        return this.colaboradoresList;
    }

    selecionaColaborador(event: angular.IAngularEvent & {detail: any}): void {
        if (event) {
            this.filters.trabalhador = event.detail.value;
        }
    }

    limpaColaborador(): void {
        delete this.filters.trabalhador;
    }

    limpaNsjSearch(id: string): void {
        document.querySelectorAll(`#${id} .componentPosition .customIcon`)[0]?.dispatchEvent(new Event('click'));
        document.querySelectorAll(`#${id} .componentPosition .customIconMobile`)[0]?.dispatchEvent(new Event('click'));
    }

    comboBusy(): boolean {
        return this.TrabalhadoresService.loadParams.busy;
    }
    // fim - Busca avançada - colaboradores

    agruparLotacoes(): any[] {
        const lotacoesMap = new Map<string, any>();

        for (const entity of this.entities) {
            for (const banco of entity.bancohoras) {
                const key = `${banco.trabalhador.codigo}-${banco.lotacao.nome}`;
                if (!lotacoesMap.has(key)) {
                    lotacoesMap.set(key, {
                        key: key.replace(/\s/g, ''),
                        nome: banco.trabalhador.nome,
                        codigo: banco.trabalhador.codigo,
                        bancohoras: [],
                        lotacao: {
                            nome: banco.lotacao.nome,
                        },
                    });
                }

                const lotacao = lotacoesMap.get(key);
                lotacao.bancohoras.push(banco);
            }
        }

        return Array.from(lotacoesMap.values());
    }
}

