import angular from 'angular';
import { TrabalhadoresService } from '../../../Trabalhadores/trabalhadores.service';
import { ITrabalhador } from '../../../Trabalhadores/models/trabalhador.model';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';

export class MeurhPromocoesPassozeroController implements angular.IController {
    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$http',
        '$stateParams',
        '$location',
        '$transitions',
        'nsjRouting',
        'NewToaster',
        'TrabalhadoresService',
        '$sce',
        'ListagemService'
    ];

    public busy: boolean = false;
    public entities: Array<ITrabalhador>;
    public filters: any;
    public filter: any;
    public constructors: any;
    public passo: number = 0;
    public mensagensPassos: any = [];
    public trabalhador: string = this.$stateParams['trabalhador'];
    public carregouTudo: boolean = false;
    public colaboradorSelecionado: any = {};

    public empresas: Array<Empresa>;
    public estabelecimentos: Array<Estabelecimento>;
    public listagemPermissao: Array<string> = ['visualizar_promocoes'];
    public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
    public fields = [
        {
            value: 'selecionar',
            label: ' ',
            type: 'string',
            style: 'title',
            copy: '',
            customCollumnScope: {
                selecionaColaborador: (entity: any): void => {
                    this.selecionaColaboradorFunc(entity);
                },
            },
            customCollumnHtml: (): string => {
                let someHtmlVar = `
                    <input
                        type="radio"
                        ng-click="customCollumnScope.selecionaColaborador(tableRowEntity)"
                        name="selecionar"
                        ng-model="tableRowEntity.selecionar"
                        value="{{tableRowEntity.selecionar}}"
                    />
                `;
                return someHtmlVar;
            },
        },
        {
            value: 'nome',
            label: 'Colaborador',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
			value: 'codigo',
			label: 'Matrícula',
			type: 'string',
			style: 'title',
			copy: '',
		},
        {
            value: 'dataadmissao',
            label: 'Data de admissão',
            type: 'date',
            style: 'title',
            copy: '',
        },
        {
            value: 'cargo',
            label: 'Cargo',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'tipo',
            label: 'Tipo de colaborador',
            type: 'string',
            style: 'title',
            copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <span ng-if="tableRowEntity.tipo === 0">Funcionário</span>
                <span ng-if="tableRowEntity.tipo === 1">Contribuinte</span>
                <span ng-if="tableRowEntity.tipo === 2">Estagiário</span>
                `;
                return someHtmlVar;
            },
        },
        {
            value: 'nivel',
            label: 'Nível',
            type: 'string',
            style: 'title',
            copy: '',
        },
        {
            value: 'salariofixo',
            label: this.$rootScope.temPermissao('visualizar_salarios') && !this.$rootScope.modoGestorPermissao('visualizar_funcionarios') ? 'Salário' : '',
            type: 'string',
            style: 'title',
            copy: '',
            customCollumnHtml: (): string => {
                let someHtmlVar = `
                    <span
                        ng-if="${this.$rootScope.temPermissao('visualizar_salarios') &&
                            !this.$rootScope.modoGestorPermissao('visualizar_funcionarios')}"
                        ng-bind="tableRowEntity.salariofixo | currency : 'R$ ' : 2"
                    ></span>
                `;
                return someHtmlVar;
            }
        },
    ];

    constructor(
        public $rootScope: angular.IRootScopeService & {
            temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
            temPermissao: (arg: string) => boolean,
            modoGestorPermissao: (arg: string) => boolean,
            session: any,
            nsjGlobals: any
        },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $http: angular.IHttpService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public $transitions: angular.ui.bootstrap.ITransitionService,
        public nsjRouting: any,
        public NewToaster: any,
        public entityService: TrabalhadoresService,
        public $sce: angular.ISCEService,
        public ListagemService: ListagemService
    ) {
        this.busy = true;
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        entityService.constructors = {
            status: '00',
            tipo: '-1',
            forcagestor: $rootScope.modoGestorPermissao('visualizar_promocoes')
        };
        this.filters = entityService.filters = {};
        this.filters.tipo = '-1';
        this.montaListagemVisibilidade();
        this.entityService.reload();

        $scope.$on('persona_trabalhadores_list_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.entities = this.alteraEntities(args);
            this.colaboradorSelecionado = {};
            this.busy = false;
        });

        $scope.$on('persona_trabalhadores_list_finished', (event: angular.IAngularEvent, args: any): void => {
            this.entities = this.alteraEntities(args);
            this.carregouTudo = true;
            this.busy = false;
        });
    }

    montaListagemVisibilidade(): void {
        this.filters.empresa = this.$rootScope.session.entities.empresa.empresa;
        this.filters.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
        this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
    }

    mudaEstabelecimentos(): void {
        this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
        this.filters.estabelecimento = '';
        this.filtraEmpresaEstabelecimento();
    }

    filtraEmpresaEstabelecimento(): void {
        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.entityService.reload();
    }

    filtraTipoColaborador(): void {

        this.entityService.constructors['empresa'] = this.filters.empresa;
        this.entityService.constructors['estabelecimento'] = this.filters.estabelecimento;
        this.entityService.constructors['tipo'] = this.filters.tipo;
        this.entityService.reload();

    }

    isBusy(): boolean {
        return this.busy;
    }

    search(): Array<ITrabalhador> {
        if (this.entityService.filter !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
        }

        var filter = {
            search: this.entityService.filter,
            filters: angular.copy(this.entityService.filters)
        };

        return this.entityService.search(filter);
    }

    loadMore(): void {
        this.entityService.loadMore();
    }

    selecionaColaboradorFunc(colaborador: ITrabalhador): void {
        this.colaboradorSelecionado = {};
        this.colaboradorSelecionado = colaborador;
        this.mensagensPassos.mensagempasso0 = this.colaboradorSelecionado.nome + ' receberá a promoção que você está criando.';
    }

    alteraEntities(entities: any): Array<ITrabalhador> {
        entities.forEach((item: any): void => {
            item.selecionar = true;
            item.nome = item.nome ? item.nome : null;
            item.dataadmissao = item.dataadmissao ? item.dataadmissao : null;
            item.cargo = item.cargo ? item.cargo : null;
            item.nivel = item.nivel ? item.nivel : null;
            item.salariofixo = item.salariofixo ? item.salariofixo : null;
        });

        return entities;
    }

    verificaSelecao(): boolean {
        return !this.valorExiste(this.colaboradorSelecionado.trabalhador);
    }

    calculaPasso(): void {
        this.passo = this.passo + 1;
    }

    valorExiste(valor: string): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}
