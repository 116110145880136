import angular = require('angular');
import { ITrabalhador } from 'modules/Trabalhadores/models/trabalhador.model';
import moment = require('moment');

export class MeurhPromocoesPassotresController implements ng.IController {
    static $inject = ['$rootScope', '$scope', '$state', '$http', '$stateParams', '$location', '$transitions', 'nsjRouting', 'NewToaster', 'ControleFeriasService', '$sce', 'ModalConfirmService', '$q'];

    public passo: number = 2;
    public entity: ITrabalhador;
    public entitySolicitacao: any = {};
    public mensagensPassos: any = {};
    public trabalhador: string = this.$stateParams['trabalhador'];
    public allowRefresh: boolean = false;
    public cardContents: any = [
        {
            'url_img': 'https://s3.sa-east-1.amazonaws.com/imagens.nasajon/geral/sistemas/departamento-pessoal-e-recursos-humanos/ilustracoes/ponto/controle-de-ponto-ativado.svg',
            'title': 'Continuar o controle de horários',
            'description': 'O colaborador permanecerá com a obrigação de registrar ponto.',
        },
        {
            'url_img': 'https://s3.sa-east-1.amazonaws.com/imagens.nasajon/geral/sistemas/departamento-pessoal-e-recursos-humanos/ilustracoes/ponto/controle-de-ponto-desativado.svg',
            'title': 'Parar o controle de horários',
            'description': 'O colaborador não precisará mais registrar ponto.',
        }
    ];

    constructor(
        public $rootScope: angular.IRootScopeService & { nsjGlobals: any },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $http: angular.IHttpService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public $transitions: any,
        public nsjRouting: any,
        public NewToaster: any,
        public entityService: any,
        public $sce: angular.ISCEService,
        public ModalConfirmService: any,
        public $q: any
    ) {
        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;
    }

    $onInit() {
        this.constroiEntitySolicitacao();
    }

    constroiEntitySolicitacao(): void {
        this.entitySolicitacao = {};
        this.entitySolicitacao.solicitacao = this.entity.solicitacao ? this.entity.solicitacao : null;
        this.entitySolicitacao.situacao = -1;
        this.entitySolicitacao.trabalhador = {};
        this.entitySolicitacao.trabalhador.trabalhador = this.entity.trabalhador;
        this.entitySolicitacao.estabelecimento = this.entity.estabelecimento.estabelecimento;
        this.entitySolicitacao.data = this.entity.data;
        this.entitySolicitacao.tipopromocao = this.entity.tipopromocao;
        this.entitySolicitacao.descricaopromocao = this.entity.descricaopromocao;
        this.entitySolicitacao.cargo = this.entity.cargo.cargo;
        this.entitySolicitacao.nivelcargo = this.entity.nivelcargo.nivelcargo;
        this.entitySolicitacao.salario = this.entity.salarionovo;
        this.entitySolicitacao.bateponto = this.verificaBateponto();
        this.entitySolicitacao.tipoaumento = this.entity.tipoaumentosalarial;
        this.entitySolicitacao.percentual = this.entity.percentual;
        this.entitySolicitacao.valoradiconalsalario = this.entity.aumentosalarial;
        // this.entitySolicitacao.horario = this.entity.horario; // a implementar // obrigatorio apenas de mudouhorario for true.
        this.entitySolicitacao.mudouhorario = false; // a implementar
        this.entitySolicitacao.unidadesalarionovo = this.entity.unidadesalarionovo;
        this.entitySolicitacao.unidadesalarioantigo = this.entity.unidadesalarioantigo ? this.entity.unidadesalarioantigo : null;
        this.entitySolicitacao.descricaounidadesalarionovo = this.entity.descricaounidadesalarionovo ? this.entity.descricaounidadesalarionovo : null;
        this.entitySolicitacao.descricaounidadesalarioantigo = this.entity.descricaounidadesalarioantigo ? this.entity.descricaounidadesalarioantigo : null;

        // constructors
        this.entitySolicitacao.constructors = {};
        this.entitySolicitacao.constructors.grupoempresarial = this.$rootScope.nsjGlobals.a.grupoempresarial;
        this.entitySolicitacao.constructors.tenant = this.$rootScope.nsjGlobals.a.tenant;
    }

    verificaBateponto(): boolean | null {
        if (this.valorExiste(this.entitySolicitacao.bateponto)) {
            return this.entitySolicitacao.bateponto;
        }

        if (this.valorExiste(this.entity.bateponto)) {
            return this.entity.bateponto;
        }

        return null;
    }

    verificaMarcarPontoCard(marcar: boolean): boolean {
        return this.entitySolicitacao.bateponto === marcar;
    }

    toggleMarcarPonto(marcar: boolean): void {
        this.entitySolicitacao.bateponto = marcar;
        this.entity.bateponto = marcar;
        this.montaMensagemPasso();
    }

    montaMensagemPasso(): void {
        if (this.entitySolicitacao.bateponto) {
            this.mensagensPassos['mensagempasso3'] = this.entity.nome + ' continuará a ter seu horário controlado.';
            return;
        }

        this.mensagensPassos['mensagempasso3'] = this.entity.nome + ' não continuará a ter seu horário controlado.';
    }

    calculaPasso(continua: boolean): void {
        this.allowRefresh = false;
        this.passo = continua ? this.passo + 1 : this.passo - 1;
    }

    limpaCampos(): void {
        this.allowRefresh = false;
        this.entitySolicitacao.bateponto = null;
        this.calculaPasso(false);
    }

    formInvalido(): boolean {
        return this.entitySolicitacao.bateponto === null;
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, !this.entity.solicitacao ? ' criação' : ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'promocoes' && this.allowRefresh) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, !this.entity.solicitacao ? ' criação' : ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }
}
