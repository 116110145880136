import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { MeurhSolicitacoes } from '../../../Meurh/Solicitacoes/services/solicitacoes.service';
import { WorkflowService } from '../../../Workflow/workflow.service';
import { MeurhSolicitacoesrescisoesService } from '../solicitacoesrescisoes.service';
import { IRescisao } from '../models/rescisao.model';
import { ISolicitacao } from '../../Solicitacoes/models/solicitacao.model';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { SituacaoRescisoesEnum } from '../enums/situacaorescisoes';
import { IConfiguracaoRescisao } from '../models/configuracaorescisao';
import { TipoCumprimentoAvisoEnum } from '../enums/tipocumprimento';
import { MeurhRescisoesModalConfirmService } from '../modal/modalrescisao.confirm.service';
import { MeurhAcompanhamentoService } from '../../Acompanhamento/acompanhamento.service';
import { IModalConfirmacao } from '../models/modalconfirm';
import { TrabalhadoresService } from '../../../Trabalhadores/trabalhadores.service';
import { RescisaoPontoService } from '../../Ponto/Rescisao/rescisao.ponto.service';

export class MeurhSolicitacoesrescisoesShowController implements ng.IController {
    static $inject = [
        'entity',
        'MeurhRescisoesModalConfirmService',
        '$scope',
        '$stateParams',
        '$state',
        'MeurhSolicitacoesrescisoesService',
        'NewToaster',
        'TiposDocumentosRequeridosService',
        'WorkflowService',
        'MeurhSolicitacoes',
        'MeurhAcompanhamentoService',
        '$rootScope',
        'TrabalhadoresService',
        'RescisaoPontoService',
        'HabilitadoService',
    ];

    public action: string = 'show';
    public dadosGeraisTitle: string;
    public busy: boolean = true;
    public trabalhador: string;
    public path: number = this.$stateParams.path;
    public form: angular.IFormController;
    public configuracaoRescisao: IConfiguracaoRescisao;
    public nomeEstabilidade: string = '';
    public possuiMovimentosAcaoPendente = false;

    // documentos / anexos
    public actionAnexo: string = 'show';
    public tiposdocumentosrequeridos: Array<any>;
    public busyTipoDocumentos: boolean = true;
    public editanexo: boolean = false;
    public entityAnexo: IAnexo = {};
    public movimentosAtivados: boolean;
    private documentosValidados: boolean = false;

    private toastTimeout: number = 8000;
    private entitySave: IRescisao;
    private formchanged: boolean = false;
    private allowRefresh: boolean = false;
    private tiposubmit: string;

    private tipoCumprimentoEnum = TipoCumprimentoAvisoEnum;
    private tipoSolicitacaoEnum = SituacaoRescisoesEnum;
    private dataAtualString: string;

    private codigosErroValidacaoMovimentos: Array<number> = [2, 3, 5, 6];

    constructor(
        public entity: IRescisao,
        private MeurhRescisoesModalConfirmService: MeurhRescisoesModalConfirmService,
        private $scope: angular.IScope,
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private entityService: MeurhSolicitacoesrescisoesService,
        private NewToaster: any,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        private WorkflowService: WorkflowService,
        private MeurhSolicitacoes: MeurhSolicitacoes,
        private MeurhAcompanhamentoService: MeurhAcompanhamentoService,
        private $rootScope: angular.IRootScopeService & {
            configuracaoRescisao: IConfiguracaoRescisao,
            usuario: any,
            temPermissao(permissao: string): any
        },
        private TrabalhadoresService: TrabalhadoresService,
        private RescisaoPontoService: RescisaoPontoService,
        private HabilitadoService: any,
    ) {
        this.entity.criadoautomaticamente
            ? this.dadosGeraisTitle = 'Dados do término de contrato'
            : this.dadosGeraisTitle = 'Dados da rescisão';

        this.MeurhAcompanhamentoService.constructors.solicitacao = this.entity.solicitacao;
        this.MeurhAcompanhamentoService.reload();

        $scope.$on('rescisao_ponto_etapa_updated', () => {
            this.MeurhAcompanhamentoService.reload();
        });

        this.entity.tipocumprimentoaviso_select = this.entity.tipocumprimentoaviso;

        this.$rootScope.$on('meurh_solicitacoesrescisoes_loaded', (event: angular.IAngularEvent, args: any) => {
            this.trabalhador = args.trabalhador.trabalhador;
            this.busy = false;
        });
        this.onSubmitSuccess();
        this.onSubmitError();
        this.onDeleteSuccess();
        this.onDeleteError();

        document.querySelector('form')?.addEventListener('change', (e) => {
            this.formchanged = true;
        });

        this.entityAnexo['solicitacao'] = this.entity.solicitacao;
        this.entityAnexo['casodeuso'] = 'RESCISOES';
        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();

        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                tipodocumentorequerido.file = '';
                return tipodocumentorequerido;
            });
        });

        this.movimentosAtivados = this.HabilitadoService.estaHabilitado('LIBERAR_PAGAMENTO_MOVIMENTOS_RESCISAO');
    }

    public async $onInit(): Promise<void> {
        this.$scope.$on('workflow_reloaded_after', (event: angular.IAngularEvent, args: any): void => {
            this.$state.reload();
        });

        this.entityService.get(this.$stateParams['solicitacao']);
        this.WorkflowService.config(this.entity.solicitacao, 'meurh_solicitacoesrescisoes_');

        !!this.$rootScope.configuracaoRescisao
            ? this.configuracaoRescisao = this.$rootScope.configuracaoRescisao
            : await this.getConfiguracaoRescisao();

        // situação que permite enviar rescisão
        if (this.entity.situacao === -1) {

            this.dataAtualString = moment().format('YYYY-MM-DD');
            let afastamentosTrabalhador = [];
            let dadosCipaTrabalhador;

            await this.TrabalhadoresService.getEstabilidadeTrabalhador(this.entity.trabalhador!.trabalhador)
            .then((dadosRetornados: any) => {
                afastamentosTrabalhador = dadosRetornados.afastamentos;
                dadosCipaTrabalhador = dadosRetornados.membrocipa;
                this.entity.colaboradorTemEstabilidade = (
                    (dadosRetornados.afastamentos && dadosRetornados.afastamentos.length > 0 && this.algumAfastamentoTemPeriodoEstavel(afastamentosTrabalhador)) ||
                    dadosRetornados.membrocipa
                ) ? true : false;
            });

            if (this.entity.colaboradorTemEstabilidade) {
                let infosEstabilidade = this.getFimPeriodoEstavel(afastamentosTrabalhador, dadosCipaTrabalhador);
                this.entity.fimPeriodoEstavel = infosEstabilidade.fimperiodoestavel;
                this.nomeEstabilidade = infosEstabilidade.descricaoestabilidade.toLowerCase();
            }

            this.exibeAvisosEstabilidade(this.entity.fimPeriodoEstavel, this.entity.datarescisao);
        }
        this.possuiMovimentosAcaoPendente = !this.validaMovimentos();
    }

    /**
     * Monta as mensagens dos avisos sobre estabilidade que serão exibidos ou limpa as mensagens, caso não seja para exibir avisos
     * @param fimPeriodoEstavel A data que o período estável acaba
     * @param dataRescisao A data de rescisão
     */
    public exibeAvisosEstabilidade(fimPeriodoEstavel: string, dataRescisao: string) {
        if (this.entity.colaboradorTemEstabilidade) {
            this.entity.exibeInfosEstabilidade = !dataRescisao || (moment(dataRescisao).isSameOrBefore(fimPeriodoEstavel));

            if (this.entity.exibeInfosEstabilidade && moment(fimPeriodoEstavel).isSameOrAfter(this.dataAtualString)) {
                this.entity.msgEstabilidade = `O colaborador encontra-se em período de estabilidade até ${moment(this.entity.fimPeriodoEstavel).format('DD/MM/YYYY')}.`;
                this.entity.msgEstabilidadeModal = `${this.entity.trabalhador?.nome} está sob estabilidade de ${this.nomeEstabilidade} até ${moment(this.entity.fimPeriodoEstavel).format('DD/MM/YYYY')}.`;
            } else {
                this.entity.msgEstabilidade = this.entity.msgEstabilidadeModal = '';
            }

        } else {
            this.entity.exibeInfosEstabilidade = false;
            this.entity.msgEstabilidade = this.entity.msgEstabilidadeModal = '';
        }
        this.$scope.$applyAsync();
    }

    // início - ações do formulário
    public submit(): void {
        this.alteraEntityEnviar();
        this.form.$submitted = true;
        this.tiposubmit = 'enviar';

        let customMessage: IModalConfirmacao = this.configuracaoRescisao.workflowativado
            ? this.getMensagensModal('envioWkf')
            : this.getMensagensModal('envio');

        if (this.entity.exibeInfosEstabilidade) {
            customMessage = this.getMensagensModal('envioEstabilidade');
        }

        const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(this.entity, customMessage);

        confirm.result
            .then((): void => {
                this.busy = true;
                const isFormValid = this.validaCamposObrigatoriosEnviar();
                const isApontamentosValid = this.validaMovimentos();
                const isDocumentosValid = this.validaDocumentosAnexos();

                if (!isFormValid) {
                    this.NewToaster.pop(
                        {
                            type: 'error',
                            title: 'Alguns campos obrigatórios não estão preenchidos',
                            body: 'Preencha os campos obrigatórios assinalados com "*" e tente novamente.',
                            timeout: this.toastTimeout
                        }
                    );

                    return;
                } else if (!isDocumentosValid) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Há documento(s) obrigatório(s) não informado(s)',
                        timeout: this.toastTimeout
                    });

                    return;
                } else if (!isApontamentosValid) {
                    this.NewToaster.pop(
                        {
                            type: 'error',
                            title: 'Não é possível cadastrar um apontamento com a rubrica vazia!',
                            timeout: this.toastTimeout
                        }
                    );

                    return;
                }

                this.allowRefresh = false;
                this.entityService.save(this.entitySave);
            })
            .catch(() => {/**/ })
            .finally(() => this.busy = false);
    }

    iniciarRescisao() {
        this.busy = true;
        this.RescisaoPontoService.setEtapa(this.entity.solicitacao, {
            etapa: 1,
            etapadetalhe: 1
        })
        .then(() => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A rescisão foi iniciada com sucesso.',
                timeout: this.toastTimeout
            });
        })
        .catch(() => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao iniciar a rescisão.',
                timeout: this.toastTimeout
            });
        })
        .finally(() => {
            this.busy = false;
            this.$state.reload();
        });
    }

    public delete(force: boolean): void {
        this.entityService.delete(this.$stateParams['solicitacao'], force);
    }

    public excluir(entity: IRescisao & ISolicitacao): void {
        const customMessage: IModalConfirmacao = this.getMensagensModal('excluir');
        const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(entity, customMessage);

        confirm.result
            .then((entity: IRescisao & ISolicitacao) => {
                this.busy = true;
                this.MeurhSolicitacoes.delete(entity, true)
                    .then((response: any) => {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A solicitação de rescisão foi excluída com sucesso'
                        });
                        this.entityService.reload();
                        this.$state.go('meurh_solicitacoesrescisoes', angular.extend(this.entityService.constructors));
                    }).catch((response: any) => {
                        if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                            this.NewToaster.pop(
                                {
                                    type: 'error',
                                    title: response.data.message
                                });
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Erro ao excluir.'
                            });
                        }
                    }).finally(() => {
                        this.busy = false;
                    });
            })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    public cancelar(entity: IRescisao & ISolicitacao): void {
        const customMessage: IModalConfirmacao = this.getMensagensModal('cancelar');
        const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(entity, customMessage);

        confirm.result
            .then((entity: IRescisao & ISolicitacao) => {
                this.busy = true;
                this.MeurhSolicitacoes.cancelar(entity)
                    .then((response: any) => {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'A solicitação de rescisão foi cancelada com sucesso'
                        });
                        this.entityService.reload();
                        this.$state.go('meurh_solicitacoesrescisoes', angular.extend(this.entityService.constructors));
                    }).catch((response: any) => {
                        if (typeof (response.data.message) !== 'undefined' && response.data.message) {
                            this.NewToaster.pop(
                                {
                                    type: 'error',
                                    title: response.data.message
                                });
                        } else {
                            this.NewToaster.pop({
                                type: 'error',
                                title: 'Erro ao cancelar.'
                            });
                        }
                    }).finally(() => {
                        this.busy = false;
                    });
            })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    public prorrogarContrato(): void {
        const customMessage: IModalConfirmacao = this.getMensagensModal('prorrogarContrato');
        const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(this.entity, customMessage, true);

        confirm.result
            .then((entity: IRescisao) => {
                this.busy = true;
                this.entityService.prorrogarContratoColaboradorTemporario(entity.solicitacao, entity.dataprorrogacaocontrato!)
                    .then(() => {
                        this.NewToaster.pop({
                            type: 'success',
                            title: 'O término de contrato foi cancelado e prorrogado até o dia ' + moment(entity.dataprorrogacaocontrato!).format('DD/MM/YYYY')
                        });
                        this.$state.go('meurh_solicitacoesrescisoes', angular.extend(this.entityService.constructors));
                    })
                    .catch((error) => {
                        const message = !!error.message ? error.message : 'Ocorreu um erro ao prorrogar o término do contrato do colaborador temporário';

                        this.NewToaster.pop({
                            type: 'error',
                            title: message
                        });
                    });
            })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            })
            .finally(() => this.busy = false);
    }

    public abrirModalMovsExistentesAtualizados(): void {
        const customMessage: IModalConfirmacao = this.getMensagensModal('confirmacaoMovsExistentesAtualizados');
        const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(this.entity, customMessage);

        confirm.result.then(() => {
            // ir para edição informando que a tabela de movimentos existentes será atualizada com os movimentos buscados da api
            this.$state.go('meurh_solicitacoesrescisoes_edit', {
                solicitacao: this.entity.solicitacao,
                tipo: this.$stateParams.tipo,
                diferenca_movs_existentes: true
            });
        })
        .catch((error: any) => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
    }

    // fim - ações do formulário


    // início - controle de botões
    public mostrarBotaoCancelar(): boolean {
        return (
            !!this.entity.solicitacao &&
            this.entity.situacao === this.tipoSolicitacaoEnum.ENVIADA &&
            this.$rootScope.temPermissao('cancelar_rescisao') &&
            this.entity.created_by.email === this.$rootScope.usuario.email &&
            this.entity.origem === 1
        );
    }

    public mostrarProrrogacaoContrato(): boolean {
        return (
            !!this.entity.criadoautomaticamente &&
            this.entity.situacao === this.tipoSolicitacaoEnum.ABERTA
        );
    }

    public confirmarEdicao(): void {
        if (!!this.entity.wkf_estado) {
            const customMessage: IModalConfirmacao = this.getMensagensModal('editar');
            const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(
                this.entity,
                customMessage
            );

            confirm.result
                .then(async (): Promise<void> => {
                    this.irParaEdicao();
                })
                .catch(() => {/** */ });
        } else {
            this.irParaEdicao();
        }
    }
    // fim - controle de botões


    // início - validações
    public podeEnviar(): boolean {
        return (
            this.validaCamposObrigatoriosEnviar() &&
            this.validaDocumentosAnexos() &&
            this.validaMovimentos()
        );
    }

    public podeIniciarRescisao() {
        if (
            this.entity.etapaponto === 0 &&
            this.entity.situacao === -1 &&
            this.entity.trabalhador?.marcaponto
        ) {
            const dataAtual = moment().format('YYYY-MM-DD');
            return moment(dataAtual).isSameOrAfter(moment(this.entity.datarescisao));
        }
        return false;
    }

    public podeCancelar(): boolean {
        const isWkfValidation: boolean = this.entity._podecancelar !== null && this.entity._podecancelar !== undefined;
        let isValid: boolean = true;

        if (!isWkfValidation) {
            isValid = this.entity.created_by.email === this.$rootScope.usuario.email;
        } else {
            isValid = this.entity._podecancelar!;
        }

        return (
            isValid &&
            !!this.entity.solicitacao &&
            this.entity.situacao === 0 &&
            this.$rootScope.temPermissao('cancelar_rescisao') &&
            this.entity.origem === 1
        );
    }

    public podeExcluir(): boolean {
        return (
            !!this.entity.solicitacao &&
            this.entity.situacao === -1 &&
            this.$rootScope.temPermissao('cancelar_rescisao') &&
            this.entity.origem === 1
        );
    }
    // fim - validações


    // início - navegação
    public async irParaEdicao(params?: Object): Promise<void> {
        this.busy = true;

        await this.$state.go('meurh_solicitacoesrescisoes_edit', {
            solicitacao: this.entity.solicitacao,
            tipo: this.$stateParams.tipo
            ...params
        });

        this.busy = false;
    }
    // fim - navegação


    // inicio - validações
    private validaCamposObrigatoriosEnviar(): boolean {
        return (
            !!this.entity.trabalhador &&
            !!this.entity.datarescisao &&
            !!this.entity.motivorescisao &&
            !!this.entity.justificativa &&
            this.validaAvisoPrevio()
        );
    }

    private validaAvisoPrevio(): boolean {
        return this.entity.tipocumprimentoaviso_select === this.tipoCumprimentoEnum.DISPENSADO
            ? true
            : this.validaCamposAvisoPrevio();
    }

    private validaCamposAvisoPrevio(): boolean {
        return (
            !!this.entity.tipoconcessaoaviso &&
            !!this.entity.dataconcessaoaviso &&
            !!this.entity.dataprojetadaaviso &&
            this.entity.dataconcessaoaviso < this.entity.dataprojetadaaviso
        );
    }

    private validaDocumentosAnexos(): boolean {
        return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return (
                tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio ||
                tipodocumentorequerido.fileSelecionado
            );
        });
    }

    private validaMovimentos(): boolean {
        let movimentosExistentesValidos: boolean = true;

        if (this.entity.movimentosexistentescolaborador && this.entity.movimentosexistentescolaborador.length > 0) {

            movimentosExistentesValidos = this.entity.movimentosexistentescolaborador.every(movimentoExistente => {

                // movimento exige uma ação
                if ((movimentoExistente.tipoorigem === 2 || movimentoExistente.tipoorigem === 3 || movimentoExistente.tipoorigem === 4) ||
                    (movimentoExistente.tipoorigem === 1 && movimentoExistente.tipo === 4)) {

                    return movimentoExistente.tipoorigemstatus === 1 || movimentoExistente.tipoorigemstatus === 2;

                } else if (movimentoExistente.tipoorigem === 1 && movimentoExistente.tipo !== 4) {
                    // movimento fixo sempre é válido, não vai na requisição
                    return true;
                }

            });

        }

        return movimentosExistentesValidos;
    }
    // fim - validações


    // início - Requisições
    private async getConfiguracaoRescisao(): Promise<void> {
        await this.entityService.getConfiguracaoRescisao()
            .then((configuracao: ng.IHttpResponse<IConfiguracaoRescisao>) => {
                this.configuracaoRescisao = configuracao.data;
            })
            .catch((error) => {
                this.NewToaster.pop({
                    type: 'error',
                    tittle: 'Ocorreu um erro ao obter as configurações da rescisão',
                    timeout: this.toastTimeout
                });

                this.configuracaoRescisao = {
                    workflowativado: false
                };
            });
    }
    // fim - Requisições


    // início - pós requisições
    private onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesrescisoes_submitted', (event: angular.IAngularEvent, args: any): void => {

            const tituloToast = args.entity.situacao === this.tipoSolicitacaoEnum.ABERTA
                ? 'A solicitação de rescisão foi editada com sucesso!'
                : 'A solicitação de rescisão foi enviada com sucesso!';
            this.entityService.constructors.solicitacao = args.entity.solicitacao;
            this.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;
            this.entityAnexo['solicitacao'] = args.entity.solicitacao;

            this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

            this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                this.$scope.$apply();

                if (
                    this.tiposdocumentosrequeridos.every(
                        (tipodocumentorequerido) => tipodocumentorequerido.validateResult
                    ) &&
                    !this.documentosValidados
                ) {
                    this.documentosValidados = true;
                }
            });

            this.NewToaster.pop({
                type: 'success',
                title: tituloToast,
                timeout: this.toastTimeout
            });


            this.$state.go('meurh_solicitacoesrescisoes_show', this.entityService.constructors);
            this.$state.reload();
            this.busy = false;
        });
    }


    private onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesrescisoes_submit_error', (event: angular.IAngularEvent, args: any): void => {
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity);
                }
            } else {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    if (args.response.data.message === 'Validation Failed') {
                        if (args.response?.data?.errors?.children) {

                            let msgErro = '';
                            let tituloToaster = this.tiposubmit === 'enviar' ? 'Não foi possível enviar rescisão' : 'Não foi possível salvar rescisão';

                            for (const campo in args.response.data.errors.children) {
                                if (args.response.data.errors.children[campo]['errors']) {
                                    args.response.data.errors.children[campo]['errors'].forEach((element: string) => {
                                        msgErro = msgErro + '&bull; ' + element + '<br>';
                                    });
                                }
                            }
                            this.NewToaster.pop({
                                type: 'error',
                                title: tituloToaster,
                                body: msgErro,
                                bodyOutputType: 'trustedHtml',
                                timeout: this.toastTimeout
                            });
                        } else {
                            this.NewToaster.pop(
                                {
                                    type: 'error',
                                    title: 'Alguns campos obrigatórios não estão preenchidos',
                                    body: 'Preencha os campos obrigatórios assinalados com "*" e tente novamente.',
                                    timeout: this.toastTimeout
                                }
                            );
                        }
                    } else {

                        // verificando se o erro é porque tem diferença dos movimentos existentes enviados na rescisão e os movimentos existentes
                        if (this.codigosErroValidacaoMovimentos.includes(args.response.data.code)) {

                            // abrir modal informando mudança nos movimentos existentes
                            this.abrirModalMovsExistentesAtualizados();
                        } else {
                            this.NewToaster.pop(
                                {
                                    type: 'error',
                                    title: args.response.data.message,
                                    timeout: this.toastTimeout
                                }
                            );
                        }
                    }
                } else {
                    if (args.entity.situacao === this.tipoSolicitacaoEnum.ENVIADA) {
                        this.NewToaster.pop(
                            {
                                type: 'error',
                                title: 'Ocorreu um erro ao enviar a solicitação!',
                                timeout: this.toastTimeout
                            }
                        );
                    } else {
                        this.NewToaster.pop(
                            {
                                type: 'error',
                                title: 'Ocorreu um erro ao atualizar a solicitação!',
                                timeout: this.toastTimeout
                            }
                        );
                    }
                }
            }

            this.busy = false;
        });
    }

    private onDeleteSuccess(): void {
        this.$scope.$on('meurh_solicitacoesrescisoes_deleted', (event: angular.IAngularEvent, args: any): void => {
            this.NewToaster.pop({
                type: 'success',
                title: 'A solicitação de rescisão foi excluída com sucesso!'
            });
            this.$state.go('meurh_solicitacoesrescisoes', angular.extend(this.entityService.constructors));
        });
    }

    private onDeleteError(): void {
        this.$scope.$on('meurh_solicitacoesrescisoes_delete_error', (event: angular.IAngularEvent, args: any): void => {
            if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: args.response.data.message
                    });
            } else {
                this.NewToaster.pop(
                    {
                        type: 'error',
                        title: 'Ocorreu um erro ao excluir a Solicitação.'
                    });
            }
        });
    }
    // fim - pós requisições


    // inicio - tratamentos de dados
    private getMensagensModal(tipo: 'envio' | 'envioWkf' | 'cancelar' | 'excluir' | 'editar' | 'prorrogarContrato' | 'envioEstabilidade' | 'confirmacaoMovsExistentesAtualizados'): IModalConfirmacao {

        const customText = {
            'envio': {
                titulo: 'Enviar rescisão',
                descricao: (
                    'Após enviar a rescisão, os documentos serão gerados e a ' +
                    'rescisão efetuada. Se você deseja ver uma prévia dos ' +
                    'cálculos da rescisão, utilize a opção "Salvar" ao invés da "Enviar".'
                ),
                textoConfirmacao: (
                    'Você tem certeza que deseja enviar a rescisão ' +
                    'de ' + this.entity.trabalhador?.nome + '?'
                ),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Sim, enviar'
                }
            },
            'cancelar': {
                titulo: 'Cancelar rescisão',
                descricao: (
                    'Após cancelar a rescisão, não será mais possível desfazer essa ação.'
                ),
                textoConfirmacao: (
                    'Você tem certeza que deseja cancelar a rescisão ' +
                    'de ' + this.entity.trabalhador?.nome + '?'
                ),
                botoes: {
                    fechar: 'Anular',
                    confirmar: 'Sim, cancelar'
                }
            },
            'excluir': {
                titulo: 'Excluir rescisão',
                descricao: (
                    'Todas as informações preenchidas serão perdidas.'
                ),
                textoConfirmacao: (
                    'Você tem certeza que deseja excluir a rescisão de ' + this.entity.trabalhador?.nome + '?'
                ),
                botoes: {
                    fechar: 'Anular',
                    confirmar: 'Sim, excluir'
                }
            },
            'envioWkf': {
                titulo: 'Enviar rescisão',
                descricao: (
                    'Após enviar a rescisão, as prévias serão geradas para confirmação. Pode levar ' +
                    'um tempo até elas ficarem disponíveis, mas não se preocupe, vamos lhe avisar por ' +
                    'e-mail assim que as prévias estiverem disponíveis.'
                ),
                textoConfirmacao: (
                    'Você tem certeza que deseja enviar a rescisão ' +
                    'de ' + this.entity.trabalhador?.nome + '?'
                ),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Sim, enviar'
                }
            },
            'editar': {
                titulo: 'Editar rescisão',
                descricao: (
                    'Após editar e enviar a rescisão, as prévias serão geradas novamente.'
                ),
                textoConfirmacao: (
                    'Você tem certeza que deseja editar a rescisão ' +
                    'de ' + this.entity.trabalhador?.nome + '?'
                ),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Sim, editar'
                }
            },
            'prorrogarContrato': {
                titulo: 'Prorrogar término de contrato temporário',
                descricao: (
                    `Ao cancelar a rescisão de ${this.entity.trabalhador?.nome}, o contrato temporário ` +
                    'de trabalho será prorrogado e a rescisão cancelada.'
                ),
                botoes: {
                    fechar: 'Fechar',
                    confirmar: 'Prorrogar contrato'
                }
            },
            'envioEstabilidade': {
                titulo: 'Colaborador em estabilidade',
                descricao: (this.entity.msgEstabilidadeModal),
                textoConfirmacao: (`Você tem certeza que deseja enviar a rescisão de ${this.entity.trabalhador?.nome}?`),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Enviar rescisão'
                }
            },
            'confirmacaoMovsExistentesAtualizados': {
                titulo: 'Movimentos atualizados',
                textoConfirmacao: 'Alguns movimentos foram atualizados durante o processo de rescisão. Revise as alterações e tome as ações necessárias, se for o caso. Se não houver ações a serem feitas, apenas confira as mudanças antes de enviar.',
                botoes: {
                    fechar: 'Fechar',
                    confirmar: 'Editar'
                }
            },
        };

        return customText[tipo];
    }

    /**
     * Recebe o array de afastamentos e retorna se algum deles possui período estável
     * @param afastamentos array de afastamentos
     */
    private algumAfastamentoTemPeriodoEstavel (afastamentos: Array<any>) {
        return afastamentos.some((afastamento) => {
            return afastamento.hasOwnProperty('fimperiodoestavel') && afastamento.fimperiodoestavel != null;
        });
    }

    /**
     * Dado o array de afastamentos e os dados de membro CIPA, método retornará a data final do período estável a ser considerada
     * @param afastamentos array de afastamentos
     * @param membrocipa dados de membro CIPA
     * @returns objeto com a data final do período estável e a descrição da estabilidade
     */
    private getFimPeriodoEstavel(afastamentos: Array<any>, membrocipa: any): {fimperiodoestavel: string, descricaoestabilidade: string} {

        if (!afastamentos || afastamentos.length < 1) { // não tem afastamentos, apenas cipa
            return {
                fimperiodoestavel: membrocipa.fimperiodoestavel,
                descricaoestabilidade: 'membro CIPA'
            };

        } else if (!membrocipa) { // não tem cipa, apenas afastamentos

            let afastamentosComPeriodoEstavel = afastamentos.filter((afastamento) => {
                if (afastamento.fimperiodoestavel) {
                    return afastamento;
                }
            });

            let afastamentoMaiorFimPeriodoEstavel = this.getAfastamentoMaiorFimPeriodoEstavel(afastamentosComPeriodoEstavel);
            return {
                fimperiodoestavel: afastamentoMaiorFimPeriodoEstavel.fimperiodoestavel,
                descricaoestabilidade: afastamentoMaiorFimPeriodoEstavel.descricao
            };

        } else { // tem afastamentos e cipa

            let afastamentosComPeriodoEstavel = afastamentos.filter((afastamento) => {
                if (afastamento.fimperiodoestavel) {
                    return afastamento;
                }
            });

            let afastamentoMaiorFimPeriodoEstavel = this.getAfastamentoMaiorFimPeriodoEstavel(afastamentosComPeriodoEstavel);
            let fimPeriodoEstavelAfastamentos = afastamentoMaiorFimPeriodoEstavel.fimperiodoestavel;
            let fimPeriodoEstavelCipa = membrocipa.fimperiodoestavel;

            let afastamentoMaiorQueCipa = moment(fimPeriodoEstavelAfastamentos).isSameOrAfter(fimPeriodoEstavelCipa);

            if (afastamentoMaiorQueCipa) {
                return {
                    fimperiodoestavel: afastamentoMaiorFimPeriodoEstavel.fimperiodoestavel,
                    descricaoestabilidade: afastamentoMaiorFimPeriodoEstavel.descricao
                };
            } else {
                return {
                    fimperiodoestavel: membrocipa.fimperiodoestavel,
                    descricaoestabilidade: 'membro CIPA'
                };
            }
        }

    }

    /**
     * Dada uma lista de afastamentos de um colaborador, retorna o afastamento que possui o maior fim período estável
     * @param afastamentos lista de afastamentos
     */
    private getAfastamentoMaiorFimPeriodoEstavel (afastamentos: Array<any>) {

        return afastamentos.reduce((maiorAfastamento: any, afastamentoAtual: any) => {
            const fimPeriodoEstavelMaior = moment(maiorAfastamento.fimperiodoestavel, 'YYYY-MM-DD');
            const fimPeriodoEstavelAtual = moment(afastamentoAtual.fimperiodoestavel, 'YYYY-MM-DD');

            return fimPeriodoEstavelMaior.isAfter(fimPeriodoEstavelAtual) ? maiorAfastamento : afastamentoAtual;
        });

    }

    private alteraEntityEnviar(): void {
        this.entitySave = angular.copy(this.entity);
        this.entitySave.situacao = this.tipoSolicitacaoEnum.ENVIADA;

        this.entitySave.tipocumprimentoaviso = this.entitySave.tipocumprimentoaviso_select;
        delete this.entitySave.tipocumprimentoaviso_select;

        let beneficiosadescontar: any[] = [];
        this.entitySave.beneficiostrabalhadores.map((obj: any) => {
            if (obj.descontar) {
                beneficiosadescontar.push(obj.beneficio);
            }
        });
        this.entitySave.beneficiosadescontar = JSON.stringify(beneficiosadescontar);
        if (this.entitySave.trabalhador?.tipo !== 0) {
            this.entitySave.beneficiosadescontar = null;
            this.entitySave.orientacoescalculo = null;
            this.entitySave.tipocumprimentoaviso = null;
            this.entitySave.tipoconcessaoaviso = null;
            this.entitySave.dataconcessaoaviso = null;
            this.entitySave.dataprojetadaaviso = null;
            this.entitySave.observacaoconcessaoaviso = null;
        }

        // ajustando os movimentos adicionados para enviar
        if (this.entitySave.movimentos && this.entitySave.movimentos.length > 0) {

            let movimentosAjustados: any = [];

            this.entitySave.movimentos.forEach(movimentoAdicionado => {
                movimentosAjustados.push({
                    movimento: movimentoAdicionado.movimento,
                    tipoorigem: movimentoAdicionado.tipoorigem,
                    tipoorigemregistro: movimentoAdicionado.tipoorigemregistro,
                    tipoorigemstatus: movimentoAdicionado.tipoorigemstatus,
                    evento: movimentoAdicionado.evento.evento,
                    conteudo: movimentoAdicionado.conteudo,
                    tipo: movimentoAdicionado.tipo
                });
            });

            this.entitySave.movimentos = movimentosAjustados;
        }

        // ajustando os movimentos existentes do colaborador para enviar
        if (this.entitySave.movimentosexistentescolaborador && this.entitySave.movimentosexistentescolaborador.length > 0) {

            if (!this.entitySave.movimentos) {
                this.entitySave.movimentos = [];
            }

            this.entitySave.movimentosexistentescolaborador.forEach(movimentoExistente => {

                // só adiciona o movimento no objeto a ser enviado na requisição se não for um movimento fixo
                if (!(movimentoExistente.tipoorigem === 1 && movimentoExistente.tipo !== 4)) {
                    this.entitySave.movimentos?.push({
                        tipoorigem: movimentoExistente.tipoorigem,
                        tipoorigemregistro: movimentoExistente.tipoorigemregistro,
                        tipoorigemstatus: movimentoExistente.tipoorigemstatus,
                        evento: movimentoExistente.evento.evento,
                        conteudo: movimentoExistente.conteudo,
                        tipo: movimentoExistente.tipo
                    });
                }

            });

        }

    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = { 'tiposolicitacao': TipoSolicitacaoEnum.RECISAO };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }
    // fim - tratamentos de dados
}
