import ng from 'angular';
import angular = require('angular');
import { IRescisao } from '../../models/rescisao.model';
import { TrabalhadoresService } from '../../../../Trabalhadores/trabalhadores.service';

export class ModalAdicionarMovimentoRescisaoController implements ng.IController {

    static $inject = [
        'entity',
        '$uibModalInstance',
        '$compile',
        '$scope',
        'TrabalhadoresService',
    ];

    public novoMovimento;
    public form: angular.IFormController;

    constructor(
        public entity: IRescisao,
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        private $compile: any,
        private $scope: any,
        private TrabalhadoresService: TrabalhadoresService,
    ) {}

    public selecionaRubrica() {

        this.novoMovimento.conteudo = '';

        // para criar o input conteúdo correspondente a rubrica selecionada
        let inputHtml = '';
        let inputConteudo = angular.element(document.getElementById('inputConteudo'));
        if (inputConteudo) {
            inputConteudo.remove();
        }

        if (this.novoMovimento.evento) {
            let eventounidade = this.novoMovimento.evento.evento_unidade === undefined ? this.novoMovimento.evento.unidade : this.novoMovimento.evento.evento_unidade;
            switch (eventounidade) {
                case 0:
                case 8:
                case 10:
                    // hora
                    inputHtml = '<input id="inputConteudo" type="text" class="form-control" name="conteudo" ng-model="modalAdicionarMovimentoRescisaoController.novoMovimento.conteudo" ng-disabled="!modalAdicionarMovimentoRescisaoController.novoMovimento.evento" time-mask placeholder="00:00" ng-required="true" />';
                    this.novoMovimento.evento_unidade = 'interval';
                    break;
                case 2:
                case 12:
                    // dinheiro
                    inputHtml = '<input id="inputConteudo" type="text" class="form-control" name="conteudo" ng-model="modalAdicionarMovimentoRescisaoController.novoMovimento.conteudo"  ng-disabled="!modalAdicionarMovimentoRescisaoController.novoMovimento.evento" ui-money-mask placeholder="0,00" ng-required="true" />';
                    this.novoMovimento.evento_unidade = 'currency';
                    break;
                case 5:
                    // dinheiro
                    this.TrabalhadoresService.get(this.entity.trabalhador!.trabalhador)
                    .then(response => {
                        this.novoMovimento.conteudo = response.salariofixo;
                    })
                    .catch((error) => {
                        console.log('Erro: ', error);
                    });

                    inputHtml = '<input id="inputConteudo" type="text" class="form-control" name="conteudo" ng-model="modalAdicionarMovimentoRescisaoController.novoMovimento.conteudo" ng-disabled="true" ui-money-mask placeholder="0,00" ng-required="true" />';
                    this.novoMovimento.evento_unidade = 'currency';
                    break;
                default:
                    // variado
                    inputHtml = '<input id="inputConteudo" type="number" class="form-control" name="conteudo" ng-model="modalAdicionarMovimentoRescisaoController.novoMovimento.conteudo" ng-disabled="!modalAdicionarMovimentoRescisaoController.novoMovimento.evento" min="0" ng-required="true" />';
                    this.novoMovimento.evento_unidade = 'number';
            }
        } else {
            inputHtml = '<input id="inputConteudo" type="text" class="form-control" ng-disabled="true" ng-required="true" />';
            this.novoMovimento.evento_unidade = 'number';
        }

        let compiledHtml = this.$compile(inputHtml)(this.$scope);
        let inputBox = angular.element(document.getElementById('inputBox'));
        inputBox.append(compiledHtml);
    }

    public adicionarMovimento() {

        this.$uibModalInstance.close({
            tipoorigem: null,
            tipoorigemregistro: null,
            tipoorigemstatus: null,
            evento: this.novoMovimento.evento,
            conteudo: this.novoMovimento.conteudo,
        });

    }

    public close() {
        this.$uibModalInstance.dismiss('cancelar');
    }

    public camposValidos() {
        return this.form.$valid && this.novoMovimento?.conteudo;
    }

}
