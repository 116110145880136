import angular from 'angular';
import { MeurhAnexosModule } from './modules/Meurh/Anexos/anexos.module';
import { MeurhAcompanhamentoModule } from './modules/Meurh/Acompanhamento/acompanhamento.module';
import { MeurhSolicitacoesModule } from './modules/Meurh/Solicitacoes/solicitacoes.module';
import { MeurhSolicitacoesadmissoesModule } from './modules/Meurh/Solicitacoesadmissoes/solicitacoesadmissoes.module';
import { MeurhColaboradorModule } from './shared/components/colaborador/colaborador.module';
import { MeurhTagInputModule } from './shared/components/tag-input/tag.input.module';
import { MeurhFiltrosAtivosModule } from './shared/components/filtros-ativos/filtros.ativos.module';
import { MeurhSolicitacoesfaltasModule } from './modules/Meurh/Solicitacoesfaltas/solicitacoesfaltas.module';
import { MeurhSolicitacoesvtsadicionaisModule } from './modules/Meurh/Solicitacoesvtsadicionais/solicitacoesvtsadicionais.module';
import { MeurhFeriasModule } from './modules/Meurh/Ferias/ferias.module';
import { MeurhPromocoesModule } from './modules/Meurh/Promocoes/promocoes.module';
import { MeurhSolicitacoesalteracoesvtsModule } from './modules/Meurh/Solicitacoesalteracoesvts/solicitacoesalteracoesvts.module';
import { MeurhSolicitacoesalteracoesvtstarifasModule } from './modules/Meurh/Solicitacoesalteracoesvtstarifas/solicitacoesalteracoesvtstarifas.module';
import { MeurhCreditosDescontosModule } from './modules/Meurh/CreditosDescontos/creditosdescontos.module';
import { MeurhInformesrendimentosModule } from './modules/Meurh/Informesrendimentos/informesrendimentos.module';
import { MeurhSolicitacoesalteracoesenderecosModule } from './modules/Meurh/Solicitacoesalteracoesenderecos/solicitacoesalteracoesenderecos.module';
import { WorkflowModule } from './modules/Workflow/workflow.module';
import { TiposDocumentosRequeridosModule } from './modules/Tiposdocumentosrequeridos/tiposdocumentosrequeridos.module';
import { PersonaTiposdocumentoscolaboradoresModule } from './modules/Persona/Tiposdocumentoscolaboradores/personatiposdocumentoscolaboradores.module';
import { routerConfig } from './app.routes';
import { Usuarios } from './shared/services/usuarios.service';
import { ListagemService } from './shared/services/listagem.service';
import { NsjRoutes } from './core/nsj-core';
import { MeurhSolicitacoesrescisoesModule } from './modules/Meurh/Solicitacoesrescisoes/solicitacoesrescisoes.module';
import { MeurhAfastamentosModule } from './modules/Meurh/Afastamentos/afastamentos.module';
import { MeurhVagasModule } from './modules/Meurh/Vagas/vagas.module';
import { MeurhAdmissaocompletaModule } from './modules/Meurh/AdmissaoCompleta/admissaocompleta.module';
import { MeurhAdmissaoestagiarioModule } from './modules/Meurh/AdmissaoEstagiario/admissaoestagiario.module';
import { MeurhTransferenciaColaboradoresModule } from './modules/Meurh/Transferenciacolaboradores/transferenciacolaboradores.module';
import { MeurhMudancaHorarioModule } from './modules/Meurh/MudancaHorario/mudancahorario.module';
import './lib/ckeditor';
import { CargosModule } from './modules/Cargos/cargos.module';
import { TrabalhadoresModule } from './modules/Trabalhadores/trabalhadores.module';
import { MeurhSolicitacoesadiantamentosavulsosModule } from './modules/Meurh/Solicitacoesadiantamentosavulsos/solicitacoesadiantamentosavulsos.module';
import { PontoModule } from './modules/Meurh/Ponto/ponto.module';
import { MeurhInclusaodependenteModule } from './modules/Meurh/InclusaoDependente/inclusaodependente.module';
import { ApontamentosImportacaoModule } from './modules/ApontamentosImportacao/apontamentosimportacao.module';
import { MeurhRelatoriossolicitacoesModule } from './modules/Meurh/Relatoriossolicitacoes/relatoriossolicitacoes.module';
import { MeurhEscalaModule } from './modules/Meurh/Escala/escala.module';
import { MeurhApontamentosModule } from './modules/Apontamentos/apontamentos.module';
import { MeurhApontamentosGradeModule } from './modules/ApontamentosGrade/apontamentosgrade.module';
import { MeurhQuadroHorariosModule } from './modules/Quadrohorarios/quadrohorarios.module';
import { PaginaInicialModule } from './modules/PaginaInicial/paginainicial.module';
import { DepartamentoPessoalModule } from './modules/DepartamentoPessoal/departamentopessoal.module';
import { AtestadosAVencerModule } from './modules/Relatorios/AtestadosVencer/atestadosavencer.module';
import { CargosSalariosModule } from './modules/Relatorios/CargosSalarios/cargossalarios.module';
import { RotatividadeModule } from './modules/Relatorios/Rotatividade/rotatividade.module';
import { SimulacaoFeriasModule } from './modules/SimulacaoFerias/simulacaoferias.module';
import { ApontamentosTrabalhadoresModule } from './modules/ApontamentosTrabalhadores/apontamentostrabalhadores.module';
import { SimulacaoRescisaoModule } from './modules/SimulacaoRescisao/simulacaorescisao.module';
import { ConfiguracoesModule } from './modules/Configuracoes/configuracoes.module';
import { AvisoFeriasModule } from './modules/Avisoferias/avisoferias.module';
import { ContrachequesModule } from './modules/Relatoriosliberados/Contracheques/contracheques.module';
import { ModalConfirmModule } from './shared/components/modal-confirm/modal.confirm.module';
import { MeurhAlteracaoFotoModule } from './modules/Meurh/AlteracaoFoto/alteracaofoto.module';
import { MeurhAdmissaoModule } from './modules/Meurh/Admissao/admissao.module';
import { MeurhSolicitacoesalteracoesdadossalariaisModule } from './modules/Meurh/Solicitacoesalteracoesdadossalariais/solicitacoesalteracoesdadossalariais.module';
import { MeurhSolicitacoesafastamentosretornoModule } from './modules/Meurh/Solicitacoesafastamentosretorno/solicitacoesafastamentosretorno.module';
import { MeurhSolicitacoesgenericasModule } from './modules/Meurh/Solicitacoesgenericas/solicitacoesgenericas.module';

import { MeurhArquivosModule } from './modules/Arquivos/arquivos.module';

import { NewToasterModule } from './shared/components/new-toaster/new.toaster.module';

export const app = angular.module('main',
  [
    'ui.router',
    'ui.bootstrap',
    'ui.select',
    'ngAnimate',
    'ngTouch',
    'ngSanitize',
    'ngMessages',
    'angular.filter',
    'angularMoment',
    'nasajon-ui',
    'dropzone',
    'toaster',
    'checklist-model',
    'nsjListGroup',
    'CKEditor',
    'angularPrint',
    'ui.utils.masks',
    'ui.mask',
    'oc.lazyLoad',
    'ui.router.state',
    'dateInput',
    'anexos',
    'debounce',
    'multipleDatePicker',
    'cpfValidator',
    'nisValidator',
    'convertToNumber',
    'onFileChange',
    'ng-sortable',
    'scroll.If',
    'treeGrid',
    'fileInput',
    'timeMask',
    'fileDropzone',
    'timeMask',
    MeurhAnexosModule,
    MeurhAcompanhamentoModule,
    MeurhSolicitacoesModule,
    MeurhSolicitacoesadmissoesModule,
    MeurhColaboradorModule,
    MeurhTagInputModule,
    MeurhFiltrosAtivosModule,
    MeurhSolicitacoesfaltasModule,
    MeurhFeriasModule,
    MeurhPromocoesModule,
    MeurhSolicitacoesalteracoesvtsModule,
    MeurhCreditosDescontosModule,
    MeurhSolicitacoesalteracoesvtstarifasModule,
    MeurhSolicitacoesadiantamentosavulsosModule,
    MeurhInformesrendimentosModule,
    WorkflowModule,
    CargosModule,
    TrabalhadoresModule,
    TiposDocumentosRequeridosModule,
    PersonaTiposdocumentoscolaboradoresModule,
    MeurhSolicitacoesalteracoesenderecosModule,
    MeurhAlteracaoFotoModule,
    MeurhSolicitacoesrescisoesModule,
    MeurhSolicitacoesvtsadicionaisModule,
    MeurhAfastamentosModule,
    MeurhVagasModule,
    MeurhAdmissaocompletaModule,
    MeurhAdmissaoestagiarioModule,
    MeurhTransferenciaColaboradoresModule,
    MeurhMudancaHorarioModule,
    PontoModule,
    MeurhInclusaodependenteModule,
    ApontamentosImportacaoModule,
    MeurhRelatoriossolicitacoesModule,
    MeurhEscalaModule,
    MeurhApontamentosModule,
    MeurhApontamentosGradeModule,
    MeurhQuadroHorariosModule,
    PaginaInicialModule,
    DepartamentoPessoalModule,
    AtestadosAVencerModule,
    CargosSalariosModule,
    RotatividadeModule,
    SimulacaoFeriasModule,
    ApontamentosTrabalhadoresModule,
    SimulacaoRescisaoModule,
    ConfiguracoesModule,
    AvisoFeriasModule,
    ContrachequesModule,
    ModalConfirmModule,
    MeurhAdmissaoModule,
    MeurhSolicitacoesalteracoesdadossalariaisModule,
    MeurhSolicitacoesafastamentosretornoModule,
    MeurhSolicitacoesgenericasModule,
    MeurhArquivosModule,
    NewToasterModule
  ])
  .config(routerConfig)
  .provider('nsjRouting', NsjRoutes)
  .service('Usuarios', Usuarios)
  .service('ListagemService', ListagemService)
  .constant('angularMomentConfig', {
    timezone: 'America/Sao_Paulo'
  }).name;
