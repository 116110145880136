import angular from 'angular';
import { TrabalhadoresService } from './trabalhadores.service';

export const TrabalhadoresListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        let resolve = {
            'entity': [
                'TrabalhadoresService',
                '$stateParams',
                '$state',
                '$q',
                function(
                    entityService: TrabalhadoresService,
                    $stateParams: angular.ui.IStateParamsService,
                    $state: angular.ui.IStateService,
                    $q: angular.IQService
                ): angular.ui.IStateParamsService | angular.IQService | {} {
                    if ($stateParams['entity']) {
                        return $stateParams['entity'];
                    }

                    if (!$stateParams['trabalhador']) {
                        return {};
                    }

                    return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
                        entityService.get($stateParams['trabalhador'])
                            .then((data: unknown): void => {
                                resolve(data);
                            })
                            .catch((error: any): void => {
                                if (error.status === 404) {
                                    if ($state.href('trabalhadores_not_found', $stateParams)) {
                                        $state.go('trabalhadores_not_found', $stateParams);
                                    } else {
                                        $state.go('not_found', $stateParams);
                                    }
                                }
                            });
                    });
                }
            ]
        };

        $stateProvider
            .state('trabalhadores', {
                url: '/colaboradores?q?estabelecimento?grupoempresarial?ano?mes?calculos',
                params: { q: { dynamic: true }, tipo: '-1', status: '00'},
                template: require('html-loader!./index/trabalhadores.index.html'),
                controller: 'TrabalhadoresListController',
                controllerAs: 'trblhdrs_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('trabalhadores_funcionarios', {
                url: '/colaboradores?q?estabelecimento?grupoempresarial?ano?mes?calculos',
                params: { q: { dynamic: true }, tipo: '0', status: '00'},
                template: require('html-loader!./index/trabalhadores.index.html'),
                controller: 'TrabalhadoresListController',
                controllerAs: 'trblhdrs_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('trabalhadores_contribuintes', {
                url: '/colaboradores?q?estabelecimento?grupoempresarial?ano?mes?calculos',
                params: { q: { dynamic: true }, tipo: '1', status: '00'},
                template: require('html-loader!./index/trabalhadores.index.html'),
                controller: 'TrabalhadoresListController',
                controllerAs: 'trblhdrs_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('trabalhadores_estagiarios', {
                url: '/colaboradores?q?estabelecimento?grupoempresarial?ano?mes?calculos',
                params: { q: { dynamic: true }, tipo: '2', status: '00'},
                template: require('html-loader!./index/trabalhadores.index.html'),
                controller: 'TrabalhadoresListController',
                controllerAs: 'trblhdrs_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('trabalhadores_show', {
                url: '/colaboradores/:trabalhador',
                resolve: resolve,
                template: require('html-loader!./show.html'),
                controller: 'TrabalhadoresFormController',
                controllerAs: 'trblhdrs_frm_cntrllr'
            });
    }
];
