import angular from 'angular';
import { NsjConfig } from './../core/nsj-core';
declare var nsjGlobals: any;
declare var nsj;
export class MainController {
    static $inject = [
        '$rootScope',
        'ConfiguracoesGerais',
        'moment',
        'ClientSession',
        'PermissaoService',
        'RelatoriosService',
        'Usuarios',
        'NovidadesService',
        '$scope',
        '$window',
        '$location',
        'HabilitadoService',
    ];

    public temEstabelecimento: boolean = false;
    public erroEstrutura: any = null;
    public pages = [5, 10, 15, 20];
    public carregandoFolhaApontamento: boolean;

    public diaAtual: any;
    public mesAtual: any;
    public anoFim: number;
    public anoInicio: any;
    public tenant: any;
    public estabelecimentos: any[] = [];
    public meses = [
        { 'value': null, 'label': 'Escolha o mês' },
        { 'value': '01', 'label': 'Janeiro' }, { 'value': '02', 'label': 'Fevereiro' }, { 'value': '03', 'label': 'Março' },
        { 'value': '04', 'label': 'Abril' }, { 'value': '05', 'label': 'Maio' }, { 'value': '06', 'label': 'Junho' },
        { 'value': '07', 'label': 'Julho' }, { 'value': '08', 'label': 'Agosto' }, { 'value': '09', 'label': 'Setembro' },
        { 'value': '10', 'label': 'Outubro' }, { 'value': '11', 'label': 'Novembro' }, { 'value': '12', 'label': 'Dezembro' }
    ];

    public logoutButtonTimer: any;
  public ativarRecursoNovidades: boolean = false;
  public ativarRecursoMenu: boolean = false;

    // valores de chaves de ativação para exibir ou não itens de menu
    public exibeAlteracaoDadosSalariais: boolean;
    public exibeOutrasSolicitacoes: boolean;

    constructor(
        public $rootScope: any,
        public ConfiguracoesGerais: ConfiguracoesGerais,
        protected moment: any,
        protected ClientSession: any,
        protected PermissaoService: any,
        protected RelatoriosService: any,
        public Usuarios: any,
        public NovidadesService: any,
        public $scope: angular.IScope,
        public $window: angular.IWindowService,
        public $location: angular.ILocationService,
        public HabilitadoService: any,
    ) {
        this.$scope.$on('$viewContentLoaded', (event: angular.IAngularEvent) => {
            this.$window.ga('set', 'page', this.$location.url());
            this.$window.ga('send', 'pageview');
        });

        // inserindo nsjGlobals no rootScope para permitir o seu uso dentro de expressões do angular
        this.setGlobals();
        this.$rootScope.nsjGlobals = nsj.globals;

        this.$rootScope.temPermissao = this.PermissaoService.temPermissao;
        this.$rootScope.temPermissaoGrupoEmpresarial = this.PermissaoService.temPermissaoGrupoEmpresarial;
        this.$rootScope.soPossuiPonto = this.PermissaoService.soPossuiPonto;
        this.$rootScope.liberadoTela = this.PermissaoService.liberadoTela;
        this.ativarRecursoMenu = this.$rootScope.liberadoTela('LIBERAR_MENU_PERMISSOES');
        this.$rootScope.temConfiguracao = this.PermissaoService.temConfiguracao;
        this.$rootScope.modoGestorPermissao = this.PermissaoService.modoGestorPermissao;
        // configuração de módulos habilitados
        this.$rootScope.isAdmin = this.PermissaoService.isAdmin;
        this.$rootScope.session = this.ClientSession;
        this.ClientSession.load();

        this.$rootScope.configuracoes = nsjGlobals.get('configuracoes');
        this.$rootScope.currentDate = moment();
        this.$rootScope.currentISODate = (new Date()).toISOString().substring(0, 10);

        // criando "configuração" que informa a data de fechamento anterior da folha
        this.$rootScope.configuracoes.DATA_FECHAMENTO_FOLHA_ANTERIOR = moment(this.$rootScope.configuracoes.DATA_FECHAMENTO_FOLHA).subtract(1, 'months').format('YYYY-MM-DD');

        this.tenant = this.getTenant();
        this.diaAtual = moment().format('DD');
        this.mesAtual = moment().format('MM');
        this.anoInicio = moment().year();
        this.anoFim = this.anoInicio - 11;

        this.getEstabelecimentos(this.tenant);
        $rootScope.openModal = false;
        $rootScope.text = '';
        $rootScope.isOpened = false;
        $rootScope.modalNewsIsOpen = false;
        $rootScope.temNovidades = true;
        $rootScope.novidadesClick = false;
        $rootScope.dados = this.NovidadesService.dados;
        this.$rootScope.novidadesClick = window.localStorage.getItem('novidades');
        if (this.$rootScope.novidadesClick) {
          this.$rootScope.temNovidades = false;
        }

        this.embeddedView();

        // verificando valores de chaves de ativação para exibir ou não itens de menu
        this.exibeAlteracaoDadosSalariais = this.HabilitadoService.estaHabilitado('LIBERAR_SOL_ALTERACAO_TIPO_RECEBIMENTO');
        this.exibeOutrasSolicitacoes = this.HabilitadoService.estaHabilitado('LIBERAR_SOLICITACAO_GENERICA');

      }

    openNovidades = () => {
      this.$rootScope.isOpened = !this.$rootScope.isOpened;
    }
    modalNewsOpen = () => {
      window.localStorage.setItem('novidades', this.$rootScope.temNovidades);
      this.$rootScope.isOpened = !this.$rootScope.isOpened;
      this.$rootScope.modalNewsIsOpen = true;
      this.$rootScope.temNovidades = false;
    }
    openDescriptionNews = function (title: string) {
      this.$rootScope.dados.map(dado => {
        dado.sections.map(section => {
          if (section.title === title) {
            section.isSelected = !section.isSelected;
          }
        });
      });
    };

    modalNewsClose = () => {
      this.$rootScope.dados.map(dado => {
        dado.sections.map(section => {
          if (section.title) {
            section.isSelected = false;
          }
        });
      });
      this.$rootScope.modalNewsIsOpen = false;
      this.$rootScope.isOpened = false;
    }

    fecharModalSemPermissao = () => {
        this.$rootScope.openModal = false;
    }

    semPermissao = (id: string) => {
    this.$rootScope.openModal = true;

      if (id === 'colaboradores') {
        this.$rootScope.text = () => {
          return `
      <h5>Colaboradores</h5>
      <div>
        <p>Na tela de Colaboradores são listados todos os Colaboradores cadastrados no sistema, sendo exibidos por estabelecimento selecionado, com as informações de nome, matrícula, cargo, nível e salário, além da sua situação (em serviço, afastado, de férias, etc).</p>
      </div>
    `;
        };
      }
      if (id === 'apontamento') {
        this.$rootScope.text = () => {
          return `
        <h5>Movimento</h5>
        <div>
          <h6>Registrar em grupo: </h6>
          <p>O movimento é destinado ao registro de movimentações(rubricas com seus respectivos valores) e faltas dos Colaboradores.</p>
        </div>
        <div>
          <h6>Registrar em Grade: </h6>
          <p>No movimento em grade são exibidos todos os Colaboradores ativos do estabelecimento e o preenchimento das rubricas é feito campo a campo em cada linha. </p>
        </div>
        <div>
          <h6>Imprimir Folha: </h6>
          <p>Exibe a visualização da impressão da listagem de Autorização e Anotação para Confecção da Folha de Pagamento.</p>
        </div>
      `;
        };
      }

      if (id === 'solicitacao') {
        this.$rootScope.text = () => {
          return `
        <h5>Solicitações</h5>
        <div>
          <h6>Adiantamento Avulso: </h6>
          <p>Adiantamento Avulso é qualquer valor a ser pago ao Colaborador, o qual está fora de seu salário e de seus benefícios.</p>
        </div>
        <div>
          <h6>Admissão: </h6>
          <p>Nessa tela é realizado todo o cadastro de uma admissão completa de Colaborador, incluindo todos os campos necessários para preenchimento no Persona SQL, como dados pessoais, dependentes, benefícios, entre outros.</p>
        </div>
        <div>
          <h6>Afastamentos: </h6>
          <p>Essa funcionalidade permite a criação da solicitação de afastamento do colaborador, trazendo mais agilidade para o DP que já recebe o afastamento com todas as informações necessárias.</p>
        </div>
        <div>
          <h6>Alteração de Endereços: </h6>
          <p>Ao clicar em “Criar” será aberta a tela com as informações cadastrais disponíveis para alteração. Após registrar as mudanças clique em “Salvar”.</p>
        </div>
        <div>
          <h6>Alteração de VT: </h6>
          <p>Nesta tela são cadastradas as solicitações de alterações de valor do Vale Transporte padrão do Funcionário.</p>
        </div>
        <div>
          <h6>Falta: </h6>
          <p>Nesta tela são criadas as solicitações de inclusão de faltas para o Colaborador, as faltas são criadas uma por uma.</p>
        </div>
        <div>
          <h6>Mudança de horário: </h6>
          <p>Nesta tela poderar ser criada uma mudança de horário.</p>
        </div>
        <div>
          <h6>Promoções: </h6>
          <p>O menu Promoções cria  a solicitação para a promoção do colaborador por cargo, nível ou aumento salarial espontâneo. Uma lista com os nomes de todos os colaboradores do estabelecimento é apresentada e após a escolha do colaborador deverá ser feita a escolha do tipo de promoção, do tipo de registro do ponto e a confirmação de todas as informações.</p>
        </div>
        <div>
          <h6>Rescisão: </h6>
          <p>Na tela de Solicitações de Rescisão é gerado o pedido rescisório para um determinado Colaborador. Também se pode  editar ou cancelar uma solicitação de rescisão aberta.</p>
        </div>
        <div>
          <h6>Transferência de Colaboradores: </h6>
          <p>A funcionalidade permite transferir colaboradores de uma vaga para outra. Para que ocorra a transferência é necessário o cadastro prévio da vaga que será ocupada.</p>
        </div>
        <div>
          <h6>VT Adicional: </h6>
          <p>Esta solicitação destina-se a gerar pedido de Vale Transporte Avulso(Adicional).</p>
        </div>
      `;
        };
      }

    if (id === 'ferias') {
      this.$rootScope.text = () => {
        return `
        <h5>Férias</h5>
        <div>
          <h6>Marcar em grade: </h6>
          <p>Essa tela permite que vários Colaboradores sejam  selecionados e tenham suas férias marcadas de uma única vez.</p>
        </div>
        <div>
          <h6>Marcar individual: </h6>
          <p>A opção de marcar individual seleciona apenas um colaborador por vez.</p>
        </div>
        <div>
          <h6>Calendário: </h6>
          <p>A tela do Calendário exibe as férias marcadas através de barras coloridas sobre os dias de gozo do colaborador.</p>
        </div>
        <div>
          <h6>Controle: </h6>
          <p>O controle de férias é uma tela que exibe informações pertinentes a marcação de férias, como o período aquisitivo, data de vencimento para retirada de férias e o saldo.</p>
        </div>
        <div descricao-modal>
          <p>Para poder usar essa funcionalidade você deve acessar o sistema Admin e habilitar "criar" na permissão de férias.</p>
        </div>
      `;
      };
    }

    if (id === 'ponto') {
      this.$rootScope.text = () => {
        return `
        <h5>Ponto</h5>
        <div>
          <h6>Apurações de ponto: </h6>
          <p>Essa tela lista o período de apuração do ponto, nela também é possível acessar as páginas de análises de ponto e folhas de ponto, de um referente período.</p>
        </div>
        <div>
          <h6>Análises de  ponto: </h6>
          <p>A tela de análise de ponto lista todos os colaboradores com informações referente ao ponto como, faltas, atrasos, horas extras, etc. Nela é possível listar os colaboradores por um determinado período de apuração e também por departamento.</p>
        </div>
        <div>
          <h6>Folhas de ponto: </h6>
          <p>A tela faz a listagem de colaboradores, e nela é possível gerar, visualizar ou realizar o download da folha de ponto de um determinado colaborador. Podendo realizar a buscar por período de apuração e/ou por nome do colaborador.</p>
        </div>
        <div>
          <h6>Pagamentos: </h6>
          <p>Essa tela permite visualizar os colaboradores e suas informações sobre faltas, atrasos, horas extras, etc. Nela podemos filtrar por apuração e/ou departamento, além de realizar a liberação de pagamentos.</p>
        </div>
        `;
      };
    }

      if (id === 'silumacao') {
        this.$rootScope.text = () => {
          return `
        <h5>Simulações</h5>
        <div>
          <h6>Férias: </h6>
          <p>Trata-se de um cálculo que visa ser aproximado ao cálculo definitivo, baseado nos valores cadastrados no sistema. Essa funcionalidade tem por objetivo apresentar valores que possibilitem o provisionamento dos gastos com as férias dos colaboradores.</p>
        </div>
        <div>
          <h6>Rescisão: </h6>
          <p>Se trata de um cálculo prévio baseado nos valores cadastrados no sistema. Essa funcionalidade tem por objetivo apresentar valores que possibilitem o provisionamento dos gastos com a(s) rescisão(ões) do(s) colaborador(es).</p>
        </div>
      `;
        };
      }
      if (id === 'relatorio') {
        this.$rootScope.text = () => {
          return `
        <h5>Relatórios</h5>
        <div>
          <h6>Atestados de Saúde a Vencer: </h6>
          <p>Nesta opção ficam disponíveis as informações dos atestados dos colaboradores, com sua data de vencimento vinculada a matricula, nome e cargo do colaborador.</p>
        </div>
        <div>
          <h6>Cargos e Salários: </h6>
          <p>O relatório de cargos e salários exibe todos os cargos com seus respectivos salários que atualmente estão cadastrados para os colaboradores.</p>
        </div>
        <div>
          <h6>Contracheques: </h6>
          <p>Nesta opção ficam disponíveis os contracheques dos colaboradores, filtrados por Ano, Mês, Tipo e  Funcionário.</p>
        </div>
        <div>
          <h6>Fichas Cadastrais: </h6>
          <p>Esta tela é destinada ao download de fichas cadastrais dos colaboradores.</p>
        </div>
        <div>
          <h6>Informe de Rendimentos: </h6>
          <p>Nesta opção ficam disponíveis os Informe de rendimentos dos colaboradores, filtrados por Ano e Funcionário.</p>
        </div>
        <div>
          <h6>Quadro de Horários: </h6>
          <p>Na tela do quadro de horários é exibida a jornada de cada Colaborador, suas respectivas escalas de trabalho e o dia de seu descanso semanal.</p>
        </div>
        <div>
          <h6>Planilha de Ponto: </h6>
          <p>A planilha de ponto apresenta a listagem de todos os colaboradores do estabelecimento. </p>
        </div>
        <div>
          <h6>Rotatividade: </h6>
          <p>São apresentadas as Admissões e Demissões do período selecionado nos filtros.</p>
        </div>
      `;
        };
      }
  }

    setGlobals() {
      nsj.globals.getInstance().setGlobals(NsjConfig.config.globals);
    }

    // função para contornar o bug do ui-select que perde o elemento selecionado ao dar reload
    setActive(isOpen: boolean) {
      // coleta o id do estabelecimento pela url
      let estabelecimentoId = window.location.pathname.split('/')[2];
      let estabelecimento = this.estabelecimentos.filter((item) => item.id === estabelecimentoId)[0];
      if (isOpen) {
          setTimeout(function () {
              let elementList = document.querySelectorAll('[id^="ui-select-choices-row-"]');
              if (elementList) {
                  elementList.forEach((element) => {
                      if (element.classList.contains('active')) {
                          element.classList.remove('active');
                      }
                  });
              }
              // procura o elemento na DOM correspondente ao estabelecimento selecionado
              let divsCodigoEstabelecimento = document.querySelectorAll('div.ng-binding.ng-scope');
              divsCodigoEstabelecimento.forEach(function (divCodigo: any) {
                  if (divCodigo.innerHTML === estabelecimento.codigo) {
                      divCodigo.parentNode.parentNode.classList.add('active');
                  }
              });
          }, 50);
      }
  }

    getTenant() {
        let tenant = this.$rootScope.usuario.organizacoes.filter((organizacao: any) => {
            return organizacao.codigo.localeCompare(nsjGlobals.getInstance().get('tenant')) === 0;
        });

        return tenant.length > 0 ? tenant[0] : null;
    }

    findEstabelecimentos(empresa: any, grupoempresarial: any) {
        if (this.isNull(empresa.estabelecimentos) || empresa.estabelecimentos.length < 1) {
            this.erroEstrutura = this.erroEstrutura === null ? 'Você não possui estabelecimentos nessa empresa' : this.erroEstrutura;
            return;
        }
        this.temEstabelecimento = true;
        empresa.estabelecimentos.forEach((estabelecimento: any) => {
            estabelecimento.grupoempresarial = grupoempresarial;
            this.estabelecimentos.push(estabelecimento);
        });
        this.$rootScope.nsjGlobals.a.estabelecimentosInfos = this.estabelecimentos;
    }

    findEmpresas(grupoempresarial: any) {

        if (this.isNull(grupoempresarial.empresas) || grupoempresarial.empresas.length < 1) {
            this.erroEstrutura = this.erroEstrutura === null ? 'Você não possui empresas nesse grupo empresarial' : this.erroEstrutura;
            return;
        }

        grupoempresarial.empresas.forEach((empresa: any) => {
            this.findEstabelecimentos(empresa, grupoempresarial);
        });
    }

    findGruposEmpresariais(tenant: any) {
        if (this.isNull(tenant.gruposempresariais) || tenant.gruposempresariais.length < 1) {
            this.erroEstrutura = this.erroEstrutura === null ? 'Você não possui grupos empresariais nessa organização' : this.erroEstrutura;
            return;
        }

        tenant.gruposempresariais.forEach((grupoempresarial: any) => {
            this.findEmpresas(grupoempresarial);
        });
    }


    isNull(variavel: any) {
        return !(variavel !== null && variavel !== undefined);
    }

    getEstabelecimentos(tenant: any) {
        this.findGruposEmpresariais(tenant);
        if (!this.temEstabelecimento) {
            alert(this.erroEstrutura);
        }
    }

    agruparEstabelecimentos(estabelecimento: any) {
        let label = estabelecimento.grupoempresarial.codigo + ' - ' + estabelecimento.grupoempresarial.nome;
        return label;
    }

    isLoadingConfig() {
        return this.ConfiguracoesGerais.busy;
    }

    alteraEstabelecimento() {
        this.reloadState();
    }

    reloadState() {
        let grupoempresarial = this.$rootScope.session.entities.estabelecimento.grupoempresarial.codigo;
        let estabelecimento = this.$rootScope.session.entities.estabelecimento.id;
        location.href = '/' + grupoempresarial + '/' + estabelecimento + this.$location.path();
    }

    redirect() {
        this.carregandoFolhaApontamento = true;
        const constructors = {
            'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento,
            'anoreferencia': this.moment().format('YYYY'),
            'mesreferencia': this.moment().format('MM'),
            'trabalhador': ''
        };

        const callback = () => {
            this.carregandoFolhaApontamento = false;
        };

        this.RelatoriosService.abrirRelatorioFop('apontamentos_apontamentos_pdf', constructors, callback, false);
    }

    customSelect() {
        if (this.estabelecimentos.length === 1) {
            return `
            <hr style="margin:0"/>
            <div class="header dropdown-item custom-html-padding">
                <span>Estabelecimento: </span>
                <p>{{mainctrl.estabelecimentos[0].nomefantasia}}</p>
            </div>
            <hr style="margin:0 0 10px 0"/>
            `;
        } else {
            return `
            <span ng-if="session.entities.grupoempresarial">
                <div class="dropdown-item separator header">Escolha um estabelecimento:</div>
                <div class="dropdown-item header clearfix">
                    <ui-select ng-model="session.entities.estabelecimento" title="Escolha um estabelecimento"
                    on-select="mainctrl.alteraEstabelecimento()" uis-open-close="mainctrl.setActive(isOpen)">
                    <ui-select-match placeholder="Selecione um estabelecimento">{{$select.selected.codigo}}
                        </ui-select-match>
                        <ui-select-choices group-by="mainctrl.agruparEstabelecimentos"
                            repeat="estabelecimento in mainctrl.estabelecimentos | propsFilter: {nomefantasia: $select.search, codigo: $select.search} track by estabelecimento.id">
                            <div ng-bind="estabelecimento.codigo"></div>
                            <small>{{estabelecimento.nomefantasia}}</small>
                        </ui-select-choices>
                    </ui-select>
                </div>
            </span>
            `;
        }
    }

    embeddedView() {
      let queryParams = this.$location.search();
      let body = document.querySelector('body');
      if (queryParams && queryParams.hasOwnProperty('view-style') && queryParams['view-style'] === 'erp4') {
        body?.classList.add('embedded-view');
      }
    }
}


export class ConfiguracoesGerais {
    static $inject = ['$http', 'nsjRouting', 'moment'];
    protected entities: any[] = [];
    public busy: boolean = false;
    public constructors: object = {};

    constructor(private $http: angular.IHttpService, protected nsjRouting: any, protected moment: any) {

    }

    load() {
        this.busy = true;
        this.$http.get(this.nsjRouting.generate('configuracoes_index', this.constructors, true), {})
            .then(function (response: any) {
                this.entities.splice(0, this.entities.length);
                for (const data of response.data) {
                    const val = data;
                    if (val.campo === 3) {
                        let tipos;
                        try {
                            tipos = JSON.parse(val.valor);
                        } catch (e) {
                            tipos = [];
                        }
                        this.entities[2] = new Array();
                        angular.forEach(tipos, (v: any) => {
                            this.entities[3].push(v);
                        });
                    } else {
                        this.entities[val.campo] = val.valor;
                    }
                }
                var mesApontamentoVencido;
                if (this.moment().format('DD') >= this.entities[1]) {
                    mesApontamentoVencido = this.moment().month() + 1;
                } else {
                    mesApontamentoVencido = this.moment().month();
                }
                this.entities['dataLiberaContracheque'] = this.moment(this.moment().year() + '-' + (mesApontamentoVencido) + '-' + this.entities[1]).add(this.entities[8], 'days');
                this.entities['mesLiberaContracheque'] = this.moment(this.entities['dataLiberaContracheque']).format('MM');
                this.entities['diaLiberaContracheque'] = this.moment(this.entities['dataLiberaContracheque']).format('DD');
                this.entities['usaRascunhoApontamento'] = false;
                angular.forEach(this.entities[3], (key) => {
                    if (key.tipo === 7) {
                        this.entities['usaRascunhoApontamento'] = true;
                    }
                });
            });
        return this.entities;
    }
}

angular.module('main')
    .controller('MainCtrl', MainController)
    .service('ConfiguracoesGerais', ConfiguracoesGerais);
