import angular from 'angular';
import moment from 'moment';
import { IAnalisePonto } from './../../models/analiseponto.model';
import { IApuracaoPonto } from '../../models/apuracaoponto.model';
import { AnalisesPontoService } from '../analisesponto.service';
import { ApuracoesPontoService } from '../../ApuracoesPonto/apuracoesponto.service';

type IAnalisesPontoFilters = {
	apuracao?: string | null;
	lotacao: { lotacao: string } | null;
	departamento: { departamento: string } | null;
	grupo: { grupo: string } | null;
	trabalhador: { trabalhador: string } | null;
	situacaoColaborador: string | null;
};

export class AnalisesPontoIndexController {

    static $inject = [
		'$rootScope',
		'$scope',
		'$state',
		'$stateParams',
		'AnalisesPontoService',
		'ApuracoesPontoService',
		'NewToaster'
	];

    // initial state
    public entities: Array<IAnalisePonto>;
    public busy: boolean = false;
    public apuracoes: Array<IApuracaoPonto> = [];
	public apuracoesSelect:  Array<Partial<IApuracaoPonto & { periodo: string }>> = [];
	public filters: IAnalisesPontoFilters = {
		apuracao: null,
		lotacao: null,
		departamento: null,
		grupo: null,
		trabalhador: null,
		situacaoColaborador: '00',
	};
	public filterActive: boolean = false;
    public constructors: any = {};

    // table
	public carregouTudo: boolean = false;
	public checkboxPreenchidos: Array<IAnalisePonto> = [];
	public checkToggle: any = {};
	public fields = [
		{
			value: 'codigo',
			label: 'Matrícula',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'nome',
			label: 'Colaborador',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'departamento_nome',
			label: 'Departamento',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'totalextra',
			label: 'Horas Extras',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'totalatrasos',
			label: 'Atrasos',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'totaladicional',
			label: 'Adicional Noturno',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'totalfaltas',
			label: 'Faltas',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'urlponto',
			label: 'Ações',
			type: 'string',
			style: 'title',
			copy: '',
            customCollumnHtml: () => {
                let someHtmlVar = `
                <a
					class="btn btn-sm"
					href="{{tableRowEntity.urlponto}}"
					target="_blank"
				>
					<i
						class="fa fa-external-link-alt"
						aria-hidden="true"
						uib-tooltip="Ponto"
						tooltip-placement="top"
					></i>
				</a>
              `;
                return someHtmlVar;
            },
		},
	];

    constructor (
        public $rootScope: angular.IRootScopeService & {
			liberadoTela: (arg: string) => boolean,
			temPermissao: (arg: string) => boolean,
			session: any,
			nsjGlobals: any
		},
        public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
        public $stateParams: angular.ui.IStateParamsService,
        public AnalisesPontoService: AnalisesPontoService,
        public ApuracoesPontoService: ApuracoesPontoService,
        public NewToaster: { pop: Function },
	) {
		if (
			!this.$rootScope.temPermissao('visualizar_analise') ||
			!this.$rootScope.liberadoTela('LIBERAR_ANALISES_PONTO')
		) {
            $state.go('dp');
        }

        this.busy = true;
		this.carregaApuracoes();

        $scope.$on('apuracoesponto_index_loaded', () => {
			if (this.ApuracoesPontoService.loadParams.finished ||
                this.ApuracoesPontoService.loadParams.to_load === 0
            ) {
                this.montaApuracaoSelect();
            }
        });

        $scope.$on('relatorioanalise_relatorioanalise_loaded', (event, args: any) => {
			this.entities = angular.copy(args);
			this.carregouTudo = true;
            this.busy = false;
        });

        $scope.$on('relatorioanalise_relatorioanaliseexportacao_sucesso', (event, args: any) => {
			this.trataDownload(args.data);
			this.busy = false;
        });

        $scope.$on('relatorioanalise_relatorioanaliseexportacao_erro', () => {
			this.busy = false;
        });
    }

	carregaApuracoes() {
		this.busy = true;
		this.ApuracoesPontoService.constructors['vigente'] = false;
		this.ApuracoesPontoService.constructors['empresa'] = this.$rootScope.session.entities.empresa.empresa;
		this.ApuracoesPontoService.constructors['estabelecimento'] = '';
		this.apuracoes = this.ApuracoesPontoService.reload();
	}

	montaApuracaoSelect(): void {
		this.apuracoesSelect = [];
		this.apuracoes.forEach((element: IApuracaoPonto) => {
			const inicioApuracao = moment(element.inicioapuracao, 'YYYY-MM-DD');
			const terminoApuracao = moment(element.terminoapuracao, 'YYYY-MM-DD');
			let apuracao = {
				periodo: inicioApuracao.format('DD/MM/YYYY') + ' a ' + terminoApuracao.format('DD/MM/YYYY') + this.getReferenciaApuracao(element),
				inicioapuracao: element.inicioapuracao,
				terminoapuracao: element.terminoapuracao,
				apuracaoponto: element.apuracaoponto
			};
			this.apuracoesSelect.push(apuracao);
			if (!this.valorExiste(this.$stateParams.apuracaoponto)) {
				this.filtraApuracaoAtual(inicioApuracao, terminoApuracao, apuracao.apuracaoponto);
			}
		});
		this.filterReload();
	}

	getReferenciaApuracao(apuracao: IApuracaoPonto) {
		if (apuracao.trabalhadorobj?.trabalhador) {
            return ` - Trabalhador: ${apuracao.trabalhadorobj.nome}`;
        }
		if (apuracao.lotacaoobj?.lotacao) {
            return ` - Lotação: ${apuracao.lotacaoobj.nome} `;
        }
        if (apuracao.estabelecimentoobj?.estabelecimento) {
            return ` - Estabelecimento: ${apuracao.estabelecimentoobj.nomefantasia}`;
        }
        if (apuracao.empresaobj?.empresa) {
            return ` - Empresa: ${apuracao.empresaobj.razaosocial}`;
        }
        return '';
    }

	filtraApuracaoAtual(inicioApuracao: moment.Moment, terminoApuracao: moment.Moment, guidApuracao: string) {
		const dataAtual = moment();
		if (dataAtual.isBetween(inicioApuracao, terminoApuracao, undefined, '[]')) {
			this.filters.apuracao = guidApuracao;
		}
	}

	/* INÍCIO - COMBO DE FUNÇÕES PARA HABILITAR AS FUNÇÕES SELECIONAR TODOS E DOWNLOAD EM LOTE */
	checar_todos(): void {
		this.checkToggle = document.getElementById('check_all') as HTMLInputElement;

		if (this.checkToggle.checked) {
			let checked = this.entities.filter((entity: any): boolean => !entity.disableCheckbox);
			this.checkboxPreenchidos = angular.copy(checked);

			if (checked.length !== this.entities.length) {
				this.NewToaster.pop({
					type: 'info',
					title: 'Atenção!',
					body: 'toaster-html-directive',
					directiveData: { entities: this.entities },
					bodyOutputType: 'directive',
					timeout: 5000
				});
			}
		} else {
			this.checkboxPreenchidos = [];
		}
	}

	temTodosMarcados(): boolean {
		return !!this.entities.length && (this.checkboxPreenchidos.length === this.entities.length);
	}

	naoTemTodosMarcados(): boolean {
		return !!this.checkboxPreenchidos.length && !this.temTodosMarcados();
	}
	/* FIM - COMBO DE FUNÇÕES PARA HABILITAR AS FUNÇÕES SELECIONAR TODOS E DOWNLOAD EM LOTE */

    filterReload(): void {
		this.busy = true;
        if (!this.valorExiste(this.filters.trabalhador)) {
            delete this.constructors.trabalhador;
			this.filters.trabalhador = null;
        } else {
			this.constructors['trabalhador'] = this.filters.trabalhador!.trabalhador;
		}

		if (!this.valorExiste(this.filters.lotacao)) {
            delete this.constructors.trabalhador_lotacao;
			this.filters.lotacao = null;
        } else {
			this.constructors['trabalhador_lotacao'] = this.filters.lotacao!.lotacao;
		}

        if (!this.valorExiste(this.filters.departamento)) {
            delete this.constructors.departamento;
			this.filters.departamento = null;
        } else {
			this.constructors['departamento'] = this.filters.departamento!.departamento;
		}

        if (!this.valorExiste(this.filters.grupo)) {
            delete this.constructors.grupo;
			this.filters.grupo = null;
        } else {
			this.constructors['grupo'] = this.filters.grupo!.grupo;
		}

		if (!this.valorExiste(this.filters.apuracao)) {
			this.filters.apuracao = this.valorExiste(this.$stateParams.apuracaoponto) ?
				this.$stateParams.apuracaoponto :
				this.apuracoes[0]?.apuracaoponto;
			if (!this.filters.apuracao) {
				this.entities = [];
				this.busy = false;
				return;
			}
		}
		this.constructors['apuracaoponto'] = this.filters.apuracao;

		if (!this.valorExiste(this.filters.situacaoColaborador)) {
            delete this.constructors.trabalhador_status;
			this.filters.situacaoColaborador = null;
        } else {
			this.constructors['trabalhador_status'] = this.filters.situacaoColaborador;
		}

		this.constructors.empresa = this.$rootScope.session.entities.empresa.empresa;
		this.constructors.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
		this.AnalisesPontoService.constructors = this.constructors;
		this.entities = this.AnalisesPontoService.reload();
    }

	getRowCustomClass(entity: any & { rowcustomclass: string} ): string {
		return entity.rowcustomclass;
	}

	trataDownload(data: any): void {
		let nomeArquivo = '';
        var blob = new Blob([data]);
        let fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
        var a = document.createElement('a');

		let apuracao = '';
		this.apuracoesSelect.forEach((element: any) => {
			if (apuracao === '' && element.apuracaoponto === this.filters.apuracao) {
				apuracao = element.inicioapuracao + '-a-' + element.terminoapuracao;
			}
		});
		nomeArquivo = 'analises-apuracao-' + apuracao + '.csv';

        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = fileURL;
        a.download = nomeArquivo;
        a.click();
        window.URL.revokeObjectURL(fileURL);
	}

	exportar(): void {
		this.busy = true;
		let objetoExportacao = {
			'apuracaoponto': this.filters.apuracao,
			'trabalhadores': [] as { 'trabalhador': string }[],
		};
		this.checkboxPreenchidos.forEach((element: IAnalisePonto) => {
			objetoExportacao.trabalhadores.push({'trabalhador': element.trabalhador});
		});
		this.AnalisesPontoService.exportar(objetoExportacao);
	}

	valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}
}

