import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { MeurhSolicitacoesrescisoesService } from '../solicitacoesrescisoes.service';
import { IRescisao } from '../models/rescisao.model';
import { SituacaoRescisoesEnum } from '../enums/situacaorescisoes';
import { IAnexo } from '../../Anexos/models/anexo.model';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { TiposDocumentosRequeridosService } from '../../../Tiposdocumentosrequeridos/tiposdocumentosrequeridos.service';
import { IConfiguracaoRescisao } from '../models/configuracaorescisao';
import { TipoCumprimentoAvisoEnum } from '../enums/tipocumprimento';
import { MeurhRescisoesModalConfirmService } from '../modal/modalrescisao.confirm.service';
import { RescisaoPontoService } from '../../Ponto/Rescisao/rescisao.ponto.service';
import { IModalConfirmacao } from '../models/modalconfirm';

export class MeurhSolicitacoesrescisoesNewController implements ng.IController {

    static $inject = [
        'entity',
        '$scope',
        '$rootScope',
        '$stateParams',
        '$state',
        'MeurhSolicitacoesrescisoesService',
        'NewToaster',
        'TiposDocumentosRequeridosService',
        'MeurhRescisoesModalConfirmService',
        'RescisaoPontoService',
        'HabilitadoService',
    ];

    public action: string = 'insert';
    public tipo: string;
    public form: angular.IFormController;
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;
    public busy: boolean = false;
    public tiposubmit: string;
    public configuracaoRescisao: IConfiguracaoRescisao;

    // documentos / anexos
    public mostrarAlerta: boolean = false;
    public tiposdocumentosrequeridos: Array<any>;
    public busyTipoDocumentos: boolean = true;
    public busyAnexo: boolean = false;
    public adicionarAnexo: boolean = false;
    public documentosValidados: boolean = false;
    public editanexo: boolean = true;
    public entityAnexo: IAnexo = {};
    public alterouanexo: boolean = false;
    public movimentosAtivados: boolean;

    private toastTimeout: number = 8000;
    private entitySave: IRescisao;

    private tipoCumprimentoEnum = TipoCumprimentoAvisoEnum;

    constructor(
        public entity: IRescisao,
        private $scope: angular.IScope,
        private $rootScope: angular.IRootScopeService & {
            configuracoes: any,
            configuracaoRescisao: IConfiguracaoRescisao
        },
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private entityService: MeurhSolicitacoesrescisoesService,
        private NewToaster: any,
        private TiposDocumentosRequeridosService: TiposDocumentosRequeridosService,
        private MeurhRescisoesModalConfirmService: MeurhRescisoesModalConfirmService,
        private RescisaoPontoService: RescisaoPontoService,
        private HabilitadoService: any,
    ) {
        this.entity.situacao = '';
        this.entity.tipocumprimentoaviso_select = 3;
        this.entity.indenizado = false;
        this.tipo = this.$stateParams['tipo'];

        document.querySelector('form')?.addEventListener('change', (e) => {
            this.formchanged = true;
        });

        this.entityAnexo['casodeuso'] = 'RESCISOES';
        this.resetTiposDocumentosRequeridosService();
        this.tiposdocumentosrequeridos = this.TiposDocumentosRequeridosService.load();

        this.$scope.$on('meurh_tiposdocumentosrequeridos_list_finished', () => {
            this.busyTipoDocumentos = false;
            this.tiposdocumentosrequeridos = this.tiposdocumentosrequeridos.map((tipodocumentorequerido) => {
                tipodocumentorequerido.validateResult = false;
                tipodocumentorequerido.fileSelecionado = false;
                return tipodocumentorequerido;
            });
        });

        this.onSubmitSuccess();
        this.onSubmitError();

        // todo: resolver modais de descarte de saída de tela
        // this.exitScreenDescartarModal();
        // document.onkeydown = this.refreshDescartarModal;

        this.allowRefresh = true;

        this.movimentosAtivados = this.HabilitadoService.estaHabilitado('LIBERAR_PAGAMENTO_MOVIMENTOS_RESCISAO');
    }

    async $onInit(): Promise<void> {
        !!this.$rootScope.configuracaoRescisao
            ? this.configuracaoRescisao = this.$rootScope.configuracaoRescisao
            : await this.getConfiguracaoRescisao();
    }

    // início - ações do formulário
    public submit(rascunho: boolean = false): void {
        this.alteraEntityEnviar(rascunho);
        this.form.$submitted = true;

        if (rascunho) {
            this.busy = true;
            this.tiposubmit = 'salvar';
            this.allowRefresh = false;
            if (this.podeSalvarRascunho()) {
                this.entityService.save(this.entitySave);
            } else {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não é possível cadastrar um apontamento com a rubrica vazia!',
                    timeout: this.toastTimeout
                });
                this.busy = false;
            }
            return;
        }

        let customMessage: IModalConfirmacao = this.configuracaoRescisao.workflowativado
            ? this.getMensagensModal('envioWkf')
            : this.getMensagensModal('envio');

        if (this.entity.exibeInfosEstabilidade) {
            customMessage = this.getMensagensModal('envioEstabilidade');
        }

        const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(this.entity, customMessage);

        confirm.result
            .then((): void => {
                this.busy = true;

                if (this.podeEnviar()) {
                    this.allowRefresh = false;
                    this.entityService.save(this.entitySave);
                } else {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Alguns campos obrigatórios não estão preenchidos',
                        body: 'Preencha os campos obrigatórios assinalados com "*" e tente novamente.',
                        timeout: this.toastTimeout
                    });

                    this.busy = false;
                }
            })
            .catch(function () {/**/ });
    }

    iniciarRescisao() {
        this.alteraEntityEnviar(true);
        this.form.$submitted = true;
        this.tiposubmit = 'salvar';
        this.busy = true;
        this.entityService.save(this.entitySave, false, () => {
            this.RescisaoPontoService.setEtapa(this.entitySave.solicitacao, {
                etapa: 1,
                etapadetalhe: 1
            })
            .then(() => {
                this.NewToaster.pop({
                    type: 'success',
                    title: 'A rescisão foi iniciada com sucesso.',
                    timeout: this.toastTimeout
                });
            })
            .catch(() => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao iniciar a rescisão.',
                    timeout: this.toastTimeout
                });
            })
            .finally(() => {
                this.busy = false;
                this.$state.go('meurh_solicitacoesrescisoes_show', this.entityService.constructors);
            });
        });
    }

    public buttonDescartarModal(): void {
        this.formChange();
        if (this.formchanged) {
            const customMessage: IModalConfirmacao = this.getMensagensModal('descartar');
            const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(this.entity, customMessage);

            confirm.result
                .then((): void => {
                    this.allowRefresh = false;
                    this.$state.go('meurh_solicitacoesrescisoes', angular.extend(this.entityService.constructors));
                })
                .catch((error: any): void => {/* */ });
        } else {
            this.$state.go('meurh_solicitacoesrescisoes', angular.extend(this.entityService.constructors));
        }
    }
    // fim - ações do formulário

    // todo: resolver modais de descarte de saída de tela
    // public refreshDescartarModal = (e: any) => {
    //     this.formChange();
    //     e = e || window.event;
    //     if (this.formchanged && this.allowRefresh) {
    //         if (e.keyCode === 116) {
    //             e.preventDefault();

    //             const customMessage: IModalConfirmacao = this.getMensagensModal('descartar');
    //             const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(this.entity, customMessage);

    //             confirm.result
    //                 .then((): void => {
    //                     this.allowRefresh = false;
    //                     window.location.reload();
    //                 })
    //                 .catch((error: any): void => {/* */ });
    //         }
    //     }
    // }

    // public exitScreenDescartarModal(): void {
    //     this.$transitions.onBefore({}, (trans: any) => {
    //         this.formChange();
    //         const defer: ng.IDeferred<unknown> = this.$q.defer();

    //         if (
    //             trans._targetState._identifier !== 'meurh_solicitacoesrescisoes_new' &&
    //             this.allowRefresh &&
    //             this.formchanged
    //         ) {
    //             const customMessage: IModalConfirmacao = this.getMensagensModal('descartar');
    //             const confirm: ng.ui.bootstrap.IModalInstanceService = this.MeurhRescisoesModalConfirmService.open(
    //                 this.entity,
    //                 customMessage
    //             );

    //             confirm.result
    //                 .then((): void => {
    //                     this.allowRefresh = false;
    //                     let modalConfirmed = true;
    //                     if (modalConfirmed) {
    //                         defer.resolve();
    //                     } else {
    //                         defer.reject();
    //                     }
    //                 })
    //                 .catch(function () { /**/ });
    //         } else {
    //             defer.resolve();
    //         }

    //         return defer.promise;
    //     });
    // }


    // início - verificações de validação
    public podeSalvarRascunho(): boolean {
        return (
            !!this.entity.trabalhador &&
            !!this.entity.datarescisao &&
            !!this.entity.motivorescisao &&
            !this.form.$pristine
        );
    }

    public podeIniciarRescisao() {
        if (this.podeSalvarRascunho()) {
            const dataAtual = moment().format('YYYY-MM-DD');
            return moment(dataAtual).isSameOrAfter(moment(this.entity.datarescisao)) &&
                !!this.entity.trabalhador?.marcaponto;
        }
        return false;
    }

    public podeEnviar(): boolean {
        return (
            this.validaCamposObrigatoriosEnviar() &&
            this.validaMovimentos() &&
            this.validaDocumentosAnexos()
        );
    }

    // inicio - validações
    private validaCamposObrigatoriosEnviar(): boolean {
        return (
            this.validaCamposObrigatoriosSalvar() &&
            !!this.entity.justificativa &&
            this.validaAvisoPrevio()
        );
    }

    private validaCamposObrigatoriosSalvar(): boolean {
        return (
            !!this.entity.trabalhador &&
            !!this.entity.datarescisao &&
            !!this.entity.motivorescisao
        );
    }

    private validaAvisoPrevio(): boolean {
        return this.entity.tipocumprimentoaviso_select === this.tipoCumprimentoEnum.DISPENSADO
            ? true
            : this.validaCamposAvisoPrevio();
    }

    private validaCamposAvisoPrevio(): boolean {
        return (
            !!this.entity.tipoconcessaoaviso &&
            !!this.entity.dataconcessaoaviso &&
            !!this.entity.dataprojetadaaviso &&
            this.entity.dataconcessaoaviso < this.entity.dataprojetadaaviso
        );
    }

    private validaMovimentos(): boolean {
        let movimentosExistentesValidos: boolean = true;

        if (this.entity.movimentosexistentescolaborador && this.entity.movimentosexistentescolaborador.length > 0) {

            movimentosExistentesValidos = this.entity.movimentosexistentescolaborador.every(movimentoExistente => {

                // movimento não é fixo e exige uma ação
                if ((movimentoExistente.tipoorigem === 2 || movimentoExistente.tipoorigem === 3 || movimentoExistente.tipoorigem === 4) ||
                    (movimentoExistente.tipoorigem === 1 && movimentoExistente.tipo === 4)) {

                    return movimentoExistente.tipoorigemstatus === 1 || movimentoExistente.tipoorigemstatus === 2;

                } else if (movimentoExistente.tipoorigem === 1 && movimentoExistente.tipo !== 4) {
                    // movimento fixo sempre é válido, não vai na requisição
                    return true;
                }

            });

        }

        return movimentosExistentesValidos;
    }

    private validaDocumentosAnexos(): boolean {
        return this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => {
            return (
                tipodocumentorequerido.fileSelecionado === tipodocumentorequerido.obrigatorio ||
                tipodocumentorequerido.fileSelecionado
            );
        });
    }
    // fim - validações


    // início - Requisições
    private async getConfiguracaoRescisao(): Promise<void> {
        this.entityService.getConfiguracaoRescisao()
            .then((configuracao: ng.IHttpResponse<IConfiguracaoRescisao>) => {
                this.configuracaoRescisao = configuracao.data;
            })
            .catch((error) => {
                this.NewToaster.pop({
                    type: 'error',
                    tittle: 'Ocorreu um erro ao obter as configurações da rescisão',
                    timeout: this.toastTimeout
                });

                this.configuracaoRescisao = {
                    workflowativado: false
                };
            });
    }
    // fim - Requisições


    // inicio - pós requisições
    private onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesrescisoes_submitted', (event: angular.IAngularEvent, args: any): void => {
            this.entityService.constructors.solicitacao = args.entity.solicitacao;
            this.entityAnexo['solicitacaohistorico'] = args.response.data.solicitacaohistorico;
            this.entityAnexo['solicitacao'] = args.entity.solicitacao;

            this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

            this.$scope.$on('meurh_todas_solicitacoes_documentos_complete', () => {
                this.$scope.$apply();

                if (
                    this.tiposdocumentosrequeridos.every((tipodocumentorequerido) => tipodocumentorequerido.validateResult) &&
                    !this.documentosValidados
                ) {
                    this.documentosValidados = true;
                }
            });

            if (args.callback) {
                args.callback();
                return;
            }

            const tituloToast = args.entity.situacao === SituacaoRescisoesEnum.ABERTA
                ? 'A solicitação de rescisão foi salva como rascunho com sucesso!'
                : 'A solicitação de rescisão foi enviada com sucesso!';
            this.NewToaster.pop({
                type: 'success',
                title: tituloToast,
                timeout: this.toastTimeout
            });

            this.$state.go('meurh_solicitacoesrescisoes_show', this.entityService.constructors);
            this.busy = false;
        });
    }

    private onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesrescisoes_submit_error', (event: angular.IAngularEvent, args: any): void => {
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity);
                }
            } else {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    if (args.response.data.message === 'Validation Failed') {
                        if (args.response?.data?.errors?.children) {

                            let msgErro = '';
                            let tituloToaster = this.tiposubmit === 'enviar' ? 'Não foi possível enviar rescisão' : 'Não foi possível salvar rescisão';

                            for (const campo in args.response.data.errors.children) {
                                if (args.response.data.errors.children[campo]['errors']) {
                                    args.response.data.errors.children[campo]['errors'].forEach((element: string) => {
                                        msgErro = msgErro + '&bull; ' + element + '<br>';
                                    });
                                }
                            }
                            this.NewToaster.pop({
                                type: 'error',
                                title: tituloToaster,
                                body: msgErro,
                                bodyOutputType: 'trustedHtml',
                                timeout: this.toastTimeout
                            });
                        } else {
                            this.NewToaster.pop(
                                {
                                    type: 'error',
                                    title: 'Alguns campos obrigatórios não estão preenchidos',
                                    body: 'Preencha os campos obrigatórios assinalados com "*" e tente novamente.',
                                    timeout: this.toastTimeout
                                }
                            );
                        }
                    } else {
                        this.NewToaster.pop(
                            {
                                type: 'error',
                                title: args.response.data.message,
                                timeout: this.toastTimeout
                            }
                        );
                    }
                } else {
                    if (args.entity.situacao === SituacaoRescisoesEnum.ENVIADA) {
                        this.NewToaster.pop(
                            {
                                type: 'error',
                                title: 'Ocorreu um erro ao enviar a solicitação!',
                                timeout: this.toastTimeout
                            }
                        );
                    } else {
                        this.NewToaster.pop(
                            {
                                type: 'error',
                                title: 'Ocorreu um erro ao atualizar a solicitação!',
                                timeout: this.toastTimeout
                            }
                        );
                    }
                }
            }

            this.busy = false;
        });
    }
    // fim - pós requisições


    // inicio - tratamentos de dados
    private formChange(): void {
        this.formchanged = (
            !!this.entity.trabalhador ||
            !!this.entity.datarescisao ||
            !!this.entity.motivorescisao ||
            !!this.entity.justificativa
        );
    }

    private getMensagensModal(tipo: 'envio' | 'descartar' | 'envioWkf' | 'envioEstabilidade'): IModalConfirmacao {
        const customText = {
            'envio': {
                titulo: 'Enviar rescisão',
                descricao: (
                    'Após enviar a rescisão, os documentos serão gerados e a ' +
                    'rescisão efetuada. Se você deseja ver uma prévia dos ' +
                    'cálculos da rescisão, utilize a opção "Salvar" ao invés da "Enviar".'
                ),
                textoConfirmacao: (
                    'Você tem certeza que deseja enviar a rescisão ' +
                    'de ' + this.entity.trabalhador?.nome + '?'
                ),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Sim, enviar'
                }
            },
            'descartar': {
                titulo: 'Descartar rescisão',
                descricao: (
                    'Após descartar a rescisão, todas as informações já preenchidas serão perdidas.'
                ),
                textoConfirmacao: (
                    `Você tem certeza que deseja descartar a rescisão${!!this.entity.trabalhador?.nome
                        ? ` de ${this.entity.trabalhador?.nome}`
                        : ''}?`
                ),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Sim, descartar'
                }
            },
            'envioWkf': {
                titulo: 'Enviar rescisão',
                descricao: (
                    'Após enviar a rescisão, as prévias serão geradas para confirmação. Pode levar ' +
                    'um tempo até elas ficarem disponíveis, mas não se preocupe, vamos lhe avisar por ' +
                    'e-mail assim que as prévias estiverem disponíveis.'
                ),
                textoConfirmacao: (
                    'Você tem certeza que deseja enviar a rescisão ' +
                    'de ' + this.entity.trabalhador?.nome + '?'
                ),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Sim, enviar'
                }
            },
            'envioEstabilidade': {
                titulo: 'Colaborador em estabilidade',
                descricao: (this.entity.msgEstabilidadeModal),
                textoConfirmacao: (`Você tem certeza que deseja enviar a rescisão de ${this.entity.trabalhador?.nome}?`),
                botoes: {
                    fechar: 'Cancelar',
                    confirmar: 'Enviar rescisão'
                }
            },
        };

        return customText[tipo];
    }

    private alteraEntityEnviar(rascunho: boolean): void {
        this.entitySave = angular.copy(this.entity);
        this.entitySave.situacao = rascunho ? SituacaoRescisoesEnum.ABERTA : SituacaoRescisoesEnum.ENVIADA;

        this.entitySave.tipocumprimentoaviso = this.entitySave.tipocumprimentoaviso_select;
        delete this.entitySave.tipocumprimentoaviso_select;

        let beneficiosadescontar: any[] = [];

        if (this.entitySave.beneficiostrabalhadores !== undefined) {
            this.entitySave.beneficiostrabalhadores.map((obj: any) => {
                if (obj.descontar) {
                    beneficiosadescontar.push(obj.beneficio);
                }
            });
        }
        this.entitySave.beneficiosadescontar = JSON.stringify(beneficiosadescontar);
        if (this.entitySave.trabalhador?.tipo !== 0) {
            this.entitySave.beneficiosadescontar = null;
            this.entitySave.orientacoescalculo = null;
            this.entitySave.tipocumprimentoaviso = null;
            this.entitySave.tipoconcessaoaviso = null;
            this.entitySave.dataconcessaoaviso = null;
            this.entitySave.dataprojetadaaviso = null;
            this.entitySave.observacaoconcessaoaviso = null;
        }

        // ajustando os movimentos adicionados para enviar
        if (this.entitySave.movimentos && this.entitySave.movimentos.length > 0) {

            let movimentosAjustados: any = [];

            this.entitySave.movimentos.forEach(movimentoAdicionado => {
                movimentosAjustados.push({
                    movimento: movimentoAdicionado.movimento,
                    tipoorigem: movimentoAdicionado.tipoorigem,
                    tipoorigemregistro: movimentoAdicionado.tipoorigemregistro,
                    tipoorigemstatus: movimentoAdicionado.tipoorigemstatus,
                    evento: movimentoAdicionado.evento.evento,
                    conteudo: movimentoAdicionado.conteudo,
                    tipo: movimentoAdicionado.tipo
                });
            });

            this.entitySave.movimentos = movimentosAjustados;
        }

        // ajustando os movimentos existentes do colaborador para enviar
        if (this.entitySave.movimentosexistentescolaborador && this.entitySave.movimentosexistentescolaborador.length > 0) {

            if (!this.entitySave.movimentos) {
                this.entitySave.movimentos = [];
            }

            this.entitySave.movimentosexistentescolaborador.forEach(movimentoExistente => {

                // só adiciona o movimento no objeto a ser enviado na requisição se não for um movimento fixo
                if (!(movimentoExistente.tipoorigem === 1 && movimentoExistente.tipo !== 4)) {
                    this.entitySave.movimentos?.push({
                        tipoorigem: movimentoExistente.tipoorigem,
                        tipoorigemregistro: movimentoExistente.tipoorigemregistro,
                        tipoorigemstatus: movimentoExistente.tipoorigemstatus,
                        evento: movimentoExistente.evento.evento,
                        conteudo: movimentoExistente.conteudo,
                        tipo: movimentoExistente.tipo
                    });
                }

            });

        }

    }

    private resetTiposDocumentosRequeridosService(): void {
        this.TiposDocumentosRequeridosService.constructors = {
            'tiposolicitacao': TipoSolicitacaoEnum.RECISAO
        };
        this.TiposDocumentosRequeridosService.entities = [];
        this.TiposDocumentosRequeridosService.loadParams = {
            to_load: 3,
            busy: false,
            finished: false
        };
        this.TiposDocumentosRequeridosService.after = {};
    }
    // fim - tratamentos de dados
}
