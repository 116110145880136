import ng from 'angular';
import angular from 'angular';
import { ISolicitacoesGenericasList } from '../models/solicitacaogenerica.model';
import { MeurhSolicitacoesgenericasService } from '../solicitacoesgenericas.service';
import { MeurhTipossolicitacoesgenericasService } from '../tipossolicitacoesgenericas.service';

export class MeurhSolicitacoesgenericasIndexController implements ng.IController {
	static $inject = [
		'$rootScope',
		'$scope',
		'$state',
		'$http',
		'$stateParams',
		'$location',
		'$transitions',
		'nsjRouting',
		'NewToaster',
		'MeurhSolicitacoesgenericasService',
		'MeurhTipossolicitacoesgenericasService',
		'PermissaoService',
	];

	public entities: Array<ISolicitacoesGenericasList>;
	public service: MeurhSolicitacoesgenericasService;
	public busy: boolean = false;
	public filter: any = this.$stateParams['tipo'] ? this.$stateParams['tipo'] : '';
	public filters: any = this.entityService.filters = {};
	public constructors: any = this.entityService.constructors = [];
	public listagemPermissao: Array<string> = ['visualizar_solicitacaogenerica'];
	public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);
	public maxoccurs = {};
	public filtroTipo;
	public estadosWorkflow = [];
    public estadoWorkflowSelecionado;
	public listaTipos: Array<{ tiposolicitacaogenerica: string, codigo: string, nome: string }>;

	public fields = [
		{
			value: 'tiposolicitacaogenerica.nome',
			label: 'Tipo',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'solicitacao',
			label: 'Competência',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnScope: {
                getCompetencia: (entity: any) => {

					var nomeMes = [
						'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
						'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
					];

					if (entity.mes && entity.ano) {
						return `${nomeMes[entity.mes - 1]}/${entity.ano}`;
					} else {
						return '-';
					}
                },
            },
            customCollumnHtml: () => {
                let someHtmlVar = `<span ng-bind="customCollumnScope.getCompetencia(tableRowEntity)"></span>`;
                return someHtmlVar;
            },
		},
		{
			value: 'created_by.nome',
			label: 'Criado por',
			type: 'string',
			style: 'title',
			copy: '',
		},
		{
			value: 'previsao',
			label: 'Previsão de entrega',
			type: 'date',
			style: 'title',
			copy: '',
			customCollumnHtml: () => {
                let someHtmlVar = `
					<span ng-if="tableRowEntity.previsao">{{tableRowEntity.previsao | asDate | date:'dd/MM/yyyy' }}</span>
					<span ng-if="!tableRowEntity.previsao">-</span>
				`;
                return someHtmlVar;
            },
		},
		{
			value: 'wkf_estado',
			label: 'Situação',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnHtml: (): string => {

				let someHtmlVar = `
					<span class="label label-default margin-left-5"
						ng-class='{
							"label-info": tableRowEntity.wkf_estadocodigo == "EM_EXECUCAO",
							"label-warning": tableRowEntity.wkf_estadocodigo == "ENVIADA",
							"label-success": tableRowEntity.wkf_estadocodigo == "Efetuada",
							"label-danger": (
								tableRowEntity.wkf_estadocodigo == "Cancelada" ||
								tableRowEntity.wkf_estadocodigo == "RECUSADA"
							)
						}'>

						<span ng-if='tableRowEntity.wkf_estado' ng-bind="tableRowEntity.wkf_estado"></span>
						<span ng-if='!tableRowEntity.wkf_estado'>Não informado</span>

					</span>
				`;

				return someHtmlVar;
			},
		},
	];

	constructor(
		public $rootScope: angular.IScope & {
			nsjGlobals: any,
			session: any,
			configuracoes: any,
			modoGestorPermissao: (arg: string) => boolean,
			temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
		},
		public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
		public $http: angular.IHttpService,
		public $stateParams: angular.ui.IStateParamsService,
		public $location: angular.ILocationService,
		public $transitions: any,
		public nsjRouting: any,
		public NewToaster: any,
		public entityService: MeurhSolicitacoesgenericasService,
		public serviceTipo: MeurhTipossolicitacoesgenericasService,
		public PermissaoService: any,
	) {

		if (!this.PermissaoService.temPermissao(this.listagemPermissao)) {
			$state.go('dp', $stateParams);
		}

		entityService.filter = $stateParams.q ? $stateParams.q : '';
		entityService.filters = {};
		entityService.constructors = {};

		for (let i in $stateParams) {
			if (['estabelecimento', 'situacao'].indexOf(i) > -1 && $stateParams[i] !== undefined) {
				entityService.filters[i] = $stateParams[i];
			} else if (typeof $stateParams[i] !== 'undefined' && typeof $stateParams[i] !== 'function' && i !== 'q' && i !== 'solicitacao') {
				entityService.constructors[i] = $stateParams[i];
			}
		}

		this.service = entityService;
		this.filters = entityService.filters = {};
		this.filters = {
			tiposolicitacaogenerica: '',
			situacao: '',
			estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento,
		};

		this.constructors = entityService.constructors = [];
		this.maxoccurs = {};

		$scope.$on('$destroy', (): void => {
			if (this.entityService.loading_deferred) {
				this.entityService.loading_deferred.resolve();
			}
		});

	}

	async $onInit(): Promise<void> {

		// preenchendo o select de tipos
		await this.serviceTipo._load({}, {}, '')
        .then((tiposRetornados: any) => {
            this.listaTipos = tiposRetornados;
        })
        .catch((error) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não foi possível buscar os tipos de outras solicitações',
                body: error.data?.message ?? error,
            });
        })
        .finally(() => this.busy = false);

		await this.buscarEstadosWorkflowSolGenericas();
		this.reload();
	}

	reload(): void {
		this.entityService.constructors = this.montaConstrutor();
		this.entities = this.entityService.reload();
	}

	finished(): boolean {
		return this.entityService.loadParams.finished;
	}

	montaConstrutor(): object {
		let construtor = angular.copy(this.filters);

		if (construtor.tiposolicitacaogenerica === '') {
			delete construtor.tiposolicitacaogenerica;
		}

		if (construtor['wkf.estado'] === '') {
			delete construtor['wkf.estado'];
		}

		if (construtor.situacao === '') {
			delete construtor.situacao;
		}

		let filtroTipoSelecionado;
		if (this.filtroTipo) {
			filtroTipoSelecionado = this.filtroTipo;
			delete construtor.tiposolicitacaogenerica;
		} else {
			delete construtor.tiposolicitacaogenerica;
		}

		if (filtroTipoSelecionado) {
			construtor.tiposolicitacaogenerica = filtroTipoSelecionado;
		}

		let estadoWkfSelecionado;
		if (this.estadoWorkflowSelecionado) {
			estadoWkfSelecionado = this.estadoWorkflowSelecionado.codigo;
			delete construtor['wkf.estado'];
		} else {
			delete construtor['wkf.estado'];
		}

		if (estadoWkfSelecionado) {
			construtor['wkf.estado'] = estadoWkfSelecionado;
		}

		return construtor;
	}

	generateRoute(route: string, params: any): string {
		return this.nsjRouting.generate(route, params, true);
	}

	isBusy(): boolean {
		return this.entityService.loadParams.busy;
	}

	loadMore(): void {
		this.entityService.loadMore();
	}

	goToShow(entity: any): void {

		let rota = 'meurh_solicitacoesgenericas_get';

		this.busy = true;
		this.$state.go(rota,
			angular.extend({}, {
				'solicitacao': entity.solicitacao,
			})
		);

	}

	valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}

	goToCreate () {
		this.$state.go('meurh_solicitacoesgenericas_new', this.entityService.constructors);
    }

	async buscarEstadosWorkflowSolGenericas() {
        await this.entityService.getEstadosWorkflow()
        .then((estados: any) => {
            this.estadosWorkflow = estados;
        })
        .catch((error) => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não foi possível buscar os estados de workflow de outras solicitações',
                body: error,
            });
        });
    }

}
