import angular from 'angular';
import moment from 'moment';
import { ITrabalhador } from '../../Trabalhadores/models/trabalhador.model';
import { IEvento } from './models/escala.model';
import { IConfiguracaoScheduler, IListagemScheduler, ITrabalhadorListagemScheduler } from './models/escala.scheduler.model';
import { IFiltroEventoEscala } from './models/escala.filtros.model';
import { ILotacao } from '../../Lotacao/models/lotacao.model';
import { IHorario } from '../Solicitacoesadmissoes/models/horario.model';
import { MeurhEscalaVisualizacaoModalService } from './modal.visualizacao/escala.visualizacaomodal.service';
import { TipoImportacaoEnum } from './enums/importacao';
import { EventoCorEnum, EventoImportadoCorEnum, EventoCorTextoEnum, EventoImportadoCorTextoEnum } from './enums/cores';
import { SchedulerStatic } from '../../../shared/components/dhtmlx-scheduler/dhtmlxscheduler';

export class MeurhEscalaSchedulerService {

    static $inject = ['$rootScope', 'NewToaster', 'MeurhEscalaVisualizacaoModalService'];

    public colaboradoresCalendario: Array<ITrabalhadorListagemScheduler> = []; // listagem dos colaboradores no calendario
    public lotacoesLightbox: Array<IListagemScheduler> = []; // listagem de lotacoes na lightbox
    public horariosLightbox: Array<IListagemScheduler> = []; // listagem de horarios na lightbox
    public filtroListagemAtivo: boolean = false;
    private desabilitaLightboxButtons: boolean = false;
    private filtroEventos: IFiltroEventoEscala = {
        folgas: {
            nome: 'Folgas',
            cor: EventoCorEnum.FOLGA,
            marcado: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_FOLGA'),
            permissao: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_FOLGA'),
        },
        folgasFixas: {
            nome: 'Folgas fixas',
            cor: EventoCorEnum.FOLGAFIXA,
            marcado: true,
            permissao: true,
        },
        lotacoes: {
            nome: 'Lotações',
            cor: EventoCorEnum.LOTACAO,
            marcado: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_LOTACAO'),
            permissao: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_LOTACAO'),
        },
        horarios: {
            nome: 'Horários',
            cor: EventoCorEnum.HORARIO,
            marcado: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_HORARIO'),
            permissao: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_HORARIO'),
        },
        afastamentos: {
            nome: 'Afastamentos',
            cor: EventoCorEnum.AFASTAMENTO,
            marcado: true,
            permissao: true
        },
        feriados: {
            nome: 'Feriados',
            cor: EventoCorEnum.FERIADO,
            marcado: true,
            permissao: true
        },
        outros: {
            semDataFinal: {
                nome: 'Sem data final',
                cor: '#30C01E',
                marcado: true,
                permissao: true
            },
            comDataFinal: {
                nome: 'Com data final',
                cor: '#30C01E',
                marcado: true,
                permissao: true
            }
        }
    };

    constructor (
        public $rootScope: angular.IRootScopeService & {
            temPermissao: (arg: string) => boolean,
            liberadoTela: (arg: string) => boolean,
            session: any
        },
        public NewToaster: any,
        public ConfiguraVisualizacaoModalService: MeurhEscalaVisualizacaoModalService,
	) {
    }

    reload(): void {
        this.colaboradoresCalendario = [];
        this.lotacoesLightbox = [];
        this.horariosLightbox = [];
        this.filtroListagemAtivo = false;
        this.desabilitaLightboxButtons = false;
        this.filtroEventos = {
            folgas: {
                nome: 'Folgas',
                cor: EventoCorEnum.FOLGA,
                marcado: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_FOLGA'),
                permissao: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_FOLGA'),
            },
            folgasFixas: {
                nome: 'Folgas fixas',
                cor: EventoCorEnum.FOLGAFIXA,
                marcado: true,
                permissao: true,
            },
            lotacoes: {
                nome: 'Lotações',
                cor: EventoCorEnum.LOTACAO,
                marcado: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_LOTACAO'),
                permissao: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_LOTACAO'),
            },
            horarios: {
                nome: 'Horários',
                cor: EventoCorEnum.HORARIO,
                marcado: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_HORARIO'),
                permissao: this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_HORARIO'),
            },
            afastamentos: {
                nome: 'Afastamentos',
                cor: EventoCorEnum.AFASTAMENTO,
                marcado: true,
                permissao: true
            },
            feriados: {
                nome: 'Feriados',
                cor: EventoCorEnum.FERIADO,
                marcado: true,
                permissao: true
            },
            outros: {
                semDataFinal: {
                    nome: 'Sem data final',
                    cor: '#30C01E',
                    marcado: true,
                    permissao: true
                },
                comDataFinal: {
                    nome: 'Com data final',
                    cor: '#30C01E',
                    marcado: true,
                    permissao: true
                }
            }
        };
    }

    adicionaEventoCalendario(scheduler: SchedulerStatic, evento: Partial<IEvento>, listaDeEventos: any, importacao?: boolean): void {
        if (evento.end_date) {
            evento.end_date = this.configuraDataFim(scheduler, evento.end_date);
        }
        // o componente de calendario nativamente necessita dos campos id, start_date e end_date
        listaDeEventos.push({
            evento: evento.evento, // tipo do evento
            colaborador: evento.colaborador,
            colab_nome: evento.colaborador!.nome,
            colab_id: evento.colaborador!.trabalhador, // id do colaborador que o evento pertence no calendario
            textColor: importacao ? EventoImportadoCorTextoEnum[evento.evento!.toUpperCase()] : EventoCorTextoEnum[evento.evento!.toUpperCase()], // cor do texto do evento
            color: importacao ? EventoImportadoCorEnum[evento.evento!.toUpperCase()] : EventoCorEnum[evento.evento!.toUpperCase()], // cor do evento
            id: listaDeEventos.length + 1, // id do evento
            start_date: evento.start_date,
            end_date: evento.end_date,
            start_date_only: evento.start_date_only ? evento.start_date_only : false,
            readonly_event: evento.readonly_event,
            descricao: evento.descricao,
            lotacao: evento.lotacao,
            horario: evento.horario,
            afastamento: evento.afastamento,
            empresa: evento.colaborador!.empresaobj.empresa,
            estabelecimento: evento.colaborador!.estabelecimentoobj.estabelecimento,
            mudancatrabalhador: evento.mudancatrabalhador,
            importacao
        });
    }

    configuraCalendario(scheduler: SchedulerStatic, configuracao: IConfiguracaoScheduler): void {
        this.cardEventoCalendario(scheduler);
        this.botoesHeaderCalendario(scheduler);
        scheduler.xy.scale_height = 30;

        // plugins
        scheduler.plugins({
            readonly: true,
            timeline: true,
            limit: true,
            editors: true
        });

        this.visualizacaoCalendario(scheduler, configuracao);
        scheduler.addMarkedTimespan({days: [0, 6], zones: 'fullday', css: 'timeline-weekend'}); // setting weekend background-color
        this.modalCalendario(scheduler, configuracao);
        this.configuraAcoesCalendario(scheduler);
        this.textosCalendario(scheduler);
        this.filtrosCalendario(scheduler);
        this.bloqueiaDatas(scheduler, configuracao);

        scheduler.config.dblclick_create = this.algumEventoLiberado();
    }

    habilitaCriacaoIndividualCalendario(scheduler: SchedulerStatic, habilitar: boolean): void {
        this.desabilitaLightboxButtons = habilitar;
        scheduler.config.dblclick_create = !habilitar && this.algumEventoLiberado();
    }

    private algumEventoLiberado() {
        return (
            this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_FOLGA') ||
            this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_LOTACAO') ||
            this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_HORARIO')
        );
    }

    private botoesHeaderCalendario(scheduler: SchedulerStatic): void {
        scheduler.config.header = [
            'prev',
            'today',
            'next',
            'date',
            'semana_timeline',
            'mes_timeline',
            { html: 'Configurar visualização', click: () => { this.abrirModalConfiguracao(scheduler); }, css: 'header-filter-button' },
        ];
    }

    private bloqueiaDatas(scheduler: SchedulerStatic, configuracao: IConfiguracaoScheduler): void {
        scheduler.attachEvent('onSchedulerReady', () => {
            scheduler.serverList('colaboradoresAtuais').forEach((colab) => {
                let dataAdmissao = moment(colab.colaboradorObj.dataadmissao, 'YYYY-MM-DD').subtract(1, 'd');
                let dataRescisao = colab.colaboradorObj.datarescisao && moment(colab.colaboradorObj.datarescisao, 'YYYY-MM-DD');
                if (moment(configuracao.dataInicial).isBefore(dataAdmissao)) {
                    scheduler.addMarkedTimespan({
                        start_date: configuracao.dataInicial,
                        end_date: scheduler.date.add(dataAdmissao.toDate(), 23.99, 'hour'),
                        zones: 'fullday',
                        css: 'calendar_blocked_section',
                        type: 'antecedeAdmissao' + colab.colaboradorObj.trabalhador,
                        sections: { ['semana_timeline']: [colab.key], ['mes_timeline']: [colab.key] }
                    });
                }
                if (dataRescisao && moment(dataRescisao).isBefore(configuracao.dataFinal)) {
                    scheduler.addMarkedTimespan({
                        start_date: scheduler.date.date_part(dataRescisao.toDate()),
                        end_date: scheduler.date.add(configuracao.dataFinal, 23.99, 'hour'),
                        zones: 'fullday',
                        css: 'calendar_blocked_section',
                        type: 'sucedeRescisao' + colab.colaboradorObj.trabalhador,
                        sections: { ['semana_timeline']: [colab.key], ['mes_timeline']: [colab.key] }
                    });
                }
            });
        }, '');
    }

    private abrirModalConfiguracao(scheduler: SchedulerStatic): void {
        this.ConfiguraVisualizacaoModalService.open(this.filtroEventos).result.then((result: IFiltroEventoEscala) => {
            this.filtroEventos = result;
            scheduler.render();
            this.NewToaster.pop({
                type: 'success',
                title: 'A visualização do calendário foi configurada com sucesso!'
            });
        })
        .catch((error: any): void => {/**/});
    }

    private cardEventoCalendario(scheduler: SchedulerStatic): void {
        scheduler.templates.event_bar_text = scheduler.templates.event_header = (start: Date, end: Date, event: IEvento) => {
            let textoEvento: string;
            switch (event.evento) {
                case TipoImportacaoEnum.FOLGA:
                    textoEvento = event.descricao ?
                        `Folga <i class="fas fa-comment"></i><br>` : 'Folga<br>';
                    break;
                case TipoImportacaoEnum.FOLGAFIXA:
                    textoEvento = 'Folga<br>';
                    break;
                case TipoImportacaoEnum.AFASTAMENTO:
                    textoEvento = event.afastamento ? `Afastamento <i class="fas fa-comment"></i><br>` : 'Afastamento<br>';
                    break;
                case TipoImportacaoEnum.FERIADO:
                    textoEvento = 'Feriado<br>';
                    break;
                case TipoImportacaoEnum.LOTACAO:
                    let lotacaoSelecionada = this.lotacoesLightbox.find((lotacao) => lotacao.key === event.lotacao)?.label;
                    textoEvento = 'Lotação';
                    textoEvento += lotacaoSelecionada ?
                    `<span style="font-weight: 400"> - ${lotacaoSelecionada}</span><br>` : '<br>';
                    break;
                case TipoImportacaoEnum.HORARIO:
                    let horarioSelecionado = this.horariosLightbox.find((horario) => horario.key === event.horario)?.label;
                    textoEvento = 'Horário';
                    textoEvento += horarioSelecionado ?
                    `<span style="font-weight: 400"> - ${horarioSelecionado}</span><br>` : '<br>';
                    break;
                default:
                    textoEvento = event.evento;
            }
            return '<div style="display: flex; gap: 4px; align-items: center; flex-wrap: wrap; height: 100%; font-size: 12px; padding: 0 8px; text-align: center; text-overflow: ellipsis;"><div>' + textoEvento + '</div></div>';
        };
        scheduler.templates.event_class = (start: Date, end: Date, event: IEvento) => {
            return 'padding-zero';
        };
    }

    private configuraAcoesCalendario(scheduler: SchedulerStatic): void {
        scheduler.config.drag_create = false;
        scheduler.config.drag_resize = false;
        scheduler.config.drag_move = false;
        scheduler.config.select = false;
    }

    /* INICIO - Modal configs */
    private modalCalendario(scheduler: SchedulerStatic, configuracao: IConfiguracaoScheduler): void {
        this.configuraModalHeader(scheduler);
        this.configuraModalSelect(scheduler, configuracao);
        // set lightbox buttons position
        scheduler.config.buttons_right = ['dhx_save_btn', 'dhx_cancel_btn', 'dhx_delete_btn'];
        scheduler.config.buttons_left = [];
        this.eventosModalCalendario(scheduler, configuracao);
    }

    private configuraModalHeader(scheduler: SchedulerStatic): void {
        scheduler.templates.lightbox_header = (start: Date, end: Date, event: IEvento) => {
            let title = scheduler.getState().new_event ? 'Criar evento' : 'Visualizar evento';
            return `<span class="lightbox-nsj-header-title">${title}</span>`;
        };
    }

    private configuraModalSelect(scheduler: SchedulerStatic, configuracao: IConfiguracaoScheduler, params?: {
        empresa: string,
        estabelecimento: string
    }): void {
        let selecionaEvento = (e: Event): void => {
            let eventValue = (e.target as HTMLInputElement).value;
            if (eventValue === TipoImportacaoEnum.LOTACAO || eventValue === TipoImportacaoEnum.HORARIO) {
                this.habilitaDatas(scheduler, 'Período do evento');
            } else {
                this.desabilitaDatas(scheduler, 'Período do evento');
            }
            this.ocultaCampos(scheduler, eventValue);
            this.resetDatas(scheduler, 'Período do evento');
            this.ocultaDataFim(scheduler, eventValue === TipoImportacaoEnum.FOLGA);
            scheduler.setLightboxSize();
        };

        let selecionaTipoData = (e: Event): void => {
            this.ocultaDataFim(scheduler, (e.target as HTMLInputElement).value ? true : false);
        };

        const selectEventsOptions = this.montaSelectLightbox();
        // set form fields
        scheduler.config.lightbox.sections = [
            { name: 'Colaborador', height: 35, map_to: 'colab_nome', type: 'textarea' },
            {
                name: 'Evento',
                height: 40,
                type: 'select',
                map_to: 'evento',
                options: selectEventsOptions,
                onchange: selecionaEvento
            },
            { name: 'Afastamento', height: 35, map_to: 'afastamento', type: 'textarea' },
            { name: 'Descrição do evento', height: 50, map_to: 'descricao', type: 'textarea' },
            {
                name: 'Lotação',
                height: 40,
                map_to: 'lotacao',
                type: 'select',
                options: this.montaLotacoesSelect(configuracao.lotacoes, params?.estabelecimento)
            }, {
                name: 'Horário',
                height: 40,
                map_to: 'horario',
                type: 'select',
                options: this.montaHorariosSelect(configuracao.horarios, params?.empresa)
            }, {
                name: 'Tipo de data',
                height: 40,
                type: 'select',
                map_to: 'start_date_only',
                options: [
                    {key: 'true', label: 'Sem data final'},
                    {key: '', label: 'Com data final'},
                ],
                onchange: selecionaTipoData
            },
            { name: 'Período do evento', height: 72, type: 'time', map_to: 'auto', year_range: 150 }
        ];
    }

    private montaSelectLightbox() {
        const selectOptions: {key: string, label: string}[] = [
            {key: TipoImportacaoEnum.AFASTAMENTO, label: 'Afastamento'},
            {key: TipoImportacaoEnum.FERIADO, label: 'Feriado'},
            {key: TipoImportacaoEnum.FOLGAFIXA, label: 'Folga fixa'}
        ];
        if (this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_FOLGA')) {
            selectOptions.push({key: TipoImportacaoEnum.FOLGA, label: 'Folga'});
        }
        if (this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_LOTACAO')) {
            selectOptions.push({key: TipoImportacaoEnum.LOTACAO, label: 'Lotação'});
        }
        if (this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_HORARIO')) {
            selectOptions.push({key: TipoImportacaoEnum.HORARIO, label: 'Horário'});
        }
        return selectOptions;
    }

    private eventosModalCalendario(scheduler: SchedulerStatic, configuracao: IConfiguracaoScheduler): void {
        // fires when the event is created on double-click action
        scheduler.attachEvent('onEventCreated', (id: string) => {
            this.configuraValorInicialModal(scheduler, id);
        }, '');

        // fires before open the lightbox
        scheduler.attachEvent('onBeforeLightbox', (id: string) => {
            scheduler.resetLightbox();
            const ev = scheduler.getEvent(id);
            this.configuraModalSelect(scheduler, configuracao, {
                empresa: ev.empresa,
                estabelecimento: ev.estabelecimento
            });
            return true;
        }, '');

        // fires when the lightbox is opened
        scheduler.attachEvent('onLightbox', (id: string) => {
            this.configuraCamposForm(scheduler, id, configuracao);
        }, '');

        scheduler.attachEvent('onAfterLightbox', (id: string) => {
            scheduler.resetLightbox();
        }, '');

        // fires when the user clicks on the 'save' button in the lightbox
        scheduler.attachEvent('onEventSave', (id: string, ev: Partial<IEvento>, is_new: Date) => {
            return this.respostaAcao(scheduler, ev, 'criar', id);
        }, '');

        // fires when the user clicks on the 'delete' button in the lightbox
        scheduler.attachEvent('onBeforeEventDelete', (id: string, event: IEvento) => {
            return this.respostaAcao(scheduler, event, 'excluir', id);
        }, '');

        // fires when the user clicks on the 'delete' button in the lightbox
        scheduler.attachEvent('onEventDeleted', (id: string, event: IEvento) => {
            if (scheduler.getState().new_event) {
                return;
            }
            this.$rootScope.$broadcast('calendario_exclui_evento', event);
        }, '');

        // opens an existing lightbox on a single click
        scheduler.attachEvent('onClick', (id: string) => {
            scheduler.showLightbox(id);
            return true;
        }, '');
    }

    private configuraValorInicialModal(scheduler: SchedulerStatic, id: string): void {
        let event: IEvento = scheduler.getEvent(id);
        let colaborador = this.colaboradoresCalendario.find(colaborador => colaborador.key === event.colab_id);
        event.evento = this.tipoEventoPadrao();
        event.colab_nome = colaborador!.colaboradorObj.nome;
        event.colaborador = colaborador!.colaboradorObj;
        event.empresa = colaborador!.colaboradorObj.empresaobj.empresa;
        event.estabelecimento = colaborador!.colaboradorObj.estabelecimentoobj.estabelecimento;
        event.textColor = EventoCorTextoEnum[event.evento.toUpperCase()];
        event.color = EventoCorEnum[event.evento.toUpperCase()];
        event.start_date_only = false;
        event.end_date = this.configuraDataFim(scheduler, event.end_date, true);
        scheduler.updateEvent(id);
    }

    private tipoEventoPadrao() {
        if (this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_FOLGA')) {
            return TipoImportacaoEnum.FOLGA;
        }
        if (this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_LOTACAO')) {
            return TipoImportacaoEnum.LOTACAO;
        }
        if (this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_HORARIO')) {
            return TipoImportacaoEnum.HORARIO;
        }
        return TipoImportacaoEnum.FOLGA;
    }

    private configuraDataFim(scheduler: SchedulerStatic, dataFim: Date, criacaoIndividual: boolean = false): Date {
        let nulledEnd_date = scheduler.date.date_part(dataFim);
        if (criacaoIndividual) {
            nulledEnd_date = scheduler.date.add(nulledEnd_date, -1, 'day');
        }
        return new Date(scheduler.date.add(nulledEnd_date, 23.99, 'hour'));
    }

    private configuraCamposForm(scheduler: SchedulerStatic, id: string, configuracao: IConfiguracaoScheduler): void {
        const evento: Partial<IEvento> = scheduler.getEvent(id);
        this.desabilitaCampo(scheduler, 'Colaborador');
        this.desabilitaCampo(scheduler, 'Afastamento');
        this.desabilitaDatas(scheduler, 'Período do evento');
        this.ocultaOpcaoSelect(scheduler, TipoImportacaoEnum.AFASTAMENTO);
        this.ocultaOpcaoSelect(scheduler, TipoImportacaoEnum.FERIADO);
        this.ocultaOpcaoSelect(scheduler, TipoImportacaoEnum.FOLGAFIXA);
        this.ocultaCampos(scheduler, scheduler.formSection('Evento').control.value);
        this.ocultaDataFim(scheduler, evento.start_date_only ||
            (evento.evento === TipoImportacaoEnum.FOLGA ||
            evento.evento === TipoImportacaoEnum.FOLGAFIXA ||
            evento.evento === TipoImportacaoEnum.FERIADO))
        ;
        if (scheduler.getState().new_event) {
            this.ocultaBotaoModal(scheduler, '.dhx_delete_btn_set');
            this.ocultaAnos(scheduler, 'Período do evento', configuracao);
            if (evento.evento !== TipoImportacaoEnum.FOLGA) {
                this.habilitaDatas(scheduler, 'Período do evento');
            }
        } else {
            this.ocultaBotaoModal(scheduler, '.dhx_save_btn_set');
            this.desabilitaCampo(scheduler, 'Evento');
            this.desabilitaCampo(scheduler, 'Descrição do evento');
            this.desabilitaCampo(scheduler, 'Lotação');
            this.desabilitaCampo(scheduler, 'Horário');
            this.desabilitaCampo(scheduler, 'Tipo de data');
            if (
                this.desabilitaLightboxButtons ||
                evento.evento === TipoImportacaoEnum.AFASTAMENTO ||
                evento.evento === TipoImportacaoEnum.FERIADO ||
                evento.evento === TipoImportacaoEnum.FOLGAFIXA ||
                (evento.evento !== TipoImportacaoEnum.FOLGA && !evento.start_date_only)
            ) {
                this.ocultaBotaoModal(scheduler, '.dhx_delete_btn_set');
            }
        }
    }

    private ocultaCampos(scheduler: SchedulerStatic, tipoEvento: string): void {
        const descricaoEventoStyle = scheduler.formSection('Descrição do evento').node.parentNode.style;
        const lotacaoStyle = scheduler.formSection('Lotação').node.parentNode.style;
        const horarioStyle = scheduler.formSection('Horário').node.parentNode.style;
        const afastamentoStyle = scheduler.formSection('Afastamento').node.parentNode.style;
        const tipoDataStyle = scheduler.formSection('Tipo de data').node.parentNode.style;
        descricaoEventoStyle.display = lotacaoStyle.display = horarioStyle.display = tipoDataStyle.display = afastamentoStyle.display = 'none';
        if (tipoEvento === TipoImportacaoEnum.LOTACAO) {
            lotacaoStyle.display = tipoDataStyle.display = '';
        } else if (tipoEvento === TipoImportacaoEnum.HORARIO) {
            horarioStyle.display = tipoDataStyle.display = '';
        } else if (tipoEvento === TipoImportacaoEnum.AFASTAMENTO) {
            afastamentoStyle.display = '';
        } else if (tipoEvento === TipoImportacaoEnum.FOLGA) {
            descricaoEventoStyle.display = '';
        }
    }

    private desabilitaCampo(scheduler: SchedulerStatic, nome: string): void {
        scheduler.formSection(nome).control.disabled = true;
    }

    private desabilitaDatas(scheduler: SchedulerStatic, nome: string): void {
        let dataField = scheduler.formSection(nome).node;
        let selects = dataField.getElementsByTagName('select');
        selects.forEach((select: any) => {
            select.setAttribute('disabled', true);
            if (select.className === 'dhx_lightbox_time_select') {
                select.style.setProperty('display', 'none');
            }
            if (select.className === 'dhx_lightbox_month_select') {
                select.style.setProperty('width', '200px', 'important');
            }
        });
    }

    private habilitaDatas(scheduler: SchedulerStatic, nome: string): void {
        let dataField = scheduler.formSection(nome).node;
        let selects = dataField.getElementsByTagName('select');
        selects.forEach((select: HTMLElement) => select.removeAttribute('disabled'));
    }

    private resetDatas(scheduler: SchedulerStatic, nome: string) {
        let time = scheduler.formSection(nome);
        let dataInicial = scheduler.getEvent(scheduler.getState().lightbox_id).start_date;
        let dataFinal = scheduler.getEvent(scheduler.getState().lightbox_id).end_date;
        time.setValue(null, { start_date: dataInicial, end_date: dataFinal });
    }

    private ocultaAnos(scheduler: SchedulerStatic, nome: string, configuracao: IConfiguracaoScheduler): void {
        let dataField = scheduler.formSection(nome).node;
        let selects = dataField.getElementsByTagName('select');
        selects.forEach((select: any) => {
            if (select.className === 'dhx_lightbox_year_select') {
                select.forEach((option: any) => {
                    if (option.value < configuracao.dataInicial.getFullYear() || option.value > configuracao.dataFinal.getFullYear()) {
                        option.style.setProperty('display', 'none');
                    }
                });
            }
        });
    }

    private ocultaDataFim(scheduler: SchedulerStatic, esconder: boolean = true): void {
        let dataField = scheduler.formSection('Período do evento').node;
        let selects = dataField.getElementsByTagName('select');
        selects.forEach((select: HTMLElement, index: number) =>  {
            if (index > 4) {
                esconder ? select.style.setProperty('display', 'none') : select.style.setProperty('display', 'inline-block');
            } else {
                esconder ? select.style.setProperty('margin-top', '20px') : select.style.setProperty('margin-top', '0px');
            }
        });
        scheduler.formSection('Tipo de data').setValue(esconder ? 'true' : '');
    }

    private ocultaBotaoModal(scheduler: SchedulerStatic, classe: string): void {
        scheduler.getLightbox().querySelector(classe)?.remove();
    }

    private ocultaOpcaoSelect(scheduler: SchedulerStatic, option: string): void {
        scheduler.formSection('Evento').control.children.forEach((child: HTMLOptionElement) => {
            if (child.value === option) {
                child.style.display = 'none';
            }
        });
    }

    private respostaAcao(scheduler: SchedulerStatic, ev: Partial<IEvento>, acao: string, id: string): boolean {
        if (!this.temPermissao(ev, acao)) {
            this.toastErro(`Você não tem permissão para ${acao} esse tipo de evento.`);
            return false;
        }
        if (acao === 'criar') {
            if (this.dataFinalAnteriorInicial(scheduler, ev)) {
               this.toastErro(`A data final precede a inicial.`);
                return false;
            }
            if (this.datasColidem(scheduler, ev, id)) {
                ev.evento === TipoImportacaoEnum.FOLGA ? this.toastErro(`A data colide com uma folga ou afastamento já existente.`) :
                this.toastErro(`A data colide com uma data já existente.`);
                return false;
            }
        }
        if (this.dataForaDoIntervalo(scheduler, ev, id, true)) {
            this.toastErro(`A data antecede a admissão do colaborador.`);
            return false;
        }
        if (this.dataForaDoIntervalo(scheduler, ev, id)) {
            this.toastErro(`A data sucede a rescisão do colaborador.`);
            return false;
        }
        if (acao === 'excluir' && ev.readonly_event) {
            this.toastErro(`O evento é o único existente.`);
            return false;
        }
        if (this.validaCampos(ev)) {
            if (acao === 'criar') {
                this.atualizaDadosCriacao(scheduler, ev, id).then(event => this.$rootScope.$broadcast('calendario_salva_evento', event));
            }
            return true;
        } else {
            this.toastErro(`Ocorreu um erro ao tentar ${acao} o evento.`);
            return false;
        }
    }

    private toastErro(mensagem: string) {
        this.$rootScope.$applyAsync();
        this.NewToaster.pop({
            type: 'error',
            title: mensagem
        });
    }

    private atualizaDadosCriacao(scheduler: SchedulerStatic, ev: Partial<IEvento>, id: string): Promise<IEvento> {
        let event: IEvento = scheduler.getEvent(id);
        event.color = EventoCorEnum[ev.evento!.toUpperCase()],
        event.textColor = EventoCorTextoEnum[ev.evento!.toUpperCase()];
        event.evento = ev.evento!;
        event.lotacao = ev.lotacao;
        event.horario = ev.horario;
        return new Promise((resolve) => {
            resolve(event);
          });
    }
    /* FIM - Modal configs */

    /* INICIO - Calendario views configs */
    private visualizacaoCalendario(scheduler: SchedulerStatic, configuracao: IConfiguracaoScheduler): void {
        this.criaListagemColaboradores(scheduler, configuracao.colaboradores);
        this.criaCalendarioViews(scheduler, 'semana_timeline', 'mes_timeline');
        this.configuraHeaderColaboradores(scheduler);
        this.configuraVisualizacaoDosDias(scheduler, configuracao.dataInicial, configuracao.dataFinal);
    }

    private criaListagemColaboradores(scheduler: SchedulerStatic, colaboradores: Array<ITrabalhador>): void {
        this.colaboradoresCalendario = colaboradores.map((colaborador: ITrabalhador) => {
            return { key: <string>colaborador.trabalhador, label: colaborador.codigo + ' - ' + colaborador.nome, colaboradorObj: colaborador };
        });
        scheduler.updateCollection('colaboradoresAtuais', this.colaboradoresCalendario.slice());
    }

    private criaCalendarioViews(scheduler: SchedulerStatic, nomeViewSemana: string, nomeViewMes: string): void {
        scheduler.createTimelineView({
            fit_events: true,
            name: nomeViewSemana,
            x_unit: 'day',
            x_date: '%d',
            x_step: 1,
            x_size: 7,
            x_length: 7,
            dy: 52,
            event_dy: 68,
            resize_events: false,
            section_autoheight: false,
            full_event__dy: true,
            scrollable: true, // enables horizontal scroll
            column_width: 75, // defines the minimal width of timeline date columns
            y_unit: scheduler.serverList('colaboradoresAtuais'),
            y_property: 'colab_id',
            render: 'bar',
            sort: this.configuraOrdemEvento()
        });
        scheduler.createTimelineView({
            fit_events: true,
            name: nomeViewMes,
            x_unit: 'day',
            x_date: '%d',
            x_step: 1,
            x_size: 45,
            x_length: 7,
            dy: 52,
            event_dy: 68,
            resize_events: false,
            section_autoheight: false,
            round_position: true,
            scrollable: true, // enables horizontal scroll
            column_width: 25, // defines the minimal width of timeline date columns
            y_unit: scheduler.serverList('colaboradoresAtuais'),
            y_property: 'colab_id',
            render: 'bar',
            sort: this.configuraOrdemEvento()
        });
    }

    private configuraHeaderColaboradores(scheduler: SchedulerStatic): void {
        scheduler.templates.mes_timeline_scale_header = scheduler.templates.semana_timeline_scale_header = () => {
            return '<span style="padding-bottom: 6px; font-weight: bold; color: #5a5a5a;">Colaborador</span>';
        };
    }

    private configuraVisualizacaoDosDias(scheduler: SchedulerStatic, dataInicial: Date, dataFinal: Date): void {
        scheduler.date.semana_timeline_start = scheduler.date.week_start;
        scheduler.date.mes_timeline_start = scheduler.date.month_start;
        scheduler.date.add_mes_timeline = (date: Date, inc: number) => {
            return scheduler.date.add(date, inc, 'month');
        };
        scheduler.config.time_step = 1;
        scheduler.attachEvent('onBeforeViewChange', (old_mode: string, old_date: Date, mode: string, date: Date) => {
            if (old_date !== undefined && old_date !== date || old_mode !== mode) {
                // setting days range on timeline month view
                if (mode === 'mes_timeline') {
                    let ano = date.getFullYear();
                    let mes = (date.getMonth() + 1);
                    let d = new Date(ano, mes, 0);
                    let dias = d.getDate(); // numbers of day in month
                    scheduler.matrix['mes_timeline'].x_size = dias;
                    scheduler.matrix['mes_timeline'].x_length = dias;
                }
                if (!this.filtroListagemAtivo) {
                    this.exibeListaColaboradoresHabilitados(scheduler, mode, date);
                }
            }
            if (old_date !== undefined && old_date !== date && old_date.getMonth() !== date.getMonth()) {
                this.$rootScope.$broadcast('calendario_carregaEventos', date);
            }
            return this.configuraPeriodoCalendario(scheduler, dataInicial, dataFinal, date, old_mode);
        }, '');
    }

    private exibeListaColaboradoresHabilitados(scheduler: SchedulerStatic, mode: string, date: Date): void {
        let minDate = scheduler.date[mode + '_start'](new Date(date));
        let maxDate = scheduler.date.add(minDate, 1, mode);
        let colaboradores = this.colaboradoresCalendario.filter((colab) => {
            return moment(colab.colaboradorObj.dataadmissao).isBefore(maxDate) &&
            !(colab.colaboradorObj.datarescisao && moment(colab.colaboradorObj.datarescisao).isSameOrBefore(minDate));
        });
        scheduler.updateCollection('colaboradoresAtuais', colaboradores.slice());
    }

    private configuraPeriodoCalendario(scheduler: SchedulerStatic, dataInicial: Date, dataFinal: Date, date: Date, old_mode: string): boolean {
        let limitStart = scheduler.date.add(dataInicial, 0, 'hour');
        let limitEnd = scheduler.date.add(dataFinal, 0, 'hour');
        if (old_mode && (date.getTime() < limitStart.getTime()) || (date.getTime() > limitEnd.getTime())) {
            return false;
        }
        return true;
    }

    private configuraOrdemEvento() {
        return (eventA: IEvento, eventB: IEvento): number | undefined => {
            let tipoEventoA = this.configuraPrioridadeEvento(eventA.evento);
            let tipoEventoB = this.configuraPrioridadeEvento(eventB.evento);
            if (tipoEventoA > tipoEventoB) {
                return 1;
            } else if (tipoEventoA < tipoEventoB) {
                return -1;
            } else {
                return +eventA.start_date > +eventB.start_date ? 1 : -1;
            }
        };
    }

    private configuraPrioridadeEvento(tipoEvento: string): number {
        switch (tipoEvento) {
            case TipoImportacaoEnum.LOTACAO:
                return 1;
            case TipoImportacaoEnum.HORARIO:
                return 2;
            case TipoImportacaoEnum.AFASTAMENTO:
                return 3;
            case TipoImportacaoEnum.FERIADO:
                return 3;
            case TipoImportacaoEnum.FOLGA:
                return 3;
            case TipoImportacaoEnum.FOLGAFIXA:
                return 3;
            default:
                return 4;
        }
    }
    /* FIM - Calendario views configs */

    private filtrosCalendario(scheduler: SchedulerStatic): void {
        // setting event filtros
        scheduler['filter_mes_timeline'] = scheduler['filter_semana_timeline'] = (id: any, event: IEvento & {text?: string}) => {
            if (event.text === 'Novo evento') {
                return false;
            }
            if ((event.evento !== TipoImportacaoEnum.LOTACAO || (event.evento === TipoImportacaoEnum.LOTACAO && this.filtroEventos.lotacoes!.marcado === true)) &&
            (event.evento !== TipoImportacaoEnum.HORARIO || (event.evento === TipoImportacaoEnum.HORARIO && this.filtroEventos.horarios!.marcado === true)) &&
            (event.evento !== TipoImportacaoEnum.FOLGA || (event.evento === TipoImportacaoEnum.FOLGA && this.filtroEventos.folgas!.marcado === true)) &&
            (event.evento !== TipoImportacaoEnum.AFASTAMENTO || (event.evento === TipoImportacaoEnum.AFASTAMENTO && this.filtroEventos.afastamentos!.marcado === true)) &&
            (event.evento !== TipoImportacaoEnum.FERIADO || (event.evento === TipoImportacaoEnum.FERIADO && this.filtroEventos.feriados!.marcado === true)) &&
            (event.evento !== TipoImportacaoEnum.FOLGAFIXA || (event.evento === TipoImportacaoEnum.FOLGAFIXA && this.filtroEventos.folgasFixas!.marcado === true)) &&
            (!event.start_date_only || this.filtroEventos.outros!.semDataFinal!.marcado) && (event.start_date_only || this.filtroEventos.outros!.comDataFinal!.marcado)) {
                return true;
            }
            return false;
        };
    }

    private textosCalendario(scheduler: SchedulerStatic): void {
        scheduler.i18n.setLocale('pt');
        scheduler.locale.labels.icon_delete = 'Excluir'; // lightbox delete button
        // begin - lightbox confim delete modal
        scheduler.locale.labels.confirm_deleting = 'Após a exclusão não será possível reverter a ação. Você tem certeza que deseja excluir?';
        scheduler.locale.labels.message_ok = 'Excluir';
        scheduler.locale.labels.message_cancel = 'Voltar';
        // end - lightbox confim delete modal
    }

    private montaLotacoesSelect(listagemLotacoes: Array<ILotacao>, estabelecimento?: string): any {
        const currentLotacoesLightbox: Array<{key: string, label: string}> = [];
        if (estabelecimento) {
            listagemLotacoes.filter((lotacao) => lotacao.estabelecimento === estabelecimento).forEach((lotacao) => {
                currentLotacoesLightbox.push({
                    key: lotacao.lotacao,
                    label: lotacao.nome,
                });
            });
        } else {
            listagemLotacoes.forEach((lotacao) => {
                currentLotacoesLightbox.push({
                    key: lotacao.lotacao,
                    label: lotacao.nome,
                });
            });
            this.lotacoesLightbox = currentLotacoesLightbox;
        }
        return currentLotacoesLightbox;
    }

    private montaHorariosSelect(listagemHorarios: Array<IHorario>, empresa?: string): any {
        const currentHorariosLightbox: Array<{key: string, label: string}> = [];
        if (empresa) {
            listagemHorarios.filter((horario) => horario.empresa === empresa).forEach((horario) => {
                currentHorariosLightbox.push({
                    key: horario.horario,
                    label: horario.nome,
                });
            });
        } else {
            listagemHorarios.forEach((horario) => {
                currentHorariosLightbox.push({
                    key: horario.horario,
                    label: horario.nome,
                });
            });
            this.horariosLightbox = currentHorariosLightbox;
        }
        return currentHorariosLightbox;
    }

    private temPermissao(evento: any, acao: string): boolean {
        if (acao === 'criar') {
            if (evento.evento === TipoImportacaoEnum.FOLGA &&
                this.$rootScope.temPermissao('criar_calendario') &&
                this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_FOLGA')
            ) {
                return true;
            }
            if (evento.evento === TipoImportacaoEnum.LOTACAO &&
                this.$rootScope.temPermissao('criar_calendario') &&
                this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_LOTACAO')
            ) {
                return true;
            }
            if (evento.evento === TipoImportacaoEnum.HORARIO &&
                this.$rootScope.temPermissao('criar_calendario') &&
                this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_HORARIO')
            ) {
                return true;
            }
        } else if (acao === 'excluir') {
            if (
                evento.evento === TipoImportacaoEnum.FOLGA &&
                this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_FOLGA')
            ) {
                return true;
            }
            if (
                evento.evento === TipoImportacaoEnum.LOTACAO &&
                this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_LOTACAO')
            ) {
                return true;
            }
            if (
                evento.evento === TipoImportacaoEnum.HORARIO &&
                this.$rootScope.liberadoTela('LIBERAR_CALENDARIO_HORARIO')
            ) {
                return true;
            }
        }
        return false;
    }

    private validaCampos(evento: Partial<IEvento>): boolean {
        if (evento.evento === TipoImportacaoEnum.LOTACAO && !evento.lotacao) {
            return false;
        }
        if (evento.evento === TipoImportacaoEnum.HORARIO && !evento.horario) {
            return false;
        }
        return true;
    }

    private dataForaDoIntervalo(scheduler: SchedulerStatic, ev: Partial<IEvento>, id: string, verificaAdmissao: boolean = false): boolean {
        let colaborador = scheduler.getEvent(id).colaborador;
        if (verificaAdmissao) {
            if (moment(colaborador.dataadmissao).isAfter(ev.start_date)) {
                return true;
            }
            return false;
        }
        if (colaborador.datarescisao &&
            ((!ev.start_date_only && moment(colaborador.datarescisao).isSameOrBefore(ev.end_date)) ||
            (ev.start_date_only && moment(colaborador.datarescisao).isSameOrBefore(ev.start_date)))
        ) {
            return true;
        }
        return false;
    }

    private dataFinalAnteriorInicial(scheduler: SchedulerStatic, ev: Partial<IEvento>): boolean {
        // por padrao do componente, a end_date recebe a start_date toda vez que a end_date precede a start_date
        // para pegar o valor realmente exibido da end_date, aqui a data é construida a partir do select
        let dataField = scheduler.formSection('Período do evento').node;
        let selects = dataField.getElementsByTagName('select');
        let dataFim = !ev.start_date_only && {day: Number(selects[5].value), month: Number(selects[6].value), year: Number(selects[7].value)};
        if (!dataFim) {
            return false;
        }
        let dataInicio = moment(ev.start_date, 'DD/MM/YYYY');
        let dataFimCorrente = moment(new Date(dataFim.year, dataFim.month, dataFim.day), 'DD/MM/YYYY');
        if (dataInicio.isAfter(dataFimCorrente)) {
            return true;
        }
        return false;
    }

    private datasColidem(scheduler: SchedulerStatic, ev: Partial<IEvento>, id: string): boolean {
        let evento: IEvento = scheduler.getEvent(id);
        let dataInicio = moment(ev.start_date, 'DD/MM/YYYY');
        let dataFim = !ev.start_date_only && moment(ev.end_date, 'DD/MM/YYYY');
        if (ev.evento !== TipoImportacaoEnum.FOLGA) {
            return scheduler.getEvents().some((eventoExistente: IEvento) => {
                if (evento.id !== eventoExistente.id && evento.colaborador.trabalhador === eventoExistente.colaborador.trabalhador && eventoExistente.evento === ev.evento) {
                    let existenteDataInicio = moment(eventoExistente.start_date, 'DD/MM/YYYY');
                    let existenteDataFim = moment(eventoExistente.end_date, 'DD/MM/YYYY');
                    if (!eventoExistente.start_date_only) {
                        if (dataInicio.isAfter(existenteDataFim)) {
                            return false;
                        }
                    } else {
                        if ((dataInicio && dataFim) && dataInicio.isSameOrAfter(existenteDataInicio)) {
                            return false;
                        } else if (!dataFim && dataInicio.isAfter(existenteDataInicio)) {
                            return false;
                        }
                    }
                    return true;
                }
                return false;
            });
        }
        return scheduler.getEvents().some((eventoExistente: IEvento) => {
            if (evento.id !== eventoExistente.id &&
                evento.colaborador.trabalhador === eventoExistente.colaborador.trabalhador &&
                (eventoExistente.evento === ev.evento || (
                    ev.evento === TipoImportacaoEnum.FOLGA &&
                    (eventoExistente.evento === TipoImportacaoEnum.AFASTAMENTO || eventoExistente.evento === TipoImportacaoEnum.FOLGAFIXA)
                )) &&
                dataInicio.isBetween(moment(eventoExistente.start_date, 'DD/MM/YYYY'), moment(eventoExistente.end_date, 'DD/MM/YYYY'), 'days', '[]')
            ) {
                return true;
            }
            return false;
        });
    }
}
