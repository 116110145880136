import angular from 'angular';
import moment from 'moment';
import * as lodash from 'lodash';
import { FeriasEmGradeEnum } from '../FeriasEmGradeEnum';
import { IControleFerias } from '../../ControleFerias/models/controleferias.model';
import { IFerias } from '../../models/ferias.model';
import { MeurhCalendarioFeriasModalService } from '../calendarioferiasmodal/calendarioferias.modal.service';
import { MeurhFeriasService } from '../../ferias.service';
import { TipoColaboradorEnum } from '../../../../../shared/enums/TipoColaboradorEnum';
import { TrabalhadoresService } from '../../../../Trabalhadores/trabalhadores.service';

export class MeurhFeriasmarcaremgradePassodoisController implements ng.IController {

	static $inject = ['$rootScope', '$scope', '$state', '$http', '$stateParams', '$location', '$transitions', 'nsjRouting', 'NewToaster', 'ControleFeriasService', 'MeurhCalendarioFeriasModalService', 'MeurhFeriasService', 'TrabalhadoresService', ];

	public busy: boolean = false;
	public dataAtual: Date = new Date();
	public mesAtual: string = '';
	public anoAtual: string = '';
	public anosSelect: Array<object> = [];
	public mesesSelect: Array<object> = [];
	public daysOpt: Array<number> = lodash.range(30, 4);
	public erroPreenchimento: boolean = false;
	public entitiesSolicitacoes: any = [];
	public solicitacoesRascunhoDeletadas: any = [];
	public filters: any;
	public filter: any;
	public constructors: any;
	public entities: Array<IControleFerias>;
	public existiaRascunho: boolean;
	public passo: number = 2;
	public checkboxPreenchidos: any = [];
	public datasFeriados: Array<any> = [];
	public idData: number = 0;
	public config = {
		adiantamento: this.$rootScope.configuracoes['ADIANTAMENTO_13_FERIAS'] as boolean,
		bloqueiaDobraDeFerias: this.$rootScope.configuracoes['BLOQUEAR_MARCACAO_DOBRA_FERIAS'] as boolean,
		bloquearCompraDias: this.$rootScope.configuracoes['BLOQUEAR_ABONO_PECUNIARIO_MRH'] as boolean,
		podeAplicarEmTodos: this.$rootScope.configuracoes['LIBERAR_SOL_FERIAS_GRADE_LOTES'] as boolean
	};
	public errosPossiveis: any = {
		calculaDiasFunc: false,
		calculaDiasVendidosFunc: false,
		verificaQtdDiasPeriodoFunc: false,
		verificaSobrepostosFunc: false,
		calculaDiasTotal: false,
		diaDeGozoProibidoFeriado: false,
		diaDeGozoProibidoFeriadoLista: [],
		diaDeGozoProibidoAntecedeFeriado: false,
		diaDeGozoProibidoAntecedeFeriadoLista: [],
		diaDeInicioeFolga: false,
		diaDeInicioeFolgaLista: [],
		diaDeInicioAntecedeFolga: false,
		diaDeInicioAntecedeFolgaLista: [],
		dobraDeFerias: false,
		dobraDeFeriasLista: [],
		diaDeInicioNaoSelecionavel: false,
	};
	public errosPossiveisMensagens: any = {
		calculaDiasFunc: '',
		calculaDiasVendidosFunc: '',
		verificaQtdDiasPeriodoFunc: '',
		verificaSobrepostosFunc: '',
		calculaDiasTotal: '',
		diaDeGozoProibidoFeriado: '',
		diaDeGozoProibidoAntecedeFeriado: '',
		diaDeInicioeFolga: '',
		diaDeInicioAntecedeFolga: '',
		dobraDeFerias: '',
		diaDeInicioNaoSelecionavel: '',
	};
	public avisosPossiveis: any = {
		dataAposDobra: false,
		dataAposDobraLista: []
	};
	public avisosPossiveisMensagens = {
		dataAposDobra: ''
	};

	public showMarcacoesProblematicas: boolean = false;
	public marcacoesExibidas: Array<any> = [];
	public mensagemErroTela: string;
	public clicouAplicarEmTodos: boolean = false;

	public propriedadesErroDataInicio = [
		'erroDataInicioVazia',
		'erroDataInicioMenorDataMinima',
		'erroDataInicioFeriado',
		'erroDataInicioDoisDiasAntesFeriado',
		'erroDataInicioFolga',
		'erroDataInicioDoisDiasAntesFolga',
		'erroDataInicioAposDataDobra',
		'erroDatasSobrepostas',
	];

	public propriedadesErroDiasConceder = [
		'erroDiasConcederVazio',
		'erroDiasConcederMaiorQueSaldo',
		'erroDiasConcederMenorQueSaldo',
		'erroQtdDiasPeriodoFunc',
	];

	public propriedadesErroComprarDias = [
		'erroDiasVendidosMaiorQueLimite',
	];

	public errosPossiveisMarcacao = [
		...this.propriedadesErroDataInicio,
		...this.propriedadesErroDiasConceder,
		...this.propriedadesErroComprarDias
	];

	public fields: any = [
		{
			value: 'nome',
			label: 'Colaboradores',
			type: 'string',
			style: 'title',
			copy: '',
			rowspanNaCelula: true,
			customCollumnHtml: (): string => {
				let someHtmlVar = `<span ng-bind-template="{{tableRowEntity.nome}}"></span>
				<div><small>Matrícula: <span ng-bind-template="{{tableRowEntity.codigo}}"></span></small></div>
                <div><small>Direito a partir de: <span ng-bind-template="{{tableRowEntity.direitoAPartir ? tableRowEntity.direitoAPartir : '-'}}"></span></small></div>
                <div ng-if="(tableRowEntity.datapassoudolimite != undefined) || (tableRowEntity.dataproximodolimite != undefined)"><small>Limite para a dobra: <span ng-class="!tableRowEntity.dataproximodolimite && !tableRowEntity.datapassoudolimite ? '-' : 'limite-alert'" ng-bind-template="{{tableRowEntity.datalimitegozo | asDate | date:'dd/MM/yyyy'}}"></span>
                <span ng-if="tableRowEntity.dataproximodolimite">
                <i style="color: #C82333;" class="fas fa-info-circle"></i>
                <nsj-tooltip tooltip-position="top">
                    <p>O limite está próximo do vencimento.</p>
                </nsj-tooltip>
                </span>
                <span ng-if="tableRowEntity.datapassoudolimite">
                    <i style="color: #C82333;" class="fas fa-info-circle"></i>
                    <nsj-tooltip tooltip-position="top">
                        <p>O limite foi ultrapassado.</p>
                    </nsj-tooltip>
                </span>
                </small></div>`;

				return someHtmlVar;
			},
		},
		{
			value: 'saldo',
			label: 'Saldo disponível',
			type: 'string',
			style: 'default',
			copy: '',
			rowspanNaCelula: true,
			customCollumnHtml: (): string => {
				let someHtmlVar = `<span>{{::tableRowEntity.saldo}} dias</span>`;
				return someHtmlVar;
			},
		},
		{
			value: 'saldoTotal',
			label: 'Saldo total',
			type: 'string',
			style: 'default',
			copy: '',
			rowspanNaCelula: true,
			customCellClass: 'scd-child-shadow',
			customCollumnHtml: (): string => {
				let someHtmlVar = `<span>{{::tableRowEntity.saldoTotal}} dias</span>`;
				return someHtmlVar;
			},
		},
		{
			value: 'datainiciogozo',
			label: 'Início de gozo',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnScope: {
				clicouAplicarEmTodos: () : boolean => this.clicouAplicarEmTodos,
				calculaDataFimGozo: (entity: IFerias): void => {
					this.calculaDataFimGozoFunc(entity);
				},
				verificaSeDataPassou: (data: string): void => {
					this.verificaSeDataPassouFunc(data);
				},
				verificaDia: (entity: IFerias): void => {
					this.verificaDia(entity);
				},
				isBusy: (): boolean => {
					return this.busy;
				},
				exibeBordaErroInput: (entity: IFerias): boolean => {
					this.verificaValidadeDadosTela();
					return this.exibeBordaErroInput(entity, 'datainicio');
				}
			},
			customCollumnHtml: (): string => {
				let someHtmlVar = `
                  <nsj-date-picker
                  editable="true"
                  date-position="right"
                  type-select="single"
                  timepicker="false"
                  date-change="customCollumnScope.calculaDataFimGozo(tableRowEntity);
                  customCollumnScope.verificaSeDataPassou(tableRowEntity.datainiciogozo);
				  customCollumnScope.verificaDia(tableRowEntity);
				  customCollumnScope.exibeBordaErroInput(tableRowEntity);"
                  ng-init="tableRowEntity.datainiciogozo"
                  init-value="tableRowEntity.datainiciogozo"
				  ng-class="{'border-red': customCollumnScope.exibeBordaErroInput(tableRowEntity) && (tableRowEntity.datainiciogozo || customCollumnScope.clicouAplicarEmTodos())}"
                  opts='{"class":"form-control","uib-tooltip":"Início Período", "tooltip-trigger":"&apos;focus&apos;", "tooltip-placement":"top",
                          "ng-disabled":"customCollumnScope.isBusy() || $ctrl.entity.$$__submitting || (tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1)",
                          "ng-readonly":"$ctrl.entity.$$__submitting || (tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1)",
                          "ng-model":"tableRowEntity.datainiciogozo",
                          "ng-required":"($ctrl.entity.$$__submitting) !== true"}'
                  datepickeropts='{"disable-days-before":"tableRowEntity.bloqueiadiasanteriores"}'
                ></nsj-date-picker>
              `;

				return someHtmlVar;
			},
		},
		{
			value: 'diasferiascoletivas',
			label: 'Dias a conceder',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnScope: {
				days: this.daysOpt,
				clicouAplicarEmTodos: () : boolean => this.clicouAplicarEmTodos,
				calculaDias: (entity: IFerias): void => {
					entity.bordaAtencao = false;
					this.calculaDiasFunc(entity);
				},
				calculaDataFimGozo: (entity: IFerias): void => {
					entity.bordaAtencao = false;
					this.calculaDataFimGozoFunc(entity);
				},
				verificaQtdDiasPeriodo: (entity: IFerias): void => {
					entity.bordaAtencao = false;
					this.verificaQtdDiasPeriodoFunc(entity);
				},
				exibeBordaErroInput: (entity: IFerias): boolean => {
					this.verificaValidadeDadosTela();
					return this.exibeBordaErroInput(entity, 'diasconceder');
				},
				exibeBordaAtencaoInput: (entity: IFerias): boolean => {
					return entity.bordaAtencao;
				},
			},
			customCollumnHtml: (): string => {
				let someHtmlVar = `
                <select
                    ng-disabled="tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1"
                    ng-readonly="tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1"
                    type="number"
                    class="form-control"
                    ng-model="tableRowEntity.diasferiascoletivas"
                    ng-change="customCollumnScope.calculaDias(tableRowEntity);
                    customCollumnScope.verificaQtdDiasPeriodo(tableRowEntity);
                    customCollumnScope.calculaDataFimGozo(tableRowEntity);
					customCollumnScope.exibeBordaErroInput(tableRowEntity);"
                    uib-tooltip="Dias Período" tooltip-trigger="'focus'" tooltip-placement="top"
                    ng-options="day for day in customCollumnScope.days"
					ng-style="{
						'border-color': customCollumnScope.exibeBordaErroInput(tableRowEntity) && (tableRowEntity.diasferiascoletivas || customCollumnScope.clicouAplicarEmTodos()) ? '#CC0000' :
						(customCollumnScope.exibeBordaAtencaoInput(tableRowEntity) ? '#CB8700' : '')
					}">
                </select>`;

				return someHtmlVar;
			},
		},
		...( !this.config.bloquearCompraDias ?
		[
			{
				value: 'diasvendidos',
				label: 'Comprar dias?',
				type: 'string',
				style: 'title',
				copy: '',
				customCollumnScope: {
					days: (entity: IFerias): Array<number> => {
						return entity.daysVender;
					},
					calculaDias: (entity: IFerias): void => {
						this.calculaDiasFunc(entity);
					},
					calculaDiasVendidos: (entity: IFerias): void => {
						this.calculaDiasVendidosFunc(entity);
					},
					zeraVendidos: (entity: IFerias): void => {
						this.zeraVendidosFunc(entity);
					},
					exibeBordaErroInput: (entity: IFerias): boolean => {
						this.verificaValidadeDadosTela();
						return this.exibeBordaErroInput(entity, 'comprardias');
					}
				},
				customCollumnHtml: (): string => {
					let someHtmlVar = `
					<div class="align-center">
					<div style="margin-right: 8px;margin-top: 8px;">
						<nsj-switch uib-tooltip="Comprar dias?"
							ng-disabled="tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1 || tableRowEntity.tipo_trabalhador === 2"
							ng-readonly="tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1"
							ng-init="tableRowEntity.venderdias || tableRowEntity.tipo_trabalhador === 2"
							ng-model="tableRowEntity.venderdias"
							ng-checked="tableRowEntity.venderdias"
							ng-change="customCollumnScope.zeraVendidos(tableRowEntity); customCollumnScope.exibeBordaErroInput(tableRowEntity);"
							tooltip-trigger="'focus'"
						></nsj-switch>
					</div>
					<select
						type="number"
						class="form-control"
						ng-disabled="!tableRowEntity.venderdias || (tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1 || tableRowEntity.tipo_trabalhador === 2)"
						ng-readonly="tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1 || tableRowEntity.tipo_trabalhador === 2"
						ng-model="tableRowEntity.diasvendidos"
						ng-change="
							customCollumnScope.calculaDias(tableRowEntity);
							customCollumnScope.calculaDiasVendidos(tableRowEntity);
							customCollumnScope.exibeBordaErroInput(tableRowEntity);"
						uib-tooltip="Dias Período" tooltip-trigger="'focus'" tooltip-placement="top"
						ng-options="day for day in customCollumnScope.days(tableRowEntity)"
						ng-style="{'border-color': customCollumnScope.exibeBordaErroInput(tableRowEntity) ? '#CC0000' : ''}"
						>
					</select>
					</div>
					`;

					return someHtmlVar;
				},
			}
		] : [] ),
		{
			value: 'datafimgozo',
			label: 'Fim de gozo',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnHtml: (): string => {
				let someHtmlVar = `
                <nsj-date-picker
                ng-disabled="true"
                date-position="right"
                type-select="single"
                timepicker="false"
                ng-init="tableRowEntity.datafimgozo"
                init-value="tableRowEntity.datafimgozo"
                opts='{"class":"form-control","uib-tooltip":"Fim Período", "tooltip-trigger":"&apos;focus&apos;", "tooltip-placement":"top",
                        "ng-disabled":"true",
                        "ng-model":"tableRowEntity.datafimgozo",
                        "ng-required":"($ctrl.entity.$$__submitting) !== true"}'
            ></nsj-date-picker>`;

				return someHtmlVar;
			},
		},
		...( this.config.adiantamento ?
		[
			{
				value: 'adto13nasferias',
				label: 'Adiantar 13º?',
				type: 'string',
				style: 'title',
				copy: '',
				customCellClass: 'txt-align-center',
				customCollumnScope: {
					chooseAdto: (entity: IFerias): void => {
						if (entity.adto13nasferias === false) {
							entity['adtoTrabalhadorMarcado'] = true;
							entity.adto13nasferias = true;
						} else {
							delete entity.adtoTrabalhadorMarcado;
							entity.adto13nasferias = false;
						}
					},
					hasChecked: (solicitacao: IFerias): boolean => {
						if (solicitacao.hasOwnProperty('adtoTrabalhadorMarcado')) {
							if (solicitacao['adtoTrabalhadorMarcado']) {
								return true;
							} else {
								return false;
							}
						}

						if (solicitacao['adto13nasferias']) {
							return true;
						} else {
							return false;
						}
					},
					initializeUnchecked: (solicitacao: IFerias): void => {
						if (solicitacao.hasOwnProperty('adtoTrabalhadorMarcado')) {
							solicitacao.adtoTrabalhadorMarcado = false;
						}
					},
					someIsChecked: (solicitacao: IFerias): any  => {
						let solicitacoesTrabalhador = this.getSolicitacoesTrabalhador(solicitacao);
						let retorno = solicitacoesTrabalhador.some((element: IFerias): boolean => element['adto13nasferias']);

						return retorno;
					}
				},
				customCollumnHtml: (): string => {
					let someHtmlVar = `
						<input ng-if="tableRowEntity.rowspan === false" type='checkbox' name="adto13nasferias"
						ng-model="tableRowEntity.adto13nasferias"
						checked="tableRowEntity.adto13nasferias"
						ng-disabled="tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1 || tableRowEntity.tipo_trabalhador === 2"
						ng-readonly="tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1 || tableRowEntity.tipo_trabalhador === 2"/>

						<input ng-if="tableRowEntity.rowspan === true" type='checkbox' name="adto13nasferias"
						ng-model="tableRowEntity.adto13nasferias"
						checked="tableRowEntity.adto13nasferias"
						ng-init="customCollumnScope.initializeUnchecked(tableRowEntity);"
						ng-disabled="(!customCollumnScope.hasChecked(tableRowEntity) && customCollumnScope.someIsChecked(tableRowEntity)) || (tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1) || tableRowEntity.tipo_trabalhador === 2"
						ng-readonly="tableRowEntity.situacao === 0 || tableRowEntity.situacao === 1 || tableRowEntity.tipo_trabalhador === 2"
						ng-click="customCollumnScope.chooseAdto(tableRowEntity)" />
					`;
					return someHtmlVar;
				},
			}
		] : [] ),
		{
			value: 'situacao',
			label: 'Situação',
			type: 'string',
			style: 'title',
			copy: '',
			customCollumnHtml: (): string => {
				let someHtmlVar = `
                <div ng-if="tableRowEntity.situacao === ''" class="label label-primary"
                ng-class='label-default'>
                    <span>À marcar</span>
                </div>
                <div ng-if="tableRowEntity.situacao === -1" class="label label-default"
                ng-class='label-default'>
                    <span>Rascunho</span>
                </div>
                <div ng-if="tableRowEntity.situacao === 0" class="label label-warning"
                ng-class='label-default'>
                    <span>Enviada</span>
                </div>
                <div ng-if="tableRowEntity.situacao === 1" class="label label-success"
                ng-class='label-default'>
                    <span>Aprovada</span>
                </div>
                `;

				return someHtmlVar;
			},
		},
		...( this.config.podeAplicarEmTodos ?
		[
			{
				value: 'trabalhador', // precisa ter o nome de uma propriedade do objeto de marcação de férias
				label: '',
				type: 'string',
				style: 'title',
				copy: '',
				customCollumnScope: {
					aplicarEmTodos: (entity: IFerias): void => {
						this.aplicarEmTodos(entity, 'datainiciogozo');
						this.aplicarEmTodos(entity, 'diasferiascoletivas');
					},
					exibirAplicarEmTodos: (entity: IFerias): boolean => {
						return this.exibirAplicarEmTodos(entity);
					},

				},
				customCollumnHtml: (): string => {
					let someHtmlVar = `
						<a ng-if="customCollumnScope.exibirAplicarEmTodos(tableRowEntity)" class="btn btn-sm btn-tertiary" ng-click="customCollumnScope.aplicarEmTodos(tableRowEntity)">
							<i class="fa fa-layer-group"></i>
							Aplicar em todos
						</a>
					`;
					return someHtmlVar;
				},
			}
		] : [] ),
	];

	public configColaboradorHipersuficiente: boolean = this.$rootScope.nsjGlobals.a.configuracoes.COLABORADOR_HIPERSUFICIENTE;
	public configDiasAntecedenciaFerias: number = this.$rootScope.nsjGlobals.a.configuracoes.QTD_DIAS_ANTECEDENCIA_FERIAS_MRH;

	constructor(
		public $rootScope: angular.IRootScopeService & { configuracoes: any, nsjGlobals: any },
		public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
		public $http: angular.IHttpService,
		public $stateParams: angular.ui.IStateParamsService,
		public $location: any,
		public $transitions: any,
		public nsjRouting: any,
		public NewToaster: any,
		public entityService: any,
		public MeurhCalendarioFeriasModalService: MeurhCalendarioFeriasModalService,
		public MeurhFeriasService: MeurhFeriasService,
		public TrabalhadoresService: TrabalhadoresService,
	) {
    	this.daysOpt[this.daysOpt.length] = 0;

		this.MeurhFeriasService.getFeriados(undefined, 1);
		this.$scope.$on('meurh_solicitacoesferias_feriados_loaded', (event: any, args: any) => {
			// remove datas duplicadas
			let unicos = this.uniqueArray(args);
			this.datasFeriados = angular.copy(unicos);
		});

		this.$scope.$on('meurh_solicitacoesferias_horarios_loaded', (event: any, args: any) => {
			// monta folgas de cada colaborador
			this.entitiesSolicitacoes.forEach((solicitacao: any) => {
				if (solicitacao.trabalhador === args.trabalhador) {
					let folgasTrabalhador = args;

					if (folgasTrabalhador.tipo >= 0 && folgasTrabalhador.tipo <= 2 && folgasTrabalhador.dsrsobredomingoseferiados === null) {
						folgasTrabalhador.dsrsobredomingoseferiados = true;
					}

					solicitacao.folgas = this.montaFolgas(folgasTrabalhador);

					// verificando se data de inicio de gozo das solicitações em rascunho já existentes são válidas
					if (solicitacao.situacao === FeriasEmGradeEnum.SITUACAO_RASCUNHO) {
						this.verificaDia(solicitacao);
					}
				}
			});
			this.busy = false;

			this.marcacoesExibidas = this.entitiesSolicitacoes;

		});

		entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
	}

	$onInit(): void {
		if (this.entitiesSolicitacoes.length === 0) {
			this.entities = angular.copy(this.checkboxPreenchidos);
			this.alteraEntities();
		} else {
			this.marcacoesExibidas = this.entitiesSolicitacoes;
		}

		this.existiaRascunho = this.existeRascunho();
	}

	uniqueArray(a: any) {
		var seen = {};
		return a.filter((item) => {
			return seen.hasOwnProperty(item) ? false : (seen[item] = true);
		});
	}

	isBusy(): boolean {
		return this.busy;
	}

	existeRascunho(): boolean {
		return this.entitiesSolicitacoes.some((element: any) => {
			return element.situacao === '' || element.situacao === FeriasEmGradeEnum.SITUACAO_RASCUNHO;
		});
	}

	retornaDataDireitoFerias(entity: IControleFerias): moment.Moment {
		if (entity.tipo === TipoColaboradorEnum.ESTAGIARIO) {
			return moment(entity.inicioperiodoaquisitivoferias, 'YYYY-MM-DD');
		}

		if (entity.hasOwnProperty('inicioproximoperiodoaquisitivoferias') && entity.inicioproximoperiodoaquisitivoferias != null) {
			return moment(entity.inicioproximoperiodoaquisitivoferias, 'YYYY-MM-DD');
		}
	}

	buscaFolgasTrabalhador(trabalhador: any): void {
		if (trabalhador) {
			this.busy = true;
			this.MeurhFeriasService.getHorarios(trabalhador);
		}
	}

	alteraEntities(): void {
		this.entities.forEach(async (item: IControleFerias) => {
			this.busy = true;
			item.direitoAPartir = this.retornaDataDireitoFerias(item);

			// calcular máximo de dias possíveis para venda, com base no direito(total de dias)
			let daysVender = lodash.range(1, Math.trunc((item.direito / 3) + 1));

			let colaboradorHipersuficiente: boolean = false;
			await this.TrabalhadoresService.get(item.trabalhador).then((dadosColaborador) => {
				colaboradorHipersuficiente = dadosColaborador.hipersuficiente;
			}).finally(() => this.busy = false);

			if (item.ferias.length === 0) {
				let novoPeriodo = {
					// uTILIZAR IDENTIFICADOR AO INVES DE TRABABALHADOR NA TABLE
					identificador: item.trabalhador,
					codigo: item.codigo,
					trabalhador: item.trabalhador,
					tipo_trabalhador: item.tipo,
					nome: item.nome,
					datapassoudolimite: item.datapassoudolimite,
					dataproximodolimite: item.dataproximodolimite,
					inicioproximoperiodoaquisitivoferias: moment(item.inicioproximoperiodoaquisitivoferias).format('DD/MM/YYYY'),
					datalimitegozo: item.datalimitegozo,
					direito: item.direito,
					daysVender: daysVender,
					saldo: item.saldo,
          			saldoTotal: item.saldoTotal,
					datainicioperiodoaquisitivo: item.inicioperiodoaquisitivoferias,
					datafimperiodoaquisitivo: item.fimperiodoaquisitivoferias,
					bloqueiadiasanteriores: this.verificaBloqueiaDiasAnteriores(item.tipo === TipoColaboradorEnum.ESTAGIARIO ? item.inicioperiodoaquisitivoferias : item.inicioproximoperiodoaquisitivoferias, item.tipo),
					solicitacao: '',
					datainiciogozo: '',
					diasferiascoletivas: '',
					venderdias: false,
					diasvendidos: '',
					datafimgozo: '',
					adto13nasferias: false,
					situacao: '',
					rowspan: false,
					direitoAPartir: item.direitoAPartir?.format('DD/MM/YYYY'),
					empresaobj: item.empresaobj,
					estabelecimentoobj: item.estabelecimentoobj,
					colaboradorhipersuficiente: colaboradorHipersuficiente,
				};

				this.entitiesSolicitacoes.push(novoPeriodo);
			} else {
				item.ferias.forEach((solicitacao: IFerias) => {
					solicitacao['identificador'] = item.trabalhador;
					solicitacao['codigo'] = item.codigo;
					solicitacao['trabalhador'] = item.trabalhador;
					solicitacao['tipo_trabalhador'] = item.tipo;
					solicitacao['nome'] = item.nome;
					solicitacao['datainicioperiodoaquisitivo'] = item.inicioperiodoaquisitivoferias;
					solicitacao['inicioproximoperiodoaquisitivoferias'] = moment(item.inicioproximoperiodoaquisitivoferias).format('DD/MM/YYYY');
					solicitacao['datafimperiodoaquisitivo'] = item.fimperiodoaquisitivoferias;
					solicitacao['datalimitegozo'] = item.datalimitegozo;
					solicitacao['direito'] = item.direito;
					solicitacao['saldo'] = item.saldo;
          			solicitacao['saldoTotal'] = item.saldoTotal;
					solicitacao['venderdias'] = solicitacao.diasvendidos ? true : false;
					solicitacao['rowspan'] = true;
					solicitacao['daysVender'] = daysVender;
					solicitacao['direitoAPartir'] = item.direitoAPartir.format('DD/MM/YYYY');
					solicitacao['empresaobj'] = item.empresaobj;
					solicitacao['estabelecimentoobj'] = item.estabelecimentoobj;
					solicitacao['colaboradorhipersuficiente'] = colaboradorHipersuficiente;
					solicitacao['bloqueiadiasanteriores'] = this.verificaBloqueiaDiasAnteriores(item.tipo === TipoColaboradorEnum.ESTAGIARIO ? item.inicioperiodoaquisitivoferias : item.inicioproximoperiodoaquisitivoferias, item.tipo),

					this.entitiesSolicitacoes.push(solicitacao);
				});
			}
			this.buscaFolgasTrabalhador(item.trabalhador);
		});
	}

	montaFolgas(dias: any) {
		dias.totalFolgas = [];

		if (dias.dsrsobredomingoseferiados) {
			dias.totalFolgas.push(0);
			return dias;
		}

        if (dias.domingo) {
			dias.totalFolgas.push(0);
        }
        if (dias.segunda) {
			dias.totalFolgas.push(1);
        }
        if (dias.terca) {
			dias.totalFolgas.push(2);
        }
        if (dias.quarta) {
			dias.totalFolgas.push(3);
        }
        if (dias.quinta) {
			dias.totalFolgas.push(4);
        }
        if (dias.sexta) {
			dias.totalFolgas.push(5);
        }
        if (dias.sabado) {
			dias.totalFolgas.push(6);
        }
		return dias;
	}

	verificaBloqueiaDiasAnteriores(inicioproximoperiodoaquisitivoferias: string | moment.Moment, tipoFuncionario: number): moment.Moment | string {
		if (tipoFuncionario === TipoColaboradorEnum.ESTAGIARIO) {
			return moment() > moment(inicioproximoperiodoaquisitivoferias) ? moment().format('YYYY-MM-DD') : moment(inicioproximoperiodoaquisitivoferias).format('YYYY-MM-DD');
		} else {

			let dataAtual = moment();

			// data que será considerada para desativar dias no calendário
			let dataLiberacaoCalendario = (
				this.$rootScope.nsjGlobals.a.configuracoes.MARCAR_FERIAS_ANTES_PERIODO === false
				&& dataAtual < moment(inicioproximoperiodoaquisitivoferias)
			)
			? moment(inicioproximoperiodoaquisitivoferias)
			: dataAtual;

			let diferencaDatas = dataLiberacaoCalendario.diff(dataAtual, 'days');

			// só deve somar o valor da configuração com a data de liberação do calendário caso a diferença entre os dias seja menor que o valor da configuração
			if (diferencaDatas < this.configDiasAntecedenciaFerias) {
				dataLiberacaoCalendario.add(this.configDiasAntecedenciaFerias, 'days');
			}

			return dataLiberacaoCalendario.format('YYYY-MM-DD');

		}
	}

	conferePrimeiro(entity: IFerias): boolean {
		let solicitacoesTrabalhador = this.getSolicitacoesTrabalhador(entity);
		var index = solicitacoesTrabalhador.indexOf(entity);

		return index === 0 ? false : true;
	}

	exibirAplicarEmTodos(entity: IFerias): boolean {

		if (this.showMarcacoesProblematicas || this.getQtdTrabalhadoresDiferentes() === 1) {
			return false;
		}

		if (entity.situacao === '' || entity.situacao === -1) {
			return true;
		} else {
			return false;
		}

	}

	conferePeriodos(entity: IFerias): boolean {
		let solicitacoesTrabalhador = this.getSolicitacoesTrabalhador(entity);
		return solicitacoesTrabalhador.length === 3 ? false : true;
	}

	dividePeriodo(entity: IFerias): void {
		if (entity.rowspan === false || entity.rowspan === undefined || entity.rowspan === null) {
			entity['rowspan'] = true;
		}

		let tempSolicitacoes = angular.copy(this.entitiesSolicitacoes);

		let novoPeriodo = {
			identificador: entity.trabalhador,
			codigo: entity.codigo,
			trabalhador: entity.trabalhador,
			tipo_trabalhador: entity.tipo_trabalhador,
			colaboradorhipersuficiente: entity.colaboradorhipersuficiente,
			datapassoudolimite: entity.datapassoudolimite,
			dataproximodolimite: entity.dataproximodolimite,
			direitoAPartir: entity.direitoAPartir,
			nome: entity.nome,
			inicioproximoperiodoaquisitivoferias: entity.inicioproximoperiodoaquisitivoferias,
			datalimitegozo: entity.datalimitegozo,
			direito: entity.direito,
			daysVender: entity.daysVender,
			saldo: entity.saldo,
      		saldoTotal: entity.saldoTotal,
			datainicioperiodoaquisitivo: entity.datainicioperiodoaquisitivo,
			datafimperiodoaquisitivo: entity.datafimperiodoaquisitivo,
			bloqueiadiasanteriores: this.verificaBloqueiaDiasAnteriores(moment(entity.inicioproximoperiodoaquisitivoferias, 'DD/MM/YYYY'), entity.tipo_trabalhador),
			solicitacao: '',
			datainiciogozo: '',
			diasferiascoletivas: '',
			diasvendidos: '',
			venderdias: false,
			datafimgozo: '',
			adto13nasferias: false,
			adtoTrabalhadorMarcado: false,
			situacao: '',
			rowspan: true,
			empresaobj: entity.empresaobj,
			estabelecimentoobj: entity.estabelecimentoobj
		};

		let solicitacoesTrabalhador = this.getSolicitacoesTrabalhador(entity);

		// bloqueia o adiantamento do elemento a ser adicionado caso
		// já haja um adiantamento marcado nesse trabalhador
		solicitacoesTrabalhador.forEach((element: IFerias) => {
			if (element.adto13nasferias === true) {
				novoPeriodo.adtoTrabalhadorMarcado = true;
			}
		});

		var index = this.entitiesSolicitacoes.indexOf(solicitacoesTrabalhador[solicitacoesTrabalhador.length - 1]);

		tempSolicitacoes.splice((index + 1), 0, novoPeriodo);

		this.entitiesSolicitacoes = [];
		this.entitiesSolicitacoes = angular.copy(tempSolicitacoes);
		this.buscaFolgasTrabalhador(entity.trabalhador);
		this.existeRascunho();
	}

	removerPeriodo(entity: IFerias): void {
		// cASO SEJA RASCUNHO ADICIONAR AO ARRAY DE DELETADAS PARA SEREM DELETADAS NO BANCO NO TERCEIRO PASSO
		if (this.valorExiste(entity.solicitacao) && entity.situacao === -1) {
			this.solicitacoesRascunhoDeletadas.push(entity);
		}

		let solicitacoesTrabalhador = this.getSolicitacoesTrabalhador(entity);
		if (solicitacoesTrabalhador.length === 1) {
			// zera o rowspan se só sobrar apenas um elemento daquele trabalhador
			solicitacoesTrabalhador[0].rowspan = false;
		} else {
			// desbloqueia o adiantamento dos elementos daquele trabalhador caso esteja excluindo o marcado
			if (entity.adto13nasferias === true) {
				solicitacoesTrabalhador.forEach((element: any) => {
					element.adto13nasferias = false;
					element['adtoTrabalhadorMarcado'] = false;
				});
			}
		}

		var index = this.entitiesSolicitacoes.indexOf(entity);
		this.entitiesSolicitacoes.splice(index, 1);

		// caso tenha algum erro de validação, ele é removido da lista de erros
		this.errosPossiveis.diaDeInicioeFolgaLista = this.errosPossiveis.diaDeInicioeFolgaLista.filter(elem => elem !== entity.idData);
		this.errosPossiveis.diaDeInicioAntecedeFolgaLista = this.errosPossiveis.diaDeInicioAntecedeFolgaLista.filter(elem => elem !== entity.idData);
		this.errosPossiveis.diaDeGozoProibidoFeriadoLista = this.errosPossiveis.diaDeGozoProibidoFeriadoLista.filter(elem => elem !== entity.idData);
		this.errosPossiveis.diaDeGozoProibidoAntecedeFeriadoLista = this.errosPossiveis.diaDeGozoProibidoAntecedeFeriadoLista.filter(elem => elem !== entity.idData);
		this.errosPossiveis.dobraDeFeriasLista = this.errosPossiveis.dobraDeFeriasLista.filter(elem => elem !== entity.idData);
		this.avisosPossiveis.dataAposDobraLista = this.avisosPossiveis.dataAposDobraLista.filter(elem => elem !== entity.idData);

		this.verificaDatasErros();
		this.verificaQtdDiasPeriodoFunc(entity);
		this.calculaDiasFunc(entity);
		this.existeRascunho();
	}

	limparCampos(entity: IFerias): void {
		entity.datainiciogozo = '';
		entity.diasferiascoletivas = null;
		entity.diasvendidos = null;
		entity.datafimgozo = '';
		entity.adto13nasferias = false;
		entity.adtoTrabalhadorMarcado = false;
		entity.venderdias = false;
		entity.bordaAtencao = false;

		this.calculaDiasFunc(entity);
		this.verificaDiaFolga(entity);
		this.dataInicioAntesDataMinima();
	}

	loadMore(): void {
		this.entityService.loadMore();
	}

	finished(): boolean {
		return this.entityService.finished;
	}

	/*
	******
	*************
	*******************
	************ Regras de negócios ***
	*******************
	*************
	******
	*/
	calculaDataFimGozoFunc(entity: IFerias): void {
		if (this.valorExiste(entity.datainiciogozo) && (this.valorExiste(entity.diasferiascoletivas) || entity.diasferiascoletivas === '')) {
			if (entity.diasferiascoletivas > 0) {
				entity.datafimgozo = moment(entity.datainiciogozo).add(entity.diasferiascoletivas - 1, 'days').format('YYYY-MM-DD');
			} else {
				entity.datafimgozo = moment(entity.datainiciogozo).add(entity.diasferiascoletivas, 'days').format('YYYY-MM-DD');
			}
		}

		if (!this.valorExiste(entity.datainiciogozo)) {
			entity.datafimgozo = '';
		}

		this.verificaSobrepostosFunc(entity);
	}

	calculaDiasFunc(entity: IFerias): void {
		// - A soma dos saldos dos períodos (dias de férias tirados + dias vendidos) não pode ser maior que a quantidade de dias de férias na qual um funcionário tem direito naquele período aquisitivo (o saldo não pode ser negativo).
		let totalDias = 0;
		let solicitacoesTrabalhador = this.getSolicitacoesTrabalhador(entity);

		solicitacoesTrabalhador.forEach((element: IFerias) => {
			if (this.valorExiste(element.diasferiascoletivas)) {
				totalDias = Number(totalDias) + Number(element.diasferiascoletivas);
			}

			if (this.valorExiste(element.diasvendidos)) {
				totalDias = Number(totalDias) + Number(element.diasvendidos);
			}
		});

		if (solicitacoesTrabalhador.length === 3) {
			let marcouTodosDiasPeriodos = true;

			solicitacoesTrabalhador.forEach((element: any) => {
				if (marcouTodosDiasPeriodos) {
					if (!this.valorExiste(element.diasferiascoletivas) && !this.valorExiste(element.diasvendidos)) {
						marcouTodosDiasPeriodos = false;
					}
				}
			});

			if (marcouTodosDiasPeriodos) {
				if (totalDias < entity.direito) {
					this.errosPossiveis.calculaDiasTotal = true;
					this.errosPossiveisMensagens.calculaDiasTotal = `O total da soma dos dias concedidos ${this.config.bloquearCompraDias ? '' : 'mais os dias vendidos' } de um trabalhador deve ser igual ao direito (${entity.direito}) caso todos os três períodos já tenham sido definidos.`;
				} else {
					this.errosPossiveis.calculaDiasTotal = false;
				}
			} else {
				this.errosPossiveis.calculaDiasTotal = false;
			}
		} else {
			this.errosPossiveis.calculaDiasTotal = false;
		}

		if (totalDias > entity.direito) {
			this.errosPossiveis.calculaDiasFunc = true;
			this.errosPossiveisMensagens.calculaDiasFunc = `O total da soma dos dias concedidos ${this.config.bloquearCompraDias ? '' : 'mais os dias vendidos' } de um trabalhador não deve ultrapassar o saldo do mesmo.`;
		} else {
			this.errosPossiveis.calculaDiasFunc = false;
			if (solicitacoesTrabalhador.length === 1) {
				this.verificaArrayErros();
			}
		}

		this.verificaSobrepostosFunc(entity);
	}

	calculaDiasVendidosFunc(entity: IFerias): void {
		let solicitacoesTrabalhador = this.getSolicitacoesTrabalhador(entity);
		let totalVendidos = 0;

		solicitacoesTrabalhador.forEach((element: IFerias) => {
			if (this.valorExiste(element.diasvendidos)) {
				totalVendidos = totalVendidos + element.diasvendidos;
			}
		});

		if (totalVendidos > entity.daysVender[entity.daysVender.length - 1]) {
			this.errosPossiveis.calculaDiasVendidosFunc = true;
			this.errosPossiveisMensagens.calculaDiasVendidosFunc = 'O máximo de dias que podem ser vendidos para um trabalhador é ' + entity.daysVender[entity.daysVender.length - 1] + '.';
		} else {
			this.errosPossiveis.calculaDiasVendidosFunc = false;
		}

		this.verificaArrayErros();
	}

	zeraVendidosFunc(entity: IFerias): void {
		if (!entity.venderdias) {
			entity.diasvendidos = 0;

			this.calculaDiasVendidosFunc(entity);
		}
		this.calculaDiasFunc(entity);
	}

	verificaDia(entity: IFerias): void {
		// identificador para diferenciar as datas
		if (!entity.hasOwnProperty('idData')) {
			this.idData += 1;
			entity.idData = this.idData;
		}

		let umDiaAntesDoFeriado = moment(entity.datainiciogozo).add(1, 'days').format('YYYY-MM-DD');
		let doisDiasAntesDoFeriado = moment(entity.datainiciogozo).add(2, 'days').format('YYYY-MM-DD');
		let existeConflito = this.datasFeriados.filter(function (dataFeriado: any) {
			return (dataFeriado === umDiaAntesDoFeriado) || (dataFeriado === doisDiasAntesDoFeriado);
		});
		let diaDeFeriado = this.datasFeriados.filter(function (dataFeriado: any) {
			return (dataFeriado === entity.datainiciogozo);
		});

		// verifica se a data selecionada é um dia de folga ou se antecede dois dias de folga
		this.verificaDiaFolga(entity);
		// verifica se a data selecionada é um feriado
		if (diaDeFeriado.length > 0) {
			this.errosPossiveis.diaDeGozoProibidoFeriado = true;
			this.errosPossiveisMensagens.diaDeGozoProibidoFeriado = 'O início de gozo não pode ser em dia de feriado.';
			if (!this.errosPossiveis.diaDeGozoProibidoFeriadoLista.includes(entity.idData)) {
				this.errosPossiveis.diaDeGozoProibidoFeriadoLista.push(entity.idData);
			}
		} else if (this.errosPossiveis.diaDeGozoProibidoFeriadoLista.includes(entity.idData)) {
			this.errosPossiveis.diaDeGozoProibidoFeriadoLista = this.errosPossiveis.diaDeGozoProibidoFeriadoLista.filter(elem => elem !== entity.idData);
		}
		// verifica se a data selecionada antecede dois dias de feriado
		if (existeConflito.length > 0) {
			this.errosPossiveis.diaDeGozoProibidoAntecedeFeriado = true;
			this.errosPossiveisMensagens.diaDeGozoProibidoAntecedeFeriado = 'O início de gozo não pode ser no período de dois dias que antecedem feriado.';
			if (!this.errosPossiveis.diaDeGozoProibidoAntecedeFeriadoLista.includes(entity.idData)) {
				this.errosPossiveis.diaDeGozoProibidoAntecedeFeriadoLista.push(entity.idData);
			}
		} else if (this.errosPossiveis.diaDeGozoProibidoAntecedeFeriadoLista.includes(entity.idData)) {
			this.errosPossiveis.diaDeGozoProibidoAntecedeFeriadoLista = this.errosPossiveis.diaDeGozoProibidoAntecedeFeriadoLista.filter(elem => elem !== entity.idData);
		}

		this.verificaAvisoDobra(entity);
		this.verificaDatasErros();
		this.dataInicioAntesDataMinima();
		this.verificaArrayErros();
	}

	verificaDiaFolga(entity: IFerias): void {
		let diaDaSemana = moment(entity.datainiciogozo).day();
		if (
			((entity.folgas.tipo >= 0 && entity.folgas.tipo <= 2) ||
			entity.folgas.dsrsobredomingoseferiados === true) && diaDaSemana === 0
		) {
			this.errosPossiveis.diaDeInicioeFolga = true;
			this.errosPossiveisMensagens.diaDeInicioeFolga = 'O início de gozo não pode ser um dia de folga.';
			if (!this.errosPossiveis.diaDeInicioeFolgaLista.includes(entity.idData)) {
				this.errosPossiveis.diaDeInicioeFolgaLista.push(entity.idData);
			}
		} else if (entity.folgas.totalFolgas.includes(diaDaSemana)) {
			this.errosPossiveis.diaDeInicioeFolga = true;
			this.errosPossiveisMensagens.diaDeInicioeFolga = 'O início de gozo não pode ser um dia de folga.';
			if (!this.errosPossiveis.diaDeInicioeFolgaLista.includes(entity.idData)) {
				this.errosPossiveis.diaDeInicioeFolgaLista.push(entity.idData);
			}
		} else if (
			!(entity.colaboradorhipersuficiente && this.configColaboradorHipersuficiente) &&
			(entity.folgas.totalFolgas.includes(moment(entity.datainiciogozo).add(1, 'days').day()) ||
			entity.folgas.totalFolgas.includes(moment(entity.datainiciogozo).add(2, 'days').day()) ||
			((entity.folgas.tipo >= 0 && entity.folgas.tipo <= 2) ||
			entity.folgas.dsrsobredomingoseferiados === true) &&
			(moment(entity.datainiciogozo).add(1, 'days').day() === 0 || moment(entity.datainiciogozo).add(2, 'days').day() === 0))
		) {
			this.errosPossiveis.diaDeInicioAntecedeFolga = true;
			this.errosPossiveisMensagens.diaDeInicioAntecedeFolga = 'O início de gozo não pode ser no período de dois dias que antecedem folga.';
			if (!this.errosPossiveis.diaDeInicioAntecedeFolgaLista.includes(entity.idData)) {
				this.errosPossiveis.diaDeInicioAntecedeFolgaLista.push(entity.idData);
			}
		} else if (this.errosPossiveis.diaDeInicioeFolgaLista.includes(entity.idData)) {
			this.errosPossiveis.diaDeInicioeFolgaLista = this.errosPossiveis.diaDeInicioeFolgaLista.filter(elem => elem !== entity.idData);
		} else if (this.errosPossiveis.diaDeInicioAntecedeFolgaLista.includes(entity.idData)) {
			this.errosPossiveis.diaDeInicioAntecedeFolgaLista = this.errosPossiveis.diaDeInicioAntecedeFolgaLista.filter(elem => elem !== entity.idData);
		}
	}

	verificaAvisoDobra(entity: IFerias) {
		if (moment(entity.datainiciogozo).isAfter(moment(entity.datalimitegozo))) {
			if (this.config.bloqueiaDobraDeFerias) {
				this.errosPossiveis.dobraDeFerias = true;
				this.errosPossiveisMensagens.dobraDeFerias = 'O início de gozo selecionado é após a data de dobra.';
				if (!this.errosPossiveis.dobraDeFeriasLista.includes(entity.idData)) {
					this.errosPossiveis.dobraDeFeriasLista.push(entity.idData);
				}
			} else {
				this.avisosPossiveis.dataAposDobra = true;
				this.avisosPossiveisMensagens.dataAposDobra = 'O início de gozo selecionado é após a data de dobra.';
				if (!this.avisosPossiveis.dataAposDobraLista.includes(entity.idData)) {
					this.avisosPossiveis.dataAposDobraLista.push(entity.idData);
				}
			}
		} else if (
			this.avisosPossiveis.dataAposDobraLista.includes(entity.idData) ||
			this.errosPossiveis.dobraDeFeriasLista.includes(entity.idData)
		) {
			this.avisosPossiveis.dataAposDobraLista = this.avisosPossiveis.dataAposDobraLista.filter(elem => elem !== entity.idData);
			this.errosPossiveis.dobraDeFeriasLista = this.errosPossiveis.dobraDeFeriasLista.filter(elem => elem !== entity.idData);
		}
	}

	verificaSeDataPassouFunc(data: string): void {
		if (moment().isAfter(data, 'day')) {
			this.NewToaster.pop({
				type: 'warning',
				title: 'Aviso informativo: Você selecionou uma data passada.'
			});
		}
	}

	verificaQtdDiasPeriodoFunc(entity: IFerias): void {
		// - Caso as férias sejam particionadas, pelo menos um dos períodos de férias deve ser igual ou maior do que 14 dias (sem considerar os dias vendidos naquele período).
		let solicitacoesTrabalhador = this.getSolicitacoesTrabalhador(entity);
		let possivelPeriodoQuatorzeDias: boolean = false;

		if (solicitacoesTrabalhador.length > 1) {
			if (solicitacoesTrabalhador.length === 3) {
				solicitacoesTrabalhador.forEach((element: any) => {
					if (element.diasferiascoletivas >= 14) {
						possivelPeriodoQuatorzeDias = true;
					}
				});
			}

			if (solicitacoesTrabalhador.length === 2) {
				if (((30 - (solicitacoesTrabalhador[0].diasferiascoletivas + solicitacoesTrabalhador[1].diasferiascoletivas)) >= 14 ||
					(solicitacoesTrabalhador[0].diasferiascoletivas >= 14) ||
					(solicitacoesTrabalhador[1].diasferiascoletivas) >= 14)) {
					possivelPeriodoQuatorzeDias = true;
				}
			}
		} else {
			possivelPeriodoQuatorzeDias = true;
		}

		if (possivelPeriodoQuatorzeDias) {
			this.errosPossiveis.verificaQtdDiasPeriodoFunc = false;
		} else {
			this.errosPossiveis.verificaQtdDiasPeriodoFunc = true;
			this.errosPossiveisMensagens.verificaQtdDiasPeriodoFunc = 'Um dos períodos de férias precisa ser igual ou maior que 14 dias.';
		}

		this.verificaSobrepostosFunc(entity);
	}

	verificaSobrepostosFunc(entity: IFerias): void {
		// - O usuário não pode marcar períodos de férias sobrepostos.
		let estaSobreposto = false;

		// ordena as marcações por trabalhador e data de início
		const solicitacoesOrdenadas = this.entitiesSolicitacoes.slice().sort((a, b) => {
			if (a.trabalhador !== b.trabalhador) {
				return a.trabalhador.localeCompare(b.trabalhador);
			}
			return a.datainiciogozo.localeCompare(b.datainiciogozo);
		});

		for (let i = 1; i < solicitacoesOrdenadas.length; i++) {
			if (
				solicitacoesOrdenadas[i - 1].trabalhador === solicitacoesOrdenadas[i].trabalhador &&
				solicitacoesOrdenadas[i - 1].datafimgozo && solicitacoesOrdenadas[i].datainiciogozo
			) {

			  const fimAnterior = moment(solicitacoesOrdenadas[i - 1].datafimgozo);
			  const inicioAtual = moment(solicitacoesOrdenadas[i].datainiciogozo);

			  // verifica se a marcação atual começa antes do fim da marcação anterior
			  if (inicioAtual.isSameOrBefore(fimAnterior)) {
				estaSobreposto = true;
			  }
			}
		}

		if (estaSobreposto) {
			this.errosPossiveis.verificaSobrepostosFunc = true;
			this.errosPossiveisMensagens.verificaSobrepostosFunc = 'Os períodos de férias não podem ser sobrepostos.';
		} else {
			this.errosPossiveis.verificaSobrepostosFunc = false;
		}

		this.verificaArrayErros();
	}

	// verifica array de erros de preenchimento criado para bloquear o botão continuar para passo 3
	verificaArrayErros(): void {
		let existeErro = false;

		for (var i in this.errosPossiveis) {
			if (this.errosPossiveis[i] === true) {
				existeErro = true;
				break;
			}
		}

		if (existeErro) {
			this.erroPreenchimento = true;
		} else {
			this.erroPreenchimento = false;
		}
	}

	verificaDatasErros(): void {
		if (this.errosPossiveis.diaDeInicioeFolgaLista.length === 0) {
			this.errosPossiveis.diaDeInicioeFolga = false;
		}
		if (this.errosPossiveis.diaDeInicioAntecedeFolgaLista.length === 0) {
			this.errosPossiveis.diaDeInicioAntecedeFolga = false;
		}
		if (this.errosPossiveis.diaDeGozoProibidoFeriadoLista.length === 0) {
			this.errosPossiveis.diaDeGozoProibidoFeriado = false;
		}
		if (this.errosPossiveis.diaDeGozoProibidoAntecedeFeriadoLista.length === 0) {
			this.errosPossiveis.diaDeGozoProibidoAntecedeFeriado = false;
		}
		if (this.errosPossiveis.dobraDeFeriasLista.length === 0) {
			this.errosPossiveis.dobraDeFerias = false;
		}
		if (this.avisosPossiveis.dataAposDobraLista.length === 0) {
			this.avisosPossiveis.dataAposDobra = false;
		}
	}

	faltaPreencherCampoObrigatorio(): boolean {
		let faltaPreencher = false;

		this.entitiesSolicitacoes.forEach((element: any) => {
			if (!faltaPreencher) {
				if ((element.diasvendidos === undefined || element.diasvendidos === null || element.diasvendidos <= 0) && (!this.valorExiste(element.diasferiascoletivas) || !this.valorExiste(element.datainiciogozo))) {
					faltaPreencher = true;
				}
			}
		});

		if (!faltaPreencher) {
			this.dataInicioAntesDataMinima();
			faltaPreencher = this.errosPossiveis.diaDeInicioNaoSelecionavel;
		}

		return faltaPreencher;
	}

	/**
	 * Verificando se há alguma solicitação que possui data de início menor que a data mínima de início para marcar férias
	 */
	dataInicioAntesDataMinima() {

		let dataMenorDataMinima: boolean = false;

		dataMenorDataMinima = this.entitiesSolicitacoes.some((entity) => {
			// só verificar se a data de início é menor que a data mínima se a marcação tiver a situação "a marcar" ou "rascunho"
			return (
				(entity.situacao === '' || entity.situacao === FeriasEmGradeEnum.SITUACAO_RASCUNHO) &&
				entity.tipo_trabalhador !== TipoColaboradorEnum.ESTAGIARIO &&
				moment(entity.datainiciogozo, 'YYYY-MM-DD').isBefore(entity.bloqueiadiasanteriores)
			);
		});

		if (dataMenorDataMinima) {
			this.errosPossiveis.diaDeInicioNaoSelecionavel = true;
			this.errosPossiveisMensagens.diaDeInicioNaoSelecionavel = 'Há marcações com data de início de gozo antes da data mínima permitida.';
		} else {
			this.errosPossiveis.diaDeInicioNaoSelecionavel = false;
		}
	}

	calculaPasso(continua: boolean): void {
		if (continua) {
			this.passo = this.passo + 1;
			this.busy = false;
		} else {
			this.entitiesSolicitacoes = [];
			this.passo = this.passo - 1;
		}
	}

	showModal(selectedColaborador: string): void {
		let draftEntities = this.entitiesSolicitacoes.filter((solicitacao: any) => {
			// filtra para retornar rascunhos e não retornar o colaborador selecionado pois os dados dele já estão no selectedEntity e retornar aqui faria ser duplicado.
			return (
				(solicitacao.situacao === '' || solicitacao.situacao === FeriasEmGradeEnum.SITUACAO_RASCUNHO));
		});
		let entities = this.entityService.entities.filter((entity: any) => {
			// verificar se está no draftEntities ou no selectedColaborador
			return entity.ferias.length > 0 || entity.trabalhador === draftEntities.trabalhador || entity.trabalhador === selectedColaborador;
		});
		this.MeurhCalendarioFeriasModalService.open(entities, draftEntities, selectedColaborador);
	}

	aplicarEmTodos(solicitacaoParaReplicar: IFerias, colunaTabela: 'datainiciogozo' | 'diasferiascoletivas') {

		const trabalhadoresJaAtualizadosDataInicio = new Set<string>();
		const trabalhadoresJaAtualizadosDiasConceder = new Set<string>();

		this.clicouAplicarEmTodos = true;

		this.entitiesSolicitacoes.forEach(feriasExistente => {

			if (
				feriasExistente.situacao !== FeriasEmGradeEnum.SITUACAO_PENDENTE &&
				feriasExistente.situacao !== FeriasEmGradeEnum.SITUACAO_APROVADO &&
				feriasExistente.trabalhador !== solicitacaoParaReplicar.trabalhador
			) {

				if (colunaTabela === 'datainiciogozo') {

					// se já tiver atualizado a data de início de uma marcação desse trabalhador, não atualizar outras marcações desse trabalhador
					if (!trabalhadoresJaAtualizadosDataInicio.has(feriasExistente.trabalhador)) {

						feriasExistente[colunaTabela] = solicitacaoParaReplicar[colunaTabela];
						trabalhadoresJaAtualizadosDataInicio.add(feriasExistente.trabalhador);

						this.calculaDataFimGozoFunc(feriasExistente);
						this.verificaSeDataPassouFunc(feriasExistente.datainiciogozo);
						this.verificaDia(feriasExistente);
					}

				} else {

					// se já tiver atualizado os dias a conceder de uma marcação desse trabalhador, não atualizar outras marcações desse trabalhador
					if (!trabalhadoresJaAtualizadosDiasConceder.has(feriasExistente.trabalhador)) {

						// se o saldo do colaborador for menor que a quantidade de dias de férias a aplicar, usar a quantidade máxima possível
						if (feriasExistente.saldo < solicitacaoParaReplicar[colunaTabela]) {
							feriasExistente.bordaAtencao = true;
							feriasExistente[colunaTabela] = feriasExistente.saldo;
						} else {
							feriasExistente[colunaTabela] = solicitacaoParaReplicar[colunaTabela];
						}

						trabalhadoresJaAtualizadosDiasConceder.add(feriasExistente.trabalhador);

						this.calculaDataFimGozoFunc(feriasExistente);
						this.calculaDiasFunc(feriasExistente);
						this.verificaQtdDiasPeriodoFunc(feriasExistente);

					}

				}

				this.dataInicioAntesDataMinima();

			}

		});

		this.verificaValidadeDadosTela();

	}

	verificaValidadeDadosTela() {

		// mensagens antigas, mais específicas
		// estados de erro
		// this.errosPossiveis.diaDeInicioNaoSelecionavel = false;
		// this.errosPossiveis.diaDeGozoProibidoFeriado = false;
		// this.errosPossiveis.diaDeGozoProibidoAntecedeFeriado = false;
		// this.errosPossiveis.diaDeInicioeFolga = false;
		// this.errosPossiveis.diaDeInicioAntecedeFolga = false;
		// this.errosPossiveis.dobraDeFerias = false;
		// this.errosPossiveis.verificaSobrepostosFunc = false;
		// this.errosPossiveis.calculaDiasFunc = false;
		// this.errosPossiveis.verificaQtdDiasPeriodoFunc = false;
		// this.errosPossiveis.calculaDiasVendidosFunc = false;

		// mensagens de erro
		// this.errosPossiveisMensagens.diaDeInicioNaoSelecionavel = '';
		// this.errosPossiveisMensagens.diaDeGozoProibidoFeriado = '';
		// this.errosPossiveisMensagens.diaDeGozoProibidoAntecedeFeriado = '';
		// this.errosPossiveisMensagens.diaDeInicioeFolga = '';
		// this.errosPossiveisMensagens.diaDeInicioAntecedeFolga = '';
		// this.errosPossiveisMensagens.dobraDeFerias = '';
		// this.errosPossiveisMensagens.verificaSobrepostosFunc = '';
		// this.errosPossiveisMensagens.calculaDiasFunc = '';
		// this.errosPossiveisMensagens.verificaQtdDiasPeriodoFunc = '';
		// this.errosPossiveisMensagens.calculaDiasVendidosFunc = '';

		this.entitiesSolicitacoes.forEach(solicitacaoFerias => {

			if (solicitacaoFerias.situacao === '' || solicitacaoFerias.situacao === -1) {
				// possiveis erros
				solicitacaoFerias.erroDataInicioVazia = false;
				solicitacaoFerias.erroDataInicioMenorDataMinima = false;
				solicitacaoFerias.erroDataInicioFeriado = false;
				solicitacaoFerias.erroDataInicioDoisDiasAntesFeriado = false;
				solicitacaoFerias.erroDataInicioFolga = false;
				solicitacaoFerias.erroDataInicioDoisDiasAntesFolga = false;
				solicitacaoFerias.erroDataInicioAposDataDobra = false;
				solicitacaoFerias.erroDatasSobrepostas = false;
				solicitacaoFerias.erroDiasConcederMaiorQueSaldo = false;
				solicitacaoFerias.erroDiasConcederMenorQueSaldo = false;
				solicitacaoFerias.erroDiasConcederVazio = false;
				solicitacaoFerias.erroQtdDiasPeriodoFunc = false;
				solicitacaoFerias.erroDiasVendidosMaiorQueLimite = false;

				let arrMarcacoesTrabalhador = this.entitiesSolicitacoes.filter(solicitacao => solicitacao.trabalhador === solicitacaoFerias.trabalhador);

				// verificando data de início vazia
				if (!solicitacaoFerias.datainiciogozo) {
					solicitacaoFerias.erroDataInicioVazia = true;
				} else {

					// verificando data de início menor que a data mínima
					if (moment(solicitacaoFerias.datainiciogozo).isBefore(solicitacaoFerias.bloqueiadiasanteriores)) {
						solicitacaoFerias.erroDataInicioMenorDataMinima = true;
					}

					// verificando datas sobrepostas
					// ordena as marcações por data de início
					let arrVerificarDatasSobrepostas = this.entitiesSolicitacoes
						.filter(solicitacao => solicitacao.trabalhador === solicitacaoFerias.trabalhador)
						.slice()
						.sort((a, b) => {
							if (a.datainiciogozo && b.datainiciogozo) {
								return a.datainiciogozo.localeCompare(b.datainiciogozo);
							}
						});

					solicitacaoFerias.erroDatasSobrepostas = arrVerificarDatasSobrepostas.some((solicitacao, i) => {
						// ignora o primeiro item, pois não há anterior para comparar
						if (i === 0) {
							return false;
						}

						const fimAnterior = arrVerificarDatasSobrepostas[i - 1].datafimgozo ? moment(arrVerificarDatasSobrepostas[i - 1].datafimgozo) : null;
						const inicioAtual = arrVerificarDatasSobrepostas[i].datainiciogozo ? moment(arrVerificarDatasSobrepostas[i].datainiciogozo) : null;

						if (!fimAnterior || !inicioAtual) {
							return false;
						}

						// verifica se a marcação atual começa antes do fim da marcação anterior
						return inicioAtual.isSameOrBefore(fimAnterior);
					});

					// verificando feriados
					let umDiaAntesDoFeriado = moment(solicitacaoFerias.datainiciogozo).add(1, 'days').format('YYYY-MM-DD');
					let doisDiasAntesDoFeriado = moment(solicitacaoFerias.datainiciogozo).add(2, 'days').format('YYYY-MM-DD');
					let dataInicioAntesFeriado = this.datasFeriados.filter(function (dataFeriado: any) {
						return (dataFeriado === umDiaAntesDoFeriado) || (dataFeriado === doisDiasAntesDoFeriado);
					});
					let diaDeFeriado = this.datasFeriados.filter(function (dataFeriado: any) {
						return (dataFeriado === solicitacaoFerias.datainiciogozo);
					});

					if (diaDeFeriado.length > 0) {
						solicitacaoFerias.erroDataInicioFeriado = true;
					} else if (dataInicioAntesFeriado.length > 0) {
						solicitacaoFerias.erroDataInicioDoisDiasAntesFeriado = true;
					}

					// verificando folgas
					let diaDaSemana = moment(solicitacaoFerias.datainiciogozo).day();
					if (
						((solicitacaoFerias.folgas?.tipo >= 0 && solicitacaoFerias.folgas?.tipo <= 2) ||
						solicitacaoFerias.folgas.dsrsobredomingoseferiados === true) && diaDaSemana === 0
					) {
						solicitacaoFerias.erroDataInicioFolga = true;
					} else if (solicitacaoFerias.folgas.totalFolgas.includes(diaDaSemana)) {
						solicitacaoFerias.erroDataInicioFolga = true;
					} else if (
						!(solicitacaoFerias.colaboradorhipersuficiente && this.configColaboradorHipersuficiente) &&
						(solicitacaoFerias.folgas.totalFolgas.includes(moment(solicitacaoFerias.datainiciogozo).add(1, 'days').day()) ||
						solicitacaoFerias.folgas.totalFolgas.includes(moment(solicitacaoFerias.datainiciogozo).add(2, 'days').day()) ||
						((solicitacaoFerias.folgas?.tipo >= 0 && solicitacaoFerias.folgas?.tipo <= 2) ||
						solicitacaoFerias.folgas.dsrsobredomingoseferiados === true) &&
						(moment(solicitacaoFerias.datainiciogozo).add(1, 'days').day() === 0 || moment(solicitacaoFerias.datainiciogozo).add(2, 'days').day() === 0))
					) {
						solicitacaoFerias.erroDataInicioDoisDiasAntesFolga = true;
					}

					// verificando data de início após a data de dobra
					if (moment(solicitacaoFerias.datainiciogozo).isAfter(solicitacaoFerias.datalimitegozo)) {
						solicitacaoFerias.erroDataInicioAposDataDobra = true;
					}
				}

				// verificando dias a conceder vazio ou zerado
				if (!solicitacaoFerias.diasferiascoletivas) {
					solicitacaoFerias.erroDiasConcederVazio = true;
				} else {

					// verificando dias a conceder maior que saldo disponível
					const totalDiasFeriasColetivas = arrMarcacoesTrabalhador.reduce((total, item) => {
						return total + (item.diasferiascoletivas || 0) + (item.diasvendidos || 0);
					}, 0);

					// compara o total com a propriedade direito de solicitacaoFerias
					solicitacaoFerias.erroDiasConcederMaiorQueSaldo = totalDiasFeriasColetivas > solicitacaoFerias.direito;

					// verificando se trabalhador tem 3 marcações onde a soma dos dias é menor que seu direito
					if (arrMarcacoesTrabalhador.length === 3) {
						let marcouTodosDiasPeriodos = true;

						arrMarcacoesTrabalhador.forEach((element: any) => {
							if (marcouTodosDiasPeriodos) {
								if (!this.valorExiste(element.diasferiascoletivas) && !this.valorExiste(element.diasvendidos)) {
									marcouTodosDiasPeriodos = false;
								}
							}
						});

						if (marcouTodosDiasPeriodos) {
							solicitacaoFerias.erroDiasConcederMenorQueSaldo = totalDiasFeriasColetivas < solicitacaoFerias.direito;
						}
					}

					// verificando se pelo menos 1 dos períodos tem 14 dias
					// caso as férias sejam particionadas, pelo menos um dos períodos de férias deve ser igual ou maior do que 14 dias (sem considerar os dias vendidos naquele período).
					let possivelPeriodoQuatorzeDias: boolean = false;

					if (arrMarcacoesTrabalhador.length > 1) {
						if (arrMarcacoesTrabalhador.length === 3) {
							arrMarcacoesTrabalhador.forEach((element: any) => {
								if (element.diasferiascoletivas >= 14) {
									possivelPeriodoQuatorzeDias = true;
								}
							});
						}

						if (arrMarcacoesTrabalhador.length === 2) {
							if (((30 - (arrMarcacoesTrabalhador[0].diasferiascoletivas + arrMarcacoesTrabalhador[1].diasferiascoletivas)) >= 14 ||
								(arrMarcacoesTrabalhador[0].diasferiascoletivas >= 14) ||
								(arrMarcacoesTrabalhador[1].diasferiascoletivas) >= 14)) {
								possivelPeriodoQuatorzeDias = true;
							}
						}
					} else {
						possivelPeriodoQuatorzeDias = true;
					}

					if (!possivelPeriodoQuatorzeDias) {
						solicitacaoFerias.erroQtdDiasPeriodoFunc = true;
					}

				}

				// verificando se a quantidade de dias vendidos é maior que o limite
				let totalVendidos = 0;

				arrMarcacoesTrabalhador.forEach((element: IFerias) => {
					if (this.valorExiste(element.diasvendidos)) {
						totalVendidos = totalVendidos + element.diasvendidos;
					}
				});

				solicitacaoFerias.erroDiasVendidosMaiorQueLimite = totalVendidos > solicitacaoFerias.daysVender[solicitacaoFerias.daysVender.length - 1];

			}

			// mensagens antigas, mais específicas
			// verificação de exibição das mensagens de erro
			// if (solicitacaoFerias.erroDataInicioMenorDataMinima) {
			// 	this.errosPossiveis.diaDeInicioNaoSelecionavel = true;
			// 	this.errosPossiveisMensagens.diaDeInicioNaoSelecionavel = 'Há marcações com data de início de gozo antes da data mínima permitida.';
			// }

			// if (solicitacaoFerias.erroDataInicioFeriado) {
			// 	this.errosPossiveis.diaDeGozoProibidoFeriado = true;
			// 	this.errosPossiveisMensagens.diaDeGozoProibidoFeriado = 'O início de gozo não pode ser em dia de feriado.';
			// }

			// if (solicitacaoFerias.erroDataInicioDoisDiasAntesFeriado) {
			// 	this.errosPossiveis.diaDeGozoProibidoAntecedeFeriado = true;
			// 	this.errosPossiveisMensagens.diaDeGozoProibidoAntecedeFeriado = 'O início de gozo não pode ser no período de dois dias que antecedem feriado.';
			// }

			// if (solicitacaoFerias.erroDataInicioFolga) {
			// 	this.errosPossiveis.diaDeInicioeFolga = true;
			// 	this.errosPossiveisMensagens.diaDeInicioeFolga = 'O início de gozo não pode ser um dia de folga.';
			// }

			// if (solicitacaoFerias.erroDataInicioDoisDiasAntesFolga) {
			// 	this.errosPossiveis.diaDeInicioAntecedeFolga = true;
			// 	this.errosPossiveisMensagens.diaDeInicioAntecedeFolga = 'O início de gozo não pode ser no período de dois dias que antecedem folga.';
			// }

			// if (solicitacaoFerias.erroDataInicioAposDataDobra && this.config.bloqueiaDobraDeFerias) {
			// 	this.errosPossiveis.dobraDeFerias = true;
			// 	this.errosPossiveisMensagens.dobraDeFerias = 'O início de gozo selecionado é após a data de dobra.';
			// }

			// if (solicitacaoFerias.erroDatasSobrepostas) {
			// 	this.errosPossiveis.verificaSobrepostosFunc = true;
			// 	this.errosPossiveisMensagens.verificaSobrepostosFunc = 'Os períodos de férias não podem ser sobrepostos.';
			// }

			// if (solicitacaoFerias.erroDiasConcederMaiorQueSaldo) {
			// 	this.errosPossiveis.calculaDiasFunc = true;
			// 	this.errosPossiveisMensagens.calculaDiasFunc = `O total da soma dos dias concedidos ${this.config.bloquearCompraDias ? '' : 'mais os dias vendidos' } de um trabalhador não deve ultrapassar o saldo do mesmo.`;
			// }

			// if (solicitacaoFerias.erroDiasConcederMenorQueSaldo) {
			// 	this.errosPossiveis.calculaDiasTotal = true;
			// 	this.errosPossiveisMensagens.calculaDiasTotal = `O total da soma dos dias concedidos ${this.config.bloquearCompraDias ? '' : 'mais os dias vendidos' } de um trabalhador deve ser igual ao direito (${solicitacaoFerias.direito}) caso todos os três períodos já tenham sido definidos.`;
			// }

			// if (solicitacaoFerias.erroQtdDiasPeriodoFunc) {
			// 	this.errosPossiveis.verificaQtdDiasPeriodoFunc = true;
			// 	this.errosPossiveisMensagens.verificaQtdDiasPeriodoFunc = 'Um dos períodos de férias precisa ser igual ou maior que 14 dias.';
			// }

			// if (solicitacaoFerias.erroDiasVendidosMaiorQueLimite) {
			// 	this.errosPossiveis.calculaDiasVendidosFunc = true;
			// 	this.errosPossiveisMensagens.calculaDiasVendidosFunc = 'O máximo de dias que podem ser vendidos para um trabalhador é ' + solicitacaoFerias.daysVender[solicitacaoFerias.daysVender.length - 1] + '.';
			// }

			this.definirMensagemErroTela();

		});

	}

	exibeBordaErroInput(marcacaoFerias: IFerias, nomeInput: 'datainicio' | 'diasconceder' | 'comprardias') {

		if (nomeInput === 'datainicio') {
			return this.propriedadesErroDataInicio.some(prop => marcacaoFerias[prop] === true);
		} else if (nomeInput === 'diasconceder') {
			return this.propriedadesErroDiasConceder.some(prop => marcacaoFerias[prop] === true);
		} else {
			return this.propriedadesErroComprarDias.some(prop => marcacaoFerias[prop] === true);
		}

	}

	changeExibicaoMarcacoesProblematicas(mostrarMarcacoesProblematicas: boolean) {

		if (mostrarMarcacoesProblematicas) {

			this.marcacoesExibidas = this.entitiesSolicitacoes.filter((marcacao) => {
				if (this.marcacaoComInconsistencia(marcacao)) {
					return marcacao;
				}
			});

		} else {

			this.marcacoesExibidas = this.entitiesSolicitacoes;

		}

	}

	marcacaoComInconsistencia(marcacaoFerias: IFerias) {

		return this.errosPossiveisMarcacao.some(nomeErro => marcacaoFerias[nomeErro] === true);

	}

	algumaMarcacaoComInconsistencia() {

		return this.entitiesSolicitacoes.some((marcacao) => {
			return this.marcacaoComInconsistencia(marcacao);
		});

	}

	getQtdTrabalhadoresDiferentes() {
		const trabalhadoresUnicos = new Set<string>();

		this.entitiesSolicitacoes.forEach(item => {
			trabalhadoresUnicos.add(item.trabalhador);
		});

		return trabalhadoresUnicos.size;
	}

	algumaMarcacaoDiasConcederAjustado() {

		return this.entitiesSolicitacoes.some((marcacao) => {
			return marcacao.bordaAtencao;
		});

	}

	definirMensagemErroTela() {

		const errosIdentificados: { [key: string]: boolean } = {
			datainicio: false,
			diasconceder: false,
			comprardias: false
		};

		for (const marcacao of this.entitiesSolicitacoes) {

			// verifica cada tipo de erro para a marcação atual
			const erroDataInicio = this.exibeBordaErroInput(marcacao, 'datainicio');
			const erroDiasConceder = this.exibeBordaErroInput(marcacao, 'diasconceder');
			const erroComprarDias = this.exibeBordaErroInput(marcacao, 'comprardias');

			// verificando erro em cada campo
			if (erroDataInicio && (marcacao.datainiciogozo || this.clicouAplicarEmTodos)) {
				errosIdentificados.datainicio = true;
			}

			if (erroDiasConceder && (marcacao.diasferiascoletivas || this.clicouAplicarEmTodos)) {
				errosIdentificados.diasconceder = true;
			}

			if (erroComprarDias) {
				errosIdentificados.comprardias = true;
			}

			// se todos os erros forem encontrados, para a execução
			if (errosIdentificados.datainicio && errosIdentificados.diasconceder && errosIdentificados.comprardias) {
				break;
			}
		}

		if (errosIdentificados.datainicio || errosIdentificados.diasconceder || errosIdentificados.comprardias) {

			// define mensagens específicas com base nos erros identificados
			this.mensagemErroTela = this.obterMensagemErros(errosIdentificados);

		} else {
			this.mensagemErroTela = '';
		}

	}

	obterMensagemErros(errosIdentificados: { [key: string]: boolean }): string {

		const errosAtivos = Object.keys(errosIdentificados).filter(key => errosIdentificados[key]);

		// se todos os três erros estiverem presentes
		if (errosAtivos.length === 3) {
		  return 'Alguns colaboradores apresentam inconsistências no "início de gozo", nos "dias a conceder" e no "comprar dias".';

		} else if (errosAtivos.includes('datainicio') && errosAtivos.includes('diasconceder')) {
		  return 'Alguns colaboradores apresentam inconsistências no "início de gozo" e nos "dias a conceder".';

		} else if (errosAtivos.includes('datainicio') && errosAtivos.includes('comprardias')) {
		  return 'Alguns colaboradores apresentam inconsistências no "início de gozo" e no "comprar dias".';

		} else if (errosAtivos.includes('diasconceder') && errosAtivos.includes('comprardias')) {
		  return 'Alguns colaboradores apresentam inconsistências nos "dias a conceder" e no "comprar dias".';

		} else if (errosAtivos.includes('datainicio')) {
		  return 'Alguns colaboradores apresentam inconsistências no "início de gozo".';

		} else if (errosAtivos.includes('diasconceder')) {
		  return 'Alguns colaboradores apresentam inconsistências nos "dias a conceder".';

		} else if (errosAtivos.includes('comprardias')) {
		  return 'Alguns colaboradores apresentam inconsistências no "comprar dias".';

		} else {
		  return '';
		}
	}

	/*
	******
	*************
	*******************
	************ Geral ***
	*******************
	*************
	******
	*/
	valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}

	getSolicitacoesTrabalhador(entity: IFerias): Array<IFerias> {
		return this.entitiesSolicitacoes.filter((element: any): boolean => element.trabalhador === entity.trabalhador);
	}
}
