import angular from 'angular';
import { RescisaoPontoService } from './rescisao.ponto.service';
import { ModalConfirmService } from '../../../../shared/components/modal-confirm/modal.confirm.service';
import { IEtapaPontoRescisao, ISaldoPontoRescisao } from './rescisao.ponto.model';

type Step = {
    etapa: number,
    etapadetalhe: 1 | 2 | 3;
};

export class RescisaoPontoController {

    static $inject = [
        '$scope',
        '$rootScope',
        'RescisaoPontoService',
        'ModalConfirmService',
        'NewToaster',
    ];

    public id: string;
    public currentStep: number;
    public hideActions: boolean;

    public step: Step;
    public stepHistory: {
        etapa_1: IEtapaPontoRescisao | null,
        etapa_2: IEtapaPontoRescisao | null,
        etapa_3: IEtapaPontoRescisao | null,
        etapa_4: IEtapaPontoRescisao | null,
        etapa_5: IEtapaPontoRescisao | null,
        etapa_6: IEtapaPontoRescisao | null,
    };
    public label: {
        class: string;
        content: string
    };
    public tag = {
        ajustes: 0,
        inconsistencias: 0,
    };

    public isBusy = false;
    public isBusyBtn = false;

    constructor(
        public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            nsjGlobals: { a: { url_pontoweb: string } }
        },
        public RescisaoPontoService: RescisaoPontoService,
        public ModalConfirmService: ModalConfirmService,
        public NewToaster: { pop: Function },
    ) {
        this.$scope.$watch('$ctrl.step', (newValue: Step, oldValue: Step) => {
            if (newValue !== oldValue) {
                this.currentStep = newValue.etapa;
                this.label = this.setLabel(newValue.etapa);
                if (newValue.etapa > 0 && newValue.etapa < 3) {
                    this.RescisaoPontoService.getSaldo(this.id);
                }
            }
        }, true);
        this.$scope.$on('meurh_solicitacoesrescisaoetapas_busca_loaded', (event, args: IEtapaPontoRescisao[]) => {
            this.isBusy = false;
            if (Array.isArray(args) && args[0]) {
                this.stepHistory = this.setStepHistory(args);
                this.step = {
                    etapa: args[0].etapa,
                    etapadetalhe: args[0].etapadetalhe,
                };
            }
        });
        this.$scope.$on('meurh_solicitacoesrescisaoetapas_insert_submitted', (event, args: IEtapaPontoRescisao) => {
            this.isBusyBtn = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Tratamento solicitado com sucesso.'
            });
            this.$rootScope.$broadcast('rescisao_ponto_etapa_updated', args);

            if (args && !this.isEmptyObj(args)) {
                this.stepHistory['etapa_' + args.etapa] = args;
                this.step = {
                    etapa: args.etapa,
                    etapadetalhe: args.etapadetalhe,
                };
            }
        });
        this.$scope.$on('meurh_solicitacoesrescisaoetapas_insert_submit_error', () => {
            this.isBusyBtn = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao solicitar tratamento.'
            });
        });
        this.$scope.$on('meurh_solicitacoesrescisaoetapas_saldo_loaded', (event, args: ISaldoPontoRescisao) => {
            this.tag = {
                ajustes: args['qtd_ajustes'],
                inconsistencias: args['qtd_inconsistencias'],
            };
        });
    }

    $onInit() {
        this.isBusy = true;
        this.RescisaoPontoService.getEtapa(this.id);
    }

    solicitarTratamento(resend: boolean = false) {
        this.isBusyBtn = true;
        this.RescisaoPontoService.setEtapa(this.id, {
            etapa: 2,
            etapadetalhe: resend ? 2 : 1
        });
    }

    desbloquearParaAlteracoes() {
        const text = {
            title: 'Desbloquear para alterações',
            confirmText: `Esta ação irá desfazer as últimas 3 etapas,
                permitindo que os responsáveis do ponto façam as alterações necessárias.
                Você tem certeza que deseja desbloquear o ponto para alterações?`,
            closeButton: 'Cancelar',
            confirmButton: 'Desbloquear para alterações',
        };
		this.ModalConfirmService.open(null, 'Rescisão', false, text)
            .result.then(() => {
				this.isBusyBtn = true;
                this.RescisaoPontoService.setEtapa(this.id, {
                    etapa: 2,
                    etapadetalhe: 3
                });
			})
			.catch(() => {/**/});
    }

    private setStepHistory(stepList: IEtapaPontoRescisao[]) {
        const etapa_1 = stepList.find(step => step.etapa === 1);
        const etapa_2 = stepList.find(step => step.etapa === 2);
        const etapa_3 = stepList.find(step => step.etapa === 3);
        const etapa_4 = stepList.find(step => step.etapa === 4);
        const etapa_5 = stepList.find(step => step.etapa === 5);
        const etapa_6 = stepList.find(step => step.etapa === 6);
        return {
            etapa_1: etapa_1 ? etapa_1 : null,
            etapa_2: etapa_2 ? etapa_2 : null,
            etapa_3: etapa_3 ? etapa_3 : null,
            etapa_4: etapa_4 ? etapa_4 : null,
            etapa_5: etapa_5 ? etapa_5 : null,
            etapa_6: etapa_6 ? etapa_6 : null,
        };
    }

    private setLabel(step: number) {
        switch (step) {
            case 2:
                return {
                    class: 'label-info',
                    content: 'Tratamento solicitado'
                };
            case 6:
                return {
                    class: 'label-success',
                    content: 'Tarefas concluídas'
                };
            default:
                return {
                    class: 'label-warning',
                    content: 'Tarefas pendentes'
                };
        }
    }

    private isEmptyObj(obj: object) {
        return Object.keys(obj).length === 0;
    }
}
