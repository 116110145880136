import angular from 'angular';
import { MeurhSolicitacoesgenericasOutrosDadosFormController } from './outrosdados.form.controller';

export class MeurhSolicitacoesgenericasOutrosDadosFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesgenericasOutrosdadosFormComponent';
	static controller = MeurhSolicitacoesgenericasOutrosDadosFormController;
	static template = require('!!html-loader!./outrosdados.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		action: '<',
	};
}
