import ng from 'angular';

export class MeurhSolicitacoesgenericasModalConfirmController implements ng.IController {

    static $inject = [
        'entity',
        'textos',
        '$uibModalInstance'
    ];

    constructor(
        public entity: any,
        public textos: any,
        private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
    ) {}

    public confirmar() {
        this.$uibModalInstance.close(this.entity);
    }

    public close() {
        this.$uibModalInstance.dismiss('cancelar');
    }

}
