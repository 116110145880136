import { ITrabalhador } from '../../Trabalhadores/models/trabalhador.model';
import { TrabalhadoresService } from '../../Trabalhadores/trabalhadores.service';
import { IApontamentoTrabalhador } from '../models/apontamentostrabalhadores.model';

export class ApontamentosTrabalhadoresFormController {
    static $inject = ['$scope', '$element', '$attrs', 'NewToaster', '$timeout', '$rootScope', 'TrabalhadoresService', 'BuscaAvancadaService', '$stateParams'];

    public tipo: any;
    public tipoInicial: any = this.$rootScope.tipo;
    public dataPagamentoConfig: any = this.$rootScope.nsjGlobals.a.configuracoes.MOVIMENTO_DATAPAGAMENTO ? true : false;
    public trabalhadorinput: any = '';
    public entitiesTrabalhadores: Array<ITrabalhador>;
    public constructorSearchList: any = {};
    public entity: IApontamentoTrabalhador;
    public form: angular.IFormController;
    public visualizacao: boolean;
    public session = this.$rootScope.session;
    public descricoessituacao: any = [
        {
            situacao: -1,
            descricao: 'Rascunho'
        },
        {
            situacao: 0,
            descricao: 'Pendente'
        },
        {
            situacao: 1,
            descricao: 'Aprovado'
        },
        {
            situacao: 2,
            descricao: 'Rejeitado'
        },
        {
            situacao: 3,
            descricao: 'Calculado'
        }
    ];

    constructor(
        public $scope: angular.IScope,
        public $element: any,
        public $attrs: any,
        public NewToaster: any,
        public $timeout: any,
        public $rootScope: angular.IRootScopeService & { modoGestorPermissao: (arg: string) => boolean, session: any, nsjGlobals: any, tipo: any },
        public trabalhadoresService: TrabalhadoresService,
        public BuscaAvancadaService: any,
        public $stateParams: any
    ) {
        if ($rootScope.session.entities.estabelecimento) {
            trabalhadoresService.constructors.estabelecimento = $rootScope.session.entities.estabelecimento.estabelecimento;
        }

        trabalhadoresService.constructors.tipo = this.tipo = this.tipoInicial;
        trabalhadoresService.constructors['forcagestor'] = ($rootScope.modoGestorPermissao('registrar_apontamento') || $rootScope.modoGestorPermissao('registrar_apontamento_grade'));
        trabalhadoresService.constructors['status'] = '00';

        this.entitiesTrabalhadores = trabalhadoresService.reload();

        $rootScope.$on('persona_trabalhadores_list_finished', (event: any, args: any) => {
            this.entitiesTrabalhadores = [...args];
        });

        $scope.$watch('$ctrl.entity', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.form.$setDirty();
            }
        }, true);
    }

    $onInit() {
        // se movimento já estiver salvo, precisa formatar rubrica para exibir código e nome corretamente
        if (this.entity?.movimentos?.length > 0) {
            this.entity.movimentos.forEach(movimento => {
                movimento.evento.codigo_nome = movimento.evento.codigo + ' - ' + movimento.evento.nome;
            });
        }
    }

    buscaAvancada() {
        this.trabalhadoresService.constructors.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
        this.trabalhadoresService.constructors.tipo = this.tipo;
        delete this.trabalhadoresService.constructors.trabalhador;

        let columns = ['Nome', 'Tipo', 'Cargo', 'Departamento', 'Admissão'];
        let values = ['nome', 'tipo', 'cargo', 'departamento', 'dataadmissao'];
        let enumValues = [];
        enumValues['nome'] = '';
        enumValues['tipo'] = 'lookupTipoTrabalhador';
        enumValues['cargo'] = '';
        enumValues['departamento'] = '';
        enumValues['dataadmissao'] = 'date';
        this.trabalhadoresService.constructors['forcagestor'] = (this.$rootScope.modoGestorPermissao('registrar_apontamento') || this.$rootScope.modoGestorPermissao('registrar_apontamento_grade'));
        this.trabalhadoresService.constructors['status'] = '00';
        let modal = this.BuscaAvancadaService.open(self, this.entity, this.trabalhadoresService, columns, values, 'Colaboradores', enumValues, 'trabalhador', false, true, false, null);
        modal.result.then(() => {/* */ }).catch(() => {/* */ });
    }

    evento(event: angular.IAngularEvent & { detail: any }) {
        this.constructorSearchList = {
            'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento,
            'tipo': this.tipo,
            'status': '00',
            'forcagestor': (this.$rootScope.modoGestorPermissao('registrar_apontamento') || this.$rootScope.modoGestorPermissao('registrar_apontamento_grade'))
        };
        let filter: any = {};
        filter.search = event.detail.value;
        this.trabalhadoresService.constructors = this.constructorSearchList;
        this.trabalhadoresService.filter = event.detail;
        this.trabalhadoresService.search(filter);
    }

    eventoTipo() {
        this.constructorSearchList = {
            'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento,
            'tipo': this.tipo,
            'status': '00',
            'forcagestor': (this.$rootScope.modoGestorPermissao('registrar_apontamento') || this.$rootScope.modoGestorPermissao('registrar_apontamento_grade'))
        };
        this.trabalhadoresService.constructors = this.constructorSearchList;
        this.entitiesTrabalhadores = this.trabalhadoresService.reload();
    }

    retornaListagemCombo() {
        return this.entitiesTrabalhadores;
    }

    comboBusy() {
        return this.trabalhadoresService.loadParams.busy;
    }

    selecionaTrabalhador(event: angular.IAngularEvent & { detail: any }) {
        if (event) {
            this.entity.trabalhador = event.detail.value;
        }
        let filter: any = {};
        filter.search = '';
        this.trabalhadoresService.search(filter);
    }

    limpaTrabalhador() {
        delete this.entity.trabalhador;
    }

    possuiMovimentosPendentos(subentity: any) {
        let movimentosPendentes = false;
        if (subentity.movimentos) {
            subentity.movimentos.forEach((movimento: any, key: any) => {
                if (movimento.situacao !== -1) {
                    movimentosPendentes = true;
                }
            });
        }
        return movimentosPendentes;
    }

    selectDtype(subentity: any, init: boolean = false) {
        if (!init) {
            subentity.conteudo = null;
        }
        subentity.dynamicType = this.testeRubrica(subentity);
        if (subentity.dynamicType === 'interval' && subentity.conteudo) {
            subentity.conteudo = Number(subentity.conteudo);
        }
        if (subentity.situacao == null) {
            subentity.situacao = -1;
        }
        // coloca a lotacao do colaborador por padrao ao adicionar um movimento
        if ((subentity.lotacao === undefined) &&
            (this.entity.trabalhador && typeof this.entity.trabalhador.lotacao === 'object')) {
            subentity.lotacao = this.entity.trabalhador.lotacao;
        } else if ((subentity.lotacao === undefined) &&
            (this.entity.trabalhador && this.entity.trabalhador.lotacaoobj)) {
            subentity.lotacao = this.entity.trabalhador.lotacaoobj;
        }
        // coloca a data de pagamento com a data atual por padrao ao adicionar um movimento
        if (subentity.datapagamento === undefined) {
            subentity.datapagamento = new Date();
        }
        // inicializando referencia com string vazia caso não tenha valor
        if (!subentity.referencia) {
            subentity.referencia = '';
        }
    }

    forcaGestor() {
        return this.$rootScope.modoGestorPermissao('registrar_apontamento');
    }

    validarValorRubrica(rubrica: any) {
        // o ngChange é chamado antes que a model seja atualizada, por isso o uso do timeout
        this.$timeout(() => {
            if (rubrica.conteudo < 0) {
                rubrica.conteudo = 0;
            }
            let limiteerro = rubrica.evento.limiteerro;
            let limitealerta = rubrica.evento.limitealerta;
            let nome = rubrica.evento.nome;
            if (limiteerro > 0) {
                if (rubrica.conteudo > limiteerro) {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Rubrica: ' + nome,
                        body: 'O valor máximo permitido para esta rubrica é ' + limiteerro + '.'
                    });
                    return;
                }
            }
            if (limitealerta > 0) {
                if (rubrica.conteudo > limitealerta) {
                    this.NewToaster.pop({
                        type: 'warning',
                        title: 'Rubrica: ' + nome,
                        body: 'O valor inserido é o maior que o limite de ' + limitealerta + '.'
                    });
                }
            }
        }, 0);
    }

    testeRubrica(rubrica: any) {
        let tipo;
        if (rubrica.evento) {
            if (rubrica.evento.evento_unidade != null) {
                tipo = rubrica.evento.evento_unidade;
            }
            if (rubrica.evento.unidade && typeof rubrica.evento.unidade !== 'undefined') {
                tipo = rubrica.evento.unidade;
            }
        }

        switch (tipo) {
            case 0:
            case 8:
            case 10:
                return 'interval';
            case 1:
                return 'number';
            case 2:
                return 'currency';
            default:
                return 'number-only-short';
        }
    }
}
