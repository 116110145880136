import angular = require('angular');
import { LoadParams } from '../../../shared/models/loadparams.model';

export class MeurhTipossolicitacoesgenericasService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public loading_deferred_filter: any = null;

    public entity: any;
    public entities: Array<any> = [];

    public loaded: boolean = false;
    public constructors: any = {};
    public after: any = '';
    public filters: any = {};
    public loadParams: LoadParams;
    public loading_deferred: any = null;
    public filter: string = '';

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IScope,
        public $q: angular.IQService
    ) {
        this.loadParams = new LoadParams();
        this.reload = this.reload.bind(this);
    }

    reload(): Array<any> {
        if (this.loading_deferred) {
            this.loading_deferred.resolve();
        }

        this.loadParams.finished = false;
        this.loadParams.to_load = 3;
        this.after = {};
        this.loaded = false;
        this.entities.length = 0;
        this.loadParams.busy = false;

        return this.load();
    }

    search(filter: any): Array<any> | angular.IHttpPromise<any> {
        let filters = {};
        let search = '';

        if (typeof filter !== 'undefined' && filter !== null) {
            this.filter = filter.search;

            Object.keys(this.filters).forEach((k: any): void => {
                delete this.filters[k];
            });

            for (let fil in filter.filters) {
                if (fil.indexOf('.') > -1) {
                    let filterSplit = fil.split('.');

                    this.filters[filterSplit[0]] = {};
                    this.filters[filterSplit[0]][filterSplit[1]] = filter.filters[fil];
                } else {
                    this.filters[fil] = filter.filters[fil];
                }
            }
        } else if (filter === null) {
            if (this.loading_deferred_filter) {
                this.loading_deferred_filter.resolve();
            }

            return this._load(this.constructors, {}, '');
        }

        if (filter.lookup) {
            if (this.loading_deferred_filter) {
                this.loading_deferred_filter.resolve();
            }

            return this._load(this.constructors, {}, search);
        } else {
            this.filter = search;
            this.filters = filters;

            return this.reload();
        }

    }

    _load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http({
                method: 'GET',
                url: this.nsjRouting.generate('meurh_tipossolicitacoesgenericas_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
                timeout: this.loading_deferred.promise
            })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((response: any) => {
                reject(response);
            });
        });
    }

    load(): Array<any> {
        if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
            this.loadParams.busy = true;

            this._load(this.constructors, this.after, this.filter)
                .then((data: any): void => {
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            this.entities.push(data[i]);
                        }

                        this.after['tiposolicitacaogenerica'] = this.entities[this.entities.length - 1]['tiposolicitacaogenerica'];
                    }

                    if (data.length < 20) {
                        this.loadParams.finished = true;
                        this.$rootScope.$broadcast('meurh_tipossolicitacoesgenericas_list_finished', this.entities);
                    } else {
                        this.loadParams.finished = false;
                    }

                    this.loaded = true;
                    this.loadParams.to_load--;
                })
                .catch((error): void => {
                    if (error.xhrStatus !== 'abort') {
                        this.loadParams.finished = true;
                    }
                })
                .finally((): void => {
                    if (this.loaded || this.loadParams.finished) {
                        this.loadParams.busy = false;
                    }

                    this.load();
                });
        }

        return this.entities;
    }

    loadMore(): void {
        this.loadParams.to_load = 3;
        this.load();
    }

    find(identifier: string): any | null {
        for (let i in this.entities) {
            if (this.entities[i]['tiposolicitacaogenerica'] === identifier) {
                return this.entities[i];
            }
        }

        return null;
    }

    get(identifier: string): angular.IHttpPromise<any> {
        this.loading_deferred = this.$q.defer();

        return this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
            this.$http.get(
                this.nsjRouting.generate('meurh_tipossolicitacoesgenericas_get', { 'id': identifier }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response: any): void => {
                this.$rootScope.$broadcast('meurh_tipossolicitacoesgenericas_loaded', response.data);
                resolve(response.data);
            })
            .catch(function (response: any): void {
                reject(response);
            });
        });
    }
}
