import angular from 'angular';
import { CargosService } from '../../../Cargos/cargos.service';
import { ITrabalhador } from 'modules/Trabalhadores/models/trabalhador.model';
import { MeurhSolicitacoespromocoesService } from '../promocoes.service';
import { SituacaoEnum } from '../../Relatoriossolicitacoes/enums/situacaoEnum';

export class MeurhPromocoesPassodoisController implements angular.IController {

    static $inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$http',
        '$stateParams',
        '$location',
        '$transitions',
        'nsjRouting',
        'NewToaster',
        '$sce',
        'CargosService',
        'ModalConfirmService',
        '$q',
        'MeurhSolicitacoespromocoesService',
    ];

    public passo: any = 2;
    public mensagensPassos: any = {};
    public entity: ITrabalhador;
    public piso: number = 0;
    public mensagemPiso: string = '';
    public nivelatual: any = [];
    public configComSalario: any = {'codigo' : 'Código', 'valorsalario' : 'Salário'};
    public configSemSalario: any = {'codigo' : 'Código'};
    public nivelsalario: any = 0;

    public cargoatual: any = [];
    public constructor_: any = {};
    public trabalhador: string = this.$stateParams['trabalhador'];
    public errosPossiveis: any = {
        aumentoSalarialMenorNeg: false
    };
    public errosPossiveisMensagens: any = {
        aumentoSalarialMenorNeg: ''
    };
    public erroPreenchimento: boolean = false;
    public allowRefresh: boolean = false;

    public unidadeSalarioNovoSelect: Array<any>;
    public unidadeSalarioAntigoConvert: string;

    public carregandoPromocoesAnteriores: boolean = false;
    public promocoesAnterioresTrabalhador: any = [];
    public exibeAvisoPromocoesAnteriores: boolean = false;

    constructor(
        public $rootScope: angular.IRootScopeService & { modoGestorPermissao: (arg: string) => boolean, temPermissao: (arg: string) => boolean  },
        public $scope: angular.IScope,
        public $state: angular.ui.IStateService,
        public $http: angular.IHttpService,
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public $transitions: any,
        public nsjRouting: any,
        public NewToaster: any,
        public $sce: angular.ISCEService,
        public CargosService: CargosService,
        public ModalConfirmService: any,
        public $q: any,
        public MeurhSolicitacoespromocoesService: MeurhSolicitacoespromocoesService,
    ) {
        this.exitScreenDescartarModal();
        document.onkeydown = this.refreshDescartarModal;
        this.allowRefresh = true;

        $scope.$on('meurh_solicitacoespromocoes_loaded', (event: angular.IAngularEvent, args: any): void => {

            if (args.length > 0) {
                // obtendo apenas as promoções nos status que devem ser considerados
                this.promocoesAnterioresTrabalhador = args.filter((promocao) => {
                    if (promocao.situacao === SituacaoEnum.ABERTA || promocao.situacao === SituacaoEnum.ENVIADA || promocao.situacao === SituacaoEnum.EFETUADA) {
                        return promocao;
                    }
                });
            }
            this.carregandoPromocoesAnteriores = false;
        });

        $scope.$watch('$ctrl.entity.salarionovo', (newValue: any, oldValue) => {
            if (newValue !== oldValue && newValue !== null && newValue !== NaN) {
                this.verificaPromocaoAnteriorMesmoSalario(parseFloat(newValue));
            }
        }, true);

     }

    $onInit(): void {
        this.alteraEntity();
        this.constructor_ = this.verificacaoCargo();
        this.carregandoPromocoesAnteriores = true;
        this.buscaPromocoesColaborador(this.entity.trabalhador);
    }

    alteraEntity(): void {
        if (this.entity.solicitacao === undefined) {
            if (
                this.valorExiste(this.entity.cargo) && this.valorExiste(this.entity.nivelcargo) &&
                !this.valorExiste(this.entity.cargoantigo) && !this.valorExiste(this.entity.nivelcargoantigo)
            ) {
                this.entity.cargoantigo = this.entity.cargo;
                if (!this.entity.nivelcargoAux) {
                    this.entity.nivelcargoAux = this.entity.nivelcargo;
                }
                this.entity.nivelcargoantigo = this.entity.nivelcargoAux;
            }
        } else {
            this.calculaAumentoSalarial();
        }

        this.cargoatual.push({ 'cargo': this.entity.cargoantigo });
        this.nivelatual.push({ 'nivelcargo': this.entity.nivelcargoantigo });

        if (!this.valorExiste(this.entity.salarionovo)) {
            switch (this.entity.tipopromocao) {
                case 0:
                    this.entity.cargo = null;
                    this.entity.nivelcargo = null;
                    break;

                case 1:
                    this.entity.nivelcargo = null;
                    break;
            }
        }

        this.entity.tipoaumentosalarial = '0';
        this.verificaCargoParaPiso();

        this.entity.unidadesalarioantigo = this.entity.unidadesalariofixo;
        this.entity.descricaounidadesalarioantigo = this.entity.descricaosalariovariavel;
        this.converteUnidadeSalario();
    }

    converteUnidadeSalario(): void {
        const unidadesSalario = {
            1: 'Hora',
            2: 'Dia',
            3: 'Semana',
            4: 'Mês',
            5: 'Tarefa',
            6: 'Variável'
        };

        this.unidadeSalarioAntigoConvert = unidadesSalario[this.entity.unidadesalarioantigo];
        this.unidadeSalarioNovoSelect = Object.keys(unidadesSalario).map(valor => ({
            nomeUnidade: unidadesSalario[valor],
            valor: parseInt(valor, 10)
        }));
    }

    limpaDescricaoSalario(): void {
        this.entity.descricaounidadesalarionovo = null;
    }

    // exclusivo tipo promocao 0 = mudança cargo&nível
    alteraNivelPorCargo(): void {
        if (this.entity.cargo) {
            this.$rootScope.$broadcast('nivelcargo_refresh', { cargo: this.entity.cargo.cargo });
            this.entity.nivelcargo = null;
        }

        this.verificaCargoParaPiso();
    }

    // exclusivo tipo promocao 0 = mudança cargo&nível
    changeCargo(): void {
        this.alteraNivelPorCargo();
        this.constructor_ = this.verificacaoCargo();
        this.montaMensagemPasso();
    }

    // exclusivo tipo promocao 0 e 1 = mudança cargo&nível, mudança nível
    changeNivel(): void {
        if (this.valorExiste(this.entity.nivelcargo)) {
            this.entity.salarionovo = this.entity.nivelcargo.valorsalario ? this.entity.nivelcargo.valorsalario : 0;
            this.nivelsalario = this.entity.nivelcargo.valorsalario ? this.entity.nivelcargo.valorsalario : 0;
            // preencher valores para montar entidade solicitação
            this.entity.tipoaumentosalarial = 0;
            this.entity.percentual = this.calculaPercentual();

            // verificar condição para prosseguir para passo três
            this.verificaAumentoSalarial();
            this.montaMensagemPasso();
        }
    }

    // exclusivo tipo promocao 2 = aumento salarial
    changeTipoAumentoSalarial(): void {
        this.entity.aumentosalarial = null;
        this.entity.percentual = null;
        this.entity.salarionovo = null;
    }

    // exclusivo tipo promocao 2 = aumento salarial
    changeAumentoSalarialValor(): void {
        this.entity.salariofixo = parseFloat(this.entity.salariofixo);
        this.entity.aumentosalarial = parseFloat(this.entity.aumentosalarial);

        if (isNaN(this.entity.salariofixo) || isNaN(this.entity.aumentosalarial) || (this.entity.aumentosalarial < 0)) {
            this.entity.percentual = null;
            this.entity.salarionovo = null;
        } else {
            this.entity.percentual = this.calculaPercentual();
            this.entity.salarionovo = this.entity.salariofixo + this.entity.aumentosalarial;
            this.montaMensagemPasso();
        }

        this.verificaAumentoSalarial(this.entity.aumentosalarial);
    }

    // explusivo tipo promocao 2 = aumento salarial
    changeAumentoSalarialPercentual(): void {
        this.entity.salariofixo = parseFloat(this.entity.salariofixo);
        this.entity.percentual = parseFloat(this.entity.percentualCampo) * 100;

        if (isNaN(this.entity.salariofixo) || isNaN(this.entity.aumentosalarial) || (this.entity.aumentosalarial < 0)) {
            this.entity.percentual = null;
            this.entity.percentualCampo = null;
            this.entity.salarionovo = null;
        } else {
            this.entity.aumentosalarial = (this.entity.salariofixo * this.entity.percentual) / 100;
            this.entity.salarionovo = this.entity.salariofixo + this.entity.aumentosalarial;
        }

        this.verificaAumentoSalarial(this.entity.percentual);
    }

    montaMensagemPasso(): void {
        switch (this.entity.tipopromocao) {
            case 0:
                if (this.valorExiste(this.entity.cargo) && this.valorExiste(this.entity.nivelcargo)) {
                    this.mensagensPassos['mensagempasso2'] = this.entity.nome + ' passará de ' + this.entity.cargoantigo.nome + ' ' + this.entity.nivelcargoantigo.codigo + ' para ' + this.entity.cargo.nome + ' ' + this.entity.nivelcargo.codigo;
                }

                break;
            case 1:
                if (this.valorExiste(this.entity.nivelcargo)) {
                    this.mensagensPassos['mensagempasso2'] = this.entity.nome + ' passará de ' + this.entity.cargoantigo.nome + ' ' + this.entity.nivelcargoantigo.codigo + ' para ' + this.entity.cargoantigo.nome + ' ' + this.entity.nivelcargo.codigo;
                }

                break;
            case 2:
                if (this.valorExiste(this.entity.salarionovo) && this.valorExiste(this.entity.aumentosalarial)) {
                    this.mensagensPassos['mensagempasso2'] = '';
                }

                break;
        }
    }

    calculaPercentual(): number {
        if (!this.valorExiste(this.entity.aumentosalarial)) {
            this.entity.aumentosalarial = this.entity.salarionovo - this.entity.salariofixo;
        }

        return (this.entity.aumentosalarial * 100) / this.entity.salariofixo;
    }

    calculaAumentoSalarial(): void {
        if (this.valorExiste(this.entity.salarionovo)) {
            this.entity.aumentosalarial = this.entity.salarionovo - this.entity.salariofixo;
            this.entity.percentual = this.calculaPercentual();
        }
    }

    verificacaoCargo(): { cargo: string, empresa: string, estabelecimento: string } {
        const params = {
            empresa: this.entity.empresa.empresa,
            estabelecimento: this.entity.estabelecimento.estabelecimento
        };
        if (this.entity.cargo) {
            if (this.entity.cargo.cargo) {
                return {
                    cargo: this.entity.cargo.cargo,
                    ...params
                };
            }
        }

        return {
            cargo: this.entity.cargoantigo.cargo,
            ...params
        };
    }

    verificaPiso(): void {
        this.entity.cargo.pisominimo = this.valorExiste(this.entity.cargo.pisominimo) ? this.entity.cargo.pisominimo : 0;
        this.entity.sindicatocargopisosalarial = this.valorExiste(this.entity.sindicatocargopisosalarial) ? this.entity.sindicatocargopisosalarial : 0;

        if (this.entity.cargo.pisominimo > this.entity.sindicatocargopisosalarial) {
            this.piso = this.entity.cargo.pisominimo;
            this.mensagemPiso = 'O salário não pode ser menor que o piso do Cargo: ';
        } else {
            this.piso = this.entity.sindicatocargopisosalarial;
            this.mensagemPiso = 'O salário não pode ser menor que o piso Sindical: ';
        }
    }

    verificaCargoParaPiso(): void {
        if (this.valorExiste(this.entity.cargo)) {
            if (this.entity.cargo.pisominimo) {
                this.verificaPiso();
                return;
            }

            this.CargosService.get(this.entity.cargo.cargo)
                .then((response: any): void => {
                    let cargoCompleto = response;
                    this.entity.cargo.pisominimo = cargoCompleto.pisominimo;
                    this.entity.sindicatocargopisosalarial = cargoCompleto.sindicato ? cargoCompleto.sindicato.pisosalarial : null;

                    this.verificaPiso();
                }).catch((e: any): void => {
                    console.log(e);
                });
        }
    }

    verificaAumentoSalarial(valor: any = null): void {
        if (((valor && (valor.toString() === '0')) ||
            (parseFloat(this.entity.salarionovo) < parseFloat(this.entity.salariofixo))) &&
            (this.$rootScope.temPermissao('visualizar_salarios') && !this.$rootScope.modoGestorPermissao('visualizar_funcionarios'))
        ) {
            this.errosPossiveis.aumentoSalarialMenorNeg = true;
            this.errosPossiveisMensagens.aumentoSalarialMenorNeg = 'O novo salário não pode ser menor que o antigo';
        } else {
            this.errosPossiveis.aumentoSalarialMenorNeg = false;
        }
        this.verificaArrayErros();
    }

    verificaArrayErros() {
        let existeErro = false;

        for (let i in this.errosPossiveis) {
            if (this.errosPossiveis[i] === true) {
                existeErro = true;
                break;
            }
        }

        this.erroPreenchimento = existeErro;
    }

    formInvalido(tipopromocao: number): boolean {
        switch (tipopromocao) {
            case 0:
                return (
                    (
                        !this.valorExiste(this.entity.cargo) ||
                        !this.valorExiste(this.entity.nivelcargo) ||
                        !this.valorExiste(this.entity.salarionovo) ||
                        !this.valorExiste(this.entity.descricaopromocao) ||
                        !this.valorExiste(this.entity.unidadesalarionovo)
                    ) ||
                    this.entity.salarionovo < this.nivelsalario ||
                    (
                        this.entity.unidadesalarionovo === 5 || this.entity.unidadesalarionovo === 6 ?
                        !this.valorExiste(this.entity.descricaounidadesalarionovo) : false
                    )
                );
            case 1:
                return (
                    (
                        !this.valorExiste(this.entity.nivelcargo) ||
                        !this.valorExiste(this.entity.salarionovo) ||
                        !this.valorExiste(this.entity.descricaopromocao) ||
                        !this.valorExiste(this.entity.unidadesalarionovo)
                    ) ||
                    this.entity.salarionovo < this.nivelsalario ||
                    (
                        this.entity.unidadesalarionovo === 5 || this.entity.unidadesalarionovo === 6 ?
                        !this.valorExiste(this.entity.descricaounidadesalarionovo) : false
                    )
                );
            case 2:
                return (
                    (
                        !this.valorExiste(this.entity.salarionovo) ||
                        !this.valorExiste(this.entity.descricaopromocao) ||
                        !this.valorExiste(this.entity.aumentosalarial || this.entity.aumentosalarial === 0) ||
                        !this.valorExiste(this.entity.unidadesalarionovo)
                    ) ||
                    this.entity.salarionovo < this.nivelsalario ||
                    (
                        this.entity.unidadesalarionovo === 5 || this.entity.unidadesalarionovo === 6 ?
                        !this.valorExiste(this.entity.descricaounidadesalarionovo) : false
                    )
                );
            default:
                return false;
        }
    }

    calculaPasso(continua: boolean): void {
        this.allowRefresh = false;
        this.passo = continua ? this.passo + 1 : this.passo - 1;
    }

    limpaCampos(): void {
        this.allowRefresh = false;
        this.entity.cargo = this.entity.cargoantigo;
        // this.entity.nivelcargo = this.entity.nivelcargoantigo;
        this.entity.salarionovo = null;
        this.entity.descricaopromocao = null;
        this.entity.aumentosalarial = null;
        this.entity.percentualCampo = null;
        this.entity.nivelcargo = null;

        this.calculaPasso(false);
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }

    refreshDescartarModal = (e: any) => {
        e = e || window.event;
        if (this.allowRefresh) {
            if (e.keyCode === 116) {
                e.preventDefault();
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, !this.entity.solicitacao ? ' criação' : ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    window.location.reload();
                })
                .catch((error: any): void => {/* */ });
            }
        }
    }

    exitScreenDescartarModal() {
        this.$transitions.onBefore({}, (trans: any) => {
            let d = this.$q.defer();
            if (trans._targetState._identifier !== 'promocoes' && this.allowRefresh) {
                let confirm = this.ModalConfirmService.OpenDescartar(this.entity, !this.entity.solicitacao ? ' criação' : ' edição', true);
                confirm.result.then((): void => {
                    this.allowRefresh = false;
                    let modalConfirmed = true;
                    if (modalConfirmed) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                })
                .catch(function () { /**/ });
            } else {
                d.resolve();
            }
            return d.promise;
        });
    }

    buscaPromocoesColaborador(idTrabalhador: string) {
        this.MeurhSolicitacoespromocoesService.filters = {'trabalhador.trabalhador': idTrabalhador};

        this.MeurhSolicitacoespromocoesService.constructors = {forcagestor: false, status: '00'};
        this.MeurhSolicitacoespromocoesService.after = {};
        this.MeurhSolicitacoespromocoesService.filter = '';

        this.MeurhSolicitacoespromocoesService.reload();
    }

    verificaPromocaoAnteriorMesmoSalario(salarioNovo: number) {
        let temPromocaoMesmoSalario = this.promocoesAnterioresTrabalhador.some((promocao) => {
            if (promocao.solicitacao !== this.entity.solicitacao) {
                return parseFloat(promocao.salario) === salarioNovo;
            }
        });

        this.exibeAvisoPromocoesAnteriores = temPromocaoMesmoSalario;

    }

}
