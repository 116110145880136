import angular from 'angular';
import { MeurhMovimentosRescisaoController } from './movimentosrescisao.controller';

export class MeurhMovimentosRescisaoComponent implements angular.IComponentOptions {
	static selector = 'meurhMovimentosRescisaoComponent';
	static controller = MeurhMovimentosRescisaoController;
	static template = require('!!html-loader!./movimentosrescisao.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		visualizacao: '<',
		exibeMovimentosExistentes: '<',
	};
}
