import angular from 'angular';
import moment from 'moment';
import { FolhasdePontoService } from '../folhasdeponto.service';
import { ApuracoesPontoService } from '../../ApuracoesPonto/apuracoesponto.service';
import { MeurhFolhasdepontoVisualizarpdfmodalService } from '../modal.visualizarpdf/folhasdeponto.visualizarpdfmodal.service';
import { ModalConfirmService } from '../../../../../shared/components/modal-confirm/modal.confirm.service';
import { IFolhaPonto } from '../../models/folhaponto.model';
import { IApuracaoPonto } from '../../models/apuracaoponto.model';

type IFolhasPontoFilters = {
	apuracao?: string | null;
	situacao: string;
	lotacao: { lotacao: string } | null;
	departamento: { departamento: string } | null;
	grupo: { grupo: string } | null;
	trabalhador: { trabalhador: string } | null;
	situacaoColaborador: string | null;
};
export class FolhasPontoIndexController {

    static $inject = [
		'$rootScope',
		'$scope',
		'$state',
		'$stateParams',
		'FolhasdePontoService',
		'ApuracoesPontoService',
		'MeurhFolhasdepontoVisualizarpdfmodalService',
		'ModalConfirmService',
		'NewToaster'
	];

    // initial state
    public entities: Array<IFolhaPonto> = [];
    public apuracoes: Array<IApuracaoPonto> = [];
    public busy: boolean = false;
	public apuracoesSelect:  Array<Partial<IApuracaoPonto & { periodo: string }>> = [];
	public filters: IFolhasPontoFilters = {
		apuracao: null,
		situacao: '',
		lotacao: null,
		departamento: null,
		grupo: null,
		trabalhador: null,
		situacaoColaborador: '00',
	};
	public constructors: any = {};
	public nomeArquivoDownload: string = '';
	public prefixoArquivo: string = 'folhasponto-';
	public botaoInformativo: any = null;
	public labelSituacao: boolean = false;
	public labelSituacaoAssinatura: boolean = false;
	public assinaturaDigitalHabilitada: boolean = false;
	public selectSituacao: string[] | number[];
	public solicitarAssinaturasBtn: boolean = false;

    // table
	public checkboxPreenchidos: Array<IFolhaPonto> = [];
	public carregouTudo: boolean = false;
	public carregouPart: boolean = false;
	public checkToggle: any = {};
	public fields: any[] = [];

    constructor (
        public $rootScope: angular.IRootScopeService & {
			temPermissao: (arg: string) => boolean,
			liberadoTela: (arg: string) => boolean,
			session: any,
			configuracoes: any,
			nsjGlobals: any
		},
		public $scope: angular.IScope,
		public $state: angular.ui.IStateService,
		public $stateParams: angular.ui.IStateParamsService,
		public FolhasdePontoService: FolhasdePontoService, // = entityService
		public ApuracoesPontoService: ApuracoesPontoService,
		public MeurhFolhasdepontoVisualizarpdfmodalService: MeurhFolhasdepontoVisualizarpdfmodalService,
		public ModalConfirmService: ModalConfirmService,
		public NewToaster: { pop: Function },
	) {
		if (
			!this.$rootScope.temPermissao('visualizar_folhas_ponto') ||
			!this.$rootScope.liberadoTela('LIBERAR_FOLHAS_PONTO')
		) {
            $state.go('dp');
        }

        this.assinaturaDigitalHabilitada =
            this.$rootScope.configuracoes.ASSINATURA_DIGITAL_HABILITADA &&
		    this.$rootScope.liberadoTela('LIBERAR_PONTO_ASSINATURA');
	    this.selectSituacao = this.setSelectSituacaoOptions(this.assinaturaDigitalHabilitada);
        this.fields = this.setTableFields(this.assinaturaDigitalHabilitada);

        this.busy = true;
		this.carregaApuracoes();

		this.botaoInformativo = 'Solicitar assinaturas de todos os colaboradores que contém a situação "Enviar para assinatura" na folha de ponto.';

        $scope.$on('ponto_apuracoesfolhas_index_loaded', (event, args: any) => {
			if (this.entities.length === 0) {
				this.entities = args;
			}
            this.setCheckboxDisabled();
			this.checar_todos();
			this.busy = false;
        });

        $scope.$on('ponto_apuracoesfolhas_index_list_finished', () => {
			this.carregouTudo = true;
			this.busy = false;
			this.solicitarAssinaturasBtn = this.desativaSolicitarAssinaturasBtn();
        });

        $scope.$on('apuracoesponto_index_loaded', () => {
			if (this.ApuracoesPontoService.loadParams.finished ||
                this.ApuracoesPontoService.loadParams.to_load === 0
            ) {
                this.montaApuracaoSelect();
            }
        });

        $scope.$on('ponto_apuracoesfolhas_downloadfolhaslote_sucesso', (event, args: any) => {
            this.busy = false;
			this.trataDownload(args.data);
            this.NewToaster.pop({
                type: 'success',
                title: 'Download realizado com sucesso!',
            });
        });

        $rootScope.$on('ponto_apuracoesfolhas_downloadfolhaslote_erro', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro no download.',
            });
        });

        $scope.$on('ponto_apuracoesfolhas_downloadfolhasunificado_sucesso', (event, args: any) => {
            this.busy = false;
			this.trataDownload(args.data, true);
            this.NewToaster.pop({
                type: 'success',
                title: 'Download realizado com sucesso!',
            });
        });

        $scope.$on('ponto_apuracoesfolhas_downloadfolhasunificado_erro', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro no download.',
            });
        });

        $scope.$on('ponto_apuracoesfolhas_regerar_folha_iniciado', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'A folha de ponto foi gerada com sucesso!',
            });
        });

        $scope.$on('ponto_apuracoesfolhas_regerar_folha_erro', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao gerar a folha de ponto.',
            });
        });

        $scope.$on('ponto_apuracoesfolhas_get_pdf_iniciado', (event, args: any, salvar: boolean) => {
            if (salvar) {
				this.busy = false;
				this.trataDownload(args.data, salvar);
				this.NewToaster.pop({
					type: 'success',
					title: 'Download realizado com sucesso!',
				});
			}
        });

		$scope.$on('ponto_apuracoesfolhas_solicitar_assinatura_sucesso', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Solicitação de assinatura enviada com sucesso!',
            });
			this.filterReload();
        });

		$rootScope.$on('ponto_apuracoesfolhas_solicitar_assinatura_erro', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao enviar a solicitação de assinatura.',
            });
        });
    }

	carregaApuracoes() {
		this.busy = true;
		this.ApuracoesPontoService.constructors['vigente'] = false;
		this.ApuracoesPontoService.constructors['empresa'] = this.$rootScope.session.entities.empresa.empresa;
		this.ApuracoesPontoService.constructors['estabelecimento'] = '';
		this.apuracoes = this.ApuracoesPontoService.reload();
	}

    setCheckboxDisabled() {
        this.entities.forEach((entity) => {
            if (entity.status === 0 || entity.status === 1) {
                entity['disableCheckbox'] = true;
            }
        });
    }

    setSelectSituacaoOptions(isAssinaturaDigitalHabilitada: boolean = false) {
        return isAssinaturaDigitalHabilitada ?
            [
                'status_0',
                'status_1',
                'status_4',
                'status_2',
                'situacaoassinatura_1',
                'situacaoassinatura_2',
                'situacaoassinatura_3'
            ] : [0, 1, 2, 3, 4];
    }

    setTableFields(isAssinaturaDigitalHabilitada: boolean = false) {
        return [
            {
                value: 'trabalhador.codigo',
                label: 'Matrícula',
                type: 'string',
                style: 'title',
                copy: '',
                customHeaderStyle: 'max-width-matricula'
            }, {
                value: 'trabalhador.nome',
                label: 'Colaborador',
                type: 'string',
                style: 'title',
                copy: '',
            }, {
                value: 'status',
                label: 'Situação',
                type: 'string',
                style: 'title',
                copy: '',
                customCollumnHtml: () => {
                    let someHtmlVar = `
                        <span ng-if="!(${isAssinaturaDigitalHabilitada} &&
                            (tableRowEntity.status === 2 || tableRowEntity.status === 3))"
                        >
                            <label
                                class="label {{tableRowEntity.status | situacaoFolhasdepontoClassesEnum}}"
                            >
                                {{tableRowEntity.status | situacaoFolhasdepontoEnum}}
                            </label>
                        </span>
                        <span ng-if="${isAssinaturaDigitalHabilitada} &&
                            (tableRowEntity.status === 2 || tableRowEntity.status === 3)"
                        >
                            <label
                                class="label {{tableRowEntity.situacaoassinatura | situacaoFolhasdepontoAssinaturaClassesEnum}}"
                            >
                                {{tableRowEntity.situacaoassinatura | situacaoFolhasdepontoAssinaturaEnum}}
                            </label>
                        </span>
                      `;
                  return someHtmlVar;
                },
            }, ...Object.values({ ...(isAssinaturaDigitalHabilitada && [{
                    value: 'datasolicitacaoassinatura',
                    label: 'Enviado em',
                    type: 'date',
                    style: 'title',
                    copy: '',
                }]
            )}), {
                value: 'apuracaofolha',
                label: '',
                type: 'string',
                style: 'title',
                copy: '',
                customCollumnScope: {
                    downloadIndividual: (entity: any): void => {
                        this.downloadIndividual(entity);
                    },
                    regerarFolha: (entity: IFolhaPonto) => {
                        this.regerarFolha(entity);
                    },
                    abrirModal: (entity: any): void => {
                        this.abrirModal(entity);
                    },
                    solicitacaoDeAssinaturaIndividualOuGrupo: (entity: any): void => {
                        this.solicitacaoDeAssinaturaIndividualOuGrupo(entity);
                    },
                    podeGerarFolha: (entity: any): boolean => {
                        return this.podeGerarFolha(entity);
                    }
                },
                customCollumnHtml: () => {
                    let someHtmlVar = `
                        <div ng-if="tableRowEntity.status >= 2"
                            class="menuEllipsisWrapper content-end"
                        >
                            <nsj-button-dropdown
                                border="none"
                                split="false"
                                size="sm"
                                icon="fas fa-ellipsis-v"
                                dropdown-direction="right"
                                class="menuEllipsis"
                            >
                                <item-dropdown
                                    ng-if="${this.$rootScope.temPermissao('solicitar_assinatura_folha')} &&
                                        ${isAssinaturaDigitalHabilitada} &&
                                        (tableRowEntity.status === 2 || tableRowEntity.status === 3) &&
                                        !tableRowEntity.situacaoassinatura"
                                    item-label="Solicitar assinatura"
                                    ng-click="customCollumnScope.solicitacaoDeAssinaturaIndividualOuGrupo(entity)"
                                ></item-dropdown>
                                <item-dropdown
                                    ng-if="(tableRowEntity.status === 2 || tableRowEntity.status === 3)"
                                    item-label="Visualizar"
                                    ng-click="customCollumnScope.abrirModal(entity)"
                                ></item-dropdown>
                                <item-dropdown
                                    ng-if="(tableRowEntity.status === 2 || tableRowEntity.status === 3)"
                                    item-label="Download"
                                    ng-click="customCollumnScope.downloadIndividual(entity)"
                                ></item-dropdown>
                                <item-dropdown
                                    ng-if="customCollumnScope.podeGerarFolha(tableRowEntity)"
                                    item-label="Gerar folha novamente"
                                    ng-click="customCollumnScope.regerarFolha(entity)"
                                ></item-dropdown>
                            </nsj-button-dropdown>
                        </div>
                      `;
                  return someHtmlVar;
                },
            },
        ];
    }

    /* INÍCIO - COMBO DE FUNÇÕES PARA HABILITAR AS FUNÇÕES SELECIONAR TODOS E DOWNLOAD EM LOTE */
	checar_todos(): void {
		this.checkToggle = document.getElementById('check_all') as HTMLInputElement;

		if (this.checkToggle.checked && !this.checkToggle.indeterminate) {
			let checked = this.entities.filter((entity: any): boolean => !entity.disableCheckbox);
			this.checkboxPreenchidos = angular.copy(checked);
		} else {
			this.checkboxPreenchidos = [];
		}
	}

	temTodosMarcados(): boolean {
		return !!this.entities.length && (this.checkboxPreenchidos.length === this.entities.length);
	}

	naoTemTodosMarcados(): boolean {
		return !!this.checkboxPreenchidos.length && !this.temTodosMarcados();
	}
	/* FIM - COMBO DE FUNÇÕES PARA HABILITAR AS FUNÇÕES SELECIONAR TODOS E DOWNLOAD EM LOTE */

	getRowCustomClass(entity: IFolhaPonto & { rowcustomclass: string} ): string {
		return entity.rowcustomclass;
	}

	montaApuracaoSelect(): void {
		this.apuracoesSelect = [];
		this.apuracoes.forEach((element: IApuracaoPonto) => {
			const inicioApuracao = moment(element.inicioapuracao, 'YYYY-MM-DD');
			const terminoApuracao = moment(element.terminoapuracao, 'YYYY-MM-DD');
			let apuracao = {
				periodo: inicioApuracao.format('DD/MM/YYYY') + ' a ' + terminoApuracao.format('DD/MM/YYYY') + this.getReferenciaApuracao(element),
				inicioapuracao: element.inicioapuracao,
				terminoapuracao: element.terminoapuracao,
				apuracaoponto: element.apuracaoponto
			};
			this.apuracoesSelect.push(apuracao);
			if (!this.valorExiste(this.$stateParams.apuracaoponto)) {
				this.filtraApuracaoAtual(inicioApuracao, terminoApuracao, apuracao.apuracaoponto);
			}
		});
		this.filterReload();
	}

	getReferenciaApuracao(apuracao: IApuracaoPonto) {
		if (apuracao.trabalhadorobj?.trabalhador) {
            return ` - Trabalhador: ${apuracao.trabalhadorobj.nome}`;
        }
		if (apuracao.lotacaoobj?.lotacao) {
            return ` - Lotação: ${apuracao.lotacaoobj.nome} `;
        }
        if (apuracao.estabelecimentoobj?.estabelecimento) {
            return ` - Estabelecimento: ${apuracao.estabelecimentoobj.nomefantasia}`;
        }
        if (apuracao.empresaobj?.empresa) {
            return ` - Empresa: ${apuracao.empresaobj.razaosocial}`;
        }
        return '';
    }

	filtraApuracaoAtual(inicioApuracao: moment.Moment, terminoApuracao: moment.Moment, guidApuracao: string) {
		const dataAtual = moment();
		if (dataAtual.isBetween(inicioApuracao, terminoApuracao, undefined, '[]')) {
			this.filters.apuracao = guidApuracao;
		}
	}

	abrirModal(entity: IFolhaPonto): void {

        let modal = this.MeurhFolhasdepontoVisualizarpdfmodalService.open(entity);

        modal.result
			// tslint:disable-next-line:no-empty
            .then((response: any): void => { })
            .catch((error: any): void => {
                console.log(error);
            });

	}

    downloadIndividual(entity: IFolhaPonto) {
        this.busy = true;
		this.nomeArquivoDownload = '';
        const inicioapuracao = moment(entity.apuracaoponto.inicioapuracao, 'YYYY-MM-DD').format('DD-MM-YYYY');
        const terminoapuracao = moment(entity.apuracaoponto.terminoapuracao, 'YYYY-MM-DD').format('DD-MM-YYYY');
        this.nomeArquivoDownload = entity.trabalhador.codigo + ' - ' + entity.trabalhador.nome + ' ' + inicioapuracao + ' ate ' + terminoapuracao;
        this.FolhasdePontoService.visualizarPdf(entity, true);
    }

	downloadLote(pdfUnico: boolean = false) {
		this.busy = true;
		this.nomeArquivoDownload = '';
        let entities: Array<any> = [];
        const inicioapuracao = moment(this.checkboxPreenchidos[0].apuracaoponto.inicioapuracao, 'YYYY-MM-DD').format('DD-MM-YYYY');
        const terminoapuracao = moment(this.checkboxPreenchidos[0].apuracaoponto.terminoapuracao, 'YYYY-MM-DD').format('DD-MM-YYYY');
        this.checkboxPreenchidos.forEach((element: IFolhaPonto) => {
            entities.push({'apuracaofolha' : element['apuracaofolha']});
        });
        this.nomeArquivoDownload = this.prefixoArquivo + inicioapuracao + '-ate-' + terminoapuracao;
        pdfUnico ?
            this.FolhasdePontoService.downloadUnificado(entities) :
            this.FolhasdePontoService.download(entities);
	}

    regerarFolha(entity: IFolhaPonto) {
        const colaboradorNome = entity.trabalhador.nome;
		const liberada = entity.status === 3 ? true : false;
		let textoPersonalizado = liberada ? `A folha de ponto do colaborador(a) ${colaboradorNome} já foi enviada para assinatura. Se você a regerar, precisará reenviá-la ao colaborador(a). Deseja continuar?` : `Você tem certeza que deseja gerar novamente a folha de ponto do colaborador(a) ${colaboradorNome}?`;
        const textos = {
            title: 'Gerar Novamente',
            confirmText: textoPersonalizado,
            closeButton: 'Cancelar',
            confirmButton: 'Sim, gerar novamente',
        };

		this.ModalConfirmService.open(entity, 'Apuração', false, textos).result
			.then(() => {
				this.busy = true;
				this.FolhasdePontoService.regerarFolhaIndividual(entity)
				.then(() => this.FolhasdePontoService.reload());
			})
			.catch(() => {/**/});
    }

    filterReload(): void {
		this.busy = true;

		if (!this.valorExiste(this.filters.departamento)) {
            delete this.constructors.trabalhador_departamento;
			this.filters.departamento = null;
        } else {
			this.constructors['trabalhador_departamento'] = this.filters.departamento!.departamento;
		}

		if (!this.valorExiste(this.filters.grupo)) {
            delete this.constructors.grupo;
			this.filters.grupo = null;
        } else {
			this.constructors['grupo'] = this.filters.grupo!.grupo;
		}

        if (!this.valorExiste(this.filters.trabalhador)) {
            delete this.constructors.trabalhador_trabalhador;
			this.filters.trabalhador = null;
        } else {
			this.constructors['trabalhador_trabalhador'] = this.filters.trabalhador!.trabalhador;
		}

		if (!this.valorExiste(this.filters.situacaoColaborador)) {
            delete this.constructors.trabalhador_status;
			this.filters.situacaoColaborador = null;
        } else {
			this.constructors['trabalhador_status'] = this.filters.situacaoColaborador;
		}

		delete this.constructors['status'];
		delete this.constructors['situacaoassinatura'];
		if (this.valorExiste(this.filters.situacao)) {
			const situacao = this.montaFiltroSituacao(this.filters.situacao);
			this.constructors[situacao.nome] = situacao.valor;
		}

		if (!this.valorExiste(this.filters.apuracao)) {
			this.filters.apuracao = this.valorExiste(this.$stateParams.apuracaoponto) ?
				this.$stateParams.apuracaoponto :
				this.apuracoes[0]?.apuracaoponto;
			if (!this.filters.apuracao) {
				this.entities = [];
				this.busy = false;
				return;
			}
		}
		this.constructors['apuracaoponto_apuracaoponto'] = this.filters.apuracao;
		this.constructors.empresa = this.$rootScope.session.entities.empresa.empresa;
		this.constructors.estabelecimento = this.$rootScope.session.entities.estabelecimento.estabelecimento;
		this.FolhasdePontoService.constructors = this.constructors;
		this.entities = [];
		this.entities = this.FolhasdePontoService.reload();
    }

	montaFiltroSituacao(situacao: string) {
		if (this.assinaturaDigitalHabilitada) {
			const situacaoNomeValor = situacao.split('_');
			return {
				nome: situacaoNomeValor[0],
				valor: situacaoNomeValor[1]
			};
		} else {
			return {
				nome: 'status',
				valor: situacao
			};
		}
	}

	reloadLotacao(): void {
        if (this.filters.lotacao !== null) {
			this.filters.trabalhador = null;
			delete this.constructors.trabalhador_lotacao;
			delete this.constructors.trabalhador_trabalhador;
			this.$rootScope.$broadcast('trabalhador_refresh', {
				lotacao: this.filters.lotacao.lotacao,
				empresa: this.$rootScope.session.entities.empresa.empresa,
				estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
			});
			this.constructors['trabalhador_lotacao'] = this.filters.lotacao.lotacao;
        } else {
            this.filters.trabalhador = null;
			delete this.constructors.trabalhador_lotacao;
			delete this.constructors.trabalhador_trabalhador;
        }

		this.FolhasdePontoService.constructors = this.constructors;
		this.entities = [];
		this.entities = this.FolhasdePontoService.reload();
    }

	trataDownload(data: any, salvar: boolean = false): void {
		let nomeArquivo = '';
        var blob = new Blob([data]);
        let fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
        var a = document.createElement('a');

		if (salvar) {
			nomeArquivo = nomeArquivo = this.nomeArquivoDownload + '.pdf';
		} else {
			nomeArquivo = nomeArquivo = this.nomeArquivoDownload + '.zip';
		}

        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = fileURL;
        a.download = nomeArquivo;
        a.click();
        window.URL.revokeObjectURL(fileURL);
	}

	loadMore(): void {
		this.FolhasdePontoService.loadMore();
	}

	valorExiste(valor: any): boolean {
		return valor !== '' && valor !== undefined && valor !== null ? true : false;
	}

	solicitacaoDeAssinaturaIndividualOuGrupo(entity: IFolhaPonto): void {
		this.busy = true;
		if (this.valorExiste(entity)) {
			let arrayEntities: Array<{ apuracaofolha: string }> = [];
			arrayEntities.push({
				'apuracaofolha': entity.apuracaofolha
			});
			this.FolhasdePontoService.solicitacaoDeAssinatura(arrayEntities);
		} else {
			let arrayEntities: Array<{ apuracaofolha: string }> = [];
			this.entities.forEach((element: IFolhaPonto) => {
				if (
					(element.status === 2 || element.status === 3) &&
					!element.situacaoassinatura
				) {
					arrayEntities.push({
						'apuracaofolha': element.apuracaofolha
					});
				}
			});
			this.FolhasdePontoService.solicitacaoDeAssinatura(arrayEntities);
		}
	}

	desativaSolicitarAssinaturasBtn() {
		return !(this.entities.filter(entity => {
			if (
				(entity.status === 2 || entity.status === 3) &&
				!entity.situacaoassinatura
			) {
				return true;
			}
			return false;
		}).length > 0);
	}

	podeGerarFolha(tableRowEntity: any): boolean {
		// enum FOLHA
		// const AGUARDANDO_GERACAO = 0;
		// const GERANDO = 1;
		// const GERADOS = 2;
		// const LIBERADA = 3;
		// const ERRO = 4;

		// enum ASSINATURA
		// const DEFAULT = 0;
		// const PENDENTE = 1;
		// const ASSINADA = 2;
		// const REJEITADA = 3;

		const temPermissao = this.$rootScope.temPermissao('gerar_folhas_ponto');
		const isValido = [2, 3, 4].includes(tableRowEntity.status) && tableRowEntity.situacaoassinatura < 2;

		return temPermissao && isValido;
	}
}

