
import * as angular from 'angular';

import { PontoListRouting } from './ApuracoesPonto/apuracoesponto.routes';
import { FolhasdePontoListRouting } from './FolhasDePonto/folhasdeponto.routes';
import { AnalisesPontoListRouting } from './AnalisesPonto/analisesponto.routes';
import { PagamentosPontoListRouting } from './Pagamentos/pagamentos.routes';
import { BancodeHorasListRouting } from './BancoDeHoras/bancodehoras.routes';
import { ExportacaoArquivoAejListRouting } from './ExportacaoArquivoAEJ/exportacaoarquivoaej.routes';
import { EstabelecimentosHabilitadosRouting } from './EstabelecimentosHabilitados/estabelecimentoshabilitados.routes';

import { ApuracoesPontoService } from './ApuracoesPonto/apuracoesponto.service';
import { FolhasdePontoService } from './FolhasDePonto/folhasdeponto.service';
import { MeurhFolhasdepontoVisualizarpdfmodalService } from './FolhasDePonto/modal.visualizarpdf/folhasdeponto.visualizarpdfmodal.service';
import { AnalisesPontoService } from './AnalisesPonto/analisesponto.service';
import { PagamentosPontoService } from './Pagamentos/pagamentos.service';
import { GruposPontoService } from './gruposponto.service';
import { TrabalhadoresPontoService } from './trabalhadoresponto.service';
import { BancodeHorasService } from './BancoDeHoras/bancodehoras.service';
import { ExportacaoArquivoAejService } from './ExportacaoArquivoAEJ/exportacaoarquivoaej.service';

import { ApuracoesPontoIndexController } from './ApuracoesPonto/index/apuracoesponto.index.controller';
import { ApuracaoPontoController } from './ApuracoesPonto/apuracaoponto/apuracaoponto.controller';
import { FolhasPontoIndexController } from './FolhasDePonto/index/folhasdeponto.index.controller';
import { AnalisesPontoIndexController } from './AnalisesPonto/index/analisesponto.index.controller';
import { PagamentosPontoIndexController } from './Pagamentos/index/pagamentos.index.controller';
import { BancodeHorasIndexController } from './BancoDeHoras/index/bancodehoras.index.controller';
import { ExportacaoArquivoAejIndexController } from './ExportacaoArquivoAEJ/index/exportacaoarquivoaej.index.controller';
import { EstabelecimentosHabilitadosIndexController } from './EstabelecimentosHabilitados/index/estabelecimentoshabilitados.index.controller';
import { EstabelecimentosHabilitadosNewController } from './EstabelecimentosHabilitados/new/estabelecimentoshabilitados.new.controller';
import { EstabelecimentosHabilitadosEditController } from './EstabelecimentosHabilitados/edit/estabelecimentoshabilitados.edit.controller';

import { ApuracaoPontoComponent } from './ApuracoesPonto/apuracaoponto/apuracaoponto.component';
import { EstabelecimentosHabilitadosService } from './EstabelecimentosHabilitados/estabelecimentoshabilitados.service';
import { EstabelecimentosHabilitadosModalService } from './EstabelecimentosHabilitados/modalBuscaColaboradores/estabelecimentoshabilitados.modalbuscarcolaboradores.service';
import { EstabelecimentosHabilitadosModalEditarResponsavelService } from './EstabelecimentosHabilitados/edit/modalEditarResponsavel/estabelecimentoshabilitados.modaleditarresponsavel.service';

import { RescisaoPontoController } from './Rescisao/rescisao.ponto.controller';
import { RescisaoPontoService } from './Rescisao/rescisao.ponto.service';
import { RescisaoPontoComponent } from './Rescisao/rescisao.ponto.component';

export const PontoModule = angular.module('PontoModule', ['ui.router.state'])
    .controller('ApuracoesPontoIndexController', ApuracoesPontoIndexController)
    .controller('ApuracaoPontoController', ApuracaoPontoController)
    .controller('FolhasPontoIndexController', FolhasPontoIndexController)
    .controller('AnalisesPontoIndexController', AnalisesPontoIndexController)
    .controller('PagamentosPontoIndexController', PagamentosPontoIndexController)
    .controller('BancodeHorasIndexController', BancodeHorasIndexController)
    .controller('ExportacaoArquivoAejIndexController', ExportacaoArquivoAejIndexController)
    .controller('EstabelecimentosHabilitadosIndexController', EstabelecimentosHabilitadosIndexController)
    .controller('EstabelecimentosHabilitadosNewController', EstabelecimentosHabilitadosNewController)
    .controller('EstabelecimentosHabilitadosEditController', EstabelecimentosHabilitadosEditController)
    .controller('RescisaoPontoController', RescisaoPontoController)
    .service('ApuracoesPontoService', ApuracoesPontoService)
    .service('FolhasdePontoService', FolhasdePontoService)
    .service('MeurhFolhasdepontoVisualizarpdfmodalService', MeurhFolhasdepontoVisualizarpdfmodalService)
    .service('AnalisesPontoService', AnalisesPontoService)
    .service('PagamentosPontoService', PagamentosPontoService)
    .service('GruposPontoService', GruposPontoService)
    .service('TrabalhadoresPontoService', TrabalhadoresPontoService)
    .service('BancodeHorasService', BancodeHorasService)
    .service('ExportacaoArquivoAejService', ExportacaoArquivoAejService)
    .service('EstabelecimentosHabilitadosService', EstabelecimentosHabilitadosService)
    .service('EstabelecimentosHabilitadosModalService', EstabelecimentosHabilitadosModalService)
    .service('EstabelecimentosHabilitadosModalEditarResponsavelService', EstabelecimentosHabilitadosModalEditarResponsavelService)
    .service('RescisaoPontoService', RescisaoPontoService)
    .component('apuracaoPontoComponent', ApuracaoPontoComponent)
    .component('rescisaoPontoComponent', RescisaoPontoComponent)
    .config(PontoListRouting)
    .config(FolhasdePontoListRouting)
    .config(AnalisesPontoListRouting)
    .config(PagamentosPontoListRouting)
    .config(BancodeHorasListRouting)
    .config(ExportacaoArquivoAejListRouting)
    .config(EstabelecimentosHabilitadosRouting)
    .name;
