import angular from 'angular';
import { MeurhSolicitacoesgenericasDocumentosanexosFormController } from './documentosanexossolicitacoesgenericas.form.controller';

export class MeurhSolicitacoesgenericasDocumentosanexosFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesgenericasDocumentosanexosFormComponent';
	static controller = MeurhSolicitacoesgenericasDocumentosanexosFormController;
	static template = require('!!html-loader!./documentosanexossolicitacoesgenericas.form.html');
	static transclude = true;
	static bindings = {
		busy: '=',
		busyTipoDocumentos: '=',
		entity: '=',
		entityanexo: '=',
		file: '=?',
		action: '=?',
		mostrarAlerta: '=',
		tiposdocumentosrequeridos: '=',
		editanexo: '=',
		alterouanexo: '='
	};
}
