import ng from 'angular';
import angular from 'angular';
import moment from 'moment';
import { IRescisao } from '../../models/rescisao.model';
import { TipoCumprimentoAvisoEnum } from '../../enums/tipocumprimento';
import { MotivoRescisaoEnum } from '../../enums/motivorescisao';
import { ConfiguracoesService } from '../../../../Configuracoes/configuracoes.service';
import { TrabalhadoresService } from '../../../../Trabalhadores/trabalhadores.service';
import { IMotivorescisao } from '../../models/motivorescisao.model';
import { IMovimentoExistenteRescisao } from '../../models/movimentorescisao';
import { MeurhSolicitacoesrescisoesService } from '../../solicitacoesrescisoes.service';
import { TipoMovimentoTrabalhadorEnum } from '../../enums/tipomovimentotrabalhador';

export class MeurhSolicitacoesrecisoesFormController implements ng.IController {
    static $inject = [
        '$scope',
        'Beneficiostrabalhadores',
        '$rootScope',
        '$stateParams',
        'ConfiguracoesService',
        'TrabalhadoresService',
        'MeurhSolicitacoesrescisoesService',
        'NewToaster',
        'HabilitadoService',
    ];

    public entity: IRescisao;
    public form: angular.IFormController;
    public action: string;
    public busy: boolean = false;
    public movimentosExistentes: Array<any> = [];

    public tipocumprimentoenum = TipoCumprimentoAvisoEnum;
    public motivorescisaoenum = MotivoRescisaoEnum;

    public tipo: string;
    public tiposubmit: string;

    public dataProjecaoInvalida: boolean = false;
    public lookupTrabalhadores: boolean = false;
    public primeiroLookupTrabalhadoresCarregado: boolean = false;
    public carregandoBeneficios: boolean = false;
    public indenizadoFixo: boolean = true;

    public constructorLookupColaborador;
    public nomeEstabilidade: string = '';
    public movimentosAtivados: boolean;
    private dataAtualString: string;

    private cumprimento: {
        old_value: number | null;
        new_value: number;
    };

    private configuracoes: any;

    constructor(
        private $scope: angular.IScope,
        private Beneficiostrabalhadores: any,
        private $rootScope: angular.IRootScopeService & {
            modoGestorPermissao: (arg: string) => boolean,
            session: any, configuracoes: any
        },
        private $stateParams: angular.ui.IStateParamsService,
        private ConfiguracoesService: ConfiguracoesService,
        private TrabalhadoresService: TrabalhadoresService,
        private MeurhSolicitacoesrescisoesService: MeurhSolicitacoesrescisoesService,
        private NewToaster: any,
        private HabilitadoService: any,
    ) {
        this.configuracoes = this.ConfiguracoesService.reload();
        this.tipo = this.$stateParams.tipo ?? '-1';

        this.constructorLookupColaborador = {
            'estabelecimento': this.$rootScope.session.entities.estabelecimento.estabelecimento,
            'status': 'NR',
            'forcagestor': this.$rootScope.modoGestorPermissao('registrar_apontamento'),
            'tipo': 0
        };

        this.$scope.$watch('$ctrl.entity', (newValue: any, oldValue: any) => {
            if ((newValue !== oldValue) && this.form.dataprojetadaaviso) {
                if (newValue.dataconcessaoaviso > newValue.dataprojetadaaviso) {
                    this.form.dataprojetadaaviso.$setValidity('dataProjecaoInvalida', false);
                } else {
                    this.form.dataprojetadaaviso.$setValidity('dataProjecaoInvalida', true);
                }
                this.form.$setDirty();
            }
        }, true);

        this.$rootScope.$on('Trabalhadores_loading', (event: angular.IAngularEvent, args: any): void => {
            if (!this.primeiroLookupTrabalhadoresCarregado) {
                this.lookupTrabalhadores = true;
                this.primeiroLookupTrabalhadoresCarregado = true;
            }
        });

        this.$rootScope.$on('Trabalhadores_loaded', (event: angular.IAngularEvent, args: any): void => {
            this.lookupTrabalhadores = false;
        });

        this.$scope.$on('beneficiostrabalhadores_list_finished', (event: angular.IAngularEvent, args: any): void => {
            this.carregandoBeneficios = false;
        });

        this.movimentosAtivados = this.HabilitadoService.estaHabilitado('LIBERAR_PAGAMENTO_MOVIMENTOS_RESCISAO');
    }

    async $onInit(): Promise<void> {
        this.cumprimento = {
            old_value: null,
            new_value: this.entity.tipocumprimentoaviso_select
        };
        this.dataAtualString = moment().format('YYYY-MM-DD');
        this.indenizadoFixo = this.getIndenizadoFixo(this.entity.motivorescisao);

        if (this.action !== 'insert') {

            // montando estrutura para exibição dos movimentos da rescisão
            this.organizarTabelasMovimentos();

            if (this.movimentosAtivados) {
                await this.getMovimentosExistentesColaborador(false);
            }

            // to do: QUANDO ESSA VERIFICAÇÃO DE DIFERENÇA ENTRE MOVIMENTOS EXISTENTES NA RESCISÃO E MOVIMENTOS DA API PRECISAR SER MELHORADA, VOLTAR COM ESSA VERIFICAÇÃO AO INICIAR TELA DE EDIÇÃO
            // se estiver editando rescisão, verificar se há diferença entre os movimentos existentes vindos da api e os movimentos existentes na rescisão
            // if (this.action === 'update' && this.entity.movimentosexistentescolaborador) {
            //     let movimentosDiferentes =  this.existeDiferencaMovimentosExistentes();

            //     if (movimentosDiferentes) {

            //         // se houver movimentos diferentes, substituir os movimentos existentes da rescisão pelos movimentos existentes vindos da api
            //         this.entity.movimentosexistentescolaborador = this.movimentosExistentes;

            //         this.NewToaster.pop({
            //             type: 'warning',
            //             title: 'Movimentos atualizados',
            //             body: 'Alguns movimentos foram atualizados e sofreram alterações durante o processo de rescisão. Por favor, revise e tome as ações necessárias antes de enviar.'
            //         });

            //     }

            // }
        }

    }

    // início - controle do formulário
    public alteraMotivoRescisao(): void {
        this.entity.indenizado = (
            this.entity.motivorescisao?.motivorescisao === this.motivorescisaoenum.RESCISAO_SEM_JUSTA_CAUSA_INICIATIVA_EMPREGADOR ||
            this.entity.motivorescisao?.motivorescisao === this.motivorescisaoenum.RESCISAO_CONTRATO_INICIATIVA_EMPREGADO
        );
        this.indenizadoFixo = this.getIndenizadoFixo(this.entity.motivorescisao);

    }

    public getIndenizadoFixo (motivoRescisao: IMotivorescisao | undefined) {

        return motivoRescisao ?
            motivoRescisao.motivorescisao !== this.motivorescisaoenum.RESCISAO_CONTRATO_INICIATIVA_EMPREGADO &&
            motivoRescisao.motivorescisao !== this.motivorescisaoenum.RESCISAO_ACORDO_ENTRE_PARTES :
            true;
    }

    public alteraTipoCumprimento(): void {
        this.cumprimento.old_value = this.cumprimento.new_value;

        if (
            this.cumprimento.old_value <= this.tipocumprimentoenum.CUMPRIDO_PARCIALMENTE_INICIATIVA_EMPREGADO &&
            this.entity.tipocumprimentoaviso_select >= this.tipocumprimentoenum.DISPENSADO &&
            this.camposAvisoPrevioPreenchidos()
        ) {
            const confirmacao = confirm(
                'Ao alterar o tipo de cumprimento, ' +
                'os dados de aviso prévio preenchidos serão perdidos.\n' +
                'Você tem certeza que deseja continuar?'
            );
            if (confirmacao) {
                this.cumprimento.new_value = this.entity.tipocumprimentoaviso_select;
                this.removerCamposAvisoPrevio();
            } else {
                this.entity.tipocumprimentoaviso_select = this.cumprimento.old_value;
            }
        } else {
            this.cumprimento.new_value = this.entity.tipocumprimentoaviso_select;
        }

        this.calcularConcessaoEProjecao();
    }

    public calcularConcessaoEProjecao(): void {
        if (
            !!this.entity.datarescisao &&
            this.entity.tipocumprimentoaviso_select !== this.tipocumprimentoenum.DISPENSADO
        ) {
            const dataprojetadaaviso = moment(this.entity.datarescisao).subtract(30, 'days');

            this.entity.dataconcessaoaviso = dataprojetadaaviso.format('YYYY-MM-DD');
            this.entity.dataprojetadaaviso = this.entity.datarescisao;
        } else {
            this.entity.dataconcessaoaviso = null;
            this.entity.dataprojetadaaviso = null;
        }
    }

    public labelAvisoIndenizado(): string {
        if (
            this.entity.motivorescisao &&
            this.entity.motivorescisao.motivorescisao === this.motivorescisaoenum.RESCISAO_CONTRATO_INICIATIVA_EMPREGADO
        ) {
            return 'Desconta aviso';
        }
        return 'Aviso indenizado';
    }

    public carregandoLookups(): boolean {
        return this.lookupTrabalhadores;
    }

    public selectDtype(movimento: any): string {
        let tipo: number = 0;

        if (!!movimento.evento) {
            tipo = movimento.evento.evento_unidade || movimento.evento.unidade || 0;
        }

        const tipos_existentes = {
            0: 'interval',
            1: 'number',
            2: 'currency',
            3: 'currency',
            4: 'currency',
            5: 'currency',
            6: 'currency',
            7: 'currency',
            8: 'interval',
            9: 'currency',
            10: 'interval',
            12: 'currency',
            13: 'currency',
            14: 'currency',
            15: 'currency',
            16: 'currency',
            17: 'currency',
            18: 'currency',
            19: 'currency'
        };

        return tipos_existentes[tipo] || 'number';
    }

    public prazoRescisao(): any {

        // se as configurações ainda não foram carregadas (objeto vazio), não faz a verificação
        if (Object.entries(this.configuracoes).length === 0 && this.configuracoes.constructor === Object) {
            return;
        }

        let prazo = '';
        const dataAtual = moment();
        let atrasoRescisao = JSON.parse(this.configuracoes.ATRASO_RESCISAO?.valor);
        if (atrasoRescisao.hasOwnProperty('prazo') && atrasoRescisao['prazo']) {
            prazo = dataAtual.subtract(parseInt(atrasoRescisao['dias'], 10), 'days').format('YYYY-MM-DD');
        }

        return prazo;
    }

    public prazoMaximoRescisao(): string | null {
        if (this.$rootScope.configuracoes['LIBERAR_TRAB_TEMPORARIO_TERMINO_AUTOMATICO'] &&
            (this.entity.trabalhador?.categoriatrabalhador === '105' || this.entity.trabalhador?.categoriatrabalhador === '106')) {
            return this.entity.trabalhador?.datafimcontrato || null;
        }

        return null;
    }
    // fim - controle do formulário

    // início - validações e verificações
    public validarCamposAvisoPrevio(): boolean {
        return this.entity.tipocumprimentoaviso_select === this.tipocumprimentoenum.DISPENSADO;
    }

    public validaDatafimcontrato(): boolean {
        return (
            !this.entity.criadoautomaticamente ||
            ((
                this.entity.motivorescisao?.motivorescisao === this.motivorescisaoenum.RESCISAO_ANTECIPADA_INICIATIVA_EMPREGADOR ||
                this.entity.motivorescisao?.motivorescisao === this.motivorescisaoenum.RESCISAO_ANTECIPADA_INICIATIVA_EMPREGADO
            ) && moment(this.entity.datarescisao).isBefore(moment(this.entity.trabalhador?.datafimcontrato!)) ||
                this.entity.motivorescisao?.motivorescisao === this.motivorescisaoenum.RESCISAO_TERMINO_CONTRATO_TERMO &&
                moment(this.entity.datarescisao).isBefore(moment(this.entity.trabalhador?.datafimcontrato!).add(1, 'day'))
            )
        );
    }

    /**
     * Monta as mensagens dos avisos sobre estabilidade que serão exibidos ou limpa as mensagens, caso não seja para exibir avisos
     * @param fimPeriodoEstavel A data que o período estável acaba
     * @param dataRescisao A data de rescisão
     */
    public exibeAvisosEstabilidade(fimPeriodoEstavel: string, dataRescisao: string) {
        if (this.entity.colaboradorTemEstabilidade) {
            this.entity.exibeInfosEstabilidade = !dataRescisao || (moment(dataRescisao).isSameOrBefore(fimPeriodoEstavel));

            if (this.entity.exibeInfosEstabilidade && moment(fimPeriodoEstavel).isSameOrAfter(this.dataAtualString)) {
                this.entity.msgEstabilidade = `O colaborador encontra-se em período de estabilidade até ${moment(this.entity.fimPeriodoEstavel).format('DD/MM/YYYY')}.`;
                this.entity.msgEstabilidadeModal = `${this.entity.trabalhador?.nome} está sob estabilidade de ${this.nomeEstabilidade} até ${moment(this.entity.fimPeriodoEstavel).format('DD/MM/YYYY')}.`;
            } else {
                this.entity.msgEstabilidade = this.entity.msgEstabilidadeModal = '';
            }

        } else {
            this.entity.exibeInfosEstabilidade = false;
            this.entity.msgEstabilidade = this.entity.msgEstabilidadeModal = '';
        }
        this.$scope.$applyAsync();
    }

    private camposAvisoPrevioPreenchidos(): boolean {
        return (
            !!this.entity.tipoconcessaoaviso ||
            !!this.entity.dataconcessaoaviso ||
            !!this.entity.dataprojetadaaviso ||
            !!this.entity.observacaoconcessaoaviso
        );
    }
    // fim - validações e verificações


    // início - tratamento de dados
    private async onChangeTrabalhador(): Promise<void> {
        if (this.entity.trabalhador) {
            this.carregandoBeneficios = true;
            this.Beneficiostrabalhadores.constructors.trabalhador = this.entity.trabalhador.trabalhador;
            this.entity.beneficiostrabalhadores = this.Beneficiostrabalhadores.reload();

            let afastamentosTrabalhador = [];
            let dadosCipaTrabalhador;

            await this.TrabalhadoresService.getEstabilidadeTrabalhador(this.entity.trabalhador.trabalhador)
            .then((dadosRetornados: any) => {
                afastamentosTrabalhador = dadosRetornados.afastamentos;
                dadosCipaTrabalhador = dadosRetornados.membrocipa;
                this.entity.colaboradorTemEstabilidade = (
                    (afastamentosTrabalhador && afastamentosTrabalhador.length > 0 && this.algumAfastamentoTemPeriodoEstavel(afastamentosTrabalhador)) ||
                    dadosRetornados.membrocipa
                ) ? true : false;
            });

            if (this.entity.colaboradorTemEstabilidade) {
                let infosEstabilidade = this.getFimPeriodoEstavel(afastamentosTrabalhador, dadosCipaTrabalhador);
                this.entity.fimPeriodoEstavel = infosEstabilidade.fimperiodoestavel;
                this.nomeEstabilidade = infosEstabilidade.descricaoestabilidade.toLowerCase();
            }

            this.exibeAvisosEstabilidade(this.entity.fimPeriodoEstavel, this.entity.datarescisao);

            this.entity.movimentos = [];
            if (this.movimentosAtivados) {
                await this.getMovimentosExistentesColaborador(true);
            }
        } else {
            this.entity.colaboradorTemEstabilidade = this.entity.exibeInfosEstabilidade = false;
            this.entity.msgEstabilidade = this.entity.msgEstabilidadeModal = '';
            this.entity.movimentos = this.entity.movimentosexistentescolaborador = [];
        }
    }

    /**
     * Recebe o array de afastamentos e retorna se algum deles possui período estável
     * @param afastamentos array de afastamentos
     */
    private algumAfastamentoTemPeriodoEstavel (afastamentos: Array<any>) {
        return afastamentos.some((afastamento) => {
            return afastamento.hasOwnProperty('fimperiodoestavel') && afastamento.fimperiodoestavel != null;
        });
    }

    /**
     * Dado o array de afastamentos e os dados de membro CIPA, método retornará a data final do período estável a ser considerada
     * @param afastamentos array de afastamentos
     * @param membrocipa dados de membro CIPA
     * @returns objeto com a data final do período estável e a descrição da estabilidade
     */
    private getFimPeriodoEstavel(afastamentos: Array<any>, membrocipa: any): {fimperiodoestavel: string, descricaoestabilidade: string} {

        if (!afastamentos || afastamentos.length < 1) { // não tem afastamentos, apenas cipa
            return {
                fimperiodoestavel: membrocipa.fimperiodoestavel,
                descricaoestabilidade: 'membro CIPA'
            };

        } else if (!membrocipa) { // não tem cipa, apenas afastamentos

            let afastamentosComPeriodoEstavel = afastamentos.filter((afastamento) => {
                if (afastamento.fimperiodoestavel) {
                    return afastamento;
                }
            });

            let afastamentoMaiorFimPeriodoEstavel = this.getAfastamentoMaiorFimPeriodoEstavel(afastamentosComPeriodoEstavel);
            return {
                fimperiodoestavel: afastamentoMaiorFimPeriodoEstavel.fimperiodoestavel,
                descricaoestabilidade: afastamentoMaiorFimPeriodoEstavel.descricao
            };

        } else { // tem afastamentos e cipa

            let afastamentosComPeriodoEstavel = afastamentos.filter((afastamento) => {
                if (afastamento.fimperiodoestavel) {
                    return afastamento;
                }
            });

            let afastamentoMaiorFimPeriodoEstavel = this.getAfastamentoMaiorFimPeriodoEstavel(afastamentosComPeriodoEstavel);
            let fimPeriodoEstavelAfastamentos = afastamentoMaiorFimPeriodoEstavel.fimperiodoestavel;
            let fimPeriodoEstavelCipa = membrocipa.fimperiodoestavel;

            let afastamentoMaiorQueCipa = moment(fimPeriodoEstavelAfastamentos).isSameOrAfter(fimPeriodoEstavelCipa);

            if (afastamentoMaiorQueCipa) {
                return {
                    fimperiodoestavel: afastamentoMaiorFimPeriodoEstavel.fimperiodoestavel,
                    descricaoestabilidade: afastamentoMaiorFimPeriodoEstavel.descricao
                };
            } else {
                return {
                    fimperiodoestavel: membrocipa.fimperiodoestavel,
                    descricaoestabilidade: 'membro CIPA'
                };
            }
        }

    }

    /**
     * Dada uma lista de afastamentos de um colaborador, retorna o afastamento que possui o maior fim período estável
     * @param afastamentos lista de afastamentos
     */
    private getAfastamentoMaiorFimPeriodoEstavel (afastamentos: Array<any>) {

        return afastamentos.reduce((maiorAfastamento: any, afastamentoAtual: any) => {
            const fimPeriodoEstavelMaior = moment(maiorAfastamento.fimperiodoestavel, 'YYYY-MM-DD');
            const fimPeriodoEstavelAtual = moment(afastamentoAtual.fimperiodoestavel, 'YYYY-MM-DD');

            return fimPeriodoEstavelMaior.isAfter(fimPeriodoEstavelAtual) ? maiorAfastamento : afastamentoAtual;
        });

    }

    private removerCamposAvisoPrevio(): void {
        this.entity.tipoconcessaoaviso = null;
        this.entity.dataconcessaoaviso = null;
        this.entity.dataprojetadaaviso = null;
        this.entity.observacaoconcessaoaviso = null;
    }

    /**
     * Busca os movimentos existentes de um colaborador. Baseado no valor recebido por parâmetro, atribui os movimentos existentes na própria rescisão ou na propriedade movimentosExistentes
     * @param atribuirMovsNaRescisao Informa se os movimentos buscados na api devem ser atribuídos na rescisão ou na propriedade movimentosExistentes
     */
    private async getMovimentosExistentesColaborador(atribuirMovsNaRescisao: boolean) {
        this.busy = true;

        await this.MeurhSolicitacoesrescisoesService.getMovimentosRescisaoTrabalhador(this.entity.trabalhador!.trabalhador)
            .then((dadosRetornados: Array<IMovimentoExistenteRescisao>) => {

                if (atribuirMovsNaRescisao) {
                    this.entity.movimentosexistentescolaborador = dadosRetornados;
                    this.movimentosExistentes = [];
                    this.ordenaMovimentosExistentes();
                } else {
                    this.movimentosExistentes = dadosRetornados;
                }

            })
            .catch((error: any) => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não foi possível carregar os movimentos existentes do colaborador',
                    body: error.data?.message
                });
            })
        .finally(() => this.busy = false);
    }

    /**
     * Baseado nos dados dos movimentos de uma rescisão, coloca o movimento na tabela de movimentos existentes ou na tabela de movimentos da rescisão
     */
    private organizarTabelasMovimentos() {

        this.entity.movimentosexistentescolaborador = [];

        if (this.entity.movimentos && this.entity.movimentos.length > 0) {

            for (let i = this.entity.movimentos.length - 1; i >= 0; i--) {
                if (this.entity.movimentos[i].tipoorigem) {
                    this.entity.movimentosexistentescolaborador.push(this.entity.movimentos.splice(i, 1)[0]);
                }
            }

        }

        let temMovFixo: boolean = false;

        // verificando se há movimento fixo
        if (this.entity.movimentosexistentescolaborador.length > 0) {
            temMovFixo = this.entity.movimentosexistentescolaborador.some(movExistente => movExistente.tipoorigem === 1 && movExistente.tipo !== 4);
        }

        // se houver movimentos fixos, exibí-los sempre nas primeiras linhas da tabela
        if (temMovFixo) {
            this.ordenaMovimentosExistentes();
        }

    }

    /**
     * Compara se o array de movimentos existentes vindo da api é igual ao array de movimentos existentes já adicionados na rescisão.
     * Retorna booleano que informa se movimentos são diferentes ou não
     */
    private existeDiferencaMovimentosExistentes(): boolean {
        let movimentosDiferentes: boolean = false;

        // se quantidade de movimentos existentes da rescisão é diferente dos movimentos existentes vindos via api
        if (this.entity.movimentosexistentescolaborador?.length !== this.movimentosExistentes.length) {
            movimentosDiferentes = true;
        } else {

            // checando se todos os guids dos movimentos da rescisão estão nos movimentos existentes vindos via api
            const idsMovimentosExistentesApi = new Set(this.movimentosExistentes.map(movimento => movimento.tipoorigemregistro));
            const todosIdsPresentes = this.entity.movimentosexistentescolaborador!.every(movimentoRescisao => idsMovimentosExistentesApi.has(movimentoRescisao.tipoorigemregistro));

            if (!todosIdsPresentes) {
                movimentosDiferentes = true;
            } else {
                // verificar se houve alteração nos dados dos movimentos
                const mapMovimentosExistentesApi = new Map(this.movimentosExistentes.map(movimentoExistenteApi => [movimentoExistenteApi.tipoorigemregistro, movimentoExistenteApi]));

                // verificando se propriedades conteudo e evento estão iguais nos objetos correspondentes
                movimentosDiferentes = !this.entity.movimentosexistentescolaborador!.every(movimentoExistenteRescisao => {
                    const movimentoExistenteApi = mapMovimentosExistentesApi.get(movimentoExistenteRescisao.tipoorigemregistro);

                    return (
                        movimentoExistenteApi !== undefined &&
                        movimentoExistenteRescisao.conteudo.toString() === movimentoExistenteApi.conteudo.toString() &&
                        movimentoExistenteRescisao.evento.evento === movimentoExistenteApi.evento.evento
                    );
                });
            }
        }

        return movimentosDiferentes;

    }

    /**
     * Ordena os movimentos existentes para manter os fixos sempre nas primeiras linhas da tabela e também ordena os movimentos fixos baseados nos seus tipos
     */
    private ordenaMovimentosExistentes() {

        // ordenação dos tipos nos movimentos fixos
        const ordemTipo = [
            TipoMovimentoTrabalhadorEnum.EMPRESA, TipoMovimentoTrabalhadorEnum.ESTABELECIMENTO,
            TipoMovimentoTrabalhadorEnum.SINDICATO, TipoMovimentoTrabalhadorEnum.DEPARTAMENTO,
            TipoMovimentoTrabalhadorEnum.LOTACAO, TipoMovimentoTrabalhadorEnum.CARGO
        ];

        this.entity.movimentosexistentescolaborador!.sort((movA, movB) => {
            const movAFixo = movA.tipoorigem === 1 && movA.tipo !== 4;
            const movBFixo = movB.tipoorigem === 1 && movB.tipo !== 4;

            if (movAFixo && !movBFixo) {
                return -1; // coloca movA antes de movB
            } else if (!movAFixo && movBFixo) {
                return 1; // coloca movB antes de movA
            } else if (movAFixo && movBFixo) {
                return ordemTipo.indexOf(movA.tipo) - ordemTipo.indexOf(movB.tipo); // os dois são movimentos fixos, ordenar baseado no tipo
            } else {
                return 0; // mantém a ordem original
            }
        });

    }

    // fim - tratamento de dados
}
