
import angular from 'angular';

// controllers
import { MeurhAcompanhamentoListController } from './index/acompanhamento.index.controller';
import { MeurhAcompanhamentoModalController } from './components/modal/acompanhamentomodal.controller';
import { MeurhModalEstabilidadeController } from './components/modal/modal-estabilidade.controller';

// services
import { MeurhAcompanhamentoService } from './acompanhamento.service';
import { MeurhAcompanhamentoModalService } from './components/modal/acompanhamentomodal.service';

// components
import { MeurhAcompanhamentoDefault } from './index/acompanhamento.component';

export const MeurhAcompanhamentoModule = angular.module('MeurhAcompanhamentoModule', ['ui.router.state'])
    .controller('MeurhAcompanhamentoIndexController', MeurhAcompanhamentoListController)
    .controller('MeurhAcompanhamentoModalController', MeurhAcompanhamentoModalController)
    .controller('MeurhModalEstabilidadeController', MeurhModalEstabilidadeController)
    .service('MeurhAcompanhamentoService', MeurhAcompanhamentoService)
    .service('MeurhAcompanhamentoModalService', MeurhAcompanhamentoModalService)
    .component('meurhAcompanhamentoDefault', MeurhAcompanhamentoDefault)
    .name;
