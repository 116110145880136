import ng from 'angular';
import angular from 'angular';
import { MeurhSolicitacoesgenericasService } from '../solicitacoesgenericas.service';
import { TipoSolicitacaoEnum } from '../../../../shared/enums/TipoSolicitacaoEnum';
import { ISolicitacoesGenericasCreate } from '../models/solicitacaogenerica.model';

export class MeurhSolicitacoesgenericasNewController implements ng.IController {

    static $inject = [
        'entity',
        '$scope',
        '$rootScope',
        '$stateParams',
        '$state',
        'MeurhSolicitacoesgenericasService',
        'NewToaster',
    ];

    public action: string = 'insert';
    public tipo: string;
    public form: angular.IFormController;
    public allowRefresh: boolean = false;
    public formchanged: boolean = false;
    public busy: boolean = false;

    public tipoSolicitacaoEnum = TipoSolicitacaoEnum;

    // documentos anexos
    public dropzone = document.getElementById('dropzone_outras_solicitacoes');
    public input = document.getElementById('input_dropzone');

    public tiposPermitidos = ['image/jpeg', 'image/png', 'application/pdf'];
    public magicNumbersPermitidos: { [key: string]: string } = {
        '25504446': 'application/pdf',
        '89504E47': 'image/png',
        'FFD8FFE0': 'image/jpeg', // jpeg e jpg
    };

    // lista de arquivos anexados
    public base64Files: {
        nome: string,
        arquivo: string, // string base64
        tamanho: number,
        extensao: string,
    }[] = [];

    public qtdMaximaAnexos: number;
    public qtdMinimaAnexos: number;
    public anexoObrigatorio: boolean = false;
    public tamanhoMaximoAnexo: number = 15 * 1024 * 1024; // 15MB em bytes

    private toastTimeout: number = 8000;
    private entitySave: ISolicitacoesGenericasCreate | any;

    constructor(
        public entity: any,
        private $scope: angular.IScope,
        private $rootScope: angular.IRootScopeService & {
            configuracoes: any,
        },
        private $stateParams: angular.ui.IStateParamsService,
        private $state: angular.ui.IStateService,
        private entityService: MeurhSolicitacoesgenericasService,
        private NewToaster: any,
    ) {
        this.tipo = this.$stateParams['tipo'];

        this.onSubmitSuccess();
        this.onSubmitError();

        this.allowRefresh = true;

        // configuração do dropzone e estrutura de anexos
        this.entity.anexo = [];

        this.dropzone?.addEventListener('click', () => {
            this.input?.click();
        });

        this.input?.addEventListener('change', (e) => {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (file) {
                this.processaArquivoEnviado(file);
            }
            (e.target as HTMLInputElement).value = ''; // limpa o valor do input para permitir reenvio do mesmo arquivo
        });

        this.dropzone?.addEventListener('dragover', (e) => {
            e.preventDefault();
        });

        this.dropzone?.addEventListener('drop', (e) => {
            e.preventDefault();
            const files = e.dataTransfer?.files;

            if (files && ((files?.length + this.base64Files.length) > this.qtdMaximaAnexos)) {
                this.NewToaster.pop({
                    type: 'warning',
                    title: 'Não foi possível adicionar os anexos',
                    body: `Não é possível adicionar mais do que ${this.qtdMaximaAnexos} arquivo(s).`,
                    timeout: this.toastTimeout
                });
                return;
            }

            if (files && files.length > 0) {
                for (const file of files) {
                    this.processaArquivoEnviado(file);
                }
            }
        });

    }

    /**
     * Recebe o arquivo enviado pelo usuário e faz os processamentos necessários, como validação de formato, tamanho e quantidade de arquivos
     * @param file arquivo enviado pelo usuário
     */
    public processaArquivoEnviado(file: File) {

        // se o tipo do arquivo não estiver explícito, precisa verificar magic number
        if (!file.type) {

            this.obterMagicNumber(file).then((magicNumber) => {
                const magicNumberValido = this.magicNumbersPermitidos[magicNumber];

                if (!magicNumberValido) {
                    // magic number não permitido
                    this.NewToaster.pop({
                        type: 'warning',
                        title: 'O formato do arquivo não é válido',
                        body: 'Os formatos válidos são: JPEG / JPG, PNG e PDF.',
                        timeout: this.toastTimeout
                    });
                    return;
                }

            }).catch((error) => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Erro ao processar arquivo',
                    body: 'Não foi possível validar o tipo do arquivo.',
                    timeout: this.toastTimeout
                });
            });

        } else {
            // verificação de tipo de arquivo
            if (!this.tiposPermitidos.includes(file.type)) {
                this.NewToaster.pop({
                    type: 'warning',
                    title: 'O formato do arquivo não é válido',
                    body: 'Os formatos válidos são: JPEG / JPG, PNG e PDF.',
                    timeout: this.toastTimeout
                });
                return;
            }
        }

        // verificar se o arquivo já está na lista
        // verifica se há extensão no nome do arquivo e remove caso exista
        const nomeSemExtensao = file.name.includes('.')
            ? file.name.split('.').slice(0, -1).join('.')
            : file.name;

        const fileExists = this.base64Files.some(f => f.nome === nomeSemExtensao);
        if (fileExists) {
            this.NewToaster.pop({
                type: 'warning',
                title: 'Arquivo já adicionado',
                body: `O arquivo ${file.name} já foi adicionado.`,
                timeout: this.toastTimeout
            });
            return;
        }

        // verificar se já foi adicionada a quantidade máxima de anexos
        if (this.base64Files.length === this.qtdMaximaAnexos) {
            this.NewToaster.pop({
                type: 'warning',
                title: 'Quantidade máxima atingida',
                body: 'Não é possível adicionar mais anexos pois a quantidade máxima de anexos já foi atingida.',
                timeout: this.toastTimeout
            });
            return;
        }

        // verificando tamanho do arquivo
        if (file.size > this.tamanhoMaximoAnexo) {
            this.NewToaster.pop({
                type: 'warning',
                title: 'Arquivo maior que permitido',
                body: `O arquivo tem ${(file.size / 1024 / 1024).toFixed(2)}MB e o tamanho máximo é ${this.tamanhoMaximoAnexo / 1024 / 1024}MB.`,
                timeout: this.toastTimeout
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = async () => {
            const base64 = reader.result as string;

            // verifica se há extensão no nome do arquivo e remove caso exista
            const nomeSemExtensao = file.name.includes('.')
                ? file.name.split('.').slice(0, -1).join('.')
                : file.name;

            let extensaoArquivo = await this.getExtensaoArquivo(file);
            this.base64Files.push({ nome: nomeSemExtensao, arquivo: base64, tamanho: file.size, extensao: extensaoArquivo });

            this.$scope.$applyAsync();

        };

        reader.onerror = () => {
            this.NewToaster.pop({
                type: 'error',
                title: 'Upload de arquivo não realizado',
                body: `Não foi possível realizar a leitura do arquivo.`,
                timeout: this.toastTimeout
            });
        };

        reader.readAsDataURL(file);
    }

    /**
     * Remove o arquivo da lista de arquivos base64
     * @param nomeArquivoRemovido nome do arquivo para remover
     */
    public removerArquivo(nomeArquivoRemovido: string) {
        this.base64Files = this.base64Files.filter(file => file.nome !== nomeArquivoRemovido);
    }

    /**
     * Informa se exibirá a seção de anexos ou não baseado no tipo selecionado
     */
    public exibeAnexos() {
        const campoAnexo = this.entity.dadosTipoSelecionado?.campos?.find(campoArray => campoArray.codigo === 'anexo');

        // se não exibir anexos, limpar possíveis anexos adicionados previamente
        if (!(campoAnexo?.habilitado ?? false)) {

            this.anexoObrigatorio = false;
            this.base64Files = [];

        } else {
            this.qtdMaximaAnexos = campoAnexo.configuracao.quantidade.maxima;
            this.qtdMinimaAnexos = campoAnexo.configuracao.quantidade.minima;
            this.anexoObrigatorio = campoAnexo.obrigatorio;
        }

        return campoAnexo?.habilitado ?? false;
    }

    /**
     * Método necessário para verificar o tipo de arquivo mesmo quando a extensão não está explícita em seu nome
     * @param file o arquivo a ser verificado
     */
    public obterMagicNumber(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (e) => {
            const arrayBuffer = e.target?.result as ArrayBuffer;
            if (!arrayBuffer) {
              reject('Não foi possível obter o conteúdo do arquivo.');
              return;
            }

            // obter os primeiros bytes
            const bytes = new Uint8Array(arrayBuffer).subarray(0, 4); // ler até 4 bytes, se necessário
            const magicNumber = Array.from(bytes)
              .map((byte) => byte.toString(16).toUpperCase().padStart(2, '0'))
              .join('');

            resolve(magicNumber);
          };

          reader.onerror = () => {
            reject('Erro ao ler o arquivo.');
          };

          // ler os primeiros 4 bytes do arquivo
          reader.readAsArrayBuffer(file.slice(0, 4));
        });
    }

    /**
     * Recebe uma quantidade em bytes e converte para MB ou KB, caso seja uma quantidade muito baixa em MB
     * @param bytes a quantidade de bytes a se converter
     */
    public converterParaMB(bytes: number): string {
        if (!bytes) {
            return '0 MB';
        }

        const megabytes = bytes / (1024 * 1024);

        // se for menor que 0.01 MB, exibe em KB
        if (megabytes < 0.01) {
            const kilobytes = bytes / 1024;
            return `${kilobytes.toFixed(2)} KB`;
        }

        return `${megabytes.toFixed(2)} MB`;
    }

    /**
     * Recebe um arquivo e retorna uma string correspondente ao seu tipo
     * @param file arquivo do tipo File que se deseja saber a extensão
     */
    public getExtensaoArquivo(file: File): Promise<string> {

        return new Promise<string>(async (resolve, reject) => {

            // se o tipo não estiver explícito no nome do arquivo
            if (!file.type) {

                await this.obterMagicNumber(file).then((magicNumber) => {
                    const formatoArquivo = this.magicNumbersPermitidos[magicNumber];

                    switch (formatoArquivo) {
                        case 'image/jpeg':
                            resolve('jpg');
                            break;

                        case 'image/png':
                            resolve('png');
                            break;

                        case 'application/pdf':
                            resolve('pdf');
                            break;

                        default:
                            resolve('');
                            break;
                    }

                }).catch((error) => {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Erro ao processar arquivo',
                        body: 'Não foi possível obter o tipo do arquivo.',
                        timeout: this.toastTimeout
                    });
                    reject(error);
                });

            } else {

                switch (file.type) {

                    case 'image/jpeg':
                        resolve('jpg');
                        break;

                    case 'image/png':
                        resolve('png');
                        break;

                    case 'application/pdf':
                        resolve('pdf');
                        break;

                    default:
                        resolve('');
                        break;
                }
            }

        });
    }

    // início - ações do formulário
    public submit(): void {

        this.alteraEntityEnviar();
        this.form.$submitted = true;

        this.busy = true;
        this.allowRefresh = false;
        this.entityService.save(this.entitySave);
    }

    // fim - ações do formulário

    // início - verificações de validação
    public podeEnviar(): boolean {
        return (
            this.camposObrigatoriosValidos() &&
            this.validaDocumentosAnexos()
        );
    }

    public irParaListagem() {
        this.$state.go('meurh_solicitacoesgenericas');
    }

    // inicio - validações
    private camposObrigatoriosValidos(): boolean {

        let validacaoListaTrabalhadores: boolean = true;
        if (this.entity.exigeTrabalhador && this.entity.listaTrabalhadores.length < 1) {
            validacaoListaTrabalhadores = false;
        }

        return validacaoListaTrabalhadores && this.form.$valid;
    }

    private validaDocumentosAnexos(): boolean {
        return !this.anexoObrigatorio ? true : (this.base64Files.length >= this.qtdMinimaAnexos && this.base64Files.length <= this.qtdMaximaAnexos);
    }
    // fim - validações

    // inicio - pós requisições
    private onSubmitSuccess(): void {
        this.$scope.$on('meurh_solicitacoesgenericas_submitted', (event: angular.IAngularEvent, args: any): void => {

            this.$rootScope.$broadcast('meurh_todas_solicitacoes_submitted', {});

            this.NewToaster.pop({
                type: 'success',
                title: 'Sucesso',
                body: 'A solicitação foi enviada com sucesso',
                timeout: this.toastTimeout
            });

            this.$state.go('meurh_solicitacoesgenericas', this.entityService.constructors);

            this.busy = false;
        });
    }

    private onSubmitError(): void {
        this.$scope.$on('meurh_solicitacoesgenericas_submit_error', (event: angular.IAngularEvent, args: any): void => {
            if (args.response.status === 409) {
                if (confirm(args.response.data.message)) {
                    this.entity[''] = args.response.data.entity[''];
                    this.entityService.save(this.entity);
                }
            } else {
                if (typeof (args.response.data.message) !== 'undefined' && args.response.data.message) {
                    if (args.response.data.message === 'Validation Failed') {
                        if (args.response?.data?.errors?.children) {

                            let msgErro = '';
                            let tituloToaster = 'Não foi possível enviar a solicitação';

                            for (const campo in args.response.data.errors.children) {
                                if (args.response.data.errors.children[campo]['errors']) {
                                    args.response.data.errors.children[campo]['errors'].forEach((element: string) => {
                                        msgErro = msgErro + '&bull; ' + element + '<br>';
                                    });
                                }
                            }
                            this.NewToaster.pop({
                                type: 'error',
                                title: tituloToaster,
                                body: msgErro,
                                bodyOutputType: 'trustedHtml',
                                timeout: this.toastTimeout
                            });
                        } else {
                            this.NewToaster.pop(
                                {
                                    type: 'error',
                                    title: 'Alguns campos obrigatórios não estão preenchidos',
                                    body: 'Preencha os campos obrigatórios assinalados com "*" e tente novamente.',
                                    timeout: this.toastTimeout
                                }
                            );
                        }
                    } else {
                        this.NewToaster.pop(
                            {
                                type: 'error',
                                title: args.response.data.message,
                                timeout: this.toastTimeout
                            }
                        );
                    }
                } else {
                    this.NewToaster.pop(
                        {
                            type: 'error',
                            title: 'Ocorreu um erro ao enviar a solicitação',
                            timeout: this.toastTimeout
                        }
                    );
                }
            }

            this.busy = false;
        });
    }
    // fim - pós requisições

    // inicio - tratamentos de dados
    private alteraEntityEnviar(): void {
        this.entitySave = angular.copy(this.entity);

        if (this.entitySave.listaTrabalhadores.length > 0) {

            this.entitySave.trabalhador = [];

            this.entitySave.listaTrabalhadores.forEach(trabalhador => {
                this.entitySave.trabalhador.push(trabalhador.trabalhador);
            });

        }

        // obtendo os dados necessários dos arquivos para enviar ao backend
        if (this.base64Files.length > 0) {
            this.entitySave.anexo = this.base64Files.map(arquivo => ({
                nome: arquivo.nome,
                arquivo: arquivo.arquivo,
            }));
        }

        // apagando propriedades de controle no front
        delete this.entitySave.exigeTrabalhador;
        delete this.entitySave.listaTrabalhadores;

    }

    // fim - tratamentos de dados
}
