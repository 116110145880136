import * as angular from 'angular';
import { MeurhSolicitacoesrescisoesService } from './solicitacoesrescisoes.service';

export const MeurhSolicitacoesrescisoesListRouting = [
    '$stateProvider',
    function ($stateProvider: angular.ui.IStateProvider): void {
        let resolve = {
            'entity': [
                'MeurhSolicitacoesrescisoesService',
                '$stateParams',
                '$state',
                '$q',
                function (
                    entityService: MeurhSolicitacoesrescisoesService,
                    $stateParams: angular.ui.IStateParamsService,
                    $state: angular.ui.IStateService,
                    $q: angular.IQService
                ): angular.ui.IStateParamsService | angular.IQService | {} {

                if ($stateParams['entity']) {
                    return $stateParams['entity'];
                }

                if (!$stateParams['solicitacao']) {
                    return {};
                }

                return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>) => {
                    entityService.get($stateParams['solicitacao'])
                        .then((data: any) => {
                            resolve(data);
                        })
                        .catch((error: any) => {
                            if (error.status === 404) {
                                if ($state.href('meurh_solicitacoesrescisoes_not_found', $stateParams)) {
                                    $state.go('meurh_solicitacoesrescisoes_not_found', $stateParams);
                                } else {
                                    $state.go('not_found', $stateParams);
                                }
                            }
                        });
                });
            }]
        };

        $stateProvider
            .state('meurh_solicitacoesrescisoes', {
                url: '/solicitacoes/rescisoes?q?tipo',
                template: require('html-loader!./index/solicitacoesrescisoes.index.html'),
                controller: 'Meurh\SolicitacoesrescisoesIndexController',
                controllerAs: 'mrh_slctcsrscss_lst_cntrllr',
                reloadOnSearch: false
            })

            .state('meurh_solicitacoesrescisoes_new', {
                url: '/solicitacoes/rescisoes/new?q?tipo',
                resolve: resolve,
                template: require('html-loader!./new/solicitacoesrescisoes.new.html'),
                controller: 'MeurhSolicitacoesrescisoesNewController',
                controllerAs: 'mrh_slctcsrscss_frm_nw_cntrllr'
            })
            .state('meurh_solicitacoesrescisoes_show', {
                url: '/solicitacoes/rescisoes/:solicitacao?path&tipo',
                resolve: resolve,
                template: require('html-loader!./show/solicitacoesrescisoes.show.html'),
                controller: 'MeurhSolicitacoesrescisoesShowController',
                controllerAs: 'mrh_slctcsrscss_frm_shw_cntrllr'
            })
            .state('meurh_solicitacoesrescisoes_edit', {
                url: '/solicitacoes/rescisoes/:solicitacao/edit?q?tipo',
                resolve: resolve,
                template: require('html-loader!./edit/solicitacoesrescisoes.edit.html'),
                controller: 'MeurhSolicitacoesrecisoesEditController',
                controllerAs: 'mrh_slctcsrscss_frm_edit_cntrllr',
                params: {
                    diferenca_movs_existentes: false
                }
            });
    }
];
