import angular from 'angular';
import moment from 'moment';
import { ITransferenciaColaboradores } from '../../models/transferenciacolaboradores.model';
import { ITrabalhador } from '../../../../Trabalhadores/models/trabalhador.model';
import { Empresa, Estabelecimento } from '../../../../../core/usuario/models/usuario-model';
import { TrabalhadoresService } from '../../../../Trabalhadores/trabalhadores.service';
import { ListagemService } from '../../../../../shared/services/listagem.service';
import { IEstabelecimento } from '../../../../Estabelecimentos/models/estabelecimento.model';

export class MeurhTransferenciaColaboradoresFormController {

  static $inject = [
    '$scope',
    '$http',
    'nsjRouting',
    '$stateParams',
    '$rootScope',
    'TrabalhadoresService',
    'ListagemService'
  ];

  public entity: ITransferenciaColaboradores;
  public form: angular.IFormController;
  public action: string;
  public posteriordatafim: boolean = false;

  // inicio - Controle gestores
  public entitiesTrabalhadores: any;
  public listaTrabalhadores: Array<any>;
  public listaGestores: Array<any> = [];
  // fim - Controle gestores

  public filters = {
    empresa: this.$rootScope.session.entities.empresa.empresa,
    estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
  };
  public empresas: Array<Empresa>;
  public estabelecimentos: Array<Estabelecimento>;
  public listagemPermissao: Array<string> = ['criar_transferencias_vagas'];
  public listagemPermissaoGestores: Array<string> = ['visualizar_transferencias_vagas'];
  public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

  public constructorLookupColaborador;
  public dataAtual: moment.Moment = moment();

  constructor(
    public $scope: angular.IScope,
    public $http: angular.IHttpService,
    public nsjRouting: any,
    public $stateParams: angular.ui.IStateParamsService,
    public $rootScope: angular.IRootScopeService & {
      temPermissao: (arg: string) => boolean,
      temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
      modoGestorPermissao: (arg: string) => boolean,
      liberadoTela: (arg: string) => boolean,
      session: any,
      nsjGlobals: any,
      configuracoes: any,
    },
    public trabalhadoresService: TrabalhadoresService,
    public ListagemService: ListagemService
  ) {
    $scope.$watch('$ctrl.entity', (newValue: any, oldValue: any) => {
      if (this.form && (newValue !== oldValue)) {
        if (newValue.datafim !== null && newValue.datainicio !== null && newValue.datafim !== '' && newValue.datainicio !== '') {
          if (moment(newValue.datainicio).isAfter(moment(newValue.datafim))) {
            this.form.datainicio.$setValidity('posteriordatafim', false);
          } else {
            this.form.datainicio.$setValidity('posteriordatafim', true);
          }
        }

        this.form.$setDirty();
      }
    }, true);
    // controle gestores
    $rootScope.$on('persona_trabalhadores_list_loaded', (event: angular.IAngularEvent, args: any): void => {
      this.listaTrabalhadores = [...args];
      if (this.trabalhadoresService.constructors.tipo !== '1') {
        this.entitiesTrabalhadores = [...args];
      } else {
      this.listaTrabalhadores = [...args];
      }
    });
  }

  $onInit(): void {
    this.montaListagemVisibilidade();
    this.carregaTrabalhadores();

    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.$rootScope.modoGestorPermissao('criar_transferencias_vagas')
    };

    if (this.action === 'insert') {
      this.entity.gestores = [];
    }

    if (this.entity.vaga && this.entity.vaga.ativa) {
      this.entity.ativa = true;
    } else {
      this.entity.ativa = false;
    }

    if (this.action === 'edit') {
      this.entity.gestores.map(gestor => {
        if (gestor.identificacaonasajongestor) {
          gestor.nome = '';
          gestor.tipoGestor = 'Externo';
          gestor.email = gestor.identificacaonasajongestor;
        } else {
          gestor.nome = gestor.gestor!['nome'];
          gestor.tipoGestor = 'Interno';
          gestor.email = gestor.gestor!['email'];
          gestor.gestor = gestor.gestor!['trabalhador'];
        }
      });
    }
  }

  montaListagemVisibilidade(): void {
    if (this.action === 'edit') {
      this.filters.empresa = this.entity.estabelecimentoobj.empresa;
      this.filters.estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
    }
    this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
    this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
  }

  mudaEstabelecimentos(): void {
    this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
    this.filters.estabelecimento = '';
    this.filtraEmpresaEstabelecimento();
  }

  filtraEmpresaEstabelecimento(): void {
    this.carregaTrabalhadores();

    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.$rootScope.modoGestorPermissao('criar_transferencias_vagas')
    };

  }

  carregaTrabalhadores(): void {
    let constructor = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
    };
    this.trabalhadoresService.constructors = constructor;
    this.trabalhadoresService.filter = '';
    this.trabalhadoresService.reload();
  }

  setTrabalhadorFields(fields: {
    estabelecimentodestino: IEstabelecimento,
    departamento: any,
    lotacao: any,
    grupodeponto: any,
  }) {
    this.entity.estabelecimentodestino = fields.estabelecimentodestino;
    this.entity.departamento = fields.departamento;
    this.entity.lotacao = fields.lotacao;
    this.entity.grupodeponto = fields.grupodeponto.grupo ? fields.grupodeponto : null;
    this.refreshFields(this.entity.estabelecimentodestino);
    this.verificaGestores();
  }

  preencheInfosTrabalhador(trabalhadorSelecionado: any): void {
    if (trabalhadorSelecionado && trabalhadorSelecionado.estabelecimentoobj && trabalhadorSelecionado.departamentoobj && trabalhadorSelecionado.lotacaoobj && trabalhadorSelecionado.grupoobj) {
        this.entity.ativa = false;
        delete this.entity.vaga;
        this.setTrabalhadorFields({
          estabelecimentodestino: trabalhadorSelecionado.estabelecimentoobj,
          departamento: trabalhadorSelecionado.departamentoobj,
          lotacao: trabalhadorSelecionado.lotacaoobj,
          grupodeponto: trabalhadorSelecionado.grupoobj
        });
    }
  }

  onChangeTrabalhador(trabalhadorSelecionado: any) {

    if (trabalhadorSelecionado) {
      this.entity.ativa = false;
      delete this.entity.vaga;
      this.setTrabalhadorFields({
        estabelecimentodestino: trabalhadorSelecionado.estabelecimentoobj,
        departamento: trabalhadorSelecionado.departamentoobj,
        lotacao: trabalhadorSelecionado.lotacaoobj,
        grupodeponto: trabalhadorSelecionado.grupoobj
      });
    } else {
      delete this.entity.estabelecimentodestino;
      delete this.entity.lotacao;
      delete this.entity.departamento;
      delete this.entity.grupodeponto;
      this.entity.gestores = [];
    }

  }

  verificaGestores(): void {
    if (this.entity.trabalhador != null) {
      this.inserirGestoresTrabalhadores(this.entity.trabalhador.trabalhador);
      if (this.action === 'insert') {
        this.inserirTrabalhadoresListaGestores();
      }
    } else {
      this.listaTrabalhadores = [];
    }
  }

  inserirGestoresTrabalhadores(trabalhador: string) {
    this.entity.gestores = [];

    this.trabalhadoresService.getGestoresTrabalhador(trabalhador)
      .then((gestores: any) => {

        gestores.forEach(gestor => {
          this.entity.gestores.unshift({
            nome: gestor.gestor ? gestor.nome : '',
            email: gestor.identificacaonasajongestor,
            tipoGestor: gestor.gestor ? 'Interno' : 'Externo',
            gestor: gestor.gestor
          });
        });

      });
  }

  inserirTrabalhadoresListaGestores() {
    this.listaGestores = [];
    this.entitiesTrabalhadores.map((trabalhador: ITrabalhador) => {
      this.listaGestores.push({ nome: trabalhador.nome, trabalhador: trabalhador.trabalhador});
    });
  }

  validarVaga() {
    delete this.entity.vaga;
    if (this.entity.ativa === true) {
      delete this.entity.estabelecimentodestino;
      delete this.entity.lotacao;
      delete this.entity.departamento;
      delete this.entity.grupodeponto;
      this.entity.gestores = [];
    } else {
      this.preencheInfosTrabalhador(this.entity.trabalhador);
    }
  }

  refreshFields(estabelecimentoDestino?: IEstabelecimento, emptyFields: boolean = false): void {
    if (emptyFields) {
      delete this.entity.lotacao;
      delete this.entity.departamento;
    } else {
      this.$rootScope.$broadcast('estabelecimentodestino_refresh', {
        filter: this.entity.trabalhador.empresaobj.codigo
      });
    }

    const estabelecimentoTrabalhador = this.action === 'edit' ?
      this.entity.estabelecimentoobj.estabelecimento :
      this.entity.trabalhador.estabelecimentoobj.estabelecimento;

    if (this.entity.ativa !== true) {
      this.$rootScope.$broadcast('vaga_refresh', {
        estabelecimento: estabelecimentoTrabalhador
      });
    }

    const estabelecimento = estabelecimentoDestino ?
      estabelecimentoDestino.estabelecimento :
      estabelecimentoTrabalhador;

    this.$rootScope.$broadcast('lotacao_refresh', { estabelecimento });
    this.$rootScope.$broadcast('departamento_refresh', { estabelecimento });
  }

}
