import angular from 'angular';
import moment from 'moment';
import { ApuracoesPontoService } from '../apuracoesponto.service';
import { PagamentosPontoService } from '../../Pagamentos/pagamentos.service';
import { FolhasdePontoService } from '../../FolhasDePonto/folhasdeponto.service';
import { IApuracaoPonto } from './../../models/apuracaoponto.model';

export class ApuracaoPontoController implements angular.IController {

    static $inject = [
        '$scope',
        '$rootScope',
        '$state',
        'ModalConfirmService',
        'PagamentosPontoService',
        'FolhasdePontoService',
        'NewToaster',
    ];

    public entities: Array<IApuracaoPonto>;
    public apuracoesPontoService: ApuracoesPontoService;
    public busy: boolean;
    public nomeArquivoDownload: string = '';
    public prefixoArquivo = 'folhasponto-';

    constructor(
        public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            configuracoes: any,
            session: any
        },
        public $state: angular.ui.IStateService,
        public ModalConfirmService: any,
        public PagamentosPontoService: PagamentosPontoService,
        public FolhasPontoService: FolhasdePontoService,
        public NewToaster: any,
    ) {
        $rootScope.$on('apuracoesponto_fechado', (event: angular.IAngularEvent, args: any) => {
            this.atualizaApuracao(args);
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'A apuração de ponto foi fechada com sucesso!',
            });
        });

        $rootScope.$on('apuracoesponto_fechar_erro', (err: any) => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao fechar a apuração.',
            });
        });

        $rootScope.$on('apuracoesponto_reaberto', (event: angular.IAngularEvent, args: any) => {
            this.atualizaApuracao(args);
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'A apuração de ponto foi reaberta com sucesso!',
            });
        });

        $rootScope.$on('apuracoesponto_reabrir_erro', (err: any) => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Pagamentos já foram efetuados para essa apuração de ponto! Não é possível reabrir a apuração!',
            });
        });

        $scope.$on('apuracoesprocessamento_iniciaprocessamentofolhas_iniciado', (event, args: any) => {
            this.atualizaApuracaoProcessamento(args);
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                body: `As folhas de ponto foram enviadas para processamento.
                    Uma notificação será enviada para o seu e-mail assim que for concluído.`,
            });
        });

        $scope.$on('apuracoesprocessamento_iniciaprocessamentofolhas_erro', () => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao gerar as folhas de ponto.',
            });
        });

        $scope.$on('apuracoesprocessamento_downloadfolhaslote_sucesso', (event: angular.IAngularEvent, args: any) => {
            this.trataDownload(args.data);
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Download realizado com sucesso!',
            });
        });

        $rootScope.$on('apuracoesprocessamento_downloadfolhaslote_erro', (err: any) => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro no download.',
            });
        });

        $rootScope.$on('apuracoesponto_regerarpagamentos_iniciado', (event: angular.IAngularEvent, args: any) => {
            this.atualizaApuracao(args);
            this.busy = false;
            this.NewToaster.pop({
                type: 'success',
                title: 'Os pagamentos foram gerados com sucesso!',
                // body: 'Os relatórios foram enviados para a fila de processamento. Uma notificação será enviada para o seu e-mail assim que for concluído.',
            });
        });

        $rootScope.$on('apuracoesponto_regerarpagamentos_erro', (err: any) => {
            this.busy = false;
            this.NewToaster.pop({
                type: 'error',
                title: 'Ocorreu um erro ao gerar os pagamentos.',
            });
        });

        $scope.$watch('$ctrl.entities', (newValue: any, oldValue: any): void => {
			if (newValue !== oldValue) {
                this.getAccordionTitles();
			}
		}, true);
    }

    $onInit() {
        this.getAccordionTitles();
    }

    getAccordionTitles() {
        this.entities.forEach((element: IApuracaoPonto) => {
            const inicioapuracao = moment(element.inicioapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY');
            const terminoapuracao = moment(element.terminoapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY');
            element['titleaccordion'] = 'Apuração ' + inicioapuracao + ' a ' + terminoapuracao + this.getReferenciaApuracao(element);
            if (element.lastupdate) {
                const recalculo = moment(element.lastupdate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
                element['subtitleaccordion'] = 'Último recálculo em ' + recalculo;
            }
            this.verificaDataApuracao(element);
        });
    }

    getReferenciaApuracao(apuracao: IApuracaoPonto) {
        if (apuracao.trabalhadorobj?.trabalhador) {
            return ` - Trabalhador: ${apuracao.trabalhadorobj.nome} `;
        }
        if (apuracao.lotacaoobj?.lotacao) {
            return ` - Lotação: ${apuracao.lotacaoobj.nome} `;
        }
        if (apuracao.estabelecimentoobj?.estabelecimento) {
            return ` - Estabelecimento: ${apuracao.estabelecimentoobj.nomefantasia} `;
        }
        if (apuracao.empresaobj?.empresa) {
            return ` - Empresa: ${apuracao.empresaobj.razaosocial} `;
        }
        return ' ';
    }

    atualizaApuracao(args: any) {
        let found = false;
        for (let i = 0; i < this.entities.length; i++) {
            if (found === false && this.entities[i].apuracaoponto === args.data.apuracaoponto) {
                this.entities[i] = angular.copy(args.data);
                found = true;
            }
        }
        this.getAccordionTitles();
    }

    atualizaApuracaoProcessamento(args: any) {
        let found = false;
        for (let i = 0; i < this.entities.length; i++) {
            if (found === false && this.entities[i].apuracaoponto === args.data.apuracaoponto.apuracaoponto) {
                this.entities[i].apuracaoprocessamento = angular.copy(args.data);
                found = true;
            }
        }
        this.getAccordionTitles();
    }

    download(apuracao: IApuracaoPonto): void {
        this.busy = true;
        this.nomeArquivoDownload = '';
        let inicioapuracao = moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('DD-MM-YYYY');
        let terminoapuracao = moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('DD-MM-YYYY');
        this.nomeArquivoDownload = this.prefixoArquivo + inicioapuracao + '-a-' + terminoapuracao;
        this.apuracoesPontoService.download(apuracao);
    }

    fechar(apuracao: IApuracaoPonto): void {
        let dataHoje = new Date();
        if (moment(dataHoje).isAfter(apuracao.terminoapuracao, 'day')) {
            this.busy = true;
            this.apuracoesPontoService.fechar(apuracao);
        } else {
            this.NewToaster.pop({
                type: 'error',
                title: 'Não foi possível fechar a apuração.',
                body: 'Não é possível fechar uma apuração cuja data de término (' + moment(apuracao.terminoapuracao).format('DD/MM/YYYY') + ') seja igual ou posterior a data de hoje (' + moment(dataHoje).format('DD/MM/YYYY') + ').',
            });
        }
    }

    reabrir(apuracao: IApuracaoPonto): void {
        this.busy = true;
        this.apuracoesPontoService.reabrir(apuracao);
    }

    fecharConfirm(apuracao: IApuracaoPonto): void {
        let inicioapuracao = moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY');
        let terminoapuracao = moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY');
        let textos = {
            title: 'Fechar Apuração',
            subtitle: 'Após fechar a apuração, os colaboradores não poderão fazer alterações em seus pontos. Além disso serão geradas suas folhas de ponto e a prévia de seus pagamentos, sendo que essa última, ainda irá requerer uma liberação para que seja de fato disponibilizada.',
            confirmText: 'Você tem certeza que deseja fechar a apuração de ' + inicioapuracao + ' a ' + terminoapuracao + '? Após sua confirmação, os itens supracitados irão para uma fila de processamento, e após a conclusão, você será notificado por email e poderá acessá-los. ',
            confirmTextNum: '',
            closeButton: 'Cancelar',
            confirmButton: 'Sim, fechar apuração',
        };
		let confirm = this.ModalConfirmService.open(apuracao, 'Apuração', false, textos);

        confirm.result.then((): void => {
            this.fechar(apuracao);
        })
        .catch((error: any): void => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
    }

    reabrirConfirm(apuracao: IApuracaoPonto): void {
        let inicioapuracao = moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY');
        let terminoapuracao = moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY');
        let textos = {
            title: 'Reabrir Apuração',
            subtitle: 'Ao reabrir a apuração, alterações poderão ser feitas nos pontos dos colaboradores, e as folhas de ponto e as prévias de pagamentos gerados anteriormente serão descartadas.',
            confirmText: 'Você tem certeza que deseja reabrir a apuração de ' + inicioapuracao + ' a ' + terminoapuracao + ' ',
            confirmTextNum: '',
            closeButton: 'Cancelar',
            confirmButton: 'Sim, reabrir apuração',
        };
		let confirm = this.ModalConfirmService.open(apuracao, 'Apuração', false, textos);

        confirm.result.then((): void => {
            this.reabrir(apuracao);
        })
        .catch((error: any): void => {
            if (error !== 'backdrop click' && error !== 'cancelar' && error !== 'escape key press') {
                this.NewToaster.pop({
                    type: 'error',
                    title: error
                });
            }
        });
    }

    trataDownload(data: any): void {
        var blob = new Blob([data]);
        let fileURL = (window.URL || window.webkitURL).createObjectURL(blob);
        var a = document.createElement('a');

        let nomeArquivo = '';
        nomeArquivo = this.nomeArquivoDownload + '.zip';

        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = fileURL;
        a.download = nomeArquivo;
        a.click();
        window.URL.revokeObjectURL(fileURL);
    }

    goToAnalises(entity: IApuracaoPonto): void {
        this.$state.go('analisesponto', {'apuracaoponto': entity.apuracaoponto});
    }

    gerarFolhasdeponto(entity: IApuracaoPonto) {
        this.busy = true;
        this.FolhasPontoService.gerarFolhas(entity);
    }

    regerarFolhasdeponto(apuracao: IApuracaoPonto) {
        const inicioapuracao = moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY');
        const terminoapuracao = moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('DD/MM/YYYY');
        const textos = {
            title: 'Gerar novamente',
            confirmText: `Você tem certeza que deseja gerar novamente
                os relatórios da apuração de ${inicioapuracao} a ${terminoapuracao}?`,
            closeButton: 'Cancelar',
            confirmButton: 'Sim, gerar novamente',
        };

		this.ModalConfirmService.open(apuracao, 'Apuração', false, textos).result
            .then(() => {
                this.gerarFolhasdeponto(apuracao);
            }).catch(() => {/**/});
    }

    gerarPagamentos(entity: IApuracaoPonto): void {
        this.busy = true;
        this.PagamentosPontoService.regerarPagamentos(entity.apuracaoponto);
    }

    verificaDataApuracao(apuracao: IApuracaoPonto): void {
        let dataAtual = moment().format('YYYY-MM');
        let dataAtualMenosUmMes = moment().subtract(1, 'month').format('YYYY-MM');

        // verifica se existe algum periodo com um mes anterior ao mes da data atual ou no mesmo mes
        if (dataAtualMenosUmMes === moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('YYYY-MM') || dataAtualMenosUmMes === moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('YYYY-MM')) {
            apuracao.initopenaccordion = true;
        } else if (dataAtual === moment(apuracao.inicioapuracao, 'YYYY-MM-DD').format('YYYY-MM') || dataAtual === moment(apuracao.terminoapuracao, 'YYYY-MM-DD').format('YYYY-MM')) {
            apuracao.initopenaccordion = true;
        } else {
            apuracao.initopenaccordion = false;
        }
    }

    valorExiste(valor: any): boolean {
        return valor !== '' && valor !== undefined && valor !== null ? true : false;
    }
}

