import * as angular from 'angular';
import { MeurhSolicitacoesgenericasService } from './solicitacoesgenericas.service';

export const MeurhSolicitacoesgenericasListRouting = [
    '$stateProvider',
    function($stateProvider: angular.ui.IStateProvider): void {
        let resolve = {
            'entity': [
                'MeurhSolicitacoesgenericasService',
                '$stateParams',
                '$state',
                '$q',
                function(
                    entityService: MeurhSolicitacoesgenericasService,
                    $stateParams: angular.ui.IStateParamsService,
                    $state: angular.ui.IStateService,
                    $q: angular.IQService
                ): angular.ui.IStateParamsService | angular.IQService | {} {
                    if ($stateParams['entity']) {
                        return $stateParams['entity'];
                    }

                    if (!$stateParams['solicitacao']) {
                        return {};
                    }

                    return $q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
                        entityService.get($stateParams['solicitacao'])
                            .then((data: any): void => {
                                resolve(data);
                            })
                            .catch((error: any): void => {
                                if (error.status === 404) {
                                    if ($state.href('meurh_solicitacoesgenericas_not_found', $stateParams)) {
                                        $state.go('meurh_solicitacoesgenericas_not_found', $stateParams);
                                    } else {
                                        $state.go('not_found', $stateParams);
                                    }
                                }
                            });
                    });
                }
            ]
        };

        $stateProvider
            .state('meurh_solicitacoesgenericas', {
                url: '/solicitacoes/genericas?q?estabelecimento',
                template: require('html-loader!./index/solicitacoesgenericas.index.html'),
                controller: 'Meurh\SolicitacoesgenericasIndexController',
                controllerAs: 'mrh_solicitacoesgenericas_lst_cntrllr',
                reloadOnSearch: false
            })
            .state('meurh_solicitacoesgenericas_new', {
                url: '/solicitacoes/genericas/new',
                resolve: resolve,
                template: require('html-loader!./new/solicitacoesgenericas.new.html'),
                controller: 'MeurhSolicitacoesgenericasNewController',
                controllerAs: 'mrh_solicitacoesgenericas_frm_nw_cntrllr'
            })
            .state('meurh_solicitacoesgenericas_get', {
                url: '/solicitacoes/genericas/show/:solicitacao?path',
                resolve: resolve,
                template: require('html-loader!./show/solicitacoesgenericas.show.html'),
                controller: 'MeurhSolicitacoesgenericasShowController',
                controllerAs: 'mrh_solicitacoesgenericas_frm_shw_cntrllr'
            });
    }
];
