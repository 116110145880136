import ng from 'angular';
import { TipoMovimentoTrabalhadorEnum } from '../../enums/tipomovimentotrabalhador';
import { ModalAdicionarMovimentoRescisaoService } from '../../modal/adicionar-movimento-rescisao/modaladicionarmovimentorescisao.service';

export class MeurhMovimentosRescisaoController implements ng.IController {

    static $inject = [
        'ModalAdicionarMovimentoRescisaoService',
        '$scope',
    ];

    public entity: any;
    public visualizacao: boolean;
    public exibeMovimentosExistentes: boolean;

    constructor(
        private ModalAdicionarMovimentoRescisaoService: ModalAdicionarMovimentoRescisaoService,
        public $scope: angular.IScope,
    ) {}

    public getNomeTipoMovimento(tipoMovimento: number) {
        switch (tipoMovimento) {
            case TipoMovimentoTrabalhadorEnum.DEPARTAMENTO:
                return 'Departamento';

            case TipoMovimentoTrabalhadorEnum.ESTABELECIMENTO:
                return 'Estabelecimento';

            case TipoMovimentoTrabalhadorEnum.LOTACAO:
                return 'Lotação';

            case TipoMovimentoTrabalhadorEnum.EMPRESA:
                return 'Empresa';

            case TipoMovimentoTrabalhadorEnum.TRABALHADOR:
                return 'Trabalhador';

            case TipoMovimentoTrabalhadorEnum.SINDICATO:
                return 'Sindicato';

            case TipoMovimentoTrabalhadorEnum.CARGO:
                return 'Cargo';

            default:
                return 'Não informado';
        }
    }

    public formataConteudoMovimento(conteudo: string | number, unidade: number): string {

        let unidadeTexto;

        switch (unidade) {
            case 0:
            case 8:
            case 10:
                unidadeTexto = 'hours';
                break;
            case 1:
                unidadeTexto = 'number';
                break;
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 9:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
                unidadeTexto = 'money';
                break;
            default:
                unidadeTexto = 'number';
                break;
        }

        switch (unidadeTexto) {
            case 'hours':
                return conteudo ? this.formataHoras(conteudo) : '-';
            case 'money':
                return conteudo ? this.formataDinheiro(conteudo) : '-';
            case 'number':
                return conteudo ? this.formataInteiro(conteudo) : '-';
            default:
                return 'Unidade não suportada';
        }
    }

    public formataHoras(conteudo: string | number): string {
        const numValue = typeof conteudo === 'string' ? parseFloat(conteudo) : conteudo;
        const hours = Math.floor(numValue);
        const minutes = Math.round((numValue - hours) * 60);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }

    public formataDinheiro(conteudo: string | number): string {
        const numValue = typeof conteudo === 'string' ? parseFloat(conteudo) : conteudo;
        return `R$ ${numValue.toFixed(2).replace('.', ',')}`;
    }

    public formataInteiro(conteudo: string | number): string {
        return String(conteudo);
    }

    public acaoMovimento (movimento: any, acao: 1 | 2) {
        movimento.tipoorigemstatus = acao;
    }

    public desfazerAcaoMovimento (movimento: any) {
        delete movimento.tipoorigemstatus;
    }

    public abrirModalAdicionarMovimento () {
        const confirm: ng.ui.bootstrap.IModalInstanceService = this.ModalAdicionarMovimentoRescisaoService.open(this.entity);

        confirm.result.then((movimentoAdicionado): void => {
            if (!this.entity.movimentos) {
                this.entity.movimentos = [];
            }

            this.entity.movimentos.push(movimentoAdicionado);
        })
        .catch((error: any): void => {/* */ });
    }

    public removerMovimentoAdicionado (movimento: any) {
        let indexRemover = this.entity.movimentos?.indexOf(movimento);

        if (indexRemover !== null && indexRemover !== undefined && indexRemover !== -1) {
            this.entity.movimentos?.splice(indexRemover, 1);
        }
    }

}
