import angular from 'angular';
import { IEtapaPontoRescisao, ISaldoPontoRescisao, ITratamentoPontoRescisao } from './rescisao.ponto.model';

export class RescisaoPontoService {
    static $inject = ['$http', 'nsjRouting', '$rootScope', '$q'];

    public loading_deferred: angular.IDeferred<unknown>;

    constructor(
        public $http: angular.IHttpService,
        public nsjRouting: any,
        public $rootScope: angular.IRootScopeService,
        public $q: angular.IQService,
    ) {}

    setEtapa(id: string, data: {etapa: number, etapadetalhe: number}) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve, reject) => {
            this.$http.post<IEtapaPontoRescisao>(
                this.nsjRouting.generate('meurh_solicitacoesrescisaoetapas_insert', {
                    solicitacaorescisao: id
                }, true),
                data,
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('meurh_solicitacoesrescisaoetapas_insert_submitted', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                this.$rootScope.$broadcast('meurh_solicitacoesrescisaoetapas_insert_submit_error', response);
                reject(response);
            });
        });
    }

    getEtapa(id: string) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve, reject) => {
            this.$http.get<IEtapaPontoRescisao[]>(
                this.nsjRouting.generate('meurh_solicitacoesrescisaoetapas_busca', {
                    solicitacaorescisao: id
                }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('meurh_solicitacoesrescisaoetapas_busca_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }

    getSaldo(id: string) {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve, reject) => {
            this.$http.get<ISaldoPontoRescisao>(
                this.nsjRouting.generate('meurh_solicitacoesrescisaoetapas_saldo', {
                    solicitacaorescisao: id
                }, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('meurh_solicitacoesrescisaoetapas_saldo_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }

    getTratamento() {
        this.loading_deferred = this.$q.defer();
        return this.$q((resolve, reject) => {
            this.$http.get<ITratamentoPontoRescisao>(
                this.nsjRouting.generate('meurh_solicitacoesrescisaoetapas_saldoagrupado', {}, true),
                { timeout: this.loading_deferred.promise }
            )
            .then((response) => {
                this.$rootScope.$broadcast('meurh_solicitacoesrescisaoetapas_saldoagrupado_loaded', response.data);
                resolve(response.data);
            })
            .catch((response) => {
                reject(response);
            });
        });
    }
}
