import angular from 'angular';
import { LoadParams } from '../../../shared/models/loadparams.model';

export class MeurhAcompanhamentoService {
	static $inject = ['$http', 'nsjRouting', '$rootScope', '$q', 'Municipios'];

	public loading_deferred_filter: any = null;
	public entity: any = {};
	public solicitacao: string;
	public entities: Array<any> = [];
	public loaded: boolean = false;
	public constructors: any = {};
	public after: any = '';
	public filters: any = {};
	public loadParams: LoadParams;
	public loading_deferred: any = null;
	public filter: string = '';

	constructor(
		public $http: angular.IHttpService,
		public nsjRouting: any,
		public $rootScope: angular.IScope,
		public $q: angular.IQService,
		public Municipios: any
	) {
		this.loadParams = new LoadParams();
	}

	reload(): Array<any> {
		if (this.loading_deferred) {
			this.loading_deferred.resolve();
		}

		this.loadParams.finished = false;
		this.loadParams.to_load = 3;
		this.after = {};
		this.loaded = false;
		this.entities.length = 0;
		this.loadParams.busy = false;

		return this.load();
	}

	_load(constructors: any, offset: any, filter: any): angular.IHttpPromise<any> {
		this.loading_deferred = this.$q.defer();

		return this.$q((resolve, reject): void => {
			this.$http({
				method: 'GET',
				url: this.nsjRouting.generate('meurh_solicitacoeshistoricosController_index', angular.extend({}, constructors, { 'offset': offset, 'filter': filter }, this.filters), true),
				timeout: this.loading_deferred.promise
			})
			.then(function (response: any) {

				// removendo acompanhamentos sem anexos, com tipohistorico 6 e com valorantigo e valornovo vazios
				let historicosFiltrados = angular.copy(response.data);

				// campos podem vir como uma string
				historicosFiltrados.forEach(historico => {

					if (typeof historico.valorantigo === 'string') {
						historico.valorantigo = JSON.parse(historico.valorantigo);
					}

					if (typeof historico.valornovo === 'string') {
						historico.valornovo = JSON.parse(historico.valornovo);
					}

				});

				historicosFiltrados = historicosFiltrados.filter((historico) => {
					if (!(!historico.anexos && historico.tipohistorico === 6 &&
						(!historico.valorantigo || historico.valorantigo.campos.length === 0) &&
						(!historico.valornovo || historico.valornovo.campos.length === 0))
					) {
						return historico;
					}
				});

				resolve(historicosFiltrados);
			});
		});

	}

	load(): Array<any> {
		this.entities = [];

		if (!this.loadParams.busy && !this.loadParams.finished && this.loadParams.to_load > 0) {
			this.loadParams.busy = true;

			this._load(this.constructors, this.after, this.filter)
				.then((data: any): void => {
					if (data.length > 0) {
						for (let i = 0; i < data.length; i++) {
							this.entities.push(data[i]);
						}
					}

					if (data.length < 20 || this.loadParams.to_load === 1) {
						this.loadParams.finished = true;
						this.$rootScope.$broadcast('meurh_solicitacoeshistoricos_list_finished', this.entities);
					} else {
						this.loadParams.finished = false;
					}

					this.loaded = true;
					this.loadParams.to_load--;
				})
				.catch((error): void => {
					if (error.xhrStatus !== 'abort') {
						this.loadParams.finished = true;
					}
				})
				.finally((): void => {
					if (this.loaded || this.loadParams.finished) {
						this.loadParams.busy = false;
					}

					this.load();
				});
		}

		return this.entities;
	}

	loadMore(): void {
		this.loadParams.to_load = 3;
		this.load();
	}
}
