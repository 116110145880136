import angular from 'angular';

export class ApontamentosTrabalhadoresFormComponent implements angular.IComponentOptions {
    static selector = 'apontamentosTrabalhadoresFormComponent';
    static controller = 'ApontamentosTrabalhadoresFormController';
    static template = require('!!html-loader!./apontamentostrabalhadores.form.html');
    static transclude = true;
    static bindings = {
        entity: '=',
        form: '<',
        visualizacao: '<'
    };
}
