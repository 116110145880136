
import * as angular from 'angular';

import { MeurhSolicitacoesgenericasIndexController } from './index/solicitacoesgenericas.index.controller';
import { MeurhSolicitacoesgenericasShowController } from './show/solicitacoesgenericas.show.controller';
import { MeurhSolicitacoesgenericasNewController } from './new/solicitacoesgenericas.new.controller';
import { MeurhSolicitacoesgenericasFormController } from './form/dadossolicitacaogenerica/solicitacoesgenericas.form.controller';
import { MeurhSolicitacoesgenericasOutrosDadosFormController } from './form/outrosdados/outrosdados.form.controller';
import { MeurhSolicitacoesgenericasModalConfirmController } from './modal/modalsolicitacaogenerica.confirm.controller';
import { MeurhSolicitacoesgenericasDocumentosanexosFormController } from './documentosanexos/documentosanexos.form.controller';
import { MeurhSolicitacoesgenericasFormComponent } from './form/dadossolicitacaogenerica/solicitacoesgenericas.form.component';
import { MeurhSolicitacoesgenericasOutrosDadosFormComponent } from './form/outrosdados/outrosdados.form.component';
import { MeurhSolicitacoesgenericasDocumentosanexosFormComponent } from './documentosanexos/documentosanexos.form.component';
import { MeurhSolicitacoesgenericasService } from './solicitacoesgenericas.service';
import { MeurhTipossolicitacoesgenericasService } from './tipossolicitacoesgenericas.service';
import { MeurhSolicitacoesgenericasModalConfirmService } from './modal/modalsolicitacaogenerica.confirm.service';
import { MeurhSolicitacoesgenericasListRouting } from './solicitacoesgenericas.routes';

export const MeurhSolicitacoesgenericasModule = angular.module('MeurhSolicitacoesgenericasModule', ['ui.router.state', ])
    .controller('MeurhSolicitacoesgenericasIndexController', MeurhSolicitacoesgenericasIndexController)
    .controller('MeurhSolicitacoesgenericasShowController', MeurhSolicitacoesgenericasShowController)
    .controller('MeurhSolicitacoesgenericasNewController', MeurhSolicitacoesgenericasNewController)
    .controller('MeurhSolicitacoesgenericasFormController', MeurhSolicitacoesgenericasFormController)
    .controller('MeurhSolicitacoesgenericasOutrosDadosFormController', MeurhSolicitacoesgenericasOutrosDadosFormController)
    .controller('MeurhSolicitacoesgenericasModalConfirmController', MeurhSolicitacoesgenericasModalConfirmController)
    .controller('MeurhSolicitacoesgenericasDocumentosanexosFormController', MeurhSolicitacoesgenericasDocumentosanexosFormController)
    .component('meurhSolicitacoesgenericasFormComponent', MeurhSolicitacoesgenericasFormComponent)
    .component('meurhSolicitacoesgenericasOutrosdadosFormComponent', MeurhSolicitacoesgenericasOutrosDadosFormComponent)
    .component('meurhSolicitacoesgenericasDocumentosanexosFormComponent', MeurhSolicitacoesgenericasDocumentosanexosFormComponent)
    .service('MeurhSolicitacoesgenericasService', MeurhSolicitacoesgenericasService)
    .service('MeurhTipossolicitacoesgenericasService', MeurhTipossolicitacoesgenericasService)
    .service('MeurhSolicitacoesgenericasModalConfirmService', MeurhSolicitacoesgenericasModalConfirmService)
    .config(MeurhSolicitacoesgenericasListRouting)
    .name;
