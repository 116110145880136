declare var nsjGlobals: any;
import moment from 'moment';
import angular from 'angular';
import { ListagemService } from '../../../../shared/services/listagem.service';
import { IAfastamento } from '../models/afastamento.model';
import { IAfastamentoMotivo } from '../models/afastamentomotivo.model';
import { Empresa, Estabelecimento } from '../../../../core/usuario/models/usuario-model';
import { MeurhAfastamentosService } from '../afastamentos.service';

export class MeurhAfastamentosFormController {
  static $inject = [
    '$scope',
    '$rootScope',
    'ListagemService',
    'MeurhAfastamentosService',
    'NewToaster',
  ];

  public entity: IAfastamento;
  public form: angular.IFormController;
  public tipo: string = '-1';
  public busy: boolean = false;
  public countFunctionDiasAfastamento: number = 0;
  public action: string;

  public dataAtual: moment.Moment = moment();
  public motivos: Array<IAfastamentoMotivo> = [];

  public filters = {
    empresa: this.$rootScope.session.entities.empresa.empresa,
    estabelecimento: this.$rootScope.session.entities.estabelecimento.estabelecimento
  };
  public empresas: Array<Empresa>;
  public estabelecimentos: Array<Estabelecimento>;
  public listagemPermissao: Array<string> = ['criar_afastamentos'];
  public permissaoGrupoEmpresarial = this.$rootScope.temPermissaoGrupoEmpresarial(this.listagemPermissao, this.$rootScope.nsjGlobals.a.grupoempresarial);

  public motivosHabilitamAfastamentoOrigem: Array<IAfastamentoMotivo> = [
    {
      id: 1,
      descricao: 'Prorrogação decorrente do mesmo acidente de trabalho',
      tipohistorico: '002'
    },
    {
      id: 4,
      descricao: 'Prorrogação decorrente da mesma doença',
      tipohistorico: '004'
    },
    {
      id: 5,
      descricao: 'Prorrogação decorrente do mesmo acidente não relacionado ao trabalho',
      tipohistorico: '004'
    },
  ];

  public constructorAfastamentoOrigem;
  public constructorLookupColaborador;

  public clienteAna: boolean = nsjGlobals.get('clienteana');

  constructor(
    public $scope: angular.IScope,
    public $rootScope: angular.IRootScopeService & {
      temPermissao: (arg: string) => boolean,
      modoGestorPermissao: (arg: string) => boolean,
      temPermissaoGrupoEmpresarial: (permissoes: Array<string>, grupo: string) => boolean,
      liberadoTela: (arg: string) => boolean,
      session: any,
      nsjGlobals: any,
    },
    public ListagemService: ListagemService,
    public MeurhAfastamentosService: MeurhAfastamentosService,
    public NewToaster: any,
  ) {}

  $onInit(): void {
    if (this.action !== 'update') {
      this.entity.motivo = '';
    }
    this.entity.erroDiasafastamento = undefined;

    if (this.action === 'retrieve' || this.action === 'update') {
      this.alteraEntity();
    }
    this.montaListagemVisibilidade();

    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.$rootScope.modoGestorPermissao('criar_afastamentos'),
      ...(this.tipo && { 'tipo': this.tipo }),
    };

  }

  montaListagemVisibilidade(): void {
    if (this.action === 'retrieve' || this.action === 'update') {
      this.filters.empresa = this.entity.estabelecimentoobj.empresa;
      this.filters.estabelecimento = this.entity.estabelecimentoobj.estabelecimento;
    }
    this.empresas = this.ListagemService.montaEmpresas(this.$rootScope.nsjGlobals.a.grupoempresarial, this.listagemPermissao);
    this.estabelecimentos = this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao);
  }

  mudaEstabelecimentos(): void {
    this.estabelecimentos = this.filters.empresa ? this.ListagemService.montaEstabelecimentos(this.filters.empresa, this.empresas, this.listagemPermissao) : [];
    this.filters.estabelecimento = '';
    this.filtraEmpresaEstabelecimento();
  }

  filtraEmpresaEstabelecimento(): void {
    this.constructorLookupColaborador = {
      'empresa': this.filters.empresa,
      'estabelecimento': this.filters.estabelecimento,
      'status': '00',
      'forcagestor': this.$rootScope.modoGestorPermissao('criar_afastamentos'),
      ...(this.tipo && { 'tipo': this.tipo }),
    };
  }

  montaMotivos(): void {
    this.motivos = [
      {
        id: 0,
        descricao: 'Acidente de Trabalho',
        tipohistorico: '001'
      },
      {
        id: 1,
        descricao: 'Prorrogação decorrente do mesmo acidente de trabalho',
        tipohistorico: '002'
      },
      {
        id: 2,
        descricao: 'Doença',
        tipohistorico: '003'
      },
      {
        id: 3,
        descricao: 'Acidente não relacionado ao trabalho',
        tipohistorico: '003'
      },
      {
        id: 4,
        descricao: 'Prorrogação decorrente da mesma doença',
        tipohistorico: '004'
      },
      {
        id: 5,
        descricao: 'Prorrogação decorrente do mesmo acidente não relacionado ao trabalho',
        tipohistorico: '004'
      },
      {
        id: 7,
        descricao: 'Maternidade',
        tipohistorico: '006'
      },
      {
        id: 8,
        descricao: 'Maternidade de filho com microencefalia',
        tipohistorico: '033'
      },
      {
        id: 9,
        descricao: 'Maternidade por aborto expontâneo',
        tipohistorico: '008'
      },
      {
        id: 10,
        descricao: 'Serviço militar',
        tipohistorico: '009'
      },
      {
        id: 11,
        descricao: 'Licença não remunerada ou sem vencimentos',
        tipohistorico: '011'
      },
      {
        id: 12,
        descricao: 'Suspensão',
        tipohistorico: '030'
      },
      {
        id: 13,
        descricao: 'Licença remunerada',
        tipohistorico: '041'
      },
      {
        id: 14,
        descricao: 'Adoção',
        tipohistorico: '042'
      },
      {
        id: 15,
        descricao: 'Guarda judicial de criança',
        tipohistorico: '042'
      },
      {
        id: 16,
        descricao: 'Vítima de violência - Lei Maria da Penha',
        tipohistorico: '044'
      },
      {
        id: 17,
        descricao: 'Prorrogação de licença maternidade',
        tipohistorico: '050'
      },
      {
        id: 18,
        descricao: 'Qualificação - Afastamento por suspensão do contrato de acordo com o art 476-A da CLT',
        tipohistorico: '013'
      },
      {
        id: 19,
        descricao: 'Licença Maternidade - 121 dias a 180 dias, Lei 11.770/2008 (Empresa Cidadã), inclusive para o cônjuge sobrevivente',
        tipohistorico: '007'
      },
      {
        id: 20,
        descricao: 'Mandato Sindical - Afastamento temporário para exercício de mandato sindical',
        tipohistorico: '010'
      },
      {
        id: 21,
        descricao: 'Exercício de mandato eleitoral, sem remuneração',
        tipohistorico: '012'
      },
      {
        id: 22,
        descricao: 'Aposentadoria por Invalidez',
        tipohistorico: '014'
      },
      {
        id: 23,
        descricao: 'Gozo de férias ou recesso',
        tipohistorico: '015'
      },
      {
        id: 24,
        descricao: 'Cessão / Requisição',
        tipohistorico: '016'
      },
      {
        id: 25,
        descricao: 'Cárcere',
        tipohistorico: '017'
      },
      {
        id: 26,
        descricao: 'Regime próprio (estatuto), sem remuneração',
        tipohistorico: '035'
      },
      {
        id: 27,
        descricao: 'Acompanhamento de membro da família enfermo',
        tipohistorico: '036'
      },
      {
        id: 28,
        descricao: 'Participar de atividade do Conselho Curador do FGTS - art. 65, §6º, Dec. 99.684/90 (Regulamento do FGTS)',
        tipohistorico: '037'
      },
      {
        id: 29,
        descricao: 'Regime próprio (estatuto), com remuneração',
        tipohistorico: '038'
      },
      {
        id: 30,
        descricao: 'Cargo Eletivo - Candidato a cargo eletivo - Lei 7.664/1988. art. 25°, parágrafo único',
        tipohistorico: '039'
      },
      {
        id: 31,
        descricao: 'Cargo Eletivo - Candidato a cargo eletivo - Lei Complementar 64/1990. art. 1°, inciso II, alínea 1',
        tipohistorico: '040'
      },
      {
        id: 32,
        descricao: 'Exercício de mandato eleitoral, com remuneração',
        tipohistorico: '043'
      },
      {
        id: 33,
        descricao: 'Participação de empregado no Conselho Nacional de Previdência Social-CNPS (art. 3º, Lei 8.213/1991)',
        tipohistorico: '045'
      },
      {
        id: 34,
        descricao: 'Representante Sindical - Pelo tempo que se fizer necessário',
        tipohistorico: '046'
      },
      {
        id: 35,
        descricao: 'Licença Maternidade - de 180 dias, Lei 13.301/2016',
        tipohistorico: '048'
      },
      {
        id: 36,
        descricao: 'Inatividade do trabalhador avulso (portuário ou não portuário) por período superior a 90 dias',
        tipohistorico: '049'
      },
      {
        id: 37,
        descricao: 'Suspensão temporária de contrato nos termos do Plano Emergencial de Manutenção do Emprego e da Renda',
        tipohistorico: '051'
      },
      {
        id: 38,
        descricao: 'Licença por Acordo CCT (Convenção Coletiva de Trabalho)',
        tipohistorico: '053'
      },
      {
        id: 39,
        descricao: 'Qualificação - Afastamento por suspensão do contrato de acordo com o art. 17 da MP 1.116/2022',
        tipohistorico: '055'
      },
      {
        id: 40,
        descricao: 'Qualificação - Afastamento por suspensão do contrato de acordo com o art. 19 da MP 1.116/2022',
        tipohistorico: '056'
      }
    ];

    if (this.entity.trabalhador.sexo === 1) {
      this.motivos.push(
        {
          id: 6,
          descricao: 'Paternidade',
          tipohistorico: '005'
        }
      );
    }
  }

  alteraEntity(): void {
    this.entity.datainicioFormatted = moment(this.entity.datainicio, 'YYYY-MM-DD').format('DD/MM/YYYY');
    this.entity.dataretornoFormatted = moment(this.entity.dataretorno, 'YYYY-MM-DD').format('DD/MM/YYYY');

    if ( this.action === 'update') {
      let motivo = this.motivos.filter(motivo => motivo.descricao === this.entity.descricao);
      this.entity.motivo = angular.copy(motivo[0].id!).toString();
      this.entity.trabalhador = angular.copy(this.entity.trabalhadorobj);
    }
  }

  getMotivo(): void {
    this.motivos.forEach((motivo: any) => {
      if (motivo.id === Number(this.entity.motivo)) {
        this.entity.tipohistorico = motivo.tipohistorico;
        this.entity.descricao = motivo.descricao;
      }
    });
    if (this.clienteAna) {
      this.setConstructorAfastamentoOrigem();
    }
  }

  changeDiasAfastamento(diasAfastamento: any) {
    if (diasAfastamento) {
      this.calculaDataRetorno();
    }
  }

  calculaDataRetorno(): void {
    if (this.entity.datainicio && this.entity.diasafastamento) {
      this.entity.dataretornoFormatted = moment(this.entity.datainicio).add(this.entity.diasafastamento, 'days').format('DD/MM/YYYY');
      this.entity.dataretorno = moment(this.entity.dataretornoFormatted, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }
  }

  onChangeTrabalhador(trabalhadorSelecionado: any): void {

    this.entity.motivo = '';
    this.entity.descricao = '';
    this.entity.datainicio = '';
    this.entity.diasafastamento = 0;
    this.entity.dataretornoFormatted = '';

    if (trabalhadorSelecionado) {
      this.montaMotivos();
    } else {
      this.motivos = [];
    }
  }

  setConstructorAfastamentoOrigem() {

    let constructor: any = {
      trabalhador: null,
      filtraAfastamentos: null
    };

    if (this.action === 'retrieve') {
      constructor.trabalhador = this.entity.trabalhador;
    } else {
      constructor.trabalhador = this.entity.trabalhador.trabalhador;
    }

    constructor.filtraAfastamentos = this.entity.tipohistorico ? this.entity.tipohistorico : null;
    this.constructorAfastamentoOrigem = constructor;

  }

  checkMotivoHabilitaAfastamentoOrigem() {

    let habilitaAfastamentoOrigem: boolean = false;

    habilitaAfastamentoOrigem = this.motivosHabilitamAfastamentoOrigem.some((motivo) => {
      return motivo.id === Number(this.entity.motivo);
    });

    if (!habilitaAfastamentoOrigem && this.action !== 'retrieve') {
      this.entity.afastamentoorigem = null;
    }

    return habilitaAfastamentoOrigem;

  }

  exibeAfastamentoOrigem() {

    if (this.clienteAna) {
      if ((this.action === 'insert' || this.action === 'update') && this.entity.trabalhador) {
        return true;
      } else if (this.action === 'retrieve') {
        return true;
      }
    } else {
      return false;
    }

  }

  /**
   * Buscando os afastamentos do colaborador selecionado para verificar se há sobreposição das datas selecionadas com as datas de algum afastamento existente
   */
  buscaAfastamentosColaborador() {

    if (this.entity.trabalhador && this.entity.datainicio && this.entity.dataretorno && this.entity.diasafastamento) {

      this.busy = true;

      // obtendo todos os afastamentos do trabalhador selecionado
      this.MeurhAfastamentosService._load({trabalhador: this.entity.trabalhador.trabalhador}, {}, '')
      .then((retorno: any) => {

        // se houver algum afastamento com a situação -1 (rascunho), 0 (enviado) ou 1 (efetuado), verificar se há datas sobrepostas com as selecionadas
        if (retorno.some((afastamentoExistente) => afastamentoExistente.situacao < 2)) {

          let datasSobrepostas: boolean = false;

          let afastamentosParaVerificar = retorno.filter(afastamentoExistente => {
            return afastamentoExistente.situacao < 2;
          });

          // verificando se há algum afastamento com sobreposição de datas com as datas selecionadas
          datasSobrepostas = afastamentosParaVerificar.some((afastamentoEnviado) => {
            return this.verificaSobreposicaoDatasAfastamentos(
              this.entity.datainicio,
              this.entity.dataretorno,
              afastamentoEnviado.datainicio,
              afastamentoEnviado.dataretorno
            );
          });

          if (datasSobrepostas) {

            this.NewToaster.pop({
              type: 'warning',
              title: 'Solicitação já enviada',
              body: 'Já existe uma solicitação de afastamento para este colaborador dentro das datas enviadas'
            });

            // usado para controlar botão de salvar/enviar afastamento na criação/edição de afastamento
            this.form.datasSobrepostas = true;

          } else {
            this.form.datasSobrepostas = false;
          }

        } else {
          this.form.datasSobrepostas = false;
        }

      }).finally(() => {
        this.busy = false;
      });

    } else {
      this.form.datasSobrepostas = false;
    }
  }

  /**
   * Recebe a data de início e fim do afastamento a ser criado/editado e as datas de início e fim do afastamento a ser testado. Retorna booleano informando se há sobreposição de datas
   * @param dataInicioSelecionada o campo 'iniciando em' no afastamento da tela
   * @param dataFimSelecionada o campo 'retornando em' no afastamento da tela
   * @param dataInicioAfastamentoExistente a data de início do afastamento testado
   * @param dataFimAfastamentoExistente a data de término do afastamento testado
   * @returns booleano informando se há sobreposição de datas
   */
  verificaSobreposicaoDatasAfastamentos(
    dataInicioSelecionada: string,
    dataFimSelecionada: string,
    dataInicioAfastamentoExistente: string,
    dataFimAfastamentoExistente: string
  ) {

    // datas no formato moment para fazer comparações
    const dataInicioSelecionadaMoment = moment(dataInicioSelecionada, 'YYYY-MM-DD');
    const dataFimSelecionadaMoment = moment(dataFimSelecionada, 'YYYY-MM-DD');
    const dataInicioAfastamentoExistenteMoment = moment(dataInicioAfastamentoExistente, 'YYYY-MM-DD');
    const dataFimAfastamentoExistenteMoment = moment(dataFimAfastamentoExistente, 'YYYY-MM-DD');

    const condicao1 = dataInicioSelecionadaMoment.isBetween(dataInicioAfastamentoExistenteMoment, dataFimAfastamentoExistenteMoment, undefined, '[)');
    const condicao2 = dataFimSelecionadaMoment.isBetween(dataInicioAfastamentoExistenteMoment, dataFimAfastamentoExistenteMoment, undefined, '[)');
    const condicao3 = dataInicioAfastamentoExistenteMoment.isBetween(dataInicioSelecionadaMoment, dataFimSelecionadaMoment, undefined, '[]');
    const condicao4 = dataFimAfastamentoExistenteMoment.isBetween(dataInicioSelecionadaMoment, dataFimSelecionadaMoment, undefined, '(]');

    // retorna true se qualquer uma das condições for verdadeira
    return condicao1 || condicao2 || condicao3 || condicao4;
  }

}
