import angular from 'angular';
import { IAfastamento, ITrabalhador } from '../models/trabalhador.model';
import { TrabalhadoresService } from '../trabalhadores.service';
import { MeurhRendimentosModalService } from '../modal/rendimentos/rendimentos.modal.service';
import moment from 'moment';
import { conversaoFormatoData } from '../../../utils/utils';
import { ModalAjustarRetornoService } from '../components/afastamento/modalAjustarRetorno/modalAjustarRetorno.service';
import { IDadosModalRetornoAfastamento, IRetornosAfastamento } from '../../Meurh/Solicitacoesafastamentosretorno/models/retornoAfastamento.model';
import { ModalVisualizarAjustarRetornoService } from '../components/afastamento/modalVisualizarAjusteRetorno/modalVisualizarAjustarRetorno.service';
import { MeurhArquivosService } from '../../Arquivos/arquivos.service';
import { MeurhVisualizarArquivoModalService } from '../../Arquivos/modal/visualizararquivo/visualizararquivo.modal.service';
import { IArquivo, IPasta } from '../../Arquivos/arquivos.model';

export class TrabalhadoresFormController implements angular.IController {
    static $inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        '$state',
        'TrabalhadoresService',
        'entity',
        'NewToaster',
        'RelatoriosService',
        '$http',
        'DocumentoColaboradorModalService',
        'MeurhRendimentosModalService',
        'ModalAjustarRetornoService',
        'ModalVisualizarAjustarRetornoService',
        'MeurhArquivosService',
        'MeurhVisualizarArquivoModalService',
        'nsjRouting',
        '$q',
        '$uibModal'
    ];

    public dadosPrazoContrato: {
        tipoContrato?: string;
        fimContrato?: string;
        prorrogacaoContrato?: string;
        diasExperienciaInicial?: string | number;
        prorrogacaoDiasExperiencia?: string | number;
    } = {};

    public action: string;
    public semFoto: boolean = true;
    public carregandoRelatorio: boolean = false;
    public listaDocumentosFop: Array<{ codigodocumento: string, nomedocumento: string, tenant: number }>;
    public form: angular.IFormController;

    public retornosAfastamento: IRetornosAfastamento[] = [];
    public afastamentoRetornoAtual: IRetornosAfastamento;

    public pastas: IPasta[] = [];
    public pastaAtual: IPasta;
    public arquivos: (IArquivo & {selecionado?: boolean })[]  = [];
    public loading = {
        pastas: false,
        arquivos: false
    };
    public busy = false;

    public afastamentoMaisRecente;


    constructor(
        public $scope: angular.IScope,
        public $rootScope: angular.IRootScopeService & {
            nsjGlobals: any,
            liberadoTela(arg: string): boolean,
            temPermissao(permissao: string): boolean
        },
        public $stateParams: angular.ui.IStateParamsService,
        public $state: angular.ui.IStateService,
        public entityService: TrabalhadoresService,
        public entity: ITrabalhador,
        public NewToaster: any,
        public RelatoriosService: any,
        public $http: angular.IHttpService,
        public DocumentoColaboradorModalService: any,
        public RendimentosModalService: MeurhRendimentosModalService,
        public ModalAjustarRetornoService: ModalAjustarRetornoService,
        public ModalVisualizarAjustarRetornoService: ModalVisualizarAjustarRetornoService,
        public MeurhArquivosService: MeurhArquivosService,
        public MeurhVisualizarArquivoModalService: MeurhVisualizarArquivoModalService,
        public nsjRouting: any,
        public $q: angular.IQService,
        public $uibModal: any
    ) {
        this.action = entity.trabalhador ? 'update' : 'insert';
        this.carregaFotoTrabalhador();

        if ($rootScope.liberadoTela('LIBERAR_UPLOAD_ARQUIVO_TRABALHADOR')) {
            this.getPastas();
        }

        this.RelatoriosService.listarDocumentosFop('persona.trabalhadores').then((result: Array<{ codigodocumento: string, nomedocumento: string, tenant: number }>) => {
            this.listaDocumentosFop = result;
        });

        $scope.$watch('trblhdrs_frm_cntrllr.entity', (newValue: any, oldValue: any): void => {
            if (newValue !== oldValue && this.form) {
                this.form.$setDirty();
            }
        }, true);

        this.getDadosPrazoContrato();

        if (this.funcionarioAfastado()) {
            if (this.entity.afastamentos?.length) {

                this.afastamentoMaisRecente = this.entity.afastamentos!.reduce((maisRecente, atual) => {
                    return moment(atual.data).isAfter(moment(maisRecente.data)) ? atual : maisRecente;
                }, this.entity.afastamentos![0]);
                this.getRetornosAfastamento(this.afastamentoMaisRecente.afastamentotrabalhador);
            }
        }
    }

    montaTextoGestores(): string {
        return (this.entity.gestores === null || this.entity.gestores === undefined || this.entity.gestores.length <= 0) ? 'Não possui' : this.entity.gestores.map(gestor => gestor.nome ? gestor.nome : 'Nome não informado').join(' / ');
    }

    carregaFotoTrabalhador(): void {
        this.$http.get(this.entity.foto, { responseType: 'arraybuffer' })
            .then((response: any): void => {
                this.semFoto = false;

                let mimeType = response.headers('Content-Type');
                let file = new Blob([response.data], { type: mimeType });
                let fileUrl = URL.createObjectURL(file);

                this.entity.foto_url = fileUrl;
            })
            .catch((error: any): void => {
                this.semFoto = true;
            });
    }

    abrirModalRendimentos(): void {
        this.RendimentosModalService.open({
            rendimentos: this.entity.rendimentos!,
            total: this.entity.outrosrendimentos!
        }).result
            .then(() => {/**/ })
            .catch((error: any): void => {/**/ });
    }

    visualizarFichaCadastral(id: string): void {
        this.carregandoRelatorio = true;
        let ids: any = id !== null ? [id] : [];

        if (ids.length > 0) {
            let constructor = { 'trabalhadores': ids, 'id': 'null' };
            let callback = (): void => {
                this.carregandoRelatorio = false;
            };

            this.RelatoriosService.abrirRelatorioFop('persona_trabalhadores_ficha', constructor, callback, false);
        }
    }

    visualizarFichaCadastralResumida(id: string): void {
        this.carregandoRelatorio = true;
        let ids: any = id !== null ? [id] : [];

        if (ids.length > 0) {
            let constructor = { 'trabalhadores': ids, 'id': 'null' };
            let callback = (): void => {
                this.carregandoRelatorio = false;
            };

            this.RelatoriosService.abrirRelatorioFop('persona_trabalhadores_ficha_resumida', constructor, callback, false);
        }
    }

    visualizarRelatorioFop(id: string, estabelecimento: string, relatorio: string): void {
        this.carregandoRelatorio = true;

        let constructor = { id, estabelecimento, relatorio };
        let callback = (): void => {
            this.carregandoRelatorio = false;
        };

        this.RelatoriosService.abrirRelatorioFop('persona_trabalhadores_relatoriofop', constructor, callback, false);
    }

    visualizarDocumentoColaborador(trabalhador: string, document: any): void {
        if (document.possuibin) {
            if (document.urldocumento.includes('pdf')) {
                let callback = (): void => {
                    this.carregandoRelatorio = false;
                };

                this.RelatoriosService.abrirRelatorioFop('persona_documentoscolaboradores_download', { 'trabalhador': trabalhador, 'id': document.documentocolaborador, 'download': true }, callback, false);
            } else {
                let url = this.nsjRouting.generate('persona_documentoscolaboradores_download', { 'trabalhador': trabalhador, 'id': document.documentocolaborador }, true);

                this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
                    this.$http.get(url, { responseType: 'arraybuffer' })
                        .then((response: any): void => {
                            this.semFoto = false;

                            let mimeType = response.headers('Content-Type');
                            let file = new Blob([response.data], { type: mimeType });
                            let fileUrl = URL.createObjectURL(file);

                            let documentoAux: any = [];
                            documentoAux = angular.copy(document);

                            documentoAux.bindocumento = fileUrl;
                            documentoAux.possuibin = document.possuibin;

                            this.DocumentoColaboradorModalService.open(documentoAux);
                            resolve(response);
                        })
                        .catch((response: any): void => {
                            reject(response);
                        });
                });
            }
        } else {
            let modal = this.DocumentoColaboradorModalService.open(document);
            // modal.result.then((): void => {}).catch((): void => {});
        }
    }

    baixarDocumentoColaborador(trabalhador: string, documento: any): void {
        if (documento.possuibin) {
            let url = this.nsjRouting.generate('persona_documentoscolaboradores_download', { 'trabalhador': trabalhador, 'id': documento.documentocolaborador, 'download': true }, true);

            this.$q((resolve: angular.IQResolveReject<any>, reject: angular.IQResolveReject<any>): void => {
                this.$http.get(url, { responseType: 'blob' })
                    .then((response: any): void => {
                        this.handleDownload(response.data, documento.urldocumento, 'application/octet-stream');
                    })
                    .catch((response: any): void => {
                        reject(response);
                    });
            });
        } else {
            let modal = this.DocumentoColaboradorModalService.open(documento);
            // modal.result.then((): void => {}).catch((): void => {});
        }
    }

    getVtTotal(): number {
        return this.entity.valor_vt!.reduce((prevVal: number, elem: any): number => {
            return prevVal + (elem.tarifaconcessionariavt.valor * elem.quantidade);
        }, 0);
    }

    getDataFormatada(data: string): string {
        return conversaoFormatoData(data);
    }

    calculosEmDatas(data: string, operacao: 'add' | 'sub', quantidade: number, unidadeTempo: 'days' | 'months' | 'years') {

        let dataCalculada;

        if (operacao === 'add') {
            dataCalculada = moment(data).add(quantidade, unidadeTempo).format('YYYY-MM-DD');
        } else if (operacao === 'sub') {
            dataCalculada = moment(data).subtract(quantidade, unidadeTempo).format('YYYY-MM-DD');
        }

        return dataCalculada;
    }

    public podeExibirFichaResumida(): boolean {

        const flagFichaResumida = this.$rootScope.nsjGlobals.a.habilitados.LIBERAR_FICHA_CADASTRAL_RESUMIDA;
        const permissaoFicha = this.$rootScope.temPermissao('relatorios_fichascadastrais');

        return flagFichaResumida && permissaoFicha;
    }

    public podeAjustarRetornoAfastamento(): boolean {
        const estaAfastado = this.funcionarioAfastado();
        const temAjusteRetornoAfastamento = this.temAjusteRetornoAfastamento();

        return estaAfastado && !temAjusteRetornoAfastamento;
    }

    public funcionarioAfastado(): boolean {
        const statusAfastamento = ['03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15'];
        const estaAfastado = statusAfastamento.includes(this.entity.situacao.situacaofuncionario);

        return estaAfastado;
    }

    public temAjusteRetornoAfastamento(): boolean {
        const buscaAfastamentoretorno = this.retornosAfastamento.find(retorno => retorno.trabalhador.trabalhador === this.entity.trabalhador);

        return !!buscaAfastamentoretorno;
    }

    public abrirAjustarRetorno(): void {
        const dataRetornoOriginal: moment.Moment = moment(this.afastamentoMaisRecente.data).add(this.afastamentoMaisRecente.dias, 'd');
        const afastamento: IDadosModalRetornoAfastamento = {
            dataRetornoOriginal: dataRetornoOriginal,
            nomeColaborador: this.entity.nome,
            tipoAfastamento: this.afastamentoMaisRecente.tipohistorico.descricao
        };

        const confirm = this.ModalAjustarRetornoService.open(afastamento);

        confirm.result.then((dataAjusteRetorno: string) => {
            const params: {
                situacao: number,
                estabelecimento: string,
                trabalhador: string,
                dataretorno: string,
                afastamentotrabalhador: string
            } = {
                situacao: 0,
                estabelecimento: this.entity.estabelecimento.estabelecimento,
                trabalhador: this.entity.trabalhador,
                dataretorno: dataAjusteRetorno,
                afastamentotrabalhador: this.afastamentoMaisRecente.afastamentotrabalhador
            };

            this.entityService.ajustarRetorno(params)
                .then(() => {
                    this.NewToaster.pop({
                        type: 'success',
                        title: 'A data de retorno do afastamento foi alterada'
                    });

                    this.$state.reload();
                })
                .catch(() => {
                    this.NewToaster.pop({
                        type: 'error',
                        title: 'Ocorreu um erro ao ajustar a nova data de retorno do afastamento'
                    });
                });
        })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    public visualizarAjusteRetorno(dadosAfastamento: IAfastamento): void {
        const dataRetornoOriginal: moment.Moment = moment(this.afastamentoMaisRecente.data).add(this.afastamentoRetornoAtual.afastamentotrabalhador.dias, 'd');
        const afastamento: IDadosModalRetornoAfastamento = {
            dataRetornoOriginal: dataRetornoOriginal,
            nomeColaborador: this.entity.nome,
            tipoAfastamento: dadosAfastamento.tipohistorico.descricao,
            situacao: this.retornosAfastamento[0].situacao,
            dataNovoRetorno: moment(this.afastamentoRetornoAtual.dataretorno)
        };

        const confirm = this.ModalVisualizarAjustarRetornoService.open(afastamento);

        confirm.result.then(() => {
            try {
                this.$state.go('meurh_solicitacoesafastamentosretorno_show', { solicitacao: this.afastamentoRetornoAtual.solicitacao });
            } catch {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Não foi possível encontrar a solicitação'
                });
            }
        })
            .catch((error: any) => {
                if (error !== 'backdrop click' && error !== 'fechar' && error !== 'escape key press') {
                    this.NewToaster.pop({
                        type: 'error',
                        title: error
                    });
                }
            });
    }

    public getRetornosAfastamento(afastamento: string): void {
        this.entityService.getRetornoAfastamento(afastamento)
            .then((retornosAfastamento) => {
                this.retornosAfastamento = retornosAfastamento.data;

                for (const retornoAfastamento of this.retornosAfastamento) {
                    if (retornoAfastamento.trabalhador.trabalhador === this.entity.trabalhador) {
                        const diasParaRetornar = moment(retornoAfastamento.dataretorno).diff(moment(this.afastamentoMaisRecente.data), 'd');
                        const afastamentoRecebidoParametro = this.entity.afastamentos!.find(afastamentoFind => afastamentoFind.afastamentotrabalhador === afastamento);

                        if (afastamentoRecebidoParametro) {
                            afastamentoRecebidoParametro.dias = diasParaRetornar;
                        }

                        this.afastamentoRetornoAtual = retornoAfastamento;
                        break;
                    }
                }
            })
            .catch(() => {
                this.NewToaster.pop({
                    type: 'error',
                    title: 'Ocorreu um erro ao buscar as alterações de retorno de afastamento'
                });
            });
    }


    verificaAfastamentoMaisRecente(dadosAfastamento: IAfastamento) {
        return dadosAfastamento.afastamentotrabalhador === this.afastamentoMaisRecente.afastamentotrabalhador;
    }


    getPastas() {
        this.loading.pastas = true;
        this.MeurhArquivosService.getPastas().then((result) => {
            this.pastas = result;
        }).finally(() => this.loading.pastas = false);
    }

    getArquivos(pasta: IPasta) {
        this.pastaAtual = pasta;
        this.arquivos = [];
        this.loading.arquivos = true;
        this.MeurhArquivosService.getArquivos(pasta.tipoarquivo, this.entity.trabalhador)
            .then((result) => {
                this.arquivos = result;
            }).finally(() => this.loading.arquivos = false);
    }

    visualizarArquivo(id: string, extensao: string) {
        const modal = this.MeurhVisualizarArquivoModalService.open(id, extensao);
        modal.result
            .then(() => {/* */})
            .catch((error) => {
                console.log(error);
            });
    }

    downloadArquivos(arquivo: IArquivo) {
        const arquivosSelecionados = arquivo ? [arquivo] : this.arquivos.filter(arquivo => arquivo.selecionado);
        this.busy = true;
        if (arquivosSelecionados.length === 1) {
            this.MeurhArquivosService.getConteudo(arquivosSelecionados[0].arquivotrabalhador)
            .then((response) => {
                this.handleDownload(response.data, arquivosSelecionados[0].nome, 'application/octet-stream');
            }).finally(() => this.busy = false);
        } else {
            const param = (!arquivosSelecionados.length ? this.arquivos : arquivosSelecionados)
            .map((arq) => ({ arquivotrabalhador: arq.arquivotrabalhador }));

            this.MeurhArquivosService.downloadArquivos(param)
            .then((result) => {
                this.handleDownload(result, 'arquivos.zip');
            }).finally(() => this.busy = false);
        }
    }

    private handleDownload(file: File, name: string, type?: string) {
        const blob = new Blob([file], { type });
        const fileURL = URL.createObjectURL(blob);
        const a = document.createElement('a');

        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = fileURL;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(fileURL);
    }

    private getDadosPrazoContrato(): void {
        const tipoContrato = {
            1: 'Indeterminado',
            2: 'Determinado (em dias)',
            3: 'Determinado (por fato)'
        };

        this.dadosPrazoContrato.tipoContrato = tipoContrato[this.entity.tipocontrato] || 'Não informado.';
        this.dadosPrazoContrato.fimContrato = this.entity.datafimcontrato || 'Não informado.';
        this.dadosPrazoContrato.prorrogacaoContrato = this.entity.dataprorrogacaocontrato || 'Não informado.';
        this.dadosPrazoContrato.diasExperienciaInicial =
            this.entity.diasexperienciacontrato !== undefined && this.entity.diasexperienciacontrato !== null
                ? this.entity.diasexperienciacontrato
                : 'Não informado';
        this.dadosPrazoContrato.prorrogacaoDiasExperiencia =
            this.entity.diasprorrogacaocontrato !== undefined && this.entity.diasprorrogacaocontrato !== null
                ? this.entity.diasprorrogacaocontrato
                : 'Não informado';
    }
}
