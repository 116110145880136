import angular from 'angular';
import { RescisaoPontoController } from './rescisao.ponto.controller';

export class RescisaoPontoComponent implements angular.IComponentOptions {
  static selector = 'rescisaoPontoComponent';
  static controller = RescisaoPontoController;
  static template = require('!!html-loader!./rescisao.ponto.html');
  static transclude = true;
  static bindings = {
    id: '=',
    currentStep: '=',
    hideActions: '=?'
  };
}
