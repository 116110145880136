import { ui } from 'angular';
import { IRescisao } from '../../models/rescisao.model';

export class ModalAdicionarMovimentoRescisaoService {

    static $inject = [
        '$uibModal'
    ];

    constructor(
        private $uibModal: ng.ui.bootstrap.IModalService,
    ) { }

    public open(entity: IRescisao): ui.bootstrap.IModalInstanceService {
        return this.$uibModal.open({
            template: require('html-loader!./modaladicionarmovimentorescisao.html'),
            controller: 'ModalAdicionarMovimentoRescisaoController',
            controllerAs: 'modalAdicionarMovimentoRescisaoController',
            windowClass: 'nsj-modal-lg-no-height',
            backdrop: 'static',
            resolve: {
                entity: () => entity,
            }
        });
    }
}
