import angular from 'angular';
// import { ITarifaVT } from '../models/tarifavt.model';
import { MeurhAdmissaocompletaService } from '../admissaocompleta.service';

export class MeurhAdmissaocompletaModalService {
    static $inject = ['MeurhAdmissaocompletaService', '$uibModal'];

    constructor(
        public entityService: MeurhAdmissaocompletaService,
        public $uibModal: any
    ) { }

    open(entity: any, parameters: any): any {
        return this.$uibModal.open({
            template: require('html-loader!./admissaocompleta.modal.html'),
            controller: [
                'entity',
                '$uibModalInstance',
                'NewToaster',
                function MeurhAdmissaocompletaModal(
                    entity: any,
                    $uibModalInstance: any,
                    NewToaster: any
                ): void {
                    let selfModal = this;

                    selfModal.NewToaster = NewToaster;
                    selfModal.parameters = parameters;

                    selfModal.form = parameters.form;

                    selfModal.entity = entity;
                    selfModal.entitycopy = angular.copy(entity);

                    if (selfModal.parameters.componente === 'Dependente') {
                        selfModal.entitycopy.desativacpf = parameters.desativacpf;
                    } else if (selfModal.parameters.componente === 'Benefício') {
                        if (selfModal.parameters.desativausobeneficio) {
                            selfModal.entitycopy.usobeneficio = 2;
                            selfModal.entitycopy.desativausobeneficio = selfModal.parameters.desativausobeneficio;
                            selfModal.entitycopy.beneficiosvaga = selfModal.parameters.beneficiosvaga;
                        }
                    }

                    selfModal.submit = (): void => {
                        selfModal.form.$submitted = true;
                        if (selfModal.parameters.componente === 'Benefício') {
                            if (
                                selfModal.valorExiste(selfModal.entitycopy.beneficio) &&
                                selfModal.valorExiste(selfModal.entitycopy.tipoperiodo) &&
                                selfModal.valorExiste(selfModal.entitycopy.quantidade) &&
                                selfModal.valorExiste(selfModal.entitycopy.lotacao) &&
                                selfModal.validaValor() &&
                                selfModal.validaTipoperiodo() &&
                                selfModal.validaDesconto() &&
                                selfModal.validaDadosDependenteBeneficio()
                            ) {
                                selfModal.entitycopy.desconto = selfModal.entitycopy.desconto ? selfModal.entitycopy.desconto : null;
                                selfModal.entitycopy.descricao = selfModal.entitycopy.descricao ? selfModal.entitycopy.descricao : null;
                                selfModal.entity = angular.copy(selfModal.entitycopy);
                                $uibModalInstance.close(selfModal.entity);
                            } else {
                                selfModal.erroForm();
                            }
                        } else if (selfModal.parameters.componente === 'Dependente') {
                            if (
                                selfModal.valorExiste(selfModal.entitycopy.nome) &&
                                selfModal.valorExiste(selfModal.entitycopy.cpf) &&
                                selfModal.valorExiste(selfModal.entitycopy.parentesco) &&
                                selfModal.valorExiste(selfModal.entitycopy.sexo) &&
                                selfModal.valorExiste(selfModal.entitycopy.datanascimento) &&
                                selfModal.form.cpf.$valid &&
                                selfModal.validaBeneficiario() &&
                                selfModal.validaRecebimento() &&
                                selfModal.validaVencimentoEscolar() &&
                                selfModal.validaVencimentoVacinacao()
                            ) {

                                if (selfModal.dependenteTemCpfDuplicado()) {
                                    selfModal.NewToaster.pop({
                                        type: 'error',
                                        title: 'CPF já cadastrado',
                                        body: 'Já existe um dependente cadastrado com esse CPF. Verifique os números digitados para adicionar um novo dependente.',
                                    });
                                    return;
                                }

                                selfModal.entitycopy.impostorenda = selfModal.entitycopy.impostorenda ? selfModal.entitycopy.impostorenda : false;
                                selfModal.entitycopy.salariofamilia = selfModal.entitycopy.salariofamilia ? selfModal.entitycopy.salariofamilia : false;
                                selfModal.entitycopy.pensaoalimenticia = selfModal.entitycopy.pensaoalimenticia ? selfModal.entitycopy.pensaoalimenticia : false;
                                selfModal.entity = angular.copy(selfModal.entitycopy);
                                $uibModalInstance.close(selfModal.entity);
                            } else {
                                selfModal.erroForm();
                            }
                        } else if (selfModal.parameters.componente === 'Vale-transporte') {
                            if (
                                selfModal.valorExiste(selfModal.entitycopy.concessionariavt) &&
                                selfModal.valorExiste(selfModal.entitycopy.tarifaconcessionariavt) &&
                                selfModal.valorExiste(selfModal.entitycopy.quantidade)
                            ) {
                                selfModal.entity = angular.copy(selfModal.entitycopy);
                                $uibModalInstance.close(selfModal.entity);
                            } else {
                                selfModal.erroForm();
                            }
                        } else if (selfModal.parameters.componente === 'VT Personalizado') {
                            if (
                                selfModal.valorExiste(selfModal.entitycopy.datainicio) &&
                                selfModal.valorExiste(selfModal.entitycopy.datafim) &&
                                selfModal.valorExiste(selfModal.entitycopy.tipo) &&
                                selfModal.valorExiste(selfModal.entitycopy.conteudo) &&
                                selfModal.valorExiste(selfModal.entitycopy.motivo)
                            ) {
                                selfModal.entity = angular.copy(selfModal.entitycopy);
                                $uibModalInstance.close(selfModal.entity);
                            } else {
                                selfModal.erroForm();
                            }
                        }
                    };

                    selfModal.close = (): void => {
                        if (selfModal.parameters.objadmissao?.desativaBotoesSalvarModal) {
                            selfModal.parameters.objadmissao.desativaBotoesSalvarModal = false;
                        }
                        $uibModalInstance.dismiss('fechar');
                    };

                    selfModal.valorExiste = (valor: any): boolean => {
                        return valor !== '' && valor !== undefined && valor !== null ? true : false;
                    };

                    selfModal.erroForm = (): void => {
                        selfModal.NewToaster.pop({
                            type: 'error',
                            title: 'Alguns campos do formulário de ' + selfModal.parameters.componente + ' apresentam erros',
                            body: 'Favor verificar os campos obrigatórios, assinalados com *.',
                        });
                    };

                    selfModal.validaTiporecebimento = (): boolean => {
                        if (selfModal.entitycopy.salariofamilia === true) {
                            if (selfModal.valorExiste(selfModal.entitycopy.tiporecebimento)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    };

                    selfModal.validaBeneficiario = (): boolean => {
                        if (selfModal.entitycopy.pensaoalimenticia === true) {
                            if (selfModal.valorExiste(selfModal.entitycopy.tiporecebimento)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    };

                    selfModal.validaRecebimento = (): boolean => {
                        if (selfModal.entitycopy.tiporecebimento === '1' ||
                        selfModal.entitycopy.tiporecebimento === '2' ||
                        selfModal.entitycopy.tiporecebimento === '4' ||
                        selfModal.entitycopy.tiporecebimento === '64') {
                            if (selfModal.valorExiste(selfModal.entitycopy.banco) &&
                            selfModal.valorExiste(selfModal.entitycopy.agencia) &&
                            selfModal.valorExiste(selfModal.entitycopy.numeroconta)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else if (selfModal.entitycopy.tiporecebimento === '128' ||
                        selfModal.entitycopy.tiporecebimento === '256' ||
                        selfModal.entitycopy.tiporecebimento === '512' ||
                        selfModal.entitycopy.tiporecebimento === '1024') {
                            if (selfModal.valorExiste(selfModal.entitycopy.chavepix)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    };

                    selfModal.validaValor = (): boolean => {
                        if (selfModal.entitycopy && selfModal.entitycopy.beneficio.tipovalor === 1) {
                            if (selfModal.valorExiste(selfModal.entitycopy.valor)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    };

                    selfModal.validaDesconto = (): boolean => {
                        if (selfModal.entitycopy && selfModal.entitycopy.beneficio.tipodesconto === 2) {
                            if (selfModal.valorExiste(selfModal.entitycopy.desconto)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    };

                    selfModal.validaTipoperiodo = (): boolean => {
                        if (selfModal.entitycopy.tipoperiodo === '1') { // todo mes x
                            if (selfModal.valorExiste(selfModal.entitycopy.mes)) {
                                return true;
                            } else {
                                return false;
                            }
                        } else if (selfModal.entitycopy.tipoperiodo === '2') { // entre mes e ano
                            if (
                                selfModal.valorExiste(selfModal.entitycopy.mesinicial) &&
                                selfModal.valorExiste(selfModal.entitycopy.anoinicial) &&
                                selfModal.valorExiste(selfModal.entitycopy.anofinal) &&
                                selfModal.valorExiste(selfModal.entitycopy.mesfinal)
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    };

                    selfModal.validaVencimentoEscolar = (): Boolean => {
                        if (selfModal.vencimentoEscolarObrigatorio()) {
                            return !!selfModal.entitycopy.vencimentoescolar;
                        } else {
                            return true;
                        }
                    };

                    selfModal.validaVencimentoVacinacao = (): Boolean => {
                        if (selfModal.vencimentoVacinacaoObrigatorio()) {
                            return !!selfModal.entitycopy.vencimentovacinacao;
                        } else {
                            return true;
                        }
                    };

                    selfModal.vencimentoEscolarObrigatorio = (): Boolean => {
                        return (
                            (selfModal.entitycopy.idade !== undefined && selfModal.entitycopy.idade !== null) &&
                            (selfModal.entitycopy.idade >= 7 && selfModal.entitycopy.idade <= 14) &&
                            selfModal.entitycopy.salariofamilia &&
                            !selfModal.entitycopy.incapacidadefisicamental
                        );
                    };

                    selfModal.vencimentoVacinacaoObrigatorio = (): Boolean => {

                        // atribuição apenas para evitar valor undefined e manter booleanos
                        const salariofamilia = selfModal.entitycopy.salariofamilia ? selfModal.entitycopy.salariofamilia : false;
                        const incapacidadefisicamental = selfModal.entitycopy.incapacidadefisicamental ? selfModal.entitycopy.incapacidadefisicamental : false;

                        return (
                            (selfModal.entitycopy.idade !== undefined && selfModal.entitycopy.idade !== null) &&
                            (selfModal.entitycopy.idade < 7 || selfModal.entitycopy.idade > 14) &&
                            salariofamilia &&
                            !incapacidadefisicamental
                        );
                    };

                    selfModal.validaDadosDependenteBeneficio = (): Boolean => {
                        if (selfModal.entitycopy.usobeneficio === 2) {
                            return selfModal.entitycopy.dependenteSelecionado != null;
                        }

                        return true;
                    };

                    selfModal.dependenteTemCpfDuplicado = (): Boolean => {

                        let cpfNaListagem: boolean = false;

                        if (selfModal.parameters.objadmissao.dependentes.length > 0) {

                            cpfNaListagem = selfModal.parameters.objadmissao.dependentes.some((dependenteLista) => {
                                if (selfModal.entitycopy.cpf === dependenteLista.cpf) {

                                    if (selfModal.entitycopy.counter == null) {
                                        return true;
                                    } else if (selfModal.entitycopy.counter === dependenteLista.counter) {
                                        return false;
                                    }
                                }
                            });
                        }

                        return cpfNaListagem;
                    };

                }
            ],
            controllerAs: '$ctrl',
            backdrop: 'static',
            windowClass: (parameters.componente === 'Dependente' ? 'nsj-modal-lg' : 'nsj-modal-lg-no-height'),
            resolve: {
                entity: (): any => {
                    return entity;
                }
            }
        });
    }
}
