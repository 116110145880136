import angular from 'angular';
import { MeurhSolicitacoesgenericasFormController } from './solicitacoesgenericas.form.controller';

export class MeurhSolicitacoesgenericasFormComponent implements angular.IComponentOptions {
	static selector = 'meurhSolicitacoesgenericasFormComponent';
	static controller = MeurhSolicitacoesgenericasFormController;
	static template = require('!!html-loader!./solicitacoesgenericas.form.html');
	static transclude = true;
	static bindings = {
		entity: '=',
		form: '<',
		busy: '=',
		action: '<',
	};
}
